export default {
  'assessment.title': 'Değerlendirme Listesi',
  'assessment.obj': 'Değerlendirme',
  'assessment.subtitle': 'Değerlendirmelerinizi yönetin',
  'assessment.emptySubtitle':
    'Bu birim veya ders için ilk değerlendirmeyi oluşturun',
  'assessment.name': 'DEĞERLENDİRME ADI',
  'assessment.dateCreated': 'EKLENME TARİHİ',
  'assessment.type': 'TÜR',
  'assessment.category': 'KATEGORİ',
  'assessment.status': 'Durum',
  'assessment.actions': 'İşlemler',
  'assessment.results': 'Sonuçlar',
  'assessment.edit': 'Düzenle',
  'assessment.preview': 'Sınav Görünümü',
  'assessment.duration': 'Süre',
  'assessment.dates': 'Başlama & Bitiş Tarihi',
  'assessment.booklets': 'Tüm kitapçıkları indir',
  'assessment.downloadClassicPaper': 'Kağıdı indir',
  'assessment.performPaperBasedClassic': 'Değerlendir',
  'assessment.cancel': 'Sınavı İptal Et',
  'assessment.reasonInfo':
    'Lütfen sınavı neden iptal etmek istediğinizi bir gerekçe girin. Bu gerekçe sınava girecek adaylara duyurulmak için kullanılacaktır.',
  'assessment.delete': 'Sınavı Sil',
  'assessment.importOmr': 'Optik yanıtlarının .csv dosyasını içe aktarın',
  'assessment.search': 'Ara',
  'assessment.add': 'Değerlendirme Oluştur',
  'assessment.takeExam': 'Sınavı Gerçekleştir',
  'assessment.switchInfo':
    'Süresi dolmuş ve geçersiz kılınan değerlendirmeleri de göster',
  'assessment.deleteConfirmationTitle': 'Değerlendirmeyi sil',
  'assessment.deleteConfirmationMessage':
    'Değerlendirmeyi silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'assessment.deleteConfirmationConfirmButton': 'Evet, değerlendirmeyi sil',
  'assessment.deleteConfirmationCancelButton': 'İptal',
  'assessment.languageConfirmationTitle': 'Dili değiştir',
  'assessment.languageConfirmationMessage':
    'Dili değiştirmek istediğinize emin misiniz? Yaptığınız değişiklikler kaybolacaktır.',
  'assessment.languageConfirmationConfirmButton': 'Evet, dili değiştir',
  'assessment.addSuccess': 'Değerlendirme başarıyla oluşturuldu!',
  'assessment.editSuccess': 'Değerlendirme başarıyla güncellendi!',
  'assessment.deleteSuccessAlert': 'Değerlendirme başarıyla silindi!',
  'assessment.cancelSuccessAlert': 'Değerlendirme başarıyla iptal edildi!',
  'assessment.quitMessage':
    'Değişiklikleriniz silinecek. Çıkmak istediğinizden emin misiniz?',
  'assessment.titleInformation':
    'Hay Aksi! Henüz {titleValue} bulunmamaktadır.',
  'assessment.messageInformation':
    'Organizasyonunuzun bu birim veya dersinde henüz {titleValue} bulunmamaktadır.',
  'assessment.messageInformationAdd':
    "Examod'da bulunan {titleValue} türleri ile başlayın!",
  'assessment.PAPER_BASED': 'Kağıt Tabanlı',
  'assessment.PAPER_BASEDsubtitle': 'Adaylarınızı kağıt tabanlı değerlendirin',
  'assessment.PAPER_BASEDtooltip':
    "Kağıt Tabanlı değerlendirme türü aracılığıyla çoktan seçmeli soru türü kullanılarak  farklı kitapçık türlerinde kağıt tabanlı sınavlar oluşturulabilir. Adaylara ait optik kağıtlar Examod'a aktarılarak sonuçlar dijitalleştirilir.",
  'assessment.ON_SITE': 'Yerinde / Test Merkezinde',
  'assessment.ON_SITEsubtitle':
    'Adaylarınızı yerinde veya test merkezinde değerlendirin',
  'assessment.ON_SITEtooltip':
    "Yerinde / Test Merkezinde değerlendirme türü aracılığıyla Examod'da yer alan farklı soru türleri kullanılarak sınavlar oluşturulabilir. Kaydet ve İncele, Kilitle ve İncele veya Gözetimsiz seçeneklerinin yer aldığı gözetim türleri ile adaylar güvenli bir şekilde uzaktan değerlendirilebilir.",
  'assessment.PERFORMANCE_EXAM': 'Performans',
  'assessment.PERFORMANCE_EXAMsubtitle':
    'Adaylarınızı dereceli puanlama anahtarları aracılığıyla değerlendirin',
  'assessment.PERFORMANCE_EXAMtooltip':
    'Performans değerlendirme türü aracılığıyla dereceli puanlama anahtarı kullanılarak aday performansları değerlendirilebilir. Aday performanslarına, dereceli puanlama anahtarına ek olarak sözlü ve yazılı geri bildirimler verilebilir.',
  'assessment.ONLINE': 'Çevrim İçi',
  'assessment.ONLINEsubtitle': 'Adaylarınızı uzaktan değerlendirin',
  'assessment.ONLINEtooltip':
    "Çevrim İçi değerlendirme türü aracılığıyla Examod'da yer alan farklı soru türleri kullanılarak sınavlar oluşturulabilir. Kaydet ve İncele, Kilitle ve İncele veya Gözetimsiz seçeneklerinin yer aldığı gözetim türleri ile adaylar güvenli bir şekilde uzaktan değerlendirilebilir.",
};
