export default {
  'blueprint.build': 'Build Blueprint',
  'blueprint.selectedQuestionInfo':
    '{questionCount} questions were selected from {unitCount} different units, covering {topicCount} topics.',
  'blueprint.notBuild': 'Blueprint Not Builded',
  'blueprint.buildInfo': 'Click (+) to generate the blueprint for the exam.',
  'blueprint.buildBlueprint': 'Build',
  'blueprint.reset': 'Reset',
  'blueprint.select': 'Select...',
  'blueprint.row': 'Table Row',
  'blueprint.rowInfo':
    'The parameter selected here determines the data to be included in the rows when creating the build bueprint.',
  'blueprint.column': 'Table Column',
  'blueprint.columnInfo':
    'The parameter selected here determines the data to be included in the column when creating the build bueprint.',
  'blueprint.TOPIC': 'Topics',
  'blueprint.LEARNING_OBJECTIVE': 'Learning Objectives',
  'blueprint.unitCourse': 'Unit / Course',
  'blueprint.questionFields': 'Question Attribute',
  'blueprint.required': 'This field cannot be blank!',
  'blueprint.unclassified': 'Unclassified',
  'blueprint.total': 'Total Question',
  'blueprint.percentage': 'Percentage',
  'blueprint.per': '{param}%',
  'blueprint.questionLetter': 'Q{index}',
};
