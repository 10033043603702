import { history } from './history';
import { PATHS } from './navigation/Paths';

const CONTROLLERS = {
  account: '/account',
  settings: '/settings',
  structuralSettings: '/structural-settings',
  profile: '/profile',
  users: '/users',
  userGroups: '/user-groups',
  roles: '/roles',
  questions: '/questions',
  exams: '/exams',
  pages: '/pages',
  invite: '/invite',
  myCourses: '/my-courses',
};

export const navigateToDashboard = () => {
  history.push(PATHS.dashboard);
};

export const navigateToAccount = () => {
  history.push(PATHS.account);
};

export const navigateToInternalError = () => {
  history.push(PATHS.errorInternal);
};

export const navigateToUnknownError = () => {
  history.push(PATHS.unknownError);
};
export const navigateToFeBasedError = () => {
  history.push(PATHS.feBasedError);
};
export const navigateToAboutUs = () => {
  history.push(PATHS.about);
};

export const navigateToLogin = () => {
  history.push(PATHS.login);
};
export const navigateToSetPassword = (currentPassword) => {
  history.push(PATHS.setPassword, {
    currentPassword: currentPassword,
  });
};

export const navigateToInvite = () => {
  history.push(PATHS.invitePreview);
};

export const navigateToNotAuthorised = () => {
  history.push(PATHS.errorNotAuthorised);
};

export const navigateToResourceNotFound = () => {
  history.push(PATHS.errorResourceNotFound);
};

export const navigateToRoles = () => {
  history.push(PATHS.roles);
};

export const navigateToUserDetail = (userId) => {
  history.push(`${CONTROLLERS.users}/${userId}`);
};

export const navigateToEditUserDetail = (userId) => {
  history.push(`${CONTROLLERS.users}/${userId}/edit`);
};

export const navigateToUsers = () => {
  history.push(CONTROLLERS.users);
};

export const navigateToUserGroupList = () => {
  history.push(PATHS.userGroups);
};

export const navigateToListUsersInGroup = (groupId) => {
  history.push(`${CONTROLLERS.userGroups}/${groupId}/user-list`);
};

export const navigateToPeriods = () => {
  history.push(PATHS.periods);
};

export const navigateToAddPeriod = () => {
  history.push(PATHS.addPeriod);
};

export const navigateToEditPeriod = (periodId) => {
  history.push(`${CONTROLLERS.structuralSettings}/periods/${periodId}/edit`);
};

export const navigateToAttributes = () => {
  history.push(PATHS.attributes);
};

export const navigateToAddQuestion = (unitId, page) => {
  if (!page) {
    page = 0;
  }
  history.push({
    pathname: `${CONTROLLERS.questions}/add/unit/${unitId}`,
    search: `?page=${page}`,
  });
};

export const navigateToUnitsUsers = (unitId) => {
  history.push(
    `${CONTROLLERS.structuralSettings}/units/${unitId}/manage-users`
  );
};

export const navigateToMyCourses = () => {
  history.push(PATHS.myCourses);
};

export const navigateToCourse = (id, tab, page) => {
  if (!page) {
    page = 0;
  }
  if (!tab) {
    tab = 'assessment';
  }
  history.push({
    pathname: `${CONTROLLERS.myCourses}/${id}/${tab}`,
    search: `?page=${page}`,
  });
};

export const navigateToExams = (tab) => {
  history.push(`${CONTROLLERS.structuralSettings}/exams/${tab}`);
};

export const navigateToAddAssessment = (unitId) => {
  history.push(`${CONTROLLERS.myCourses}/${unitId}/assessment/add`);
};

export const navigateToUnits = () => {
  history.push(`${CONTROLLERS.structuralSettings}/units`);
};

export const navigateToAssessmentResults = (unitId, assessmentId) => {
  history.push(
    `${CONTROLLERS.myCourses}/${unitId}/assessment/${assessmentId}/results`
  );
};

export const navigateToAssessmentStatistics = (unitId, assessmentId, type) => {
  history.push({
    pathname: `${CONTROLLERS.myCourses}/${unitId}/assessment/${assessmentId}/results/statistics`,
    search: `?type=${type}`,
  });
};

export const navigateToPerformanceAssessment = (unitId, assessmentId) => {
  history.push(
    `${CONTROLLERS.myCourses}/${unitId}/assessment/perform/${assessmentId}`
  );
};

export const navigateToCandidateReport = (
  unitId,
  assessmentId,
  candidateId
) => {
  history.push(
    `${CONTROLLERS.myCourses}/${unitId}/assessment/${assessmentId}/results/${candidateId}/report`
  );
};
export const navigateToProctoringReport = (
  unitId,
  assessmentId,
  candidateId
) => {
  history.push(
    `${CONTROLLERS.myCourses}/${unitId}/assessment/${assessmentId}/results/${candidateId}/proctoring/report`
  );
};

export const navigateToOmrProcess = (unitId, assessmentId) => {
  history.push(
    `${CONTROLLERS.myCourses}/${unitId}/assessment/${assessmentId}/omr-process`
  );
};
export const navigateToTicket = () => {
  history.push(PATHS.newTicket);
};

export const navigateToAddRubric = (unitId) => {
  history.push(`${CONTROLLERS.myCourses}/${unitId}/rubrics/create`);
};

export const navigateToEditRubric = (unitId, rubricId) => {
  history.push(`${CONTROLLERS.myCourses}/${unitId}/rubrics/${rubricId}/edit`);
};

export const navigateToCopyRubric = (unitId, rubricId) => {
  history.push(`${CONTROLLERS.myCourses}/${unitId}/rubrics/${rubricId}/copy`);
};

export const navigateToEditAssessment = (unitId, assessmentId) => {
  history.push(
    `${CONTROLLERS.myCourses}/${unitId}/assessment/edit/${assessmentId}`
  );
};

export const navigateToProfile = () => {
  history.push(CONTROLLERS.profile);
};
export const navigateToChangePassword = () => {
  history.push(`${CONTROLLERS.profile}/change-password`);
};

export const navigateToUserImport = () => {
  history.push(`${CONTROLLERS.users}/import`);
};

export const navigateToAddAttribute = () => {
  history.push(PATHS.addAttribute);
};

export const navigateToPerformaperBasedClassic = (unitId, assessmentId) => {
  history.push(
    `${CONTROLLERS.myCourses}/${unitId}/assessment/classic/${assessmentId}`
  );
};
