// ** React Imports
import React, { useState, useEffect } from 'react';
// ** Third Party Components
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Trash, MoreVertical, Plus } from 'react-feather';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';

// ** Custom Components
import { MiniEditor } from '../../../components/react-draft/index';
import ExamodEditor from '../../../components/examod/editor/ExamodEditor';
import NoOption from '../../../components/examod/emptyList/NoOption';
import AnswerCheckbox from '../../../components/examod/checkbox/AnswerCheckbox';
import ProgressBar from '../../../components/examod/progress/ProgressBar';
import QuestionAttributes from '../QuestionAttributes';
import DropzoneContent from '../attachment/DropzoneContent';
import AttachmentFileList from '../attachment/AttachmentFileList';
import Spinner from '../../../components/@vuexy/spinner/Loading-spinner';

// ** Utility imports
import { selectThemeColors } from '../../../utility/themeUtil';
import { checkResult } from '../../../utility/resultHandler';
import { toastError } from '../../../utility/toastUtil';
import { isNullOrEmpty } from '../../../utility/collectionUtil';

// ** Domain Imports
import {
  acceptedAttachmentExtensions,
  attachmentUploadingContinue,
  getAttachmentName,
  getSeperatedFileName,
  getTotalAttachmentSize,
  getUploadedFiles,
  handleZoomOptionImg,
  isOptionEmpty,
  isOptionExceedLimit,
  isQuestionOptionInvalid,
  MAX_NUMBER_OF_QUESTION_OPTION,
  maxAttachementFileSize,
  onDropRejected,
} from '../../../domain/Question';
import { attachementFileTypes } from '../../../domain/Question';

// ** Repository
import { uploadAttachement } from '../../../data/QuestionRepository';
import { addTopic } from '../../../data/TopicRepository';

// ** Config Imports
import { MAX_UPLOAD_LIMIT_MB } from '../../../configs/systemConfig';
import {
  emptyState,
  hasQuestionContentError,
  mcqInputFields,
} from '../../../domain/GroupedQuestion';

// ** Styles
import '../../../assets/scss/react/libs/react-select/_react-select.scss';
import '../../../assets/scss/plugins/extensions/editor.scss';
import '../../../assets/scss/pages/add-question.scss';
import '../../../assets/scss/components/editor.scss';
import ImageZoomModal from '../../../components/examod/sessionPreview/ImageZoomModal';

const McQuestion = ({
  setQuestionFields,
  groupedQuestions,
  activeTab,
  handleQuestionFieldError,
  showQuestionErrors,
  meta,
  unitId,
  fieldRefs,
  resetQuestion,
  setResetQuestion,
  isLoadingMeta,
  loForce,
}) => {
  const intl = useIntl();
  const { register, errors, trigger } = useForm();

  // ** State vars
  const [keywords, setKeywords] = useState(meta.keywords);
  const [topics, setTopics] = useState(meta.topics);
  const [answerId, setAnswerId] = useState(5);
  const [isEditorLengthValid, setIsEditorLengthValid] = useState(true);
  const [isResetAttributes, setIsResetAttributes] = useState(false);
  const [openImageZoomModal, setOpenImageZoomModal] = useState(false);
  const [zoomedImg, setZoomedImg] = useState('');
  const [optionsFocused, setOptionsFocused] = useState({});
  const [inputsTouched, setInputsTouched] = useState(false);
  const [questionContent, setQuestionContent] = useState(
    groupedQuestions[activeTab - 1]
  );
  const [touched, setTouched] = useState(false);
  const [isOptionsInvalid, setIsOptionsInvalid] = useState(false);

  useEffect(() => {
    if (resetQuestion) {
      setResetQuestion(false);
    }
    setIsOptionsInvalid(false);
    setQuestionContent(groupedQuestions[activeTab - 1]);
  }, [activeTab, resetQuestion]);

  useEffect(() => {
    handleAttributeError();
  }, [questionContent.fields, questionContent.attributes]);

  useEffect(() => {
    handleAttachmentError();
  }, [
    questionContent.attachments.files,
    questionContent.attachments.uploadedFiles,
    questionContent.attachments.errors,
  ]);

  useEffect(() => {
    handleZoomOptionImg(onClickQuestionImage, setOpenImageZoomModal);
  }, [questionContent.options]);

  // ** Adds new option
  const addOption = () => {
    if (questionContent.options.length == MAX_NUMBER_OF_QUESTION_OPTION) {
      setIsOptionsInvalid(true);
      toastError(intl.formatMessage({ id: 'questionAdd.addOptionError' }));
      return;
    }
    const newOpt = { id: answerId, content: '' };
    const newOptState = { id: answerId, state: emptyState };

    const newOptions = [...questionContent.options, newOpt];
    const newOptionStates = [...questionContent.optionStates, newOptState];
    setQuestionFields(mcqInputFields.options, newOptions);
    setQuestionFields(mcqInputFields.optionStates, newOptionStates);
    setAnswerId(answerId + 1);
  };

  // ** Deletes the option
  const deleteOption = (index) => {
    let newOptions = JSON.parse(JSON.stringify(questionContent.options));
    let newOptionStates = questionContent.optionStates;
    newOptions.splice(index, 1);
    newOptionStates.splice(index, 1);
    setQuestionFields(mcqInputFields.options, newOptions);
    setQuestionFields(mcqInputFields.optionStates, [...newOptionStates]);
    detectOptionErrors(newOptions);
    setIsOptionsInvalid(false);
  };

  // ** Reorders
  const reorder = (result) => {
    if (!result.destination) {
      return;
    }
    let newAnswers = questionContent.options;
    let newAnswerStates = questionContent.optionStates;
    const [removed] = newAnswers.splice(result.source.index, 1);
    const [removedState] = newAnswerStates.splice(result.source.index, 1);
    newAnswers.splice(result.destination.index, 0, removed);
    newAnswerStates.splice(result.destination.index, 0, removedState);
    setQuestionFields(mcqInputFields.options, newAnswers);
    setQuestionFields(mcqInputFields.optionStates, [...newAnswerStates]);
  };

  // ** Runs when keyword option created
  const onCreateNewKeyword = (newOption) => {
    setKeywords((options) => [...options, { label: newOption }]);
    const newKeywords = [...questionContent.keywords, { label: newOption }];
    setQuestionFields(mcqInputFields.keywords, newKeywords);
  };

  const onCreateTopic = async (option) => {
    let result = await addTopic(option, option, unitId);
    if (checkResult(result)) {
      let newTopic = { value: result.data.id, label: result.data.name };
      setTopics((prev) => [...prev, newTopic]);
      setQuestionFields(mcqInputFields.topic, newTopic);
    }
    if (!option) {
      handleQuestionFieldError(true, mcqInputFields.topic);
    } else {
      handleQuestionFieldError(false, mcqInputFields.topic);
    }
  };

  const detectOptionErrors = (options) => {
    for (let i = 0; i < options.length; i++) {
      const opt = options[i];
      if (isQuestionOptionInvalid(opt.content)) {
        handleQuestionFieldError(true, mcqInputFields.options);
        return;
      }
    }
    handleQuestionFieldError(false, mcqInputFields.options);
  };

  // ** Chnges the answer
  const onChangeAnswer = (value, index, isImage = false) => {
    let newAnswers = JSON.parse(JSON.stringify(questionContent.options));
    newAnswers[index].content = value;
    newAnswers[index].onlyImageAnswer = isImage;
    detectOptionErrors(newAnswers);
    setQuestionFields(mcqInputFields.options, newAnswers);
  };

  const onChangeAnswerState = (state, index) => {
    let newStates = questionContent.optionStates;
    newStates[index].state = state;
    setQuestionFields(mcqInputFields.optionStates, newStates);
  };

  // ** Sort data alphabetically
  const sortAlphabetically = (data) => {
    return data.sort((a, b) => a.label.localeCompare(b.label));
  };

  const onChangeFieldOption = (option, fieldId, index) => {
    let fields = [...questionContent.fields];
    let fieldIndex = fields.findIndex((option) => option.fieldId === fieldId);
    if (fieldIndex < 0) {
      fields.push(option);
    } else {
      fields[fieldIndex] = option;
    }
    setQuestionFields(mcqInputFields.fields, fields);
  };

  const onAttributeChange = (options, data) => {
    if (data.action === 'remove-value') {
      if (data.removedValue) {
        let newSelected = questionContent.fields.filter(
          (field) => field.fieldId !== data.removedValue.value
        );
        setQuestionFields(mcqInputFields.fields, newSelected);
      } else {
        setQuestionFields(mcqInputFields.fields, []);
      }
    } else if (data.action === 'clear') {
      setQuestionFields(mcqInputFields.fields, []);
    }
    setQuestionFields(mcqInputFields.attributes, options ? options : []);
  };

  const handleAttributeError = () => {
    if (questionContent.fields.length !== questionContent.attributes.length) {
      handleQuestionFieldError(true, mcqInputFields.fields);
    } else {
      handleQuestionFieldError(false, mcqInputFields.fields);
    }
  };
  const handleAttachmentError = () => {
    const isAttachmentUploading = attachmentUploadingContinue(
      questionContent.attachments.files,
      questionContent.attachments.uploadedFiles,
      questionContent.attachments.errors,
      questionContent.attachments.canceled
    );
    if (
      isAttachmentUploading ||
      !isNullOrEmpty(questionContent.attachments.errors)
    ) {
      handleQuestionFieldError(true, mcqInputFields.attachments);
    } else {
      handleQuestionFieldError(false, mcqInputFields.attachments);
    }
  };

  const formatCreateLabel = (inputValue) => (
    <>
      <FormattedMessage id={'questionAdd.create'} /> "{inputValue}"
    </>
  );

  const onClickQuestionImage = (imgInfo) => {
    setZoomedImg(imgInfo);
    setOpenImageZoomModal(true);
  };

  const toggleImageZoom = () => {
    setOpenImageZoomModal((prev) => !prev);
  };

  const handleAttachmentUpload = async (files, id, retriedId) => {
    let tempId = id;
    const uploadedFiles = [];
    const errors = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      const [fileName, extension] = getSeperatedFileName(file.name);
      const result = await uploadAttachement(
        extension.toUpperCase(),
        getAttachmentName(fileName),
        formData
      );
      if (checkResult(result)) {
        const uploadResult = { ...result.data, tempId: retriedId || tempId };
        uploadedFiles.push(uploadResult);
      } else {
        errors.push({ file: file, tempId: retriedId || tempId });
      }
      tempId++;
    }
    setQuestionFields(mcqInputFields.attachments, {
      uploadedFiles: [
        ...questionContent.attachments.uploadedFiles,
        ...uploadedFiles,
      ],
      errors: [...questionContent.attachments.errors, ...errors],
    });
  };

  // ** On file drop to the attachment dropzone
  const onFileDrop = (files) => {
    const attachments = questionContent.attachments;
    const droppedFilesSize = files.reduce(
      (prev, current) => prev + current.size,
      0
    );
    if (
      getTotalAttachmentSize(attachments.files) + droppedFilesSize >
      maxAttachementFileSize
    ) {
      toastError(
        intl.formatMessage({ id: 'questionAdd.attachmentSizeWarning' })
      );
      return;
    }
    let tempId = attachments.tempId;
    const tempAttachments = [];
    for (const file of files) {
      const [fileName, extension] = getSeperatedFileName(file.name);
      const generalType = attachementFileTypes[file.type.split('/')[0]];
      const fileInfo = {
        name: getAttachmentName(fileName),
        size: file.size,
        generalType: generalType,
        type: `${generalType}/${extension}`.toLowerCase(),
        tempId: tempId,
      };
      tempAttachments.push(fileInfo);
      tempId++;
    }
    setQuestionFields(mcqInputFields.attachments, {
      files: [...attachments.files, ...tempAttachments],
      tempId: tempId,
    });
    handleAttachmentUpload(files, tempId - tempAttachments.length);
  };

  // ** Removes attachment item from all related arrays
  const removeAttachmentItem = (file) => {
    const attachments = questionContent.attachments.files.filter(
      (attc) => attc.tempId !== file.tempId
    );
    const uploadedFiles = questionContent.attachments.uploadedFiles.filter(
      (attc) => attc.tempId !== file.tempId
    );
    const errors = questionContent.attachments.errors.filter(
      (attc) => attc.tempId !== file.tempId
    );
    setQuestionFields(mcqInputFields.attachments, {
      files: attachments,
      uploadedFiles: uploadedFiles,
      errors: errors,
    });
  };

  const retryUpload = async (fileTempId) => {
    const attachmentErrors = questionContent.attachments.errors;
    const retriedFile = attachmentErrors.find((err) => {
      return err.tempId === fileTempId;
    });
    setQuestionFields(mcqInputFields.attachments, {
      errors: attachmentErrors.filter((err) => err.tempId !== fileTempId),
    });
    handleAttachmentUpload([retriedFile.file], null, retriedFile.tempId);
  };

  const cancelAttachmentUpload = (file) => {
    const attachments = questionContent.attachments.files.filter(
      (attc) => attc.tempId !== file.tempId
    );
    const uploadedFiles = questionContent.attachments.uploadedFiles.filter(
      (attc) => attc.tempId !== file.tempId
    );
    const canceled = [...questionContent.attachments.canceled, file];
    setQuestionFields(mcqInputFields.attachments, {
      files: attachments,
      uploadedFiles: uploadedFiles,
      canceled: canceled,
    });
  };

  const onFocusOption = (index) => {
    const questionId = questionContent.options[index].id;
    setOptionsFocused((prev) => ({ ...prev, [questionId]: true }));
  };

  if (isLoadingMeta) {
    return <Spinner />;
  }

  return (
    <>
      <div
        ref={(el) => {
          fieldRefs.current[0] = el;
        }}
      >
        <Card className="add-question-question-content-card h-100">
          <CardBody className="pt-0">
            <CardHeader className="d-block pl-0 mb-2">
              <CardTitle>
                <FormattedMessage id={'questionAdd.questionContentTitle'} />
              </CardTitle>
              <CardSubtitle className="mt-0">
                <FormattedMessage id={'questionAdd.questionContentSubtitle'} />
              </CardSubtitle>
            </CardHeader>
            <div id="user-profile">
              <div id="profile-info">
                <Row className="d-flex justify-content-between">
                  <Col sm="12">
                    <FormGroup>
                      <Label>
                        <span className="text-danger">*</span>
                        <FormattedMessage id={'questionAdd.topics'} />{' '}
                      </Label>
                      <CreatableSelect
                        formatCreateLabel={formatCreateLabel}
                        isClearable
                        value={questionContent.topic}
                        theme={selectThemeColors}
                        placeholder={intl.formatMessage({
                          id: 'questionAdd.topicPlaceholder',
                        })}
                        className={`basic-single, react-select ${
                          showQuestionErrors &&
                          isNullOrEmpty(questionContent.topic)
                            ? 'is-invalid'
                            : ''
                        }`}
                        classNamePrefix="select"
                        options={topics}
                        noOptionsMessage={NoOption}
                        onChange={(option) => {
                          setQuestionFields(mcqInputFields.topic, option);
                          if (!option) {
                            handleQuestionFieldError(
                              true,
                              mcqInputFields.topic
                            );
                          } else {
                            handleQuestionFieldError(
                              false,
                              mcqInputFields.topic
                            );
                          }
                        }}
                        onCreateOption={(newOption) => {
                          onCreateTopic(newOption);
                        }}
                      />
                      {showQuestionErrors &&
                      isNullOrEmpty(questionContent.topic) ? (
                        <span className="text-error">
                          {intl.formatMessage({
                            id: 'questionAdd.requiredMessage',
                          })}
                        </span>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label>
                        <span className="text-danger"> *</span>
                        <FormattedMessage id={'questionAdd.root'} />
                      </Label>
                      <div
                        ref={(el) => {
                          fieldRefs.current[1] = el;
                        }}
                      >
                        <ExamodEditor
                          handleZoomImg={() =>
                            handleZoomOptionImg(
                              onClickQuestionImage,
                              setOpenImageZoomModal
                            )
                          }
                          innerRef={register({
                            required: true,
                          })}
                          editorClassName={classnames({
                            'examod-editor': true,
                            'border-error':
                              hasQuestionContentError(
                                questionContent.questionRoot,
                                'max'
                              ) ||
                              (showQuestionErrors &&
                                hasQuestionContentError(
                                  questionContent.questionRoot,
                                  'min'
                                )),
                            invalid:
                              hasQuestionContentError(
                                questionContent.questionRoot,
                                'max'
                              ) ||
                              (showQuestionErrors &&
                                hasQuestionContentError(
                                  questionContent.questionRoot,
                                  'min'
                                )),
                          })}
                          setText={(text) => {
                            setQuestionFields(
                              mcqInputFields.questionRoot,
                              text
                            );
                            if (
                              hasQuestionContentError(
                                questionContent.questionRoot
                              )
                            ) {
                              handleQuestionFieldError(
                                true,
                                mcqInputFields.questionRoot
                              );
                            } else {
                              handleQuestionFieldError(
                                false,
                                mcqInputFields.questionRoot
                              );
                            }
                          }}
                          editorState={questionContent.rootEditorState}
                          setIsEditorLengthValid={setIsEditorLengthValid}
                          setEditorState={(state) => {
                            setQuestionFields(
                              mcqInputFields.rootEditorState,
                              state
                            );
                          }}
                        />
                      </div>
                      {hasQuestionContentError(
                        questionContent.questionRoot,
                        'max'
                      ) ? (
                        <span className="text-error">
                          {intl.formatMessage({
                            id: 'questionAdd.editorMaxMessage',
                          })}
                        </span>
                      ) : null}
                      {showQuestionErrors &&
                      hasQuestionContentError(
                        questionContent.questionRoot,
                        'min'
                      ) ? (
                        <span className="text-error">
                          {intl.formatMessage({
                            id: 'questionAdd.editorMinMessage',
                          })}
                        </span>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg="9">
                    <FormGroup>
                      <Label>
                        <span className="text-danger"> *</span>
                        <FormattedMessage id={'questionAdd.options'} />
                        <span>
                          <i>
                            <FormattedMessage
                              id={'questionAdd.correctOption'}
                            />
                          </i>
                        </span>
                      </Label>
                      <DragDropContext onDragEnd={reorder}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              ref={(el) => {
                                provided.innerRef(el);
                                fieldRefs.current[2] = el;
                              }}
                            >
                              {questionContent.options.map((answer, index) => {
                                return (
                                  <div key={answer.id} className="mb-50">
                                    <Draggable
                                      key={answer.id}
                                      draggableId={answer.id.toString()}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className="option align-items-center"
                                        >
                                          <InputGroup className="option-group">
                                            <InputGroupAddon addonType="prepend">
                                              <AnswerCheckbox
                                                checked={
                                                  questionContent.correctOption ===
                                                  answer.id
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setQuestionFields(
                                                      mcqInputFields.correctOption,
                                                      answer.id
                                                    );
                                                    handleQuestionFieldError(
                                                      false,
                                                      mcqInputFields.correctOption
                                                    );
                                                  } else {
                                                    setQuestionFields(
                                                      mcqInputFields.correctOption,
                                                      null
                                                    );
                                                    handleQuestionFieldError(
                                                      true,
                                                      mcqInputFields.correctOption
                                                    );
                                                  }
                                                }}
                                                bsSize="medium"
                                                text={String.fromCharCode(
                                                  97 + index
                                                ).toUpperCase()}
                                              />
                                            </InputGroupAddon>
                                            <MiniEditor
                                              containerClassName={classnames({
                                                'border-success':
                                                  questionContent.correctOption ===
                                                  answer.id,
                                                'is-invalid':
                                                  (touched &&
                                                    showQuestionErrors &&
                                                    isQuestionOptionInvalid(
                                                      answer.content
                                                    )) ||
                                                  isOptionExceedLimit(
                                                    answer.content
                                                  ),
                                              })}
                                              isImage={answer.onlyImageAnswer}
                                              textValue={answer.content}
                                              editedState={
                                                questionContent.optionStates[
                                                  index
                                                ].state
                                              }
                                              setEditedState={(state) =>
                                                onChangeAnswerState(
                                                  state,
                                                  index
                                                )
                                              }
                                              onChange={(htmlText, isImage) => {
                                                onChangeAnswer(
                                                  htmlText,
                                                  index,
                                                  isImage
                                                );
                                                setTouched(true);
                                              }}
                                              onFocus={() => {
                                                onFocusOption(index);
                                                setInputsTouched(true);
                                              }}
                                              name={index}
                                            />
                                          </InputGroup>

                                          <span
                                            className="move-icon d-flex ml-25"
                                            {...provided.dragHandleProps}
                                          >
                                            <MoreVertical size={18} />
                                            <MoreVertical size={18} />
                                          </span>
                                          {questionContent.options.length >
                                          3 ? (
                                            <Trash
                                              className="delete-icon ml-25"
                                              size={18}
                                              onClick={() =>
                                                deleteOption(index)
                                              }
                                            />
                                          ) : null}
                                        </div>
                                      )}
                                    </Draggable>
                                    {isOptionExceedLimit(answer.content) && (
                                      <span className="text-error ">
                                        {intl.formatMessage({
                                          id: 'questionAdd.maxMessage',
                                        })}
                                      </span>
                                    )}
                                    {showQuestionErrors &&
                                      isOptionEmpty(answer.content) && (
                                        <span className="text-error">
                                          {intl.formatMessage({
                                            id: 'questionAdd.minMessage',
                                          })}
                                        </span>
                                      )}
                                  </div>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <InputGroup className="mb-50 question-add-area">
                        <InputGroupAddon addonType="prepend">
                          <AnswerCheckbox
                            bsSize="medium"
                            text={<Plus />}
                            onClick={addOption}
                            disabled={isOptionsInvalid}
                          />
                        </InputGroupAddon>
                        <Input
                          className={classnames('add-input-area', {
                            wider: questionContent.options.length <= 3,
                          })}
                          defaultValue={intl.formatMessage({
                            id: 'questionAdd.addOption',
                          })}
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="question-details-card">
          <CardBody className="pt-0">
            <CardHeader className="d-block pl-0 mb-2">
              <CardTitle>
                <FormattedMessage id={'questionAdd.questionDetailsTitle'} />
              </CardTitle>
              <CardSubtitle className="mt-0">
                <FormattedMessage id={'questionAdd.questionDetailsSubtitle'} />
              </CardSubtitle>
            </CardHeader>
            <div id="user-profile">
              <div id="profile-info">
                <Row className="question-tools mb-2">
                  {/* <Col md="6">
                        <FormGroup>
                          <Label>
                            <FormattedMessage
                              id={'questionAdd.addQuestionTools'}
                            />
                          </Label>
                          <div className="d-flex align-items-center mb-1">
                            <ExamodCheckbox disabled />
                            <span>
                              <FormattedMessage
                                id={'questionAdd.scientificCalculator'}
                              />
                            </span>
                          </div>
                          <Label>
                            <div className="text-nowrap">
                              <FormattedMessage id={'questionAdd.duration'} />
                            </div>
                          </Label>
                          <NumberInput
                            set={setDuration}
                            durationOptions={durationOptions}
                            index={duration}
                          />
                        </FormGroup>
                      </Col> */}
                  <Col md="6">
                    <Label className="question-attachment-dropzone-label">
                      <div
                        ref={(el) => (fieldRefs.current[3] = el)}
                        className="text-nowrap"
                      >
                        <FormattedMessage id={'questionAdd.attachments'} />{' '}
                        <span className="attachment-uploaded-file-size-info">
                          (
                          {getTotalAttachmentSize(
                            getUploadedFiles(
                              questionContent.attachments.uploadedFiles,
                              questionContent.attachments.canceled
                            ),
                            true
                          )}{' '}
                          MB / {MAX_UPLOAD_LIMIT_MB} MB)
                        </span>
                      </div>
                    </Label>
                    <ProgressBar
                      end={maxAttachementFileSize}
                      progress={getTotalAttachmentSize(
                        getUploadedFiles(
                          questionContent.attachments.uploadedFiles,
                          questionContent.attachments.canceled
                        )
                      )}
                    />
                    <Dropzone
                      onDrop={onFileDrop}
                      noClick
                      accept={acceptedAttachmentExtensions}
                      maxSize={maxAttachementFileSize}
                      onDropRejected={(files) => onDropRejected(files, intl)}
                    >
                      {({ getRootProps, getInputProps, open }) => (
                        <div>
                          <div
                            {...getRootProps({
                              className: 'dropzone',
                            })}
                          >
                            <input {...getInputProps()} />
                            <DropzoneContent open={open} />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                  <Col md="6">
                    <AttachmentFileList
                      attachments={questionContent.attachments.files}
                      attachmentUploaded={
                        questionContent.attachments.uploadedFiles
                      }
                      attachmentErrors={questionContent.attachments.errors}
                      canceledAttachments={questionContent.attachments.canceled}
                      cancelAttachmentUpload={cancelAttachmentUpload}
                      removeAttachmentItem={removeAttachmentItem}
                      retryUpload={retryUpload}
                    />
                  </Col>
                </Row>
                <Row className="attributes-section mx-n2 px-50 mb-2 pt-1">
                  <Col md="6" sm="12">
                    <Label>
                      <div
                        ref={(el) => {
                          fieldRefs.current[4] = el;
                        }}
                        className="text-nowrap"
                      >
                        <FormattedMessage id={'questionAdd.attributeInfo'} />
                      </div>
                    </Label>
                    <FormGroup>
                      <Select
                        isMulti={true}
                        theme={selectThemeColors}
                        className="select basic-single"
                        classNamePrefix="select"
                        placeholder={intl.formatMessage({
                          id: 'questionAdd.select',
                        })}
                        value={questionContent.attributes}
                        options={meta.attributes}
                        onChange={(options, data) =>
                          onAttributeChange(options, data)
                        }
                        noOptionsMessage={NoOption}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12" className="pr-0 pr-md-1">
                    <FormGroup>
                      <QuestionAttributes
                        isSubmitted={showQuestionErrors}
                        selectedAttributes={questionContent.attributes}
                        selectedFields={questionContent.fields}
                        onChangeFieldOption={onChangeFieldOption}
                        isReset={isResetAttributes}
                        setIsReset={setIsResetAttributes}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <FormattedMessage id={'questionAdd.keywords'} />
                      </Label>
                      <CreatableSelect
                        formatCreateLabel={formatCreateLabel}
                        isMulti
                        theme={selectThemeColors}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={intl.formatMessage({
                          id: 'questionAdd.select',
                        })}
                        options={sortAlphabetically(keywords)}
                        value={questionContent.keywords}
                        onChange={(options) => {
                          setQuestionFields(mcqInputFields.keywords, options);
                        }}
                        onCreateOption={(newOption) => {
                          onCreateNewKeyword(newOption);
                        }}
                        noOptionsMessage={NoOption}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <div
                      ref={(el) => {
                        fieldRefs.current[6] = el;
                      }}
                    >
                      <FormGroup>
                        <Label>
                          {loForce ? (
                            <span className="text-danger"> *</span>
                          ) : null}
                          <FormattedMessage id={'questionAdd.objectives'} />
                        </Label>
                        <Select
                          isClearable
                          theme={selectThemeColors}
                          className={classnames('basic-single react-select', {
                            'is-invalid':
                              loForce &&
                              showQuestionErrors &&
                              isNullOrEmpty(questionContent.learningObj),
                          })}
                          classNamePrefix="select"
                          placeholder={intl.formatMessage({
                            id: loForce
                              ? 'questionAdd.select'
                              : 'questionAdd.uncategorized',
                          })}
                          options={meta.learningObj}
                          value={questionContent.learningObj}
                          onChange={(option) => {
                            setQuestionFields(
                              mcqInputFields.learningObj,
                              option
                            );
                            if (!option && loForce) {
                              handleQuestionFieldError(
                                true,
                                mcqInputFields.learningObj
                              );
                            } else {
                              handleQuestionFieldError(
                                false,
                                mcqInputFields.learningObj
                              );
                            }
                          }}
                          noOptionsMessage={NoOption}
                        />
                        {showQuestionErrors &&
                        isNullOrEmpty(questionContent.learningObj) &&
                        loForce ? (
                          <span className="text-error">
                            {intl.formatMessage({
                              id: 'questionAdd.requiredMessage',
                            })}
                          </span>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col sm="12">
                    <div
                      ref={(el) => {
                        fieldRefs.current[5] = el;
                      }}
                    >
                      <FormGroup>
                        <Label>
                          <FormattedMessage id={'questionAdd.source'} />
                        </Label>
                        <Input
                          type="textarea"
                          name="answerSource"
                          value={questionContent.source}
                          innerRef={register({
                            maxLength: 200,
                          })}
                          onChange={(e) => {
                            setQuestionFields(
                              mcqInputFields.source,
                              e.target.value
                            );
                            if (e.target.value.length > 200) {
                              handleQuestionFieldError(
                                true,
                                mcqInputFields.source
                              );
                            } else {
                              handleQuestionFieldError(
                                false,
                                mcqInputFields.source
                              );
                            }
                          }}
                          className={classnames({
                            'is-invalid': errors['answerSource'],
                          })}
                          onInput={() => trigger('answerSource')}
                        />
                        {errors?.answerSource?.type === 'maxLength' && (
                          <span className="text-error">
                            {intl.formatMessage({
                              id: 'questionAdd.answerSourceMax',
                            })}
                          </span>
                        )}
                        <div className="mt-75">
                          <i>
                            <FormattedMessage id={'questionAdd.required'} />
                          </i>
                        </div>
                      </FormGroup>{' '}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <ImageZoomModal
        src={zoomedImg.src}
        isOpen={openImageZoomModal}
        toggle={toggleImageZoom}
        width={zoomedImg.width}
        height={zoomedImg.height}
      />
    </>
  );
};

export default McQuestion;
