import { getLang } from '../data/UserLocalDataSource';
import { isEmpty } from '../utility/collectionUtil';
import { supportedLanguages } from '../utility/context/Internationalization';

// ** Returns a localized number
export const getLocalizedNumber = (data) => {
  const lang = getLang();
  return lang === supportedLanguages[1].locale && data
    ? data.toString().replace('.', ',')
    : data || 0;
};

// ** Returns a localized double
export const getLocalizedDouble = (data) => {
  const lang = getLang();
  return lang === supportedLanguages[1].locale && data
    ? data.toFixed(2).toString().replace('.', ',')
    : data.toFixed(2) || 0;
};

export const registerDevice = 'registerDevice';

// ** Editable Field Statuses
export const editStatus = {
  READ_WRITE: 'READ_WRITE',
  READ_ONLY: 'READ_ONLY',
};

// ** Statuses
export const generalStatuses = {
  ACTIVE: 'ACTIVE',
  PASSIVE: 'PASSIVE',
};

export const selectActionTypes = {
  selectOption: 'select-option',
  removeValue: 'remove-value',
  clear: 'clear',
};

export const badgeColors = [
  'green-badge',
  'purple-badge',
  'orange-badge',
  'pink-badge',
  'cyan-badge',
  'yellow-badge',
  'navy-blue-badge',
  'grey-badge',
];

// ** Get status text
export const getStatusText = (bool) =>
  bool ? generalStatuses.ACTIVE : generalStatuses.PASSIVE;

// ** Get status text
export const getStatusIsIncludePassive = (bool) =>
  bool
    ? `${generalStatuses.ACTIVE},${generalStatuses.PASSIVE}`
    : generalStatuses.ACTIVE;

// ** Get active items
export const getActiveItems = (items) =>
  isEmpty(items)
    ? []
    : items.filter((item) => item.status === generalStatuses.ACTIVE);

export const onOpenOrgSite = (website) => {
  window.open(website, '_blank');
};

export const preventSubmitOnEnter = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};
// ** Get sortDirections
export const sortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};
