export default {
  'periodList.header': 'Periods',
  'periodList.name': 'Period Name',
  'periodList.title': 'Period Management',
  'periodList.subtitle': 'Design the period structure of your organization',
  'periodList.unit': 'Main Unit',
  'periodList.start': 'Start Date',
  'periodList.end': 'End Date',
  'periodList.elapsedTime': 'Elapsed Time',
  'periodList.status': 'Status',
  'periodList.actions': 'Actions',
  'periodList.add': 'Add Period',
  'periodList.edit': 'Edit',
  'periodList.delete': 'Delete',
  'periodList.search': 'Search',
  'periodList.visible': 'Visible',
  'periodList.invisible': 'Invisible',
  'periodList.deleteConfirmationTitle': 'Delete period',
  'periodList.deleteConfirmationMessage':
    'Are you sure to delete the period? This operation cannot be reversed.',
  'periodList.deleteConfirmationConfirmButton': 'Yes, delete period',
  'periodList.deleteAlert': 'Period has been successfully deleted.',
  'periodList.showPassiveStatus': 'Show completed periods',
  'periodList.progress': 'In progress',
  'periodList.completed': 'Completed',
  'periodList.startAndEnd': 'Start & End Date',
  'periodList.emptySubtitle':
    'Design the unit and course structure of your organization',
  'periodList.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'periodList.messageInformation':
    'There is no {titleValue} definition for your organization yet.',
  'periodList.messageInformationAdd':
    'Click the {messageValue} button to add {titleValue}.',
  'periodList.obj': 'Period',
  'periodList.addPeriod': '"Add Period"',
};
