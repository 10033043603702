export default {
  'learningObjPopup.title': '{param} Learning Objectives Settings',
  'learningObjPopup.nameHint': 'Type learning objective name...',
  'learningObjPopup.subTitle': 'LEARNING OBJECTIVE',
  'learningObjPopup.subTitleActions': 'ACTIONS',
  'learningObjPopup.moveButton': 'Move',
  'learningObjPopup.editButton': 'Edit',
  'learningObjPopup.deleteButton': 'Delete',
  'learningObjPopup.foundAlert': "Couldn't found any learning objectives",
  'learningObjPopup.deleteAlert':
    'Learning objective has been successfully deleted.',
  'learningObjPopup.updateAlert':
    'Learning objective has been successfully updated.',
  'learningObjPopup.moveAlert':
    'Learning objectives has been successfully moved.',
  'learningObjPopup.reset': 'reset',
  'learningObjPopup.deleteConfirmationTitle': 'Delete learning objective',
  'learningObjPopup.deleteConfirmationMessage':
    'Are you sure to delete the learning objective? This operation cannot be reversed.',
  'learningObjPopup.deleteConfirmationConfirmButton':
    'Yes, delete learning objective',
  'learningObjPopup.deleteConfirmationCancelButton': 'Cancel',
  'learningObjPopup.minMessage': 'Name should be at least 5 characters.',
  'learningObjPopup.maxMessage': 'Name should be max 30 characters.',
  'learningObjPopup.requiredMessage': 'This field cannot be blank.',
};
