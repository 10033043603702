export default {
  // Generic
  'generic.add': 'Ok',
  'generic.cancel': 'Annuler',

  // BlockType
  'components.controls.blocktype.h1': 'Titre 1',
  'components.controls.blocktype.h2': 'Titre 2',
  'components.controls.blocktype.h3': 'Titre 3',
  'components.controls.blocktype.h4': 'Titre 4',
  'components.controls.blocktype.h5': 'Titre 5',
  'components.controls.blocktype.h6': 'Titre 6',
  'components.controls.blocktype.blockquote': 'Citation',
  'components.controls.blocktype.code': 'Code',
  'components.controls.blocktype.blocktype': 'Type bloc',
  'components.controls.blocktype.normal': 'Normal',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Palette de couleur',
  'components.controls.colorpicker.text': 'Texte',
  'components.controls.colorpicker.background': 'Fond',

  // Embedded
  'components.controls.embedded.embedded': 'Embedded',
  'components.controls.embedded.embeddedlink': 'Lien iFrame',
  'components.controls.embedded.enterlink': 'Entrer le lien',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Police',

  // FontSize
  'components.controls.fontsize.fontsize': 'Taille de police',

  // History
  'components.controls.history.history': 'Historique',
  'components.controls.history.undo': 'Précédent',
  'components.controls.history.redo': 'Suivant',

  // Image
  'components.controls.image.image': 'Image',
  'components.controls.image.fileUpload': 'Téléchargement',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText':
    'Glisser une image ou cliquer pour télécharger',

  // Inline
  'components.controls.inline.bold': 'Gras',
  'components.controls.inline.italic': 'Italique',
  'components.controls.inline.underline': 'Souligner',
  'components.controls.inline.strikethrough': 'Barrer',
  'components.controls.inline.monospace': 'Monospace',
  'components.controls.inline.superscript': 'Exposant',
  'components.controls.inline.subscript': 'Indice',

  // Link
  'components.controls.link.linkTitle': 'Titre du lien',
  'components.controls.link.linkTarget': 'Cible du lien',
  'components.controls.link.linkTargetOption':
    'Ouvrir le lien dans une nouvelle fenêtre',
  'components.controls.link.link': 'Lier',
  'components.controls.link.unlink': 'Délier',

  // List
  'components.controls.list.list': 'Liste',
  'components.controls.list.unordered': 'Désordonnée',
  'components.controls.list.ordered': 'Ordonnée',
  'components.controls.list.indent': 'Augmenter le retrait',
  'components.controls.list.outdent': 'Diminuer le retrait',

  // Remove
  'components.controls.remove.remove': 'Supprimer',

  // TextAlign
  'components.controls.textalign.textalign': 'Alignement du texte',
  'components.controls.textalign.left': 'Gauche',
  'components.controls.textalign.center': 'Centre',
  'components.controls.textalign.right': 'Droite',
  'components.controls.textalign.justify': 'Justifier',

  // New Examod localizations
  'components.controls.image.modal.title': 'Image Uploader',
  'components.controls.image.modal.description':
    'Browse or drag the image you want to upload',
  'components.controls.image.modal.content.image.or': 'or',
  'components.controls.image.modal.content.image.btn': 'Browse Files',
  'components.controls.image.modal.content.image.attachment':
    'Attachments should not exceed 2 mb',
  'components.controls.image.modal.content.image.supported':
    'Supported file types: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt Text',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Image alt text',
  'components.controls.image.modal.content.input.scale.label': 'Scale (%)',
  'components.controls.image.modal.contnet.url.address.label': '*URL (Adress)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Add image from address',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Clear Highlight',
  'components.controls.color.picker.highlight.black': 'Black Highlight',
  'components.controls.color.picker.highlight.grey': 'Grey Highlight',
  'components.controls.color.picker.highlight.red': 'Red Highlight',
  'components.controls.color.picker.highlight.green': 'Green Highlight',
  'components.controls.color.picker.highlight.blue': 'Blue Highlight',
  'components.controls.color.picker.highlight.yellow': 'Yellow Highlight',
  'components.controls.color.picker.highlight.examod': 'Examod Highlight',
  'components.controls.color.picker.highlight.purple': 'Purple Highlight',
  'components.controls.color.picker.highlight.pink': 'Pink Highlight',
};
