import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL, HEADERS } from '../configs/apiConfig';

export const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: HEADERS,
});

export const setAuthToken = (token) => {
  if (token) {
    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const dropAuthToken = () => {
  delete httpClient.defaults.headers.common.Authorization;
};

export const setLanguage = (lang) => {
  httpClient.defaults.headers.common['X-Language'] = lang.toLowerCase();
};

export const setDeviceId = (id) => {
  let deviceId = id ? id : Cookies.get('device-id');
  httpClient.defaults.headers.common['X-Device-Id'] = deviceId;
};
