export default {
  'userImport.importBtn': 'İçe Aktar',
  'userImport.import': 'İçe aktarma',
  'userImport.importCSV': 'CSV dosyasını içe aktarın',
  'userImport.preview': 'Önizleme',
  'userImport.previewData': 'Verilerin önizlenmesi',
  'userImport.confirmation': 'Onay ve Özet',
  'userImport.confirmationInfo': 'Onay, özet ve bilgiler',
  'userImport.previous': 'Önceki Adım',
  'userImport.next': 'Sonraki Adım',
  'userImport.uploadType': 'Yükleme Türü',
  'userImport.uploadTypeSubtitle':
    'Yükleme türü, içe aktarılacak kullanıcıların nasıl işleneceğini belirtir',
  'userImport.APPEND': 'Yalnızca yeni kullanıcı ekle',
  'userImport.APPEND_Explanation':
    'Yüklenen dosyadaki her kullanıcı için yeni bir kayıt oluşturulur. Yüklenen dosyada mevcut kayıtlı kullanıcı varsa bu kullanıcı yok sayılır.',
  'userImport.OVERWRITE':
    'Yeni kullanıcı ekle ve mevcut kullanıcıları güncelle',
  'userImport.OVERWRITE_Explanation':
    'Yüklenen dosyadaki her kullanıcı için yeni bir kayıt oluşturulur. Yüklenen dosyada mevcut kayıtlı kullanıcı varsa bu kullanıcı bilgileri güncellenir.',
  'userImport.REFRESH': 'Yalnızca mevcut kullanıcıları güncelle',
  'userImport.REFRESH_Explanation':
    'Yüklenen dosyada mevcut kayıtlı kullanıcı varsa bu kullanıcı bilgileri güncellenir. Kayıtlı olmayan kullanıcılar yok sayılır.',
  'userImport.template': 'Şablon',
  'userImport.templateSubtitle':
    'Dosyanızı aşağıdaki şablonu kullanarak gönderebilirsiniz',
  'userImport.fileName': 'Dosya Adı: ',
  'userImport.fileSize': 'Dosya Boyutu: ',
  'userImport.creationDate': 'Oluşturma Tarihi: ',
  'userImport.download': 'İndir',
  'userImport.convertXLSX': "XLSX dosyasını CSV'ye dönüştür",
  'userImport.convertXLSX_Subtitle':
    "Düzenlediğiniz şablonunuzu CSV'ye dönüştürmek için adımları izleyin",
  'userImport.reminding': 'Hatırlatma',
  'userImport.remindingSubtitle':
    'Lütfen aşağıdaki sayfa yapısına ve açıklamalara uyduğunuzdan emin olun.',
  'userImport.remindingExplanation1':
    'Kabul edilen şablon aşağıdaki başlıklarla eşleşmeli ve ayırıcı olarak noktalı virgül (;) kullanılmalıdır.',
  'userImport.remindingExplanation2':
    'İçe aktarılacak kullanıcı verileri için grup adı ve sistem kimliği boş bırakılabilir. Bu durumda ilgili alan boşluk (" ") ile işaretlenmelidir. Kullanıcılara grup ataması ve sistem kimliği tanımlaması içe aktarıldıktan sonra da gerçekleştirilebilir.',
  'userImport.remindingExplanation3':
    'İçe aktarılacak kullanıcı verilerinde Kimlik Doğrulama Türü (auth_type) için iki farklı giriş yöntemi kullanılabilir. Kimlik Doğrulama Türü bilgisi yetkili kullanıcılar ve adaylar için tanımlanabilir. Kimlik Doğrulama Türü (auth_type) tanımlanmayan kullanıcıların değeri varsayılan olarak "Password" olarak belirlenir.',
  'userImport.password': 'Password',
  'userImport.remindingExplanation3_password':
    "Bu doğrulama türü ile kullanıcılar şifre aracılığıyla Examod'a erişim sağlar.",
  'userImport.oauth2': 'OAuth_2',
  'userImport.remindingExplanation3_oauth2':
    "Bu doğrulama türü ile kullanıcılar bağlı oldukları organizasyona ait kimlik bilgileri ile Examod'a erişim sağlar.",
  'userImport.file': 'Dosya',
  'userImport.fileSubtitle':
    'İçe aktarmak istediğiniz kullanıcıları içeren CSV dosyasını sürükleyin veya seçin',
  'userImport.dragDrop': 'Dosyaları buraya sürükleyip bırakın',
  'userImport.or': 'veya',
  'userImport.browseFiles': 'Dosyalara Göz Atın',
  'userImport.attachmentHint':
    "Dosya boyutu 100 mb'yi geçmemelidir{br}Desteklenen dosya türü: CSV",
  'userImport.fileNumberError': 'Yalnızca bir adet dosya yüklenebilir.',
  'userImport.invalidFileType':
    'Yalnızca .csv uzantılı dosyaları yükleyebilirsiniz!',

  // preview ph
  'userImport.ROW': 'SATIR',
  'userImport.STATUS': 'DURUM',
  'userImport.SYSTEM_ID': 'SİSTEM KİMLİĞİ',
  'userImport.NAME': 'İSİM',
  'userImport.LAST_NAME': 'SOYİSİM',
  'userImport.EMAIL': 'EMAIL',
  'userImport.TYPE': 'TÜR',
  'userImport.GROUP': 'GRUP',
  'userImport.AUTH_TYPE': 'GİRİŞ TÜRÜ',
  'userImport.CANDIDATE': 'Aday',
  'userImport.AUTHORIZED': 'Yetkili',
  'userImport.OAUTH_2': 'OAuth2',
  'userImport.PASSWORD': 'Şifre',
  'userImport.APPROPRIATE': 'Uygun',
  'userImport.UNAPPROPRIATE': 'Uygun Değil',
  'userImport.fileInfoPreview': 'Dosya Bilgisi ve Önizleme',
  'userImport.importedListPreview': 'İçe Aktarılan Veri Listesi Önizlemesi',
  'userImport.fileNoError': 'Yüklediğiniz dosyada hata bulunamadı.',
  'userImport.warning': '{errorName}',

  //confirmation phase
  'userImport.confirmationWarning':
    'Devam ederseniz, dosya aşağıdaki hatalarla gönderilecektir!',
  'userImport.IMPORTED': 'İçe Aktarıldı',
  'userImport.UPDATED': 'Güncellendi',
  'userImport.OPERATION_FAILED': 'İşlem Başarısız',
  'userImport.complete': 'Tamamla',
  'userImport.summaryInfo': 'Özet ve Bilgiler',
  'userImport.totalUser': 'Toplam kullanıcı',
  'userImport.importedUsers': 'İçe aktarılan kullanıcılar',
  'userImport.updatedUsers': 'Güncellenen kullanıcılar',
  'userImport.unappropriateRows': 'Uyumsuz satırlar',

  //popups
  'userImport.wantToContinue': 'Devam etmek istediğinize emin misiniz?',
  'userImport.deleteFile': 'Dosyayı silmek istediğinize emin misiniz?',
  'userImport.fileHasError': 'Yüklediğiniz dosyada hatalar var.',
  'userImport.onCompleteWithError':
    'Seçilen yükleme türü: {uploadType}{br}Yüklediğiniz dosyada hatalar var. Devam ederseniz dosyanız gönderilecektir.',
  'userImport.onComplete':
    'Seçilen yükleme türü: {uploadType}{br}Devam ederseniz dosyanız gönderilecektir.',

  'userImport.discardTitle': 'Değişiklikleri göz ardı et',
  'userImport.discardContent':
    'Yaptığınız değişiklikler kaydedilmeyecektir. Tüm değişiklikleri iptal etmek istediğinize emin misiniz?',

  'userImport.continue': 'Devam',
  'userImport.cancel': 'İptal',
  'userImport.yesDelete': 'Evet, Sil',
  'userImport.discard': 'Sil',
};
