import { logout } from '../data/logoutUserUtility';
import {
  navigateToNotAuthorised,
  navigateToInternalError,
  navigateToResourceNotFound,
  navigateToLogin,
  navigateToUnknownError,
} from '../Navigator';
import { trackApiError } from '../analytics/analyticsTracker';
import { ERROR_CODES } from '../constants/Error';

/*
 * Checks if Api Result is Success or Not
 */
export const isApiCallSuccess = (response) =>
  response.status == 200 && response.data.success;

/*
 * Runs if there's an api Error
 */
export const onApiError = (err, isLoginRequest) => {
  if (!err || !err.response) {
    navigateToUnknownError();
    return;
  }

  switch (err.response.status) {
    case ERROR_CODES.UNAUTHORIZED:
      if (!isLoginRequest) {
        logout();
        navigateToLogin();
      }
      break;

    case ERROR_CODES.FORBIDDEN:
      navigateToNotAuthorised();
      break;

    case ERROR_CODES.NOT_FOUND:
      navigateToResourceNotFound();
      break;

    case ERROR_CODES.INTERNAL_SERVER:
      navigateToInternalError();
      break;

    default:
      navigateToUnknownError();
      break;
  }

  trackApiError(err);
};
