import React from 'react';

// Third party
import { FormattedMessage } from 'react-intl';

// ** Utility
import { getAssessmentDateFormat } from '../../../../utility/dateUtil';

const NotificationSubtitle = ({ timeStamp, type }) => {
  const examNotification = 'ASSESSMENT_COMPLETED';

  return (
    <>
      <span className="date">{getAssessmentDateFormat(timeStamp)}</span>
      <span className="dot-seperator"></span>
      <span className="notification-type">
        {type === examNotification ? (
          <FormattedMessage id="notification.exam" />
        ) : (
          <FormattedMessage id="notification.account" />
        )}
      </span>
    </>
  );
};

export default NotificationSubtitle;
