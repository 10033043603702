export default {
  'plan.standart': 'Examod Standart SaaS',
  'plan.standartInfo': 'Şu anda yıllık {param1} {param2} planına abonesiniz.',
  'plan.param1': 'yıllık faturalandırmalı',
  'plan.features': 'Özellikler',
  'plan.billedCredits': 'Faturalanan Krediler',
  'plan.subscription': 'Abonelik',
  'plan.panel': 'Yönetim Paneli',
  'plan.management': 'Kullanıcı ve Rol Yönetimi',
  'plan.bank': 'Soru Bankası',
  'plan.blueprints': 'Taslaklarla Kurulum Sınavı',
  'plan.paperBased': 'Kağıt Tabanlı veya Yerinde Sınav',
  'plan.level': 'Uzantı ile Seviye 1 Gözetim',
  'plan.comprehensive': 'Kapsamlı Değerlendirme',
  'plan.candidate': 'Ayrıntılı Aday Arayüzü',
  'plan.application': 'Mobil Uygulama',
  'plan.verification': 'Kimlik Doğrulama : {per} / {percent} {param}',
  'plan.proctoring': 'AI Gözetimi : {per} / {percent} {param}',
  'plan.annually': '(yıllık)',
  'plan.perUsage': '(Kullanım başına)',
  'plan.perUsageWith': '(90 dakikalık oturumlarla kullanım başına)',
  'plan.start': 'Başlangıç Tarihi : {start}',
  'plan.end': 'Bitiş Tarihi : {end}',
  'plan.active': 'Aktif',
  'plan.planFeatures': 'Sahip olunan plan özellikleriniz',
  'plan.userLicenceFeature': 'Kullanıcı Lisansıyla Kullanılabilen Özellikler',
  'plan.branding': 'Kurumsal <b>markalaşma</b>',
  'plan.paperBasedResult':
    'Sonuçları dijitalleşen <b>kağıt tabanlı sınavlar</b>',
  'plan.onlineOnsiteExam':
    '<b>Çevrimiçi</b> ve <b>yerinde</b> <span>e-sınavlar</span>',
  'plan.performanceExam':
    'Rubrikler ile <b>performans</b> <span>e-sınavları</span>',
  'plan.faceRecognition': 'Yapay zeka ile <b>aday yüz tanıma</b> ',
  'plan.proctoringWithExtension':
    'Examod Chrome eklentisi ile <b>sınav gözetimi</b>',
  'plan.analysis': 'Kapsamlı <b>sınav, soru ve aday analizi</b>',
  'plan.mobileApps': '<b>Examod IOS</b> ve <b>Android</b> mobil uygulamaları',
  'plan.mediaStorage': 'Kurumsal Examod verileri için <b>medya depolama</b>',
  'plan.rrCreditsFeature': 'R&R Kredileriyle Kullanılabilen Özellikler',
  'plan.videoRecording':
    'Online ve yerinde <span>e-sınavlarda</span> adayların <b>video kaydı</b>',
  'plan.violationReview':
    'Videoları daha sonra izleme ve <b>ihlalleri inceleme</b>',
  'plan.RandomiseVideoRec':
    'Maliyeti azaltmak için <b>rastgele video kaydı</b>',
  'plan.storingVideoRec': 'Video kayıtlarını <b>bir ay boyunca</b> saklama',
  'plan.planDetails': 'Plan Ayrıntıları',
  'plan.planDetailsSubtitle':
    'Planınıza ait faturalandırılmış ürünleriniz ve kullanım miktarları',
  'plan.billedProducts': 'Faturalandırılmış Ürünler',
  'plan.users': 'Aktif Kullanıcılar : <b>{per} / {percent}</b> {param}',
  'plan.storage': 'Medya Depolama : <b>{per} GB / {percent} GB</b> {param}',
  'plan.reviewSession':
    'Kaydet ve İncele Oturum Kredisi : <b>{per} / {percent}</b> {param}',
  'plan.yourPlanDetails': 'Plan Ayrıntılarınız',
  'plan.yourPlanDetailsSubtitle': 'Plan detaylarınız ve bilgileriniz',
  'plan.faq': 'SSS',
  'plan.faqSubtitle': 'Planınızla ilgili sıkça sorulan sorular',
  'plan.per': '%{per}',
};
