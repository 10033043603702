/*
 * Removes the item from the given array
 */
export const remove = (ar, item) => {
  const index = ar.indexOf(item);
  if (index > -1) {
    ar.splice(index, 1);
  }
};

/*
 * Checks if array is empty or not
 */
export const isEmpty = (ar) => ar.length == 0;

/*
 * Checks if array is null or empty
 */
export const isNullOrEmpty = (ar) => ar == null || isEmpty(ar);

/*
 * Checks if object is empty
 */
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

export const isObjNullOrEmpty = (obj) => obj == null || isObjEmpty(obj);
