export default {
  'userVerification.browseFile': ' Dosyaya Gözat',
  'userVerification.candidateFaceId': ' Aday Yüz Kimliği',
  'userVerification.previewFaceId': ' Yüz Kimliğini Önizle',
  'userVerification.drop&DragPhotoHere': ' Fotoğrafı sürükle ve bırak',
  'userVerification.or': 'Ya da ',
  'userVerification.photoInstruction':
    'Fotoğraf, önden görünüm için 51x51 mm (2x2 inç) boyutunda ve en az 150 dpi olmalıdır.',
  'userVerification.supportFileTypes':
    'Desteklenen dosya türleri: JPEG, JPG, PNG, TIFF Maksimum yükleme boyutu: 2 mb',
};
