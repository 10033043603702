import { EditorState } from 'draft-js';
import { isNullOrEmpty } from '../utility/collectionUtil';
import {
  htmlToText,
  isNullOrEmpty as isStrNullOrEmpty,
} from '../utility/stringUtil';
import { toastError } from '../utility/toastUtil';
import { REGEX_IMG_TAG } from '../constants/validation';

export const mainContentTabIndex = 0;
export const minApprovableQuestionCount = 2;
// indicates ref index of the question input fields
export const fieldRefIndexes = {
  topic: 0,
  questionRoot: 1,
  options: 2,
  correctOption: 2,
  attachments: 3,
  fields: 4,
  source: 5,
  learningObj: 6,
};

export const mcqInputFields = {
  topic: 'topic',
  questionName: 'questionName',
  questionRoot: 'questionRoot',
  rootEditorState: 'rootEditorState',
  options: 'options',
  optionStates: 'optionStates',
  correctOption: 'correctOption',
  attachments: 'attachments',
  attributes: 'attributes',
  fields: 'fields',
  keywords: 'keywords',
  learningObj: 'learningObj',
  source: 'source',
};
export const emptyState = EditorState.createEmpty();

export const defaultOptions = [
  { id: 0, content: '', onlyImageAnswer: false },
  { id: 1, content: '', onlyImageAnswer: false },
  { id: 2, content: '', onlyImageAnswer: false },
  { id: 3, content: '', onlyImageAnswer: false },
  { id: 4, content: '', onlyImageAnswer: false },
];
export const defaultOptionStates = [
  { id: 0, state: emptyState },
  { id: 1, state: emptyState },
  { id: 2, state: emptyState },
  { id: 3, state: emptyState },
  { id: 4, state: emptyState },
];

export const questionNameMaxLength = 30;
export const questionContentMaxLength = 5000;
export const questionContentMinLength = 15;

export const hasQuestionContentError = (contentHtml, errorType) => {
  const hasMaxCharError =
    htmlToText(contentHtml.trim()).length > questionContentMaxLength;
  const hasMinCharError =
    htmlToText(contentHtml).trim().length < questionContentMinLength;
  if (errorType === 'min') {
    return hasMinCharError;
  } else if (errorType === 'max') {
    return hasMaxCharError;
  } else {
    return hasMaxCharError || hasMinCharError;
  }
};
export const hasExpectedAnswerError = (contentHtml) => {
  const hasMaxCharError =
    htmlToText(contentHtml.trim()).length > questionContentMaxLength;
  return hasMaxCharError;
};

export const getFilledAnswers = (question) => {
  const options = question.options;
  let filledAnswers = options.map((answer, index) => ({
    content: answer.content?.trim(),
    correctAnswer: question.correctOption === answer.id,
    order: index,
    id: answer.id,
    onlyImageAnswer:
      answer.onlyImageAnswer || REGEX_IMG_TAG.test(answer.content),
  }));
  filledAnswers = filledAnswers.filter(
    (answer) =>
      !isStrNullOrEmpty(htmlToText(answer.content)) || answer.onlyImageAnswer
  );
  if (filledAnswers.length < options.length) {
    return null;
  }
  return filledAnswers;
};

// errors
export const scrollToIncorrectField = (fieldRefs, incorrectField) => {
  fieldRefs.current[fieldRefIndexes[incorrectField]].scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

// ** Add question form submit
export const showErrorToast = (question, intl, isAttachmentError) => {
  let filledAnswers = getFilledAnswers(question);
  let isAttributeOptionsValid =
    question.attributes.length === question.fields.length;
  let isFilledAnswerValid = filledAnswers?.some(
    (answer) => answer.correctAnswer
  );
  let isRequiredFieldsValid =
    question.topic &&
    !hasQuestionContentError(question.questionRoot, 'min') &&
    !question.hasErrors.learningObj;

  if (
    isAttributeOptionsValid &&
    !isNullOrEmpty(filledAnswers) &&
    isRequiredFieldsValid
  ) {
    if (!isFilledAnswerValid) {
      toastError(intl.formatMessage({ id: 'questionAdd.answerWarning' }));
    }
  } else {
    toastError(intl.formatMessage({ id: 'questionAdd.warning' }));
    return;
  }
  if (isAttachmentError) {
    toastError(intl.formatMessage({ id: 'questionAdd.attachmentError' }));
  }
};

export const mapOptions = (items) => {
  if (!isNullOrEmpty(items)) {
    return items.map((item) => ({ value: item.id, label: item.name }));
  }
  return [];
};
