export default {
  'unitsUsers.manageUsersTitle': 'Manage Unit Users',
  'unitsUsers.manageUsersText': 'Manage Users of {unitName}',
  'unitsUsers.assignUsersTitle': 'Assign New Authorized Users in {unitName}',
  'unitsUsers.authorizedUsersTitle': 'Authorized Users in {unitName}',
  'unitsUsers.noUserFound': 'No user found.',
  'unitsUsers.selectUsers': 'Select Users',
  'unitsUsers.selectRole': 'Select Role',
  'unitsUsers.assignSubmit': 'Assign Selected Users To Unit',
  'unitsUsers.resetButton': 'Reset',
  'unitsUsers.assignSuccessMessage': 'Users assigned.',
  'unitsUsers.revokeUserTitle': 'Deauthorize User',
  'unitsUsers.revokeUserMessage':
    'Are you sure you want to deauthorize the user?',
  'unitsUsers.revokeUserConfirm': 'Confirm',
  'unitsUsers.revokeUserCancel': 'Cancel',
  'unitsUsers.userDeauthorizedMessage': 'User deauthorized.',
  'unitsUsers.userRoleChangedMessage': 'User role changed.',
  'unitsUsers.select': 'Select',
};
