// ADDING A separate file for loggout utilty only handles interaction between
// apiutilty.js and userRepository.js
// when refactoring we will remove logout function from UserRepository.js
import { removeToken, removeSwitches } from './UserLocalDataSource';
import { dropAuthToken } from '../utility/networkUtil';

/*
 * Logouts From System
 */
export const logout = () => {
  removeToken();
  dropAuthToken();
  removeSwitches();
  return true;
};
