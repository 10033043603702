export default {
  'rolesList.addUNIT': 'Birim Rolü Ekle',
  'rolesList.addSYSTEM': 'Sistem Rolü Ekle',
  'rolesList.addInfo': 'Yeni bir rol ekleyin',
  'rolesList.editRole': 'Düzenle',
  'rolesList.SYSTEM': 'Sistem Rolü',
  'rolesList.UNIT': 'Birim Rolü',
  'rolesList.anchor': 'Korunmuş',
  'rolesList.systemRoles': 'Sistem Rolleri',
  'rolesList.unitRoles': 'Birim Rolleri',
  'rolesList.view': 'Görüntüle',

  // Tooltips
  'rolesList.systemRoles_tooltip':
    'İstediğiniz izinleri gruplayarak kullanıcılarınız için <b>sistem çapında</b> roller oluşturabilirsiniz. Bu rol türü <b>aday tipi</b> kullanıcılar için kullanılamaz.',
  'rolesList.unitRoles_tooltip':
    'İstediğiniz izinleri gruplayarak kullanıcılarınız için <b>birimlere özgü</b> roller oluşturabilirsiniz. Bu rolleri kullanıcılara bir birim/ders için veya sistemdeki tüm birim/dersler için verebilirsiniz. Bu rol türü <b>aday tipi</b> kullanıcılar için kullanılamaz.',
};
