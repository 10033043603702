export default {
  'questionStat.title': 'Gelişmiş Analiz',
  'questionStat.titleInfo':
    'Soruya ilişkin gelişmiş analiz sonuçlarını ve (varsa) sistem yorumuna göz atın',
  'questionStat.questionRoot': 'Soru kökü',
  'questionStat.objective': 'Öğrenme hedefi',
  'questionStat.total': 'Toplam yanıtlar',
  'questionStat.unanswered': 'Yanıtlayamayan',
  'questionStat.unviewed': 'Görüntüleyemeyen',
  'questionStat.totalUnanswered': 'Toplam Yanıtlayamayan',
  'questionStat.totalUnviewed': 'Toplam Görüntüleyemeyen',
  'questionStat.highScoringGroup': 'Yüksek puanlı grubun yanıtları',
  'questionStat.lowScoringGroup': 'Düşük puanlı grubun yanıtları',
  'questionStat.allGroups': 'Yüksek ve düşük puanlı grupların tüm yanıtları',
  'questionStat.correctAnswer': 'Doğru cevap',
  'questionStat.chartTitle': 'Soru seçeneklerinin çok gruplu yanıt grafiği',
  'questionStat.allAnswers': 'Tüm yanıtlar',
  'questionStat.chartMinAlert':
    'Soruya verilen yanıt sayısı 3`ten az olduğu için grafik oluşturulamaktadır.',

  // Tooltips
  'questionStat.highScoringGroup_tooltip':
    "Toplam yanıtlayanlar arasında en yüksek puana sahip %27'lik grup ",
  'questionStat.lowScoringGroup_tooltip':
    "Toplam yanıtlayanlar arasında en düşük puana sahip %27'lik grup",
  'questionStat.allAnswers_tooltip':
    'Herhangi bir dışlama olmaksızın tüm yanıtlayıcıların yanıtları',
  'questionStat.allGroups_tooltip':
    "İki grubun yanıtlayıcılarının verdiği yanıtlar, tüm yanıtların %54'ünü oluşturmaktadır. Hariç tutulan orta grup (%46) soru ayırt ediciliğini etkilememektedir.",
};
