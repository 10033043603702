export default {
  'unitDelete.title': 'Birimi sil',
  'unitDelete.message':
    'Birimi silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'unitDelete.confirm': 'Evet, birimi sil',
  'unitDelete.cancel': 'İptal',
  'unitDelete.ok': 'Tamam',
  'unitDelete.move': 'Taşı',
  'unitDelete.hide': 'Gizle',
  'unitDelete.unitCantDeleted': 'Birim Silinemedi',
  'unitDelete.unitCantDeletedInfo':
    'Seçili birimin içinde sınav yapmaya uygun soru bankası olan birimler vardır. Bu birimi silmek istiyorsanız, lütfen alt birimlerini başka bir birime taşıyınız.',
};
