// ** React imports
import React from 'react';

// ** 3rd Party Components
import SweetAlert from 'react-bootstrap-sweetalert';

import '../../../assets/scss/plugins/extensions/sweet-alerts.scss';

const ConfirmAlert = ({
  isShown,
  title,
  message,
  confirmBtnText,
  cancelBtnText,
  onConfirm,
  onCancel,
  style = {
    padding: '1.375rem 3rem 2.75rem',
    borderRadius: '0.375rem',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    width: 'auto',
    maxWidth: '42rem',
  },
  btnSize = 'normal',
  reverseButtons = true,
  confirmBtnCssClass = 'btn-danger-ba',
  cancelBtnCssClass = 'btn-outline-ba',
  wrapperClassName = '',
  confirmBtnBsStyle = '',
  cancelBtnBsStyle = '',
  showCancel = true,
}) => {
  return (
    <SweetAlert
      customClass={wrapperClassName}
      title={title ? title : ''}
      warning
      show={isShown}
      showCancel={showCancel}
      reverseButtons={reverseButtons}
      cancelBtnBsStyle={cancelBtnBsStyle}
      confirmBtnBsStyle={confirmBtnBsStyle}
      confirmBtnText={confirmBtnText}
      cancelBtnText={cancelBtnText}
      onConfirm={onConfirm}
      btnSize={btnSize}
      onCancel={onCancel}
      style={style}
      confirmBtnCssClass={confirmBtnCssClass}
      cancelBtnCssClass={cancelBtnCssClass}
      closeBtnStyle={{ fontWeight: 300 }}
    >
      {message}
    </SweetAlert>
  );
};

export default ConfirmAlert;
