export default {
  'questionEdit.delete': 'Soruyu Sil',
  'questionEdit.editSuccess': 'Soru başarıyla güncellendi!',
  'questionEdit.deleteSuccess': 'Soru başarıyla silindi!',
  'questionEdit.deleteConfirmationTitle': 'Soruyu sil',
  'questionEdit.deleteConfirmationMessage':
    'Soruyu silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'questionEdit.deleteConfirmationConfirmButton': 'Evet, soruyu sil',
  'questionEdit.title': 'Soru Düzenle',
};
