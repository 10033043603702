export default {
  'users.header': 'Kullanıcı Yönetimi',
  'users.searchFilter': 'Arama Filtresi',
  'users.search': 'Ara',
  'users.addUser': 'Kullanıcı Ekle',
  'users.inviteUser': 'Kullanıcı Davet Et',
  'users.show': 'Sayfa Başı',
  'users.entries': 'Kullanıcı Listele',
  'users.selectGroup': 'Grup',
  'users.selectType': 'Kullanıcı Türü',
  'users.selectStatus': 'Durum',
  'users.selectUnit': 'Birim / Ders',
  'users.user': 'Kullanıcı',
  'users.email': 'E-posta',
  'users.addedDate': 'Eklenme Tarihi',
  'users.userGroup': 'Kullanıcı Grubu',
  'users.userType': 'Kullanıcı Türü',
  'users.status': 'Durum',
  'users.actions': 'İşlemler',
  'users.edit': 'Düzenle',
  'users.delete': 'Sil',
  'users.prevButton': 'Önceki',
  'users.nextButton': 'Sonraki',
  'users.authorized': 'Yetkili',
  'users.candidate': 'Aday',
  'users.active': 'Aktif',
  'users.pending': 'Bekleyen Davet',
  'users.passive': 'Pasif',
  'users.unknown': 'Bilinmiyor',
  'users.reset': 'Filtreleri Sıfırla',
  'users.confirmationTitle': 'Devam etmek istediğinize emin misiniz?',
  'users.passivateConfirmationMessage':
    'Kullanıcıyı pasifleştirmek istediğinize emin misiniz? Devam ederseniz, kullanıcıya ait veriler silinmeyecek ve arşivlenecektir.',
  'users.activateConfirmationMessage':
    'Kullanıcıyı aktifleştirmek istediğinize emin misiniz? Devam ederseniz, kullanıcı arşivlenmiş verileri ile tekrardan platforma erişim sağlayabilecektir.',
  'users.cancelInvitationConfirmationMessage':
    'Adayın bir değerlendirmeye eklendiği tespit edilmiştir. Devam ederseniz, adayın daveti iptal edilecek ve aday değerlendirme listesinden çıkarılacaktır.',
  'users.confirmationConfirmButton': 'Devam',
  'users.confirmationCancelButton': 'İptal',
  'users.userActivated': 'Kullanıcı başarıyla aktifleştirildi!',
  'users.userPassivated': 'Kullanıcı başarıyla pasifleştirildi!',
  'users.select': 'Seçiniz',
  'users.AUTHORIZED': 'Yetkili',
  'users.CANDIDATE': 'Aday',
  'users.activate': 'Aktifleştir',
  'users.passivate': 'Pasifleştir',
  'users.resendInvitation': 'Tekrar davet epostası gönder',
  'users.resendAlert': ' Davetiye yeniden başarıyla gönderildi!',
  'users.cancelInvitation': 'Daveti İptal Et',
  'users.cancelInvitationSuccessAlert': 'Davet başarıyla iptal edildi!',
  'users.userPassivateTicketReceiverError':
    'Destek Bilgileri ile ilişkilendirilen kullanıcı hesapları pasifleştirilemez. Kullanıcıyı pasifleştirmek için Genel > Destek Bilgileri başlığından destek epostası tercihlerini güncelleyin veya yetkiliniz ile iletişime geçin.',
  'users.authType': 'Giriş Türü',
  'users.authTypePASSWORD': 'Şifre',
  'users.authTypeOAUTH_2': 'OAuth2',
};
