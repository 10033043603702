export default {
  // Generic
  'generic.add': 'Tilføj',
  'generic.cancel': 'Annuller',

  // BlockType
  'components.controls.blocktype.h1': 'Overskrift 1',
  'components.controls.blocktype.h2': 'Overskrift 2',
  'components.controls.blocktype.h3': 'Overskrift 3',
  'components.controls.blocktype.h4': 'Overskrift 4',
  'components.controls.blocktype.h5': 'Overskrift 5',
  'components.controls.blocktype.h6': 'Overskrift 6',
  'components.controls.blocktype.blockquote': 'Blokcitat',
  'components.controls.blocktype.code': 'Kode',
  'components.controls.blocktype.blocktype': 'Blok Type',
  'components.controls.blocktype.normal': 'Normal',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Farver',
  'components.controls.colorpicker.text': 'Tekst',
  'components.controls.colorpicker.background': 'Baggrund',

  // Embedded
  'components.controls.embedded.embedded': 'Indlejre',
  'components.controls.embedded.embeddedlink': 'Indlejre Link',
  'components.controls.embedded.enterlink': 'Indtast link',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Fonttype',

  // FontSize
  'components.controls.fontsize.fontsize': 'Fontstørrelser',

  // History
  'components.controls.history.history': 'Historie',
  'components.controls.history.undo': 'Fortryd',
  'components.controls.history.redo': 'Gendan',

  // Image
  'components.controls.image.image': 'Billede',
  'components.controls.image.fileUpload': 'Filoverførsel',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText':
    'Drop filen eller klik for at uploade',

  // Inline
  'components.controls.inline.bold': 'Fed',
  'components.controls.inline.italic': 'Kursiv',
  'components.controls.inline.underline': 'Understrege',
  'components.controls.inline.strikethrough': 'Gennemstreget',
  'components.controls.inline.monospace': 'Monospace',
  'components.controls.inline.superscript': 'Hævet',
  'components.controls.inline.subscript': 'Sænket',

  // Link
  'components.controls.link.linkTitle': 'Link Titel',
  'components.controls.link.linkTarget': 'Link Mål',
  'components.controls.link.linkTargetOption': 'Åbn link i nyt vindue',
  'components.controls.link.link': 'Link',
  'components.controls.link.unlink': 'Fjern link',

  // List
  'components.controls.list.list': 'Liste',
  'components.controls.list.unordered': 'Uordnet',
  'components.controls.list.ordered': 'Ordnet',
  'components.controls.list.indent': 'Indrykning',
  'components.controls.list.outdent': 'Udrykning',

  // Remove
  'components.controls.remove.remove': 'Fjern',

  // TextAlign
  'components.controls.textalign.textalign': 'Tekstjustering',
  'components.controls.textalign.left': 'Venstre',
  'components.controls.textalign.center': 'Center',
  'components.controls.textalign.right': 'Højre',
  'components.controls.textalign.justify': 'Margener',

  // New Examod localizations
  'components.controls.image.modal.title': 'Image Uploader',
  'components.controls.image.modal.description':
    'Browse or drag the image you want to upload',
  'components.controls.image.modal.content.image.or': 'or',
  'components.controls.image.modal.content.image.btn': 'Browse Files',
  'components.controls.image.modal.content.image.attachment':
    'Attachments should not exceed 2 mb',
  'components.controls.image.modal.content.image.supported':
    'Supported file types: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt Text',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Image alt text',
  'components.controls.image.modal.content.input.scale.label': 'Scale (%)',
  'components.controls.image.modal.contnet.url.address.label': '*URL (Adress)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Add image from address',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Clear Highlight',
  'components.controls.color.picker.highlight.black': 'Black Highlight',
  'components.controls.color.picker.highlight.grey': 'Grey Highlight',
  'components.controls.color.picker.highlight.red': 'Red Highlight',
  'components.controls.color.picker.highlight.green': 'Green Highlight',
  'components.controls.color.picker.highlight.blue': 'Blue Highlight',
  'components.controls.color.picker.highlight.yellow': 'Yellow Highlight',
  'components.controls.color.picker.highlight.examod': 'Examod Highlight',
  'components.controls.color.picker.highlight.purple': 'Purple Highlight',
  'components.controls.color.picker.highlight.pink': 'Pink Highlight',
};
