export default {
  'unitAdd.titleAdd': 'Add Unit',
  'unitAdd.titleUpdate': 'Edit Unit',
  'unitAdd.name': 'Unit Name',
  'unitAdd.description': 'Description',
  'unitAdd.parentUnitId': 'Unit',
  'unitAdd.parentUnitIdHint': 'Main/Parent Unit',
  'unitAdd.questionAddAvailable':
    'Question bank available (Unit turn into Course)',
  'unitAdd.examDoneAvailable': 'Assessment available',
  'unitAdd.onlyForCategorization': 'Only for categorization',
  'unitAdd.requireUseOfLO': 'Require the use of learning objectives',
  'unitAdd.add': 'Add Unit',
  'unitAdd.update': 'Update',
  'unitAdd.id': 'Unit ID',
  'unitAdd.visible': 'Active',
  'unitAdd.invisible': 'Passive',
  'unitAdd.successMessage': 'Unit added successfully!',
  'unitAdd.editSuccess': 'Unit updated successfully!',
  'unitAdd.maxLengthID': 'Unit ID should be max 50 characters.',
  'unitAdd.minLengthID': 'Unit ID should be at least 1 characters.',
  'unitAdd.maxLengthName': 'Unit Name should be max 200 characters.',
  'unitAdd.patternName':
    "Unit name can only contain letters, digits and - _ . , ; : ' @ / \\ ( ) [ ] '{ }' symbols.",
  'unitAdd.minLengthName': 'Unit Name should be at least 1 characters.',
  'unitAdd.maxLengthDesc': 'Description should be max 500 characters.',
  'unitAdd.minLengthDesc': 'Description should be at least 1 characters.',
  'unitAdd.patternDesc':
    "Description can only contain letters, digits and - _ . , ; : ' @ / \\ ( ) [ ] '{ }' symbols.",
  'unitAdd.title': 'Edit Unit',
  'unitAdd.popupTitle': 'Edit unit',
  'unitAdd.message':
    'Are you sure to edit the unit? This operation cannot be reversed.',
  'unitAdd.confirm': 'Yes, edit unit',
  'unitAdd.cancel': 'Cancel',

  //Tooltips
  'unitAdd.requiresUseOfLo-tooltip':
    'This feature requires the use of learning objectives when adding questions to all courses in the core unit.',
};
