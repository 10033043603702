export default {
  'proctoringRules.header': 'Sınav Gözetim Kuralları',
  'proctoringRules.headerInfo':
    'Çevrimiçi ve yerinde sınavların gözetimi için organizasyon genelinde kurallar belirleyin',
  'proctoringRules.options': 'Kilitleme Seçenekleri',
  'proctoringRules.FORCE_FULL_SCREEN': 'Tam ekran zorunlu',
  'proctoringRules.FORCE_FULL_SCREENInfo':
    'İzin verirseniz, adayın ekranını tam ekran kullanmaya zorlamak mümkün olacaktır.',
  'proctoringRules.FORCE_PC': 'Sadece bilgisayarla girilebilir',
  'proctoringRules.FORCE_PCInfo':
    'İzin verirseniz, adayın yalnızca bilgisayar ile giriş yapması mümkün olacaktır.',
  'proctoringRules.ONLY_ONE_SCREEN': 'Ekran uzatma yapılamaz',
  'proctoringRules.ONLY_ONE_SCREENInfo':
    'İzin verirseniz, adayın ekran uzatma yapması mümkün olmayacaktır.',
  'proctoringRules.NO_NEW_TABS': 'Sadece tek sekme',
  'proctoringRules.NO_NEW_TABSInfo':
    'İzin verirseniz, adayın birden fazla sekme açması mümkün olmayacaktır.',
  'proctoringRules.CLOSE_OPEN_TABS': 'Açık sekmeleri kapat',
  'proctoringRules.CLOSE_OPEN_TABSInfo':
    'İzin verirseniz, adayin acik olan sekmeleri kapatilacaktir.',
  'proctoringRules.DISABLE_PRINTING': 'Yazdırılamaz',
  'proctoringRules.DISABLE_PRINTINGInfo':
    'İzin verirseniz, adayın sınavı yazdırması mümkün olmayacaktır.',
  'proctoringRules.DETECT_VM': 'Sanal makine kullanılamaz',
  'proctoringRules.DETECT_VMInfo':
    'İzin verirseniz, adayın sanal makine kullanması mümkün olmayacaktır.',
  'proctoringRules.DETECT_PRINT_SCREEN': 'Ekran yakalama yapılamaz',
  'proctoringRules.DETECT_PRINT_SCREENInfo':
    'İzin verirseniz, adayın ekran görüntüsü alması mümkün olmayacaktır.',
  'proctoringRules.CLEAR_CACHE': 'Önbelleği temizle',
  'proctoringRules.CLEAR_CACHEInfo':
    'İzin verirseniz, adayin tarayicisinin onbellegi silinecektir.',
  'proctoringRules.PREVENT_RE_ENTRY': 'Yeniden giriş yapılamaz',
  'proctoringRules.PREVENT_RE_ENTRYInfo':
    'İzin verirseniz, adayın sınavdan çıktıktan sonra tekrar giriş yapması mümkün olmayacaktır.',
  'proctoringRules.DISABLE_CLIPBOARD': 'Kes, kopyala, yapıştır yapılamaz',
  'proctoringRules.DISABLE_CLIPBOARDInfo':
    'İzin verirseniz, adayın kes, kopyala, yapıştır kısayollarını kullanması mümkün olmayacaktır.',
  'proctoringRules.DISABLE_RIGHT_CLICK': 'Sağ tık yapılamaz',
  'proctoringRules.DISABLE_RIGHT_CLICKInfo':
    'İzin verirseniz, adayın sağ tık yapması mümkün olmayacaktır.',
  'proctoringRules.DETECT_MULTI_SCREEN': 'Birden çok ekran kullanılamaz',
  'proctoringRules.DETECT_MULTI_SCREENInfo':
    'İzin verirseniz, adayın çoklu ekranlarını devre dışı bırakmak veya tespit etmek mümkün olacaktır.',
  'proctoringRules.successMessage': 'Disiplin kuralları başarıyla kaydedildi!',
  'proctoringRules.BLOCK_MOBILE_APPS': 'Mobil uygulamaları engelle',
  'proctoringRules.BLOCK_MOBILE_APPSInfo': '-',
  'proctoringRules.DISABLE_DEVTOOLS': 'Geliştirici araçları kullanılamaz',
  'proctoringRules.DISABLE_DEVTOOLSInfo':
    'İzin verirseniz, adayın geliştirici araçlarını kullanması mümkün olmayacaktır.',
  'proctoringRules.RECORD_SCREEN': 'Ekran paylaşımı gerekli',
  'proctoringRules.CANDIDATE_VERIFICATION': 'Adayın yüzünün tanınması gerekli',
  'proctoringRules.RECORD_VIDEO': 'Video kaydı gerekli',
  'proctoringRules.RECORD_WEB_TRAFFIC': 'Ağ trafiği takibi gerekli',
  'proctoringRules.CANDIDATE_VERIFICATIONInfo':
    'İzin verirseniz, adayın yüz tanıma işlemi yapılabilecektir. ',
  'proctoringRules.RECORD_VIDEOInfo':
    'İzin verirseniz, adayın video kaydının alınması mümkün olacaktır.',
  'proctoringRules.RECORD_WEB_TRAFFICInfo':
    'İzin verirseniz, adayın ağ trafiğinin takibi mümkün olacaktır.',
  'proctoringRules.RECORD_SCREENInfo':
    'İzin verirseniz, adayı ekran paylaşımı yapmaya zorlamak mümkün olacaktır.',
  'proctoringRules.needExtension': 'Extension gerekli',
  'proctoringRules.DISABLE_PRINTINGExtInfo': '-',
  'proctoringRules.DISABLE_DEVTOOLSExtInfo': '-',
  'proctoringRules.DETECT_PRINT_SCREENExtInfo': '-',
  'proctoringRules.DETECT_VMExtInfo': '-',
  'proctoringRules.RECORD_WEB_TRAFFICExtInfo': '-',
  'proctoringRules.NO_NEW_TABSExtInfo': '-',
  'proctoringRules.DETECT_MULTI_SCREENExtInfo': '-',

  'proctoringRules.RECORD_REVIEW-tooltip':
    'Bu gözetim türünde, sınav sırasında adayın video ve ekran kaydı alınır. Bu sayede sınav gözetmeni birçok adayın saatlerce sürebilecek video kayıtlarını izlemek yerine belirli adayların sadece işaretli kesitlerini izleyerek çok az zamanda tüm sınav güvenlik değerlendirmesini tamamlayabilir. Bu gözetim türü halihazırda Kilitle ve İncele gözetim türünün tüm özelliklerini kapsamaktadır. Yüksek önem düzeyine sahip sınavlar için önerilir.',
  'proctoringRules.LOCKDOWN_REVIEW-tooltip':
    'Bu gözetim türünde adayın sınava bağlandığı cihaz, tam ekran zorlama, çıktı alamama gibi çeşitli kısıtlamalara tabi tutulur. Ekran paylaşımının kapatılması gibi kısıtlama yapılamayan bazı ihlal türleri de bu gözetim türü için raporlanmaktadır. Bu gözetim türü, sınav sonrasında adaylarla ilgili bir risk puanı üreterek sınav sorumlusuna belirlenmiş adayların pencere ve cihaz üzerinde yaptığı ihlalleri değerlendirme olanağı tanır. Bu türün tüm özellikleri Kaydet ve İncele gözetim türü için de kullanılabilir. Düşük ve orta önem düzeyine sahip sınavlar için önerilir.',
  'proctoringRules.UNPROCTORED-tooltip':
    'Sınav sırasında herhangi bir gözetim ve kısıtlamanın yapılmadığı gözetim türüdür. Sınav sonrasında bir rapor üretilmez. Düşük önem düzeyine sahip sınavlar veya quiz sınavlarında kullanılabilir.',
  'proctoringRules.videoInstruction-tooltip':
    'In this type of proctoring, video and screen recording of the candidate is taken during the exam. These recordings are processed with artificial intelligence algorithms after the exam, and suspicious behavior, people and objects are presented to the examiner in the form of a marked video. In this way, the examiner can complete the entire exam security assessment in a very short time by watching only the marked sections of certain candidates, instead of watching the video recordings of many candidates that can take hours. This proctoring type currently covers all the features of the Lockdown & Review surveillance type. Recommended for exams of high importance.',
  'proctoringRules.totalViolationChart-tooltip':
    'Adayın sınav sırasındaki toplam ihlal süresinin grafiği',

  'proctoringRules.classificationRange': 'Sınıflandırma Aralığı',
  'proctoringRules.noRisk-tooltip-RECORD_REVIEW':
    'Risk yok (0 - 4 / Pencere + Cihaz İhlali)',
  'proctoringRules.lowRisk-tooltip-RECORD_REVIEW':
    'Düşük risk (5 - 9 / Pencere + Cihaz İhlali)',
  'proctoringRules.midRisk-tooltip-RECORD_REVIEW':
    'Orta risk (10 - 14 / Pencere + Cihaz İhlali)',
  'proctoringRules.highRisk-tooltip-RECORD_REVIEW': `Yüksek risk (15 ve üstü / Pencere + Cihaz + Davranış İhlali) + Yüz Kimliği eşleşmedi`,

  'proctoringRules.noRisk-tooltip-LOCKDOWN_REVIEW':
    'Risk yok (0 - 2 / Pencere + Cihaz İhlali)',
  'proctoringRules.lowRisk-tooltip-LOCKDOWN_REVIEW':
    'Düşük risk (3 - 6 / Pencere + Cihaz İhlali)',
  'proctoringRules.midRisk-tooltip-LOCKDOWN_REVIEW':
    'Orta risk (7 - 10 / Pencere + Cihaz İhlali)',
  'proctoringRules.highRisk-tooltip-LOCKDOWN_REVIEW': `Yüksek risk (11 ve üstü / Pencere + Cihaz + Davranış İhlali) + Yüz Kimliği eşleşmedi`,
};
