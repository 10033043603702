export default {
  'fieldAdd.addAttribute': 'Soru Özelliği Ekle',
  'fieldAdd.addSubtitle':
    'Sorularınıza atayabileceğiniz niteliksel soru özelliği ekleyin',
  'fieldAdd.editAttribute': 'Soru Özelliğini Düzenle',
  'fieldAdd.editSubtitle':
    'Sorularınıza atayabileceğiniz niteliksel soru özelliğini düzenleyin',
  'fieldAdd.info': 'Özellik Bilgileri',
  'fieldAdd.defineInfo': 'Bilgileri belirleyin',
  'fieldAdd.name': 'Özellik Adı',
  'fieldAdd.details': 'Özellik Detayları',

  'fieldAdd.detailsDesc': 'Detayları belirleyin',
  'fieldAdd.unit': 'Ana Birim',
  'fieldAdd.type': 'Soru Tipi',
  'fieldAdd.options': 'Özellik Seçenekleri',
  'fieldAdd.questionAttribute': 'Soru Özellik Seçenekleri',
  'fieldAdd.cancel': 'İptal',
  'fieldAdd.sortModeASCENDING': "A'dan Z'ye",
  'fieldAdd.sortModeDESCENDING': "Z'den A'ya",
  'fieldAdd.sortModeCUSTOM': 'Özel',
  'fieldAdd.sortBy': 'Sıralama Türü: {sortMode}',
  'fieldAdd.emptyQuestionType': 'Soru türü boş bırakılamaz',

  'fieldAdd.description': 'Özellik Tanımı',
  'fieldAdd.requirement': 'Gereksinim',
  'fieldAdd.availability': 'Aktif / Pasif',
  'fieldAdd.add': 'Ekle',
  'fieldAdd.edit': 'Düzenle',
  'fieldAdd.saveChanges': 'Değişiklikleri Kaydet',
  'fieldAdd.multipleChoice': 'Çoktan Seçmeli Soru',
  'fieldAdd.shortAnswer': 'Kısa Cevaplı Soru',
  'fieldAdd.longAnswer': 'Uzun Cevaplı Soru',
  'fieldAdd.openEnded': 'Açık Uçlu Soru',
  'fieldAdd.hotspot': 'Nokta Seçim Sorusu',
  'fieldAdd.mandatory': 'Zorunlu',
  'fieldAdd.optional': 'İsteğe Bağlı',
  'fieldAdd.addField': 'Soru Özelliği Ekle',
  'fieldAdd.required': ' ',
  'fieldAdd.minOption': 'En az 2 tane özellik seçeneği olmalıdır.',
  'fieldAdd.nameMax': 'Özellik adı en fazla 200 karakter olmalıdır.',
  'fieldAdd.optionMax': 'Özellik seçeneği en fazla 100 karakter olmalıdır.',
  'fieldAdd.successMessage': 'Soru özelliği başarıyla eklendi!',
  'fieldAdd.select': 'Seçiniz',
  'fieldAdd.sameOptionAlert': 'Özellik seçenekleri aynı olamaz!',
  'fieldAdd.descMax': 'Özellik tanımı en fazla 1000 karakter olmalıdır.',
  'fieldAdd.optionMinChar': 'Özellik adı boş bırakılamaz',
  'fieldAdd.descPatternError':
    "Özellik tanımı yalnızca harf, rakam ve - _ . , ; : ' @ / \\ ( ) [ ] '{ }' sembolleri içerebilir.",
  'fieldAdd.namePatternError':
    "Özellik adı yalnızca harf, rakam ve - _ . , ; : ' @ / \\ ( ) [ ] '{ }' sembolleri içerebilir.",
};
