export default {
  'notification.ASSESSMENT_COMPLETED': '{examName} sınavı sonlanmıştır.',
  'notification.UNIT_ROLE_ASSIGNED': '{unitName} için {role} olarak atandınız.',
  'notification.SYSTEM_ROLE_ASSIGNED':
    'Sistem genelinde {role} olarak atandınız',
  'notification.exam': 'Sınav Bildirimi',
  'notification.account': 'Hesap Bildirimi',
  'notification.count': '{count} Yeni',
  'notification.notifications': 'Bildirimler',
  'notification.clear': 'Tümünü Temizle',
  'notification.noNotifications': 'Henüz bildirim yok',
};
