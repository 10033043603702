export default {
  'courseReport.title': 'Ders Performans Raporları',
  'courseReport.unitTitle': 'Birim Performans Raporları',
  'courseReport.subTitle': 'Kapsamlı raporlar oluşturun ve dışa aktarın',
  'courseReport.customize': 'Raporları Özelleştir',
  'courseReport.topics': 'Konular',
  'courseReport.allCourses': 'Tüm Dersler',
  'courseReport.courses': 'Dersler',
  'courseReport.categories': 'Değerlendirme Kategorisi',
  'courseReport.assessments': 'Değerlendirmeler',
  'courseReport.periods': 'Dönemler',
  'courseReport.topics-tooltip': 'Konular',
  'courseReport.obj': 'Öğrenme Hedefleri',
  'courseReport.OBJ': 'Öğrenme Hedeflerİ',
  'courseReport.obj-tooltip': 'Öğrenme Hedefleri',
  'courseReport.field': 'Soru Özellikleri',
  'courseReport.field-tooltip': 'Soru Özellikleri',
  'courseReport.select': 'Seçiniz',
  'courseReport.apply': 'Filtreleri Uygula',
  'courseReport.reset': 'Sıfırla',
  'courseReport.filter': 'Değerlendirmeye göre filtrele',
  'courseReport.share': 'Adaylarla Paylaşın',
  'courseReport.fieldPerformance': 'Soru Özellikleri {param} Performansı',
  'courseReport.courseName': 'Ders adı',
  'courseReport.topicName': 'Konu adı',
  'courseReport.question': 'Soru',
  'courseReport.weight': 'Ağırlık (%)',
  'courseReport.all': 'Tüm {param}',
  'courseReport.selected': 'SEÇİLMİŞ {param}',
  'courseReport.options': 'Seçenekler',
  'courseReport.export': 'Seçilen Raporları Excel (.xlsx) Olarak Dışa Aktar',
  'courseReport.scoreRange': 'Puan Aralığı (%)',
  'courseReport.avgScore': 'Ortalama Puan (%)',
  'courseReport.share-tooltip': '-',
  'courseReport.radarTopicTitle': 'Konulara Göre Radar Grafiği',
  'courseReport.radarObjTitle': 'Öğrenme Hedeflerine Göre Radar Grafiği',
  'courseReport.radarCourseTitle': 'Derslere Göre Radar Grafiği',
  'courseReport.uncategorized': 'Sınıflandırılmamış',
  'courseReport.periodUndefined': 'Dönemi tanımsız',
  'courseReport.parentUnit': 'Ana Birim',
  'courseReport.units': 'Tüm Alt Birimler',
  'courseReport.unusedQuestionTooltip':
    'Raporlarda herhangi bir sınavda kullanılmayan sorulara yer verilmemektedir.',
};
