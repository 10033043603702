import { useContext } from 'react';
import { isNullOrEmpty } from '../utility/collectionUtil';
import { isActive, roleContexts } from './Role';
import {
  SETTINGS_PERMISSIONS,
  UNIT_PERMISSIONS,
  PERIODS_PERMISSIONS,
  EXAM_SETTINGS_PERMISSIONS,
  QUESTION_ATTRIBUTE_PERMISSIONS,
  USER_PERMISSIONS,
  USER_GROUP_PERMISSIONS,
  ROLE_PERMISSIONS,
  ASSESSMENT_PERMISSIONS,
  PLAN_DETAIL_PERMISSIONS,
  QUESTION_PERMISSIONS,
  REPORT_PERMISSIONS,
  RUBRIC_PERMISSIONS,
  CATEGORY_PERMISSIONS,
  PROCTORING_RULES_PERMISSIONS,
  LEARNING_OBJECTIVE_PERMISSIONS,
} from '../configs/permissions';
import { MainContext } from '../store/Store';
import { useIntl } from 'react-intl';

// ** Supported User Types
export const userTypes = {
  AUTHORIZED: 'AUTHORIZED',
  CANDIDATE: 'CANDIDATE',
};

// ** Supported User Verification status
export const userVerifications = {
  VERIFIED: 'VERIFIED',
  NOT_VERIFIED: 'NOT_VERIFIED',
};

export const userStatuses = {
  ACTIVE: 'ACTIVE',
  PASSIVATED: 'PASSIVATED',
  PENDING_INVITATION: 'PENDING_INVITATION',
};

export const userImportTypes = ['APPEND', 'OVERWRITE', 'REFRESH'];

export const importSummaryTypes = {
  unappropriateRows: 'unappropriateRows',
  updatedUsers: 'updatedUsers',
  importedUsers: 'importedUsers',
  totalUser: 'totalUser',
};

export const userImportStatus = {
  IMPORTED: 'IMPORTED',
  UPDATED: 'UPDATED',
  OPERATION_FAILED: 'OPERATION_FAILED',
  APPROPRIATE: 'APPROPRIATE',
  UNAPPROPRIATE: 'UNAPPROPRIATE',
};

export const userImportTableHeads = [
  'ROW',
  'STATUS',
  'SYSTEM_ID',
  'NAME',
  'LAST_NAME',
  'EMAIL',
  'TYPE',
  'GROUP',
  'AUTH_TYPE',
];

export const AUTH_TYPES = {
  PASSWORD: 'PASSWORD',
  OAUTH_2: 'OAUTH_2',
};
export const USER_INTRODUCE_ORIGIN = {
  USER_ADD: 'USER_ADD',
  INVITED_TO_ASSESSMENT: 'INVITED_TO_ASSESSMENT',
};

export const GetUserStatusLocalisedText = (userStatus) => {
  let intl = useIntl();
  if (!userStatus) {
    return null;
  }
  let userStatusLocalisedText = intl.formatMessage({
    id: 'userStatus.' + userStatus.toLowerCase(),
  });

  return userStatusLocalisedText;
};

// ** Gets Full name of the User
export const getFullName = (user) => {
  if (user == null || user.profile == null) {
    return '';
  }

  if (user.profile.name == null || user.profile.surname == null) {
    return '';
  }

  return `${user.profile.name} ${user.profile.surname}`;
};

/*
 * Gets Role representation of the User
 */
export const getRoleRepresentation = (user) => {
  if (user == null || isNullOrEmpty(user.roles)) {
    return '';
  }

  return user.roles[0].role.name;
};

/*
 * Gets all role representation of the User
 */
export const getAllRoleRepresentation = (user) => {
  if (user == null || isNullOrEmpty(user.roles)) {
    return '';
  }

  let unitRoles = user.roles.filter(
    (e) => e.role.context === roleContexts.UNIT
  );
  let systemRoles = user.roles.filter(
    (e) => e.role.context === roleContexts.SYSTEM
  );
  let roles = systemRoles.concat(unitRoles);

  return roles.map((e) => e.role.name).join(', ');
};

/*
 * Gets User Group representation of the User
 */
export const getUserGroupRepresentation = (user) => {
  if (user == null || isNullOrEmpty(user.userGroups)) {
    return '';
  }

  return user.userGroups[0].groupName;
};

/*
 * Gets All User Groups representation of the User
 */
export const getAllUserGroupsRepresentation = (user) => {
  if (user == null || isNullOrEmpty(user.userGroups)) {
    return '';
  }
  let filteredGroups = user.userGroups.filter((e) => !e.temp);
  return filteredGroups.map((e) => e.groupName).join(', ');
};

/*
 * Gets the organization of the user
 */
export const getOrganization = (user) => {
  if (user == null) {
    return '';
  }

  return user.organization.name;
};

/*
 * Gets Avatar Letter
 */
export const getAvatarLetters = (user) => {
  if (user) {
    if (user.profile && user.profile.name && user.profile.surname) {
      let letters = (
        user.profile.name.substring(0, 1) + user.profile.surname.substring(0, 1)
      ).toUpperCase();
      return [letters, (letters.charCodeAt(0) + letters.charCodeAt(1)) % 6];
    } else {
      if (user.username) {
        let letter = user.username.substring(0, 1).toUpperCase();
        return [letter, letter.charCodeAt(0) % 6];
      }
    }
  }
  return ['E', 0];
};

/*
 * Checks if user is authorised
 */
export const isUserAuthorised = (user) => {
  return user.type === userTypes.AUTHORIZED;
};

/*
 * Checks if user is candidate
 */
export const isUserCandidate = (user) => {
  return user.type === userTypes.CANDIDATE;
};

/*
 * Checks if the user has permission
 */
export const userHasPermission = (user, permision) => {
  if (user == null) {
    return false;
  }

  if (isNullOrEmpty(user.roles)) {
    return false;
  }

  for (let i = 0; i < user.roles.length; i++) {
    if (isActive(user.roles[i].role.state)) {
      for (let j = 0; j < user.roles[i].role.permissions.length; j++) {
        if (user.roles[i].role.permissions[j].type == permision) {
          return true;
        }
      }
    }
  }

  return false;
};
/*
 * Checks if the user has permission
 */
export const userHasSystemPerm = (user, permission) => {
  if (user == null) {
    return false;
  }

  if (isNullOrEmpty(user.roles)) {
    return false;
  }

  for (let i = 0; i < user.roles.length; i++) {
    if (isActive(user.roles[i].role.state) && user.roles[i].organizationLevel) {
      for (let j = 0; j < user.roles[i].role.permissions.length; j++) {
        if (user.roles[i].role.permissions[j].type == permission) {
          return true;
        }
      }
    }
  }

  return false;
};

/*
 * Checks the given user has any of the given permission
 */
export const userHasAnyOfPermissions = (
  user,
  permissions,
  checkSystemRolesOnly
) => {
  let result = false;

  if (user == null || permissions == null) {
    return result;
  }

  if (isNullOrEmpty(permissions)) {
    return result;
  }

  if (checkSystemRolesOnly) {
    permissions.forEach((element) => {
      result = result || userHasSystemPerm(user, element);
    });
  } else {
    permissions.forEach((element) => {
      result = result || userHasPermission(user, element);
    });
  }

  return result;
};

/*
 * Checks if the current user has any of the given permissions
 */
export const CurrentUserHasAnyOfPermissions = (permissions) => {
  return userHasAnyOfPermissions(GetCurrentUser(), permissions);
};

/*
 * Rerturn current user from session
 */
const GetCurrentUser = () => {
  const { state } = useContext(MainContext);
  return state.user;
};

/*
 * ACCOUNT DETAILS PERMISSIONS
 *
 * Checks for plan detail permissions
 * If user can browse details
 * If user can make a new order
 * If user can do payment
 * If user can browse billing
 */
export const UserCanBrowsePlanDetails = () => {
  return userHasPermission(GetCurrentUser(), PLAN_DETAIL_PERMISSIONS.BROWSE);
};

export const UserCanOrder = () => {
  return userHasPermission(GetCurrentUser(), PLAN_DETAIL_PERMISSIONS.ORDER);
};

export const UserCanDoPayment = () => {
  return userHasPermission(GetCurrentUser(), PLAN_DETAIL_PERMISSIONS.PAY);
};

export const UserCanBrowseBilling = () => {
  return userHasPermission(GetCurrentUser(), PLAN_DETAIL_PERMISSIONS.BILLING);
};

/*
 * SETTINGS PERMISSION CHECKS
 *
 * Checks for General Settings
 * If user can browse settings
 * If user can update settings
 */
export const UserCanBrowseSettings = () => {
  return userHasPermission(GetCurrentUser(), SETTINGS_PERMISSIONS.BROWSE);
};

export const UserCanUpdateSettings = () => {
  return userHasPermission(GetCurrentUser(), SETTINGS_PERMISSIONS.UPDATE);
};

/*
 * UNIT PERMISSIONS
 *
 * Checks for Unit Permissions
 * If user can browse units
 * If user can create new units
 * If user can update a unit
 * If user can delete a unit
 */
export const UserCanBrowseUnits = () => {
  return userHasPermission(GetCurrentUser(), UNIT_PERMISSIONS.BROWSE);
};

export const UserCanCreateUnit = () => {
  return userHasPermission(GetCurrentUser(), UNIT_PERMISSIONS.CREATE);
};

export const UserCanUpdateUnit = () => {
  return userHasPermission(GetCurrentUser(), UNIT_PERMISSIONS.UPDATE);
};

export const UserCanDeleteUnit = () => {
  return userHasPermission(GetCurrentUser(), UNIT_PERMISSIONS.DELETE);
};

/*
 * PERIODS PERMISSIONS
 *
 * Checks for Peroiod permissions
 * If user can browse periods
 * If user can create new period
 * If user can update a period
 * If user can delete a period
 */
export const UserCanBrowsePeriods = () => {
  return userHasPermission(GetCurrentUser(), PERIODS_PERMISSIONS.BROWSE);
};

export const UserCanCreatePeriod = () => {
  return userHasPermission(GetCurrentUser(), PERIODS_PERMISSIONS.CREATE);
};

export const UserCanUpdatePeriod = () => {
  return userHasPermission(GetCurrentUser(), PERIODS_PERMISSIONS.UPDATE);
};

export const UserCanDeletePeriod = () => {
  return userHasPermission(GetCurrentUser(), PERIODS_PERMISSIONS.DELETE);
};

/*
 * ASESSMENT PRODCTORING RULES PERMISSIONS
 *
 * Checks for Assessment proctoring rules permissions
 * If user can browse rules
 * If user can update assessment proctoring rules
 */
export const UserCanBrowseAssessmentProctoringRules = () => {
  return userHasPermission(
    GetCurrentUser(),
    PROCTORING_RULES_PERMISSIONS.BROWSE
  );
};

export const UserCanUpdateAssessmentProctoringRules = () => {
  userHasPermission(GetCurrentUser(), PROCTORING_RULES_PERMISSIONS.UPDATE);
};

/*
 * ASSESSMENT SETTINGS PERMISSIONS
 *
 * Checks for assessment settings permissions
 * If user can browse settings
 * If user can update settings
 */
export const UserCanBrowseAssessmentSettings = () => {
  return userHasPermission(GetCurrentUser(), EXAM_SETTINGS_PERMISSIONS.BROWSE);
};

export const UserCanUpdateAssessmentSettings = () => {
  return userHasPermission(GetCurrentUser(), EXAM_SETTINGS_PERMISSIONS.UPDATE);
};

/*
 * ASSESSMENT CATEGORY PERMISSIONS
 *
 * Checks for assessment category permissions
 * If user can browse assessment categories
 * If user can create new assessment category
 * If user can update an existing category
 * If user can delete an existing category
 */
export const UserCanBrowseAssessmentCategories = () => {
  return userHasPermission(GetCurrentUser(), CATEGORY_PERMISSIONS.BROWSE);
};

export const UserCanCreateAssessmentCategory = () => {
  return userHasPermission(GetCurrentUser(), CATEGORY_PERMISSIONS.CREATE);
};

export const UserCanUpdateAssessmentCategory = () => {
  return userHasPermission(GetCurrentUser(), CATEGORY_PERMISSIONS.UPDATE);
};

export const UserCanDeleteAssessmentCategory = () => {
  return userHasPermission(GetCurrentUser(), CATEGORY_PERMISSIONS.DELETE);
};

/*
 * QUESTION ATTRIBUTES PERMİSSİONS
 *
 * Checks for question attributes permissions
 * If user can browse question attributes
 * If user can create a new question attribute
 * If user can update an existing question attribute
 * If user can delete an existing question attribute
 */
export const UserCanBrowseTags = () => {
  return userHasPermission(
    GetCurrentUser(),
    QUESTION_ATTRIBUTE_PERMISSIONS.BROWSE
  );
};

export const UserCanCreateTag = () => {
  return userHasPermission(
    GetCurrentUser(),
    QUESTION_ATTRIBUTE_PERMISSIONS.CREATE
  );
};

export const UserCanUpdateTag = () => {
  return userHasPermission(
    GetCurrentUser(),
    QUESTION_ATTRIBUTE_PERMISSIONS.UPDATE
  );
};

export const UserCanDeleteTag = () => {
  return userHasPermission(
    GetCurrentUser(),
    QUESTION_ATTRIBUTE_PERMISSIONS.DELETE
  );
};

/*
 * USER PERMISSIONS
 *
 * Checks for user permissions
 * If user can browse users
 * If user can add a new user
 * If user can update an existing user
 * If user can delete an existing user
 * If user can invite a new user
 */
export const UserCanBrowseUsers = () => {
  return userHasPermission(GetCurrentUser(), USER_PERMISSIONS.BROWSE);
};

export const UserCanCreateUser = () => {
  return userHasPermission(GetCurrentUser(), USER_PERMISSIONS.CREATE);
};

export const UserCanEditUser = () => {
  return userHasPermission(GetCurrentUser(), USER_PERMISSIONS.UPDATE);
};

export const UserCanInviteUser = () => {
  return userHasPermission(GetCurrentUser(), USER_PERMISSIONS.INVITE);
};

/*
 * USER GROUP OPERATIONS
 *
 * Checks for user group permissions
 * If user can browse user groups
 * If user can create a new user group
 * If user can update an existing user group
 * If user can delete an existing user group
 */
export const UserCanBrowseGroup = () => {
  return userHasPermission(GetCurrentUser(), USER_GROUP_PERMISSIONS.BROWSE);
};

export const UserCanCreateGroup = () => {
  return userHasPermission(GetCurrentUser(), USER_GROUP_PERMISSIONS.CREATE);
};

export const UserCanEditGroup = () => {
  return userHasPermission(GetCurrentUser(), USER_GROUP_PERMISSIONS.UPDATE);
};

export const UserCanDeleteGroup = () => {
  return userHasPermission(GetCurrentUser(), USER_GROUP_PERMISSIONS.DELETE);
};

/*
 * USER ROLE PERMISSIONS
 *
 * Checks for the user role permissions
 * If user can browse roles
 * IF user can create a new role
 * If user can update an existing role
 * If user can delete an existing role
 * If user can grant a role to an existing user
 * IF user can roveke of an existing user's role
 */
export const UserCanBrowseRoles = () => {
  return userHasPermission(GetCurrentUser(), ROLE_PERMISSIONS.BROWSE);
};

export const UserCanCreateRole = () => {
  return userHasPermission(GetCurrentUser(), ROLE_PERMISSIONS.CREATE);
};

export const UserCanEditRole = () => {
  return userHasPermission(GetCurrentUser(), ROLE_PERMISSIONS.UPDATE);
};

export const UserCanDeleteRole = () => {
  return userHasPermission(GetCurrentUser(), ROLE_PERMISSIONS.DELETE);
};

export const UserCanGrantRole = () => {
  return userHasPermission(GetCurrentUser(), ROLE_PERMISSIONS.GRANT);
};

export const UserCanRevokeRole = () => {
  return userHasPermission(GetCurrentUser(), ROLE_PERMISSIONS.REVOKE);
};

/*
 * ASSESSMENT PERMISSIONS
 *
 * Checks for assessment permissions
 * If user can browse assessments
 * If user can create a new assessment
 * If user can update an existing assessment
 * If user can delete an existing assessment
 * If user can assess an assessment
 * If user can announce assessment results
 */
export const UserCanBrowseAssessments = () => {
  return userHasPermission(GetCurrentUser(), ASSESSMENT_PERMISSIONS.BROWSE);
};

export const UserCanCreateAssessment = () => {
  return userHasPermission(GetCurrentUser(), ASSESSMENT_PERMISSIONS.CREATE);
};

export const UserCanUpdateAssessment = () => {
  return userHasPermission(GetCurrentUser(), ASSESSMENT_PERMISSIONS.UPDATE);
};

export const UserCanDeleteAssessment = () => {
  return userHasPermission(GetCurrentUser(), ASSESSMENT_PERMISSIONS.DELETE);
};

export const UserCanAssess = () => {
  return userHasPermission(GetCurrentUser(), ASSESSMENT_PERMISSIONS.ASSESS);
};

export const UserCanAnnounceAssessmentResults = () => {
  return userHasPermission(GetCurrentUser(), ASSESSMENT_PERMISSIONS.ANNOUNCE);
};

/*
 * QUESTION PERMISSIONS
 *
 * Checks for question management permissions
 * If user can browsee questions
 * If user can add a new question
 * If user can update an existing question
 * If user can delete an existing question
 * If user can approve a question in review
 * If user can reject a question in review
 */
export const UserCanBrowseQuestions = () => {
  return userHasPermission(GetCurrentUser(), QUESTION_PERMISSIONS.BROWSE);
};

export const UserCanCreateQuestion = () => {
  return userHasPermission(GetCurrentUser(), QUESTION_PERMISSIONS.CREATE);
};

export const UserCanEditQuestion = () => {
  return userHasPermission(GetCurrentUser(), QUESTION_PERMISSIONS.UPDATE);
};

export const UserCanDeleteQuestion = () => {
  return userHasPermission(GetCurrentUser(), QUESTION_PERMISSIONS.DELETE);
};

export const UserCanApproveQuestion = () => {
  return userHasPermission(GetCurrentUser(), QUESTION_PERMISSIONS.APPROVE);
};

export const UserCanRejectQuestion = () => {
  return userHasPermission(GetCurrentUser(), QUESTION_PERMISSIONS.REJECT);
};

/*
 * UNIT / COURSE OBJECTIVES PERMISSIONS
 *
 * Checks for learning objective permissions
 * If user can browse learning objectives
 * If user can create a new learning objective
 * If user can update an existing learning objective
 * If user can delete an existing learning objective
 */
export const UserCanBrowseLearningObjectives = () => {
  return userHasPermission(
    GetCurrentUser(),
    LEARNING_OBJECTIVE_PERMISSIONS.BROWSE
  );
};

export const UserCanCreateLearningObjective = () => {
  return userHasPermission(
    GetCurrentUser(),
    LEARNING_OBJECTIVE_PERMISSIONS.CREATE
  );
};

export const UserCanUpdateLearningObjective = () => {
  return userHasPermission(
    GetCurrentUser(),
    LEARNING_OBJECTIVE_PERMISSIONS.UPDATE
  );
};

export const UserCanDeleteLearningObjective = () => {
  return userHasPermission(
    GetCurrentUser(),
    LEARNING_OBJECTIVE_PERMISSIONS.DELETE
  );
};

/*
 * RUBRICS PERMISSIONS
 *
 * Checks for rubrics permissions
 * If user can browse rubrics
 * If user can create rubrics
 * If user can update an existing rubric
 * If user can delete an existing rubric
 */
export const UserCanBrowseRubrics = () => {
  return userHasPermission(GetCurrentUser(), RUBRIC_PERMISSIONS.BROWSE);
};

export const UserCanCreateRubric = () => {
  return userHasPermission(GetCurrentUser(), RUBRIC_PERMISSIONS.CREATE);
};

export const UserCanEditRubric = () => {
  return userHasPermission(GetCurrentUser(), RUBRIC_PERMISSIONS.UPDATE);
};

export const UserCanDeleteRubric = () => {
  return userHasPermission(GetCurrentUser(), RUBRIC_PERMISSIONS.DELETE);
};

/*
 * REPORTS PERMISSIONS
 *
 * If user can browse Reports
 */
export const UserCanBrowseReports = () => {
  return userHasPermission(GetCurrentUser(), REPORT_PERMISSIONS.BROWSE);
};
