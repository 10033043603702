export default {
  'rubrics.title': 'Rubrics',
  'rubrics.info': 'Manage the rubrics for assess performance',
  'rubrics.emptyInfo':
    'Create the first rubric for this course',
  'rubrics.name': 'Rubric Name',
  'rubrics.obj': 'Rubric',
  'rubrics.nameUpper': 'RUBRIC NAME',
  'rubrics.description': 'Description',
  'rubrics.status': 'status',
  'rubrics.actions': 'actions',
  'rubrics.create': 'Create Rubric',
  'rubrics.editRubric': 'Edit Rubric',
  'rubrics.copyRubric': 'Generate a New Copy from Existing Rubric',
  'rubrics.editInfo': 'Edit existing rubric for assess performance',
  'rubrics.copyInfo':
    'Generate a new copy of the existing rubric for assess performance',
  'rubrics.createInfo': 'Create a new rubric for assess performance',
  'rubrics.edit': 'Edit',
  'rubrics.delete': 'Delete',
  'rubrics.duplicate': 'Duplicate',
  'rubrics.search': 'Search',
  'rubrics.deleteConfirmationTitle': 'Delete rubric',
  'rubrics.deleteConfirmationMessage':
    'Are you sure to delete the rubric? This operation cannot be reversed.',
  'rubrics.deleteConfirmationConfirmButton': 'Yes, delete rubric',
  'rubrics.deleteConfirmationCancelButton': 'Cancel',
  'rubrics.deleteAlert': 'Rubric has been successfully deleted.',
  'rubrics.reset': 'Reset',
  'rubrics.minMessageName': 'Rubric name should be at least 2 characters.',
  'rubrics.maxMessageName': 'Rubric name should be max 100 characters.',
  'rubrics.maxMessageDesc': 'Rubric description should be max 300 characters.',
  'rubrics.createStatus': 'Active / Passive',
  'rubrics.criteria': 'CRITERIA',
  'rubrics.excellent': 'EXCELLENT',
  'rubrics.satisfactory': 'SATISFACTORY',
  'rubrics.unsatisfactory': 'UNSATISFACTORY',
  'rubrics.poor': 'POOR',
  'rubrics.criteria-tooltip':
    "Criteria to be evaluated in the candidate's performance.",
  'rubrics.descriptionRow': 'Add a description.',
  'rubrics.newCriteria': 'New Criteria',
  'rubrics.newColName': 'LEVEL NAME',
  'rubrics.colNameAlert':
    'Criteria name should be at least 1 and at most 30 characters.',
  'rubrics.rowPerAlert':
    'Criteria percentage must be between 0 and 100 percent.',
  'rubrics.rowDescAlert':
    'Criteria description should be at most 250 characters.',
  'rubrics.rowCriteriaAlert':
    'Criteria name should be at least 1 and at most 250 characters.',
  'rubrics.associate': 'Associate with...',
  'rubrics.total': '{param} of total grade',
  'rubrics.drag': 'drag',
  'rubrics.option': 'Option',
  'rubrics.learningObj': 'Learning Objectives',
  'rubrics.associateNotFounded': 'Please make a selection or exit the pop up.',
  'rubrics.addSuccessAlert': 'Rubric created successfully!',
  'rubrics.editSuccessAlert': 'Rubric updated successfully!',
  'rubrics.addAlert':
    'Complete the effect of the criteria on the total score to 100%.',
  'rubrics.quitMessage':
    'Your changes will be lost. Are you sure you want to quit?',
  'rubrics.showPassiveStatus': 'Show passive rubrics',
  'rubrics.per': '{per}%',
  'rubrics.learningObjContent': 'Learning Objective :',
  'rubrics.ok': 'Ok',
  'rubrics.deleteInfoConfirmationMessage':
    'Rubric could not be deleted, at least 1 exam has been made and/or is planned. In such cases, you can passivate the rubric.',
  'rubrics.copyAlert': 'Copied rubric name cannot be the same!',
  'rubrics.editNameConfirmationTitle': 'Edit rubric',
  'rubrics.editNameConfirmationMessage':
    'Are you sure to edit the rubric`s name? This operation cannot be reversed.',
  'rubrics.editNameConfirmationConfirmButton': 'Yes, edit rubric',
  'rubrics.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'rubrics.messageInformation': 'There is no {titleValue} in this course of your organization yet.',
  'rubrics.messageInformationAdd': 'Get started by adding the {titleValue} you want to assess performance!', 
};
