export default {
  'resetMail.title': 'Check Your E-mail',
  'resetMail.subtitle':
    'We have sent password reset instructions to <b>{userMail}</b>.',
  'resetMail.login': 'Log in Examod',
  'resetMail.emailNotSent': '',
  'resetMail.support':
    'Did not receive the e-mail? Check your <b>spam</b> folder or <send>send the e-mail again</send>.{br}Need help? Contact us at <contact>support@examod.com</contact>.',
  'resetMail.emailSendAgain': 'Password reset instructions sent again.',
};
