export default {
  'assessmentAdd.setup': 'Değerlendirme Ayarları',
  'assessmentAdd.setupInfo': 'Ayarları belirleyin',
  'assessmentAdd.context': 'Değerlendirme Bağlamı',
  'assessmentAdd.contextInfo': 'Birimleri ve dersleri seçin ',
  'assessmentAdd.content': 'Değerlendirme İçeriği',
  'assessmentAdd.contentInfo': 'İçeriği oluşturun, puanları belirleyin',
  'assessmentAdd.delivery': 'Dağıtım Kurulumu',
  'assessmentAdd.deliveryInfo': 'Değerlendirmenin dağıtımını ayarlayın',
  'assessmentAdd.name': 'Değerlendirme Adı',
  'assessmentAdd.type': 'Değerlendirme Türü',
  'assessmentAdd.category': 'Değerlendirme Kategorisi',
  'assessmentAdd.desc': 'Değerlendirme Açıklaması ',
  'assessmentAdd.descInfo':
    'Bu metin değerlendirme aşamasından önce adaylara gösterilecektir',
  'assessmentAdd.process': 'Sınav Yürütme Seçenekleri',
  'assessmentAdd.results': 'Sonuçların İlanı',
  'assessmentAdd.grading': 'Notlandırma',
  'assessmentAdd.next': 'Sonraki Adım',
  'assessmentAdd.shuffleQuestions': 'Tüm soruları karıştır.',
  'assessmentAdd.shuffleOptions':
    'Soruların seçeneklerini karıştır (Sadece ÇSS).',
  'assessmentAdd.5min': 'Son beş (5) dakikanız kaldı uyarısını göster.',
  'assessmentAdd.shuffleSections': 'Bölümleri karıştır (Varsa).',
  'assessmentAdd.navigation': 'Sınavdaki sorular arasında gezinmeye izin ver.',
  'assessmentAdd.highlight': 'İmleç ile soru metnini vurgulama.',
  'assessmentAdd.elimination': 'Seçenek eleme (Sadece ÇSS).',
  'assessmentAdd.MANUALLY_BY_OWNER':
    'Değerlendirme yaratıcısı tarafından elle ilan edilsin.',
  'assessmentAdd.INSTANT_PRELIMINARY_RESULT':
    'Değerlendirme sonucunu adayın uygulaması tamamlandığında göster.',
  'assessmentAdd.PRELIMINARY_RESULT':
    'Değerlendirme sonucunu uygulama tarihi bitiminde göster.',
  'assessmentAdd.schedule': 'Süre ve Takvim',
  'assessmentAdd.minute': 'dakika',
  'assessmentAdd.hour': 'saat',
  'assessmentAdd.activate': 'Etkinleştir',
  'assessmentAdd.start': 'Değerlendirmenin başlangıcı:',
  'assessmentAdd.end': 'Değerlendirmenin bitişi:',
  'assessmentAdd.duration': 'Süre:',
  'assessmentAdd.timeOver': 'Süre dolduğunda:',
  'assessmentAdd.valid': 'Önceki yanıtlar gönderilsin ve sınav tamamlansın',
  'assessmentAdd.invalid':
    'Verilen cevaplar geçersiz sayılsın ve sınav tamamlansın',
  'assessmentAdd.select': 'Seçiniz',
  'assessmentAdd.required':
    'Değerlendirme adı, kategorisi, türü, başlangıç ve bitiş zamanı alanları boş bırakılamaz',
  'assessmentAdd.durationRequired': 'Sınav süresi en az 1 dakika olmalıdır.',
  'assessmentAdd.dateWarning':
    'Bitiş tarihi başlangıç tarihinden büyük olmalıdır',
  'assessmentAdd.passedDateWarn':
    'Değerlendirme bitiş tarihi güncel tarih veya saatten önce olacak şekilde belirlenemez.',
  'assessmentAdd.timeWarning':
    'Belirlediğiniz sınav süresi, sınav bitişi saati ile sınav başlangıç saati farkından daha fazladır. Sınav süresini bu aralıkta tutmalısınız.',
  'assessmentAdd.descMaxLength':
    'Değerlendirme açıklaması 4000 karakterden fazla olamaz',
  'assessmentAdd.ABSOLUTE': 'Mutlak',
  'assessmentAdd.GLOBAL_RATING':
    'Genel değerlendirme (Yalnızca dereceli puanlama anahtarında)',
  'assessmentAdd.criteria1Info':
    'Belirlenmiş bir geçme puanına göre adayın başarılı olma durumunu tanımlayan değerlendirme türüdür.',
  'assessmentAdd.BELL_CURVE': 'Bağıl (Çan Eğrisi)',
  'assessmentAdd.criteria2Info':
    "Bağıl notlandırma, belirlenen herhangi bir ölçütün, ölçümün yapıldığı grubun başarısı üzerinden değerlendirildiği notlandırma türüdür. Examod'da bağıl notlandırmanın en yaygın kullanılan türü olan normal dağılım (Gauss dağılımı) yani bilinen adıyla 'Çan eğrisi' türü kullanılmaktadır.",
  'assessmentAdd.criteria3Info': '-',
  'assessmentAdd.criteria': 'Geçme ölçütü:',
  'assessmentAdd.method': 'Değerlendirme yöntemi:',
  'assessmentAdd.MANUAL': 'Elle değerlendirme',
  'assessmentAdd.RUBRIC': 'Dereceli puanlama anahtarı',
  'assessmentAdd.forPass': 'Geçme puanı:',
  'assessmentAdd.points': 'Puan',
  'assessmentAdd.highestScore': 'En yüksek puan: ',
  'assessmentAdd.purify': 'Rastgele tahmin düzeltmesi',
  'assessmentAdd.purifyInfo':
    'Rastgele tahmin etmeyi caydırmak için, adayların çoktan seçmeli sorularda verdikleri her bir yanlış yanıt için o sorunun seçenek sayısına göre negatif puanlama yapılır. Örneğin, beş seçenekli ve 10 puan değerindeki bir soruya tahmin yapılarak yanlış yanıt verildiğinde; sorunun puanları 5-1=4 dilime ayrılır ve bir dilim yani -2.5 negatif puan uygulanır.',
  'assessmentAdd.numberMessage': '(Bu alan karakter içeremez!)',
  'assessmentAdd.selectResultMessage':
    'Sonuçların nasıl ilan edildiğine ilişkin bir seçenek seçmelisiniz.',
  'assessmentAdd.minMessage': 'Değerlendirme adı en az 5 karakter olmalıdır.',
  'assessmentAdd.maxMessage':
    'Değerlendirme adı en fazla 100 karakter olmalıdır.',
  'assessmentAdd.patternMessage':
    "Değerlendirme adı yalnızca harf, rakam ve - _ . , ; : ' @ / \\ ( ) [ ] '{ }' sembolleri içerebilir.",
  'assessmentAdd.passPointWarning':
    'Mutlak notlandırma türü için bir geçme puanı girilmelidir.',
  'assessmentAdd.PAPER_BASED': 'Optik Kağıt Tabanlı',
  'assessmentAdd.PAPER_BASED_CLASSIC': 'Klasik Kağıt Tabanlı',
  'assessmentAdd.PERFORMANCE_EXAM': 'Performans',
  'assessmentAdd.ONLINE': 'Çevrim İçi',
  'assessmentAdd.ON_SITE': 'Yerinde / Test Merkezinde',
  'assessmentAdd.NONE': 'Yok',
  'assessmentAdd.empty': 'Bu alan boş bırakılamaz!',
  'assessmentAdd.method1Info':
    'Adayın performansını belirlenen ölçüt göre değerlendirin.',
  'assessmentAdd.method0Info': '-',
  'assessmentAdd.displayDetails': 'Adaylara gösterilecek olan ayrıntılar: ',
  'assessmentAdd.displayDetailsCriteria':
    'Ölçütler ve performans düzeyi ifadeleri',
  'assessmentAdd.displayDetailsScore': 'Puan değerleri',
  'assessmentAdd.exameditAlert':
    'Sınav başlangıç saatinin son 15 dakikası ile başlangıç saat ve tarihinden sonra sınava ilişkin detaylar düzenlenemez sadece izlenebilir.',
  'assessmentAdd.examCancelledAlert':
    'İptal edilen sınava ilişkin detaylar düzenlenemez sadece izlenebilir.',
  'assessmentAdd.editPaperBased_info_text':
    'Düzenleme sırasında herhangi bir parametre değişirse tüm kitapçıklar değiştirilen verilere göre yeniden oluşturulur, yüklenmiş CSV ve aday sonuçları varsa silinir. Bu sonuçlara ait veriler sınav, soru, aday bağlamında hiçbir istatistiğe dahil edilmez.',
  'assessmentAdd.editPaperBased_info_title': 'Önemli not',
  'assessmentAdd.ok': 'Anladım',
  'assessmentAdd.discardChanges': 'Değişiklikleri göz ardı et',
  'assessmentAdd.cancelCreateAssessmentPopupMessage':
    'Yaptığınız değişiklikler kaydedilmeyecektir. Tüm değişiklikleri silmek istediğinize emin misiniz?',
  'assessmentAdd.discard': 'Sil',
  'assessmentAdd.cancel': 'İptal',
  'assessmentAdd.alert_message_editExam_notStarted':
    'Düzenleme sırasında herhangi bir parametrenin değiştirilmesi durumunda, ilgili değerlendirme değiştirilen verilere göre yeniden oluşturulur.',
  'assessmentAdd.candShowOnlyQuestions': 'Sadece sorular',
  'assessmentAdd.candShowQuestionsAndAnswers':
    'Sadece sorular ve doğru yanıtlar',
  'assessmentAdd.candQuestionTrueness':
    'Sorular ve aday yanıtlarının doğru olup olmadığı',
  'assessmentAdd.candScoreAndDuration':
    'Adayın soru bazlı puanı ve harcadığı süresi',
  'assessmentAdd.candStats': 'Tüm adayların soru bazlı istatistikleri',
  'assessmentAdd.candidate': 'Aday',
  'assessmentAdd.candDisplayOptTitle':
    ' Sınav Sonrası Soru ve Yanıt Gösterim Seçenekleri',

  // report display options
  'assessmentAdd.candInsightAnalysis': 'Adayların Sınav İçgörü Analizleri',
  'assessmentAdd.reportDisplayOpt': 'Sonuç raporu görüntüleme seçenekleri',
  'assessmentAdd.onlyAnnounce': 'Sadece sonuçları ilan et',
  'assessmentAdd.showGraphsByCourses':
    'Derslere göre karşılaştırmalı analiz ve grafikler',
  'assessmentAdd.showGraphsByTopics':
    'Konulara göre karşılaştırmalı analiz ve grafikler',
  'assessmentAdd.showGraphsByObjectives':
    'Öğrenme hedeflerine göre karşılaştırmalı analiz ve grafikler',
  'assessmentAdd.showGraphsByQa':
    'Soru özelliklerine göre karşılaştırmalı analiz ve grafikler',
  'assessmentAdd.showGraphsByCriteria':
    'Ölçütlere göre karşılaştırmalı analizler ve grafikler',

  // context
  'assessmentAdd.unitCourseName': 'BİRİM / DERS ADI',
  'assessmentAdd.actions': 'İŞLEMLER',
  'assessmentAdd.shuffleUnitHint':
    'Birim, ders veya soruları karıştırma seçenekleri için <b>"İşlemler"</b> menüsü altındaki {shuffleIcon} simgesini kullanın.',
  'assessmentAdd.shuffleUnitHint1':
    '<b>Birim/Dersleri kendi içinde karıştır</b> özelliği, yalnızca seçilen birimin bir alt seviyesindeki birim/dersleri karıştırır.',
  'assessmentAdd.shuffleUnitHint2':
    '<b>Soruları kendi içinde karıştır</b> özelliği yalnızca seçilen ders içindeki soruları karıştırır.',
  'assessmentAdd.shuffleTooltipUnit':
    'Seçili birim/dersin bir alt seviyesinde yer alan {count} adet birim/ders kendi içinde karıştırılacaktır.',
  'assessmentAdd.shuffleTooltipCourse':
    'Seçili dersin soru bankasında yer alan sınava eklenecek sorular kendi içinde karıştırılacaktır.',
  'assessmentAdd.shuffleSelectionError':
    'Karıştırma tercihinde bulunmak için ilgili birim/ders seçilerek değerlendirmeye dahil edilmelidir.',
};
