export default {
  'assessmentPreview.next': 'Sonraki Soru',
  'assessmentPreview.prev': 'Önceki Soru',
  'assessmentPreview.question': 'Soru #{number}',
  'assessmentPreview.groupedQuestion':
    '{first}. - {last}. soruları aşağıdaki soru içeriğine göre yanıtlayınız.',
  'assessmentPreview.groupSingle':
    'Soru #{number} - <u>Doğru</u> seçeneği işaretleyiniz.',
  'assessmentPreview.choose': '<u>Doğru</u> seçeneği işaretleyiniz.',
  'assessmentPreview.panel': 'Sınav Paneli',
  'assessmentPreview.flagged': 'İşaretli',
  'assessmentPreview.unflagged': 'İşaretle',
  'assessmentPreview.attachments': 'Ekler',
  'assessmentPreview.attachmentWarn':
    'Bu soruda video/ses dosyası içeren {count} adet ek bulunmaktadır.',
  'assessmentPreview.questionDetails': 'Soru Detayları',
  'assessmentPreview.questionDetailsSub':
    'Soru detaylarını inceleyin ve düzenleyin',
  'assessmentPreview.course': 'Ders',

  'assessmentPreview.questionOwner': 'Sahibi',
  'assessmentPreview.questionApprovedDate': 'Onay Tarihi',
  'assessmentPreview.questionAddedDate': 'Eklenme Tarihi',
  'assessmentPreview.questionLo': 'Öğrenme Hedefi',
  'assessmentPreview.view': 'Görüntüle',
  'assessmentPreview.edit': 'Düzenle',
  'assessmentPreview.examReport': 'Sınav Raporu',
  'assessmentPreview.emptyQuestion':
    'Bu soru aday tarafından yanıtlanmamıştır.',
  'assessmentPreview.acceptedCORRECT': 'Herkes için doğru kabul edildi',
  'assessmentPreview.acceptedREMOVE': 'İptal edildi',
  'assessmentPreview.topic': 'Konu: {topic}',
  'assessmentPreview.averageDuration': 'Ortalama: {average}',
  'assessmentPreview.averagePoint': 'Genel Ort: {average}',
  'assessmentPreview.CORRECT-tooltip':
    'Soru, tüm yanıtlayanlar için doğru olarak kabul edilmiştir. Bu nedenle sorunun istatistikleri performans ekranına ve bu değerlendirmeye dahil edilmemiştir.',
  'assessmentPreview.REMOVE-tooltip':
    'Soru, bu değerlendirme için iptal edilmiştir. Bu nedenle sorunun istatistikleri performans ekranına ve bu değerlendirmeye dahil edilmemiştir.',
  'assessmentPreview.description': 'Değerlendirme Açıklaması',
};
