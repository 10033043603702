import React from 'react';

import { Badge } from 'reactstrap';

const RoleBadge = ({ item, notificationIcons }) => {
  return (
    <Badge className="role-badge emerald-badge">
      {notificationIcons[item.origin]}
      {JSON.parse(item.serializedContent).extraMap.role}
    </Badge>
  );
};

export default RoleBadge;
