export default {
  'assessmentAdd.setup': 'Assessment Setup',
  'assessmentAdd.setupInfo': 'Determine the setup details',
  'assessmentAdd.context': 'Assessment Context',
  'assessmentAdd.contextInfo': 'Select units and courses',
  'assessmentAdd.content': 'Assessment Content',
  'assessmentAdd.contentInfo': 'Build the content, define the scores',
  'assessmentAdd.delivery': 'Delivery Setup',
  'assessmentAdd.deliveryInfo': "Setup the assessment's delivery",
  'assessmentAdd.name': 'Assessment Name',
  'assessmentAdd.type': 'Assessment Type',
  'assessmentAdd.category': 'Assessment Category',
  'assessmentAdd.desc': 'Assessment Description ',
  'assessmentAdd.descInfo':
    'This text will be presented to the candidates before the evaluation process',
  'assessmentAdd.process': 'Exam Execution Options',
  'assessmentAdd.results': 'Announcement of Results',
  'assessmentAdd.grading': 'Grading',
  'assessmentAdd.next': 'Next Step',
  'assessmentAdd.shuffleQuestions': 'Mix all questions.',
  'assessmentAdd.shuffleSections': 'Mix the sections (If any).',
  'assessmentAdd.shuffleOptions':
    'Mix the options of the questions (Only for MCQ).',
  'assessmentAdd.5min': "Show 'you have five (5) minutes left' warning.",
  'assessmentAdd.navigation': 'Allow navigation between questions in the exam.',
  'assessmentAdd.highlight': 'Highlight the question text with the cursor.',
  'assessmentAdd.elimination': 'Option elimination (Only for MCQ).',
  'assessmentAdd.INSTANT_PRELIMINARY_RESULT':
    'Show result after the candidate finishes the exam.',
  'assessmentAdd.MANUALLY_BY_OWNER':
    'Results are announced by the creator manually.',
  'assessmentAdd.PRELIMINARY_RESULT': 'Show result after the exam ends.',
  'assessmentAdd.schedule': 'Time Limits and Schedule',
  'assessmentAdd.minute': 'minute',
  'assessmentAdd.hour': 'hour',
  'assessmentAdd.activate': 'Activate',
  'assessmentAdd.start': 'Beginning of assessment:',
  'assessmentAdd.end': 'Finish of assessment:',
  'assessmentAdd.duration': 'Duration:',
  'assessmentAdd.timeOver': 'When time is over',
  'assessmentAdd.valid': 'Send given answers and complete the exam',
  'assessmentAdd.invalid':
    'Consider given answers invalid and complete the exam',
  'assessmentAdd.required':
    'Assessment name, category type, start and finish time fields cannot be blank',
  'assessmentAdd.durationRequired':
    'Exam duration should be at least 1 minute.',
  'assessmentAdd.dateWarning':
    'The end date must be greater than the start date',
  'assessmentAdd.passedDateWarn':
    'The assessment end date cannot be set earlier than the current date or time.',
  'assessmentAdd.timeWarning':
    'The duration must not exceed the time difference between exam end and exam start.',
  'assessmentAdd.descMaxLength':
    'Assessment description must be less than 4000 characters long',
  'assessmentAdd.ABSOLUTE': 'Absolute',
  'assessmentAdd.GLOBAL_RATING': 'Global rating (Only rubrics)',
  'assessmentAdd.criteria1Info':
    'It is a type of evaluation that defines the success of the candidate according to a specified passing score.',
  'assessmentAdd.BELL_CURVE': 'Relative (Bell Curve)',
  'assessmentAdd.criteria2Info':
    "Relative grading is a type of grading in which any specified criterion is evaluated over the success of the group in which the measurement is made. The most widely used type of relative grading in Examod is the normal distribution (Gaussian distribution), known as the 'Bell curve'.",
  'assessmentAdd.criteria3Info': '-',
  'assessmentAdd.criteria': 'Passing criteria:',
  'assessmentAdd.method': 'Assessment method:',
  'assessmentAdd.MANUAL': 'Manual assessment',
  'assessmentAdd.RUBRIC': 'Rubric',
  'assessmentAdd.forPass': 'Passing score:',
  'assessmentAdd.points': 'Points',
  'assessmentAdd.highestScore': 'The highest score: ',
  'assessmentAdd.purify': 'Random guessing fix',
  'assessmentAdd.purifyInfo':
    'To discourage random guessing, each incorrect answer given by candidates in multiple-choice questions is scored negatively based on the number of choices for that question. For example, if a question with five options and worth 10 points is answered incorrectly by making a guess; The scores of the question are divided into 5-1=4 slices, and one slice, namely -2.5 negative points is applied.',
  'assessmentAdd.numberMessage': '(This field cannot contain characters!)',
  'assessmentAdd.selectResultMessage':
    'You must select an option on how results are announced.',
  'assessmentAdd.minMessage':
    'Assessment name should be at least 5 characters.',
  'assessmentAdd.maxMessage': 'Assessment name should be max 100 characters.',
  'assessmentAdd.patternMessage':
    "Assessment name can only contain letters, digits and - _ . , ; : ' @ / \\ ( ) [ ] '{ }' symbols.",
  'assessmentAdd.passPointWarning':
    'Passing score must be entered for absolute grading.',
  'assessmentAdd.PAPER_BASED': 'Paper-based Optical',
  'assessmentAdd.PAPER_BASED_CLASSIC': 'Paper-based Classic',
  'assessmentAdd.PERFORMANCE_EXAM': 'Performance',
  'assessmentAdd.ONLINE': 'Online',
  'assessmentAdd.ON_SITE': 'Onsite / Test Center',
  'assessmentAdd.NONE': 'None',
  'assessmentAdd.empty': 'This field cannot be blank!',
  'assessmentAdd.method1Info':
    "Assessment of the candidate's performance against the established criteria.",
  'assessmentAdd.method0Info': '-',
  'assessmentAdd.select': 'Choose',
  'assessmentAdd.displayDetails': 'Display details to candidates:',
  'assessmentAdd.displayDetailsCriteria':
    'Criteria and performance level statements',
  'assessmentAdd.displayDetailsScore': 'Score values',
  'assessmentAdd.exameditAlert':
    'After the last 15 minutes of the exam start time and after the start time and date, details of the exam cannot be edited, they can only be viewed.',
  'assessmentAdd.examCancelledAlert':
    'Details of the canceled exam cannot be edited, they can only be viewed.',
  'assessmentAdd.editPaperBased_info_text':
    'If any parameter changes while editing the assessment, all booklets will be recreated based on the changed data, and any CSV and candidate results uploaded will be deleted. The data of these results are not included in any statistics in the context of exam, question and candidate.',
  'assessmentAdd.editPaperBased_info_title': 'Important note',
  'assessmentAdd.ok': 'I Understood',
  'assessmentAdd.discardChanges': 'Discard changes',
  'assessmentAdd.cancelCreateAssessmentPopupMessage':
    'The changes you make will not be saved. Are you sure you want to discard all changes?',
  'assessmentAdd.discard': 'Discard',
  'assessmentAdd.cancel': 'Cancel',
  'assessmentAdd.alert_message_editExam_notStarted':
    'If any parameter changes while editing, the relevant assessment is recreated according to the changed data.',
  'assessmentAdd.candShowOnlyQuestions': 'Questions only',
  'assessmentAdd.candShowQuestionsAndAnswers':
    'Only questions and correct answers',
  'assessmentAdd.candQuestionTrueness':
    'Whether the questions and candidate answers are correct',
  'assessmentAdd.candScoreAndDuration':
    "Candidate's question-based score and time spent",
  'assessmentAdd.candStats': 'Question-based statistics of all candidates',
  'assessmentAdd.candDisplayOptTitle':
    'Post Exam Question and Answer Display Options',

  // report display options
  'assessmentAdd.candInsightAnalysis': "Candidates' Exam Insight Analysis",
  'assessmentAdd.reportDisplayOpt': 'Result report display options',
  'assessmentAdd.onlyAnnounce': 'Only announce the results',
  'assessmentAdd.showGraphsByCourses':
    'Comparative analysis and graphs by courses',
  'assessmentAdd.showGraphsByTopics':
    'Comparative analysis and graphs by topic',
  'assessmentAdd.showGraphsByObjectives':
    'Comparative analysis and graphs according to learning objectives',
  'assessmentAdd.showGraphsByQa':
    'Comparative analysis and graphs by question attributes',
  'assessmentAdd.showGraphsByCriteria':
    'Comparative analysis and graphs by criteria',

  // context
  'assessmentAdd.unitCourseName': 'UNIT / COURSE NAME',
  'assessmentAdd.actions': 'ACTIONS',
  'assessmentAdd.shuffleUnitHint':
    'Use the  {shuffleIcon}  icon under the <b>"Actions"</b> menu for options to mix units, courses or questions.',
  'assessmentAdd.shuffleUnitHint1':
    '<b>Mix the unit/courses within itself</b> feature mixes only the units/courses at a lower level of the selected unit.',
  'assessmentAdd.shuffleUnitHint2':
    '<b>Mix the questions within itself</b> feature mixes only the questions within the selected course.',
  'assessmentAdd.shuffleTooltipUnit':
    'The selected unit/course will be mixed with {count} units/courses at a lower level.',
  'assessmentAdd.shuffleTooltipCourse':
    'The questions to be added to the exam in the question bank of the selected course will be mixed within itself.',
  'assessmentAdd.shuffleSelectionError':
    'In order to make a mixing preference, the relevant unit/course must be selected and included in the assessment.',
};
