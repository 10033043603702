import React, { useState, useRef, useEffect } from 'react';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import {
  EditorState,
  AtomicBlockUtils,
  ContentState,
  convertToRaw,
} from 'draft-js';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import './stylesmini.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  StrikeThroughButton,
} from '../Buttons/index';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import { FormattedMessage } from 'react-intl';
import UploadImage from '../controls/Image/Component';
import localeTranslations from '../i18n';
import { uploadImage } from '../../../data/QuestionRepository';
import { getLang } from '../../../data/UserLocalDataSource';
import uploadIcon from '../images/image.svg';
import createImagePlugin from '@draft-js-plugins/image';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import classNames from 'classnames';
import ModalHandler from '../event-handler/modals';
import { toastError } from '../../../utility/toastUtil';
import { checkResult } from '../../../utility/resultHandler';
import { optionHasImage } from '../../../domain/Question';

const resizeablePlugin = createResizeablePlugin({
  vertical: 'relative',
  horizontal: 'relative',
});
const focusPlugin = createFocusPlugin();
const blockDndPlugin = createBlockDndPlugin();
const decorator = composeDecorators(
  resizeablePlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator
);

const MiniEditor = ({
  textValue,
  isImage,
  onChange,
  onFocus,
  name,
  containerClassName,
  reset,
  editedState,
  setEditedState,
}) => {
  const [editorHTML, setEditorHTML] = useState(textValue); // TODO: emre: this will be checked
  const [uploadImageModal, setUploadImageModal] = useState(false);

  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(editorHTML))
    )
  );
  const modalHandler = new ModalHandler();
  const [{ plugins, InlineToolbar }] = useState(() => {
    const imagePlugin = createImagePlugin({ decorator });
    const toolbarPlugin = createInlineToolbarPlugin();
    const { InlineToolbar } = toolbarPlugin;
    const plugins = [
      toolbarPlugin,
      imagePlugin,
      resizeablePlugin,
      blockDndPlugin,
      focusPlugin,
    ];
    return {
      plugins,
      InlineToolbar,
    };
  });

  const htmlTag = draftToHtml(
    convertToRaw(
      editedState
        ? editedState.getCurrentContent()
        : editorState.getCurrentContent()
    )
  );

  useEffect(() => {
    if (reset) {
      setEditorState(EditorState.createEmpty());
      if (editedState) {
        setEditorState(editedState);
      }
    }
  }, [reset]);

  const handleImageModal = () => {
    setUploadImageModal(!uploadImageModal);
  };

  const onChangeEditedState = (editorState, is_image = false) => {
    const editorToHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const tagHasImage = optionHasImage(htmlTag);
    if ((!isImage && !tagHasImage) || isEditorEmpty(editorState) || is_image) {
      setEditedState(editorState);
      onChange(editorToHtml, is_image);
    } else {
      onFocus();
    }
  };

  const onChangeEditor = (editorState, is_image = false) => {
    const editorToHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const tagHasImage = optionHasImage(htmlTag);
    if ((!isImage && !tagHasImage) || isEditorEmpty(editorState) || is_image) {
      setEditorState(editorState);
      onChange(editorToHtml, is_image);
    } else {
      onFocus();
    }
  };

  const isEditorEmpty = (editorState) => {
    const editorToHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    return editorToHtml.replaceAll('<p></p>', '').trim() == '';
  };

  // ** Image upload
  const _uploadImageCallBack = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const mbAsByte = 2000000;
    if (file.size > mbAsByte) {
      toastError(
        <FormattedMessage
          id="toasts.fileSizeError"
          values={{
            maxSize: 2,
          }}
        />
      );
      return;
    }

    const imageURL = await uploadImage(formData);
    if (imageURL?.apiError) {
      checkResult(imageURL);
    } else {
      const imageObject = {
        file,
        localSrc: imageURL,
      };

      return new Promise((resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      });
    }
  };

  const uploadImageConfig = {
    icon: uploadIcon,
    className: 'mini-editor-config',
    popupClassName: 'upload-image-popup',
    urlEnabled: true,
    uploadEnabled: true,
    previewImage: true,
    alignmentEnabled: true,
    uploadCallback: _uploadImageCallBack,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: true, mandatory: false },
    defaultSize: {
      height: '200px',
      width: '200px',
    },
  };

  const addImage = (src, height, width, alt) => {
    const entityData = { src, height, width };
    if (uploadImageConfig.alt.present) {
      entityData.alt = alt;
    }
    const tempEditorState = editedState ? editedState : editorState;
    const entityKey = tempEditorState
      .getCurrentContent()
      .createEntity('IMAGE', 'IMMUTABLE', entityData)
      .getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      tempEditorState,
      entityKey,
      ' '
    );

    editedState
      ? onChangeEditedState(newEditorState, true)
      : onChangeEditor(newEditorState, true);
    handleImageModal();
  };

  return (
    <div
      className={classNames('editor', containerClassName)}
      onClick={modalHandler.onEditorClick}
    >
      <div className="w-100 editor-body">
        <Editor
          editorState={editedState ? editedState : editorState}
          onChange={editedState ? onChangeEditedState : onChangeEditor}
          onFocus={onFocus}
          plugins={plugins}
          name={`mini-Editor${name}`}
          ref={(editor) => (editorRef.current = editor)}
        />
      </div>
      <UploadImage
        disabled={
          !isEditorEmpty(editedState ? editedState : editorState) &&
          !optionHasImage(htmlTag)
        }
        config={uploadImageConfig}
        translations={localeTranslations[getLang().toLowerCase() || 'en']}
        expanded={uploadImageModal}
        onExpandEvent={handleImageModal}
        doCollapse={handleImageModal}
        doExpand={handleImageModal}
        onChange={addImage}
      />
      <InlineToolbar>
        {(externalProps) => (
          <>
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <StrikeThroughButton {...externalProps} />
          </>
        )}
      </InlineToolbar>
    </div>
  );
};

export default MiniEditor;
