export default {
  'proctoringReport.examSessionOverview': 'Sınava Genel Bakış',
  'proctoringReport.subTitle': `Adayın sınav oturum bilgilerini inceleyin`,
  'proctoringReport.systemId': 'Sistem Kimliği',
  'proctoringReport.email': 'E-Posta',
  'proctoringReport.assessmentName': 'Değerlendirme adı',
  'proctoringReport.examStartData': 'Sınav başlangıç zamanı',
  'proctoringReport.assessmentType': 'Değerlendirme türü',
  'proctoringReport.submissionTime': 'Gönderim zamanı',
  'proctoringReport.proctoringType': 'Gözetim türü',
  'proctoringReport.proctoringTypes': 'Gözetim Türleri',

  'proctoringReport.sessionDuration': 'Oturum süresi',
  'proctoringReport.RECORD_REVIEW': 'Kaydet ve İncele',
  'proctoringReport.unproctored': 'Gözetimsiz',
  'proctoringReport.LOCKDOWN_REVIEW': 'Kilitle ve İncele',
  'proctoringReport.systemInformation': 'Sistem Bilgileri',
  'proctoringReport.operatingSystem': 'İşletim Sistemi',
  'proctoringReport.browser': 'Tarayıcı',
  'proctoringReport.recordReview': 'Kaydet ve İncele',
  'proctoringReport.lockdownReview': 'Kilitle ve İncele',
  'proctoringReport.recordReviewTitle': 'Kaydet ve İncele Gözetim Sonuçları',
  'proctoringReport.lockdownReviewTitle': 'Kilitle ve İncele Gözetim Sonuçları',
  'proctoringReport.recordLockdownSubTitle':
    'Examod tarafından elde edilen verilerle aday gözetleme sonuçlarını inceleyin',
  'proctoringReport.invalidate': `Geçersiz Kıl`,
  'proctoringReport.candidateExamRecordTitle': `Adayın Sınav Oturum Kayıtları`,
  'proctoringReport.candidateExamRecordSubTitle': `Adayın sınav anındaki video ve ekran kaydını inceleyin`,
  'proctoringReport.totalViolations': `Toplam İhlal Süresi`,
  'proctoringReport.totalViolationsChartHeader': `Toplam İhlal Süresi`,

  'proctoringReport.suspiciousLevel': `Şüphe Seviyesi`,
  'proctoringReport.beforeExamFaceRecognition': `Sınav Öncesi Yüz Tanıma`,
  'proctoringReport.faceId': `Yüz Kimliği`,
  'proctoringReport.mismatched': `Eşleşmedi`,
  'proctoringReport.approve': `Onayla`,
  'proctoringReport.approved': `Onaylandı`,

  'proctoringReport.reviewNeeded': `İnceleme gerekli`,
  'proctoringReport.window': `Pencere`,
  'proctoringReport.device': `Cihaz`,
  'proctoringReport.behavioral': `Davranış`,
  'proctoringReport.violations': `İhlalleri`,
  'proctoringReport.violation': `İhlal`,
  'proctoringReport.violationInLogTitle': `İhlali`,
  'proctoringReport.violationInLogSec': `sn süreyle`,

  'proctoringReport.types': `Türleri`,
  'proctoringReport.details': `Detaylar`,
  'proctoringReport.netScore': `Net / Maksimum Puan`,
  'proctoringReport.helpVideoConstruct': `Video zaman çizelgesindeki işaretçiler, ihlalleri ve türlerini göstermektedir.`,
  'proctoringReport.logsEvents': `Kayıtlar ve Olaylar `,
  'proctoringReport.filter': `Filtre`,
  'proctoringReport.createdDate': `Oluşturulma tarihi`,
  'proctoringReport.fileInfo': `Dosya Bilgileri`,
  'proctoringReport.fileName': `Dosya adı`,
  'proctoringReport.videoLength': 'Video uzunluğu',
  'proctoringReport.min': `dk`,
  'proctoringReport.sec': `sn`,
  'proctoringReport.hr': `sa`,
  'proctoringReport.in': `in`,
  'proctoringReport.times': 'kez',
  'proctoringReport.recordReview-tooltip': `Bu gözetim türünde, sınav sırasında adayın video ve ekran kaydı alınır. Bu sayede sınav gözetmeni birçok adayın saatlerce sürebilecek video kayıtlarını izlemek yerine belirli adayların sadece işaretli kesitlerini izleyerek çok az zamanda tüm sınav güvenlik değerlendirmesini tamamlayabilir. Bu gözetim türü halihazırda Kilitle ve İncele gözetim türünün tüm özelliklerini kapsamaktadır. Yüksek önem düzeyine sahip sınavlar için önerilir.`,
  'proctoringReport.lockdownReview-tooltip': `Bu gözetim türünde adayın sınava bağlandığı cihaz, tam ekran zorlama, çıktı alamama gibi çeşitli kısıtlamalara tabi tutulur. Ekran paylaşımının kapatılması gibi kısıtlama yapılamayan bazı ihlal türleri de bu gözetim türü için raporlanmaktadır. Bu gözetim türü, sınav sonrasında adaylarla ilgili bir risk puanı üreterek sınav sorumlusuna belirlenmiş adayların pencere ve cihaz üzerinde yaptığı ihlalleri değerlendirme olanağı tanır. Bu türün tüm özellikleri Kaydet ve İncele gözetim türü için de kullanılabilir. Düşük ve orta önem düzeyine sahip sınavlar için önerilir.`,
  'proctoringReport.unproctored-tooltip': `Sınav sırasında herhangi bir gözetim ve kısıtlamanın yapılmadığı gözetim türüdür. Sınav sonrasında bir rapor üretilmez. Düşük önem düzeyine sahip sınavlar veya quiz sınavlarında kullanılabilir.`,
  'proctoringReport.duration-warn-tooltip':
    'Kaydet ve İncele gözetim türünde bir değerlendirme kurulumu için sınav süresi tanımlanmalıdır. Sınav süresi tanımlamak için Değerlendirme Ayarları adımına geri dönün.',
  'proctoringReport.acceptableViolation-status': `Kabul edilebilir seviyede ihlaller tespit edildi`,
  'proctoringReport.lowRisk-status': `Düşük riskte ihlaller tespit edildi`,
  'proctoringReport.midRisk-status': `Orta riskte ihlaller tespit edildi`,
  'proctoringReport.notAcceptableViolation-status': `Kabul edilebilir sınırın ötesinde ihlaller tespit edildi`,
  'proctoringReport.invalid-info': `Şüphe seviyesine göre adayın sınavını geçersiz kıl`,

  'proctoringReport.examodDetected': 'Examod, sınav sırasında',
  'proctoringReport.warning-WINDOW_VIOLATION':
    'farklı türde pencere ihlali tespit etti.',
  'proctoringReport.warning-DEVICE_VIOLATION':
    'farklı türde cihaz ihlali tespit etti.',
  'proctoringReport.warning-BEHAVIORAL_VIOLATION':
    'farklı türde davranış ihlali tespit etti.',
  'proctoringReport.warning-beforeExamFaceRecognition':
    'Examod, sınavınız sırasında sistem fotoğrafınızla eşleşemedi!',
  'proctoringReport.warning-faceIdInvalidated':
    'Adayın sınav öncesi yüz tanıma verileri ile sistemde onaylı fotoğrafı sınav sorumlusu tarafından incelenerek, arada benzerlik olmadığına karar verilmiştir.',
  'proctoringReport.vm': 'Sanal Makine ',
  'proctoringReport.video_recording': 'Video Kayıt',
  'proctoringReport.audio_recording': 'Ses Kayıt',
  'proctoringReport.full_screen': 'Tam Ekran',
  'proctoringReport.screen_share': 'Ekran Paylaşım',
  'proctoringReport.multiple_face': 'Birden Fazla Yüz',
  'proctoringReport.unrecognized_face': 'Tanınmayan Yüz',
  'proctoringReport.no_face': 'Yüz Yok',
  'proctoringReport.vm-violation': 'Sanal makine algılandı',
  'proctoringReport.video_recording-violation': 'Kamera bulunamadı',
  'proctoringReport.audio_recording-violation': 'Mikrofon bulunamadı',
  'proctoringReport.full_screen-violation': 'Tam ekran modu kapatıldı',
  'proctoringReport.screen_share-violation': 'Ekran paylaşımı kapatıldı',
  'proctoringReport.multiple_face-violation': 'Birden fazla yüz algılandı',
  'proctoringReport.unrecognized_face-violation': 'Tanınmayan yüz algılandı',
  'proctoringReport.no_face-violation': 'Yüz algılanmadı',

  'proctoringReport.timesFor': 'kez',
  'proctoringReport.times': 'kez',

  'proctoringReport.followStepsToVerify': `Adayın yüz kimliğini doğrulamak için aşağıdaki adımları izleyin. İncelemelerinizin ardından adayın yüz kimliğini onaylayın veya geçersiz kılın.`,
  'proctoringReport.faceStatusId': 'Yüz Kimliği Durumu',
  'proctoringReport.candidateInfoTitle': 'Aday Bilgileri',

  'proctoringReport.faceRecognitionSubHeaderModal': `Adayın yüz kimliği tanımlama süreçlerini gözden geçirin`,
  'proctoringReport.candidateSystemPhoto': `Adayın Sistem Fotoğrafı`,
  'proctoringReport.candidateReviewPhoto': `Adayın sisteme kayıtlı fotoğrafını inceleyin`,

  'proctoringReport.candidateVideoCapture': `Video Yakalama`,
  'proctoringReport.candidateReviewVideoCapture': `Adayın sınavdan önce çekilmiş videosunu inceleyin`,

  'proctoringReport.DEVICE_VIOLATION-subHeaderModal':
    'Examod tarafından elde edilen şüpheli cihaz ihlallerini inceleyin',
  'proctoringReport.WINDOW_VIOLATION-subHeaderModal':
    'Examod tarafından elde edilen şüpheli pencere ihlallerini inceleyin',
  'proctoringReport.BEHAVIORAL_VIOLATION-subHeaderModal':
    'Examod tarafından elde edilen şüpheli davranış ihlallerini inceleyin',
  'proctoringReport.minSession': `dk süren oturumda`,
  'proctoringReport.secIn': `sn süreyle`,

  'proctoringReport.WINDOW_VIOLATION': 'Pencere',
  'proctoringReport.DEVICE_VIOLATION': 'Cihaz',
  'proctoringReport.BEHAVIORAL_VIOLATION': 'Davranış',

  'proctoringReport.WINDOW_VIOLATION-title': 'Pencere İhlali',
  'proctoringReport.DEVICE_VIOLATION-title': 'Cihaz İhlali',
  'proctoringReport.BEHAVIORAL_VIOLATION-title': 'Davranış İhlali',

  'proctoringReport.RECORD_REVIEW': 'Kaydet ve İncele',
  'proctoringReport.LOCKDOWN_REVIEW': 'Kilitle ve İncele',
  'proctoringReport.null': 'Not Available',

  'proctoringReport.FULL_SCREEN_OFF': 'Tam ekran modu kapatıldı',
  'proctoringReport.SCREEN_SHARING_OFF': 'Ekran paylaşımı kapatıldı',
  'proctoringReport.MULTIPLE_FACE_DETECTED': 'Birden fazla yüz algılandı',
  'proctoringReport.NO_FACE_DETECTED': 'Yüz algılanmadı',
  'proctoringReport.UNRECOGNIZED_FACE_DETECTED': 'Tanınmayan yüz algılandı',
  'proctoringReport.CAMERA_NOT_FOUND': 'Kamera bulunamadı',
  'proctoringReport.MIC_NOT_FOUND': 'Mikrofon bulunamadı',
  'proctoringReport.VIRTUAL_MACHINE_DETECTED': 'Sanal makine algılandı',
  'proctoringReport.NEW_TAB': 'Birden çok sekme algılandı',
  'proctoringReport.MULTIPLE_SCREEN': 'Birden çok ekran algılandı',

  'proctoringReport.FULL_SCREEN_OFF-title': 'Tam Ekran',
  'proctoringReport.SCREEN_SHARING_OFF-title': 'Ekran Paylaşım',
  'proctoringReport.MULTIPLE_FACE_DETECTED-title': 'Birden Fazla Yüz',
  'proctoringReport.NO_FACE_DETECTED-title': 'Yüz Yok',
  'proctoringReport.UNRECOGNIZED_FACE_DETECTED-title': 'Tanınmayan Yüz',
  'proctoringReport.CAMERA_NOT_FOUND-title': 'Video Kayıt',
  'proctoringReport.MIC_NOT_FOUND-title': 'Ses Kayıt',
  'proctoringReport.VIRTUAL_MACHINE_DETECTED-title': 'Sanal Makine',
  'proctoringReport.NEW_TAB-title': 'Birden Çok Sekme',
  'proctoringReport.MULTIPLE_SCREEN-title': 'Çoklu Ekran',

  'proctoringReport.continue': 'Devam',
  'proctoringReport.cancel': 'İptal',
  'proctoringReport.cancelAlert-title': 'Değişiklikleri göz ardı et',
  'proctoringReport.cancelAlert-message': `Adayın gözetleme sonuçları sürecinden ayrılmak istediğinize emin misiniz? Süreci iptal ederseniz herhangi bir değerlendirme yapmayacaksınız.`,
  'proctoringReport.wantToContinueAlert-title':
    'Devam etmek istediğinize emin misiniz?',
  'proctoringReport.invalidateAlert-message': `Adayın gözetim sonuçlarını geçersiz kılmak istediğinize emin misiniz? Devam etmeniz durumunda adayın değerlendirmesi geçersiz sayılacaktır.`,
  'proctoringReport.invalidateFaceIdAlert-message': `Adayın yüz kimliğini geçersiz kılmak istediğinize emin misiniz? Devam etmeniz durumunda adayın değerlendirmesi geçersiz sayılacaktır.`,

  'proctoringReport.totalViolationDurationWithHour':
    '{total} süren oturumda {hr} sa {min} dk {sec} sn süreyle',
  'proctoringReport.totalViolationDurationWithMinute':
    '{total} süren oturumda {min} dk {sec} sn süreyle',
  'proctoringReport.totalViolationDurationWithSecond':
    '{total} süren oturumda {sec} sn süreyle',
  'proctoringReport.detailTitle': 'DETAY',
  'proctoringReport.description': 'AÇIKLAMA',
  'proctoringReport.action': 'İŞLEMLER',
  'proctoringReport.numberOfViolations': 'İHLAL SAYISI',
  'proctoringReport.emptyViolationTitle': 'İhlal Tespit Edilmedi',
  'proctoringReport.emptyViolationDescription':
    ' Adayın {value} süren oturumunda Examod tarafıından ihlal tespit edilmedi.',
  'proctoringReport.examSessionRecord': 'Adayın Sınav Oturum Kayıtları',
  'proctoringReport.examSessionRecordSubtitle':
    'Adayın sınav sırasındaki video ve ekran kaydını inceleyin',
  'proctoringReport.logsAndEvents': 'Kayıtlar ve Olaylar',
  'proctoringReport.camera': 'Kamera',
  'proctoringReport.screen': 'Ekran',
  'proctoringReport.fileName': 'Dosya adı',
  'proctoringReport.creationDate': 'Oluşturma tarihi',
  'proctoringReport.length': 'Uzunluk',
  'proctoringReport.recordInfo':
    'Adayın video kayıtlarının oluşturulması zaman alabilir. Lütfen kısa bir süre sonra tekrar kontrol edin.',
  'proctoringReport.logsAndEventsTooltip':
    'Zaman damga bilgileri, adayın oturum sırasındaki ihlal zamanını ifade etmektedir. Adayın kamera veya ekran kaydı süreleri oturum süresinden ve zaman damga bilgilerinden farklılık gösterebilir.',
};
