export default {
  'rubrics.title': 'Dereceli Puanlama Anahtarları',
  'rubrics.info':
    'Performans değerlendirmesi yapabilmek için dereceli puanlama anahtarlarınızı yönetin.',
  'rubrics.emptyInfo':
    'Bu ders için ilk dereceli puanlama anahtarını oluşturun',
  'rubrics.name': 'Dereceli Puanlama Anahtarı Adı',
  'rubrics.nameUpper': 'DERECELİ PUANLAMA ANAHTARI ADI',
  'rubrics.obj':'Dereceli Puanlama Anahtarı',
  'rubrics.description': 'Açıklama',
  'rubrics.status': 'durum',
  'rubrics.actions': 'işlemler',
  'rubrics.create': 'Dereceli Puanlama Anahtarı Oluştur',
  'rubrics.editRubric': 'Dereceli Puanlama Anahtarını Düzenleyin',
  'rubrics.copyRubric':
    'Mevcut Dereceli Puanlama Anahtarından Yeni Bir Kopya Üretin',
  'rubrics.editInfo':
    'Performans değerlendirmesi yapabilmek için mevcut dereceli puanlama anahtarını düzenleyin',
  'rubrics.copyInfo':
    'Performans değerlendirmesi yapabilmek için mevcut dereceli puanlama anahtarından yeni bir kopya üretin',
  'rubrics.createInfo':
    'Performans değerlendirmesi yapabilmek için yeni bir dereceli puanlama anahtarı oluşturun.',
  'rubrics.edit': 'Düzenle',
  'rubrics.delete': 'Sil',
  'rubrics.duplicate': 'Çoğalt',
  'rubrics.search': 'Ara',
  'rubrics.deleteConfirmationTitle': 'Dereceli puanlama anahtarını sil',
  'rubrics.deleteConfirmationMessage':
    'Puanlama anahtarını silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'rubrics.deleteConfirmationConfirmButton': 'Evet, puanlama anahtarını sil',
  'rubrics.deleteConfirmationCancelButton': 'İptal',
  'rubrics.deleteAlert': 'Dereceli puanlama anahtarı başarıyla silindi!',
  'rubrics.reset': 'Sıfırla',
  'rubrics.minMessageName':
    'Dereceli puanlama anahtarı adı en az 2 karakter olmalıdır.',
  'rubrics.maxMessageName':
    'Dereceli puanlama anahtarı adı en fazla 100 karakter olmalıdır.',
  'rubrics.maxMessageDesc':
    'Dereceli puanlama anahtarı açıklaması en fazla 300 karakter olmalıdır.',
  'rubrics.createStatus': 'Aktif / Pasif',
  'rubrics.criteria': 'ÖLÇÜT',
  'rubrics.excellent': 'MÜKEMMEL',
  'rubrics.satisfactory': 'TATMİN EDİCİ',
  'rubrics.unsatisfactory': 'TATMİN EDİCİ DEĞİL',
  'rubrics.poor': 'ZAYIF',
  'rubrics.criteria-tooltip':
    'Adayın performansında değerlendirilecek ölçütler.',
  'rubrics.descriptionRow': 'Açıklama ekleyin.',
  'rubrics.newCriteria': 'Yeni Ölçüt',
  'rubrics.newColName': 'SEVİYE ADI',
  'rubrics.colNameAlert': 'Ölçüt adı en az 1 en fazla 30 karakter olmalıdır.',
  'rubrics.rowPerAlert': 'Ölçüt yüzdesi yüzde 0 ile 100 arasında olmalıdır.',
  'rubrics.rowDescAlert': 'Ölçüt açıklaması en fazla 250 karakter olmalıdır.',
  'rubrics.rowCriteriaAlert':
    'Ölçüt ismi en az 1 en fazla 250 karakter olmalıdır.',
  'rubrics.associate': 'İlişkilendir...',
  'rubrics.total': ' Toplam puana etkisi: {param}',
  'rubrics.drag': 'sürükle',
  'rubrics.option': 'Seçenek',
  'rubrics.learningObj': 'Öğrenme Hedefleri',
  'rubrics.associateNotFounded':
    'Lütfen bir seçim yapınız veya açılır pencereden çıkınız.',
  'rubrics.addSuccessAlert':
    'Dereceli puanlama anahtarı başarıyla oluşturuldu!',
  'rubrics.editSuccessAlert':
    'Dereceli puanlama anahtarı başarıyla güncellendi!',
  'rubrics.addAlert': 'Ölçütlerin toplam puana etkisini %100 e tamamlayın.',
  'rubrics.quitMessage':
    'Değişiklikleriniz silinecek. Çıkmak istediğinizden emin misiniz?',
  'rubrics.showPassiveStatus': 'Pasif dereceli puanlama anahtarları göster',
  'rubrics.per': '%{per}',
  'rubrics.learningObjContent': 'Öğrenme Hedefi :',
  'rubrics.ok': 'Tamam',
  'rubrics.deleteInfoConfirmationMessage':
    'Dereceli puanlama anahtarı silinemedi, içerisinde en az 1 sınav yapılmış ve/veya planlanmıştır.\n Bu gibi durumlarda, değerlendirme listesini pasifleştirebilirsiniz.',
  'rubrics.copyAlert':
    'Kopyalanan dereceli puanlama anahtarı ismi aynı olamaz!',
  'rubrics.editNameConfirmationTitle': 'Dereceli puanlama anahtarını düzenle',
  'rubrics.editNameConfirmationMessage':
    'Puanlama anahtarının adını değiştirmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'rubrics.editNameConfirmationConfirmButton':
    'Evet, puanlama anahtarını düzenle',
  'rubrics.titleInformation': 'Hay Aksi! Henüz {titleValue} bulunmamaktadır.',
  'rubrics.messageInformation': 'Organizasyonunuzun bu dersinde henüz {titleValue} bulunmamaktadır.',
  'rubrics.messageInformationAdd': 'Performansı değerlendirmek istediğiniz dereceli puanlama anahtarını ekleyerek başlayın!',
};
