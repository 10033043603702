export default {
  // Generic
  'generic.add': 'Добавить',
  'generic.cancel': 'Отменить',

  // BlockType
  'components.controls.blocktype.h1': 'Заголовок 1',
  'components.controls.blocktype.h2': 'Заголовок 2',
  'components.controls.blocktype.h3': 'Заголовок 3',
  'components.controls.blocktype.h4': 'Заголовок 4',
  'components.controls.blocktype.h5': 'Заголовок 5',
  'components.controls.blocktype.h6': 'Заголовок 6',
  'components.controls.blocktype.blockquote': 'Цитата',
  'components.controls.blocktype.code': 'Код',
  'components.controls.blocktype.blocktype': 'Форматирование',
  'components.controls.blocktype.normal': 'Обычный',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Выбор цвета',
  'components.controls.colorpicker.text': 'Текст',
  'components.controls.colorpicker.background': 'Фон',

  // Embedded
  'components.controls.embedded.embedded': 'Встраивание',
  'components.controls.embedded.embeddedlink': 'Ссылка в iFrame',
  'components.controls.embedded.enterlink': 'Вставьте ссылку',

  // Emoji
  'components.controls.emoji.emoji': 'Эмодзи',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Шрифт',

  // FontSize
  'components.controls.fontsize.fontsize': 'Размер шрифта',

  // History
  'components.controls.history.history': 'История',
  'components.controls.history.undo': 'Отменить',
  'components.controls.history.redo': 'Вернуть',

  // Image
  'components.controls.image.image': 'Изображение',
  'components.controls.image.fileUpload': 'Файлы',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText':
    'Переместите в эту область файлы или кликните для загрузки',

  // Inline
  'components.controls.inline.bold': 'Жирный',
  'components.controls.inline.italic': 'Курсив',
  'components.controls.inline.underline': 'Подчеркивание',
  'components.controls.inline.strikethrough': 'Зачеркивание',
  'components.controls.inline.monospace': 'Monospace',
  'components.controls.inline.superscript': 'Верхний индекс',
  'components.controls.inline.subscript': 'Нижний индекс',

  // Link
  'components.controls.link.linkTitle': 'Текст',
  'components.controls.link.linkTarget': 'Адрес ссылки',
  'components.controls.link.linkTargetOption': 'Открывать в новом окне',
  'components.controls.link.link': 'Ссылка',
  'components.controls.link.unlink': 'Убрать ссылку',

  // List
  'components.controls.list.list': 'Список',
  'components.controls.list.unordered': 'Неупорядоченный',
  'components.controls.list.ordered': 'Упорядоченный',
  'components.controls.list.indent': 'Отступ',
  'components.controls.list.outdent': 'Выступ',

  // Remove
  'components.controls.remove.remove': 'Удалить',

  // TextAlign
  'components.controls.textalign.textalign': 'Выравнивание текста',
  'components.controls.textalign.left': 'Слева',
  'components.controls.textalign.center': 'По центру',
  'components.controls.textalign.right': 'Справа',
  'components.controls.textalign.justify': 'Выравнить',

  // New Examod localizations
  'components.controls.image.modal.title': 'Image Uploader',
  'components.controls.image.modal.description':
    'Browse or drag the image you want to upload',
  'components.controls.image.modal.content.image.or': 'or',
  'components.controls.image.modal.content.image.btn': 'Browse Files',
  'components.controls.image.modal.content.image.attachment':
    'Attachments should not exceed 2 mb',
  'components.controls.image.modal.content.image.supported':
    'Supported file types: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt Text',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Image alt text',
  'components.controls.image.modal.content.input.scale.label': 'Scale (%)',
  'components.controls.image.modal.contnet.url.address.label': '*URL (Adress)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Add image from address',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Clear Highlight',
  'components.controls.color.picker.highlight.black': 'Black Highlight',
  'components.controls.color.picker.highlight.grey': 'Grey Highlight',
  'components.controls.color.picker.highlight.red': 'Red Highlight',
  'components.controls.color.picker.highlight.green': 'Green Highlight',
  'components.controls.color.picker.highlight.blue': 'Blue Highlight',
  'components.controls.color.picker.highlight.yellow': 'Yellow Highlight',
  'components.controls.color.picker.highlight.examod': 'Examod Highlight',
  'components.controls.color.picker.highlight.purple': 'Purple Highlight',
  'components.controls.color.picker.highlight.pink': 'Pink Highlight',
};
