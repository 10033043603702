export default {
  'periodAdd.header': 'Dönem Ekle',
  'periodAdd.name': 'Dönem Adı',
  'periodAdd.maxLength': 'Dönem adı en fazla 100 karakter olmalıdır.',
  'periodAdd.minLength': 'Dönem adı en az 1 karakter olmalıdır.',
  'periodAdd.unit': 'Ana Birim',
  'periodAdd.status': 'Göster / Gizle',
  'periodAdd.start': 'Başlangıç Tarihi',
  'periodAdd.end': 'Bitiş Tarihi',
  'periodAdd.add': 'Dönem Ekle',
  'periodAdd.requiredMessage': ' ',
  'periodAdd.select': 'Seçiniz',
  'periodAdd.selectAlert': 'Dönem ekleme başarısız. Lütfen ana birim seçiniz!',
  'periodAdd.timerAlert':
    'Dönem ekleme başarısız. Başlangıç tarihi bitiş tarihine eşit veya bitiş tarihinden büyük olmamalı!',
  'periodAdd.successAlert': 'Dönem başarıyla eklendi!',
  'periodAdd.allUnits': 'Tüm Ana Birimler',
};
