export default {
  'assessmentStatus.PAPER_BASED': 'Paper-based',
  'assessmentStatus.PAPER_BASED_CLASSIC': 'Paper-based Classic',
  'assessmentStatus.ON_SITE': 'Onsite / Test Center',
  'assessmentStatus.PERFORMANCE_EXAM': 'Performance',
  'assessmentStatus.ONLINE': 'Online',
  'assessmentStatus.NONE': 'None',
  'assessmentStatus.ABSOLUTE': 'Absolute',
  'assessmentStatus.GLOBAL_RATING': 'Global rating (Only rubrics)',
  'assessmentStatus.BELL_CURVE': 'Relative (Bell Curve)',
  'assessmentStatus.FINISHED': 'Finished',
  'assessmentStatus.ONGOING': 'On going',
  'assessmentStatus.NOT_STARTED': 'Not started',
  'assessmentStatus.CANCELLED': 'Canceled',
  'assessmentStatus.unknown': 'Unknown',
  'assessmentStatus.INVALID': 'Invalid',
  'assessmentStatus.COMPLETED': 'Completed',
};
