// ** React imports
import React from 'react';
import { FormattedMessage } from 'react-intl';

function NoOption() {
  return (
    <div>
      <FormattedMessage id="messages.noOption" />
    </div>
  );
}

export default NoOption;
