import inline from './Inline';
import blockType from './BlockType';
import fontSize from './FontSize';
import fontFamily from './FontFamily';
import list from './List';
import textAlign from './TextAlign';
import colorPicker from './ColorPicker';
import link from './Link';
// import embedded from './Embedded';
// import emoji from './Emoji';
import image from './Image';
import remove from './Remove';
import history from './History';
import blockquote from './BlockQuote';

export default {
  inline,
  blockType,
  fontSize,
  fontFamily,
  list,
  textAlign,
  colorPicker,
  link,
  // embedded,
  // emoji,
  image,
  remove,
  history,
  blockquote,
};
