import { CONTROLLERS } from './ControllerPrefix';

export const PATHS = {
  dashboard: '/dashboard',
  account: CONTROLLERS.account,
  plan: CONTROLLERS.plan,
  errorInternal: '/500',
  unknownError: '/unknown-error',
  feBasedError: '/error',
  errorNotAuthorised: '/not-authorized',
  errorResourceNotFound: '/404',
  settings: CONTROLLERS.settings,
  structuralSettings: CONTROLLERS.structuralSettings,
  profile: CONTROLLERS.profile + '/:tab?',
  attributes: CONTROLLERS.structuralSettings + '/attributes',
  addAttribute: CONTROLLERS.structuralSettings + '/attributes/add',
  editAttribute: CONTROLLERS.structuralSettings + '/attributes/edit',
  editAttributeWithID: CONTROLLERS.structuralSettings + '/attributes/edit/:id',
  units: CONTROLLERS.structuralSettings + '/units',
  unitsUsers: CONTROLLERS.structuralSettings + '/units/:unitId/manage-users',
  periods: CONTROLLERS.structuralSettings + '/periods',
  addPeriod: CONTROLLERS.structuralSettings + '/periods/add',
  editPeriod: CONTROLLERS.structuralSettings + '/periods/:periodId/edit',
  examsTab: CONTROLLERS.structuralSettings + '/exams/:tab?',
  exams: CONTROLLERS.structuralSettings + '/exams',
  examsProctoring: CONTROLLERS.structuralSettings + '/exams/proctoring',
  examsTypeSettings: CONTROLLERS.structuralSettings + '/exams/typeSettings',
  examsCategories: CONTROLLERS.structuralSettings + '/exams/categories',
  users: CONTROLLERS.users,
  userImport: CONTROLLERS.users + '/import',
  userGroups: CONTROLLERS.userGroups,
  listUsersInGroup: CONTROLLERS.userGroups + '/:groupId/user-list',
  addUser: CONTROLLERS.users + '/add',
  viewUser: CONTROLLERS.users + '/:userId',
  editUser: CONTROLLERS.users + '/:userId/edit',
  roles: CONTROLLERS.roles,
  addQuestion: CONTROLLERS.questions + '/add/unit/:unitId',
  editQuestion: CONTROLLERS.questions + '/edit/:questionId',
  about: CONTROLLERS.pages + '/about',
  login: '/login',
  setPassword: '/set-password',
  invitePreview: CONTROLLERS.invite + '/preview',
  newTicket: CONTROLLERS.help + '/ticket',
  faq: CONTROLLERS.help + '/faq',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  myCourses: CONTROLLERS.myCourses,
  course: CONTROLLERS.myCourses + '/:unitId/:tab',
  performance:
    CONTROLLERS.myCourses + '/:unitId/assessment/perform/:assessmentId',
  paperBasedClassic:
    CONTROLLERS.myCourses + '/:unitId/assessment/classic/:assessmentId',
  addAssessment: CONTROLLERS.myCourses + '/:unitId/:tab/add',
  editAssessment: CONTROLLERS.myCourses + '/:unitId/:tab/edit/:assessmentId',
  assessmentResults:
    CONTROLLERS.myCourses + '/:unitId/:tab/:assessmentId/results',
  candidateReport:
    CONTROLLERS.myCourses +
    '/:unitId/:tab/:assessmentId/results/:candidateId/report',
  proctoringReport:
    CONTROLLERS.myCourses +
    '/:unitId/:tab/:assessmentId/results/:candidateId/proctoring/report',
  assessmentStatistics:
    CONTROLLERS.myCourses + '/:unitId/:tab/:assessmentId/results/statistics',
  assessmentOmrProcess:
    CONTROLLERS.myCourses + '/:unitId/:tab/:assessmentId/omr-process',
  addRubric: CONTROLLERS.myCourses + '/:unitId/:tab/create',
  editRubric: CONTROLLERS.myCourses + '/:unitId/:tab/:rubricId/edit',
  copyRubric: CONTROLLERS.myCourses + '/:unitId/:tab/:rubricId/copy',
  //myUnits: CONTROLLERS.myUnits,
  comingSoon: '/misc/coming-soon',
  maintenance: '/maintenance',
  questionExamPreview: '/session-preview',
  questionExamPreviewUnitId: '/session-preview/:unitId',
  questionExamPreviewId: '/session-preview/:unitId/:questionId',
  candidateExamPreview: '/response-preview/:unitId/:candidateId',
};
