import React, { useContext } from 'react';

// ** Third party
import { Navbar, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Menu } from 'react-feather';

// ** Components
import NavbarUser from './NavbarUser';

// ** Store
import { MainContext } from '../../../store/Store';

// ** Domain
import { getAvatarLetters } from '../../../domain/User';
import { onOpenOrgSite } from '../../../domain/General';

// ** Util
import { getTextWithLength } from '../../../utility/stringUtil';

// ** Config
import themeConfig from '../../../configs/themeConfig';

// ** Assets
import userImg from '../../../assets/img/portrait/no-avatar.png';
import logo from '../../../assets/img/logo/logo.png';

function ThemeNavbar(props) {
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];

  const { state } = useContext(MainContext);

  const AvatarLetters = () => {
    const avatar = getAvatarLetters(state.user);
    return avatar;
  };

  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light':
              props.navbarColor === 'default' ||
              !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden' && !props.horizontal,
            'floating-nav':
              (props.navbarType === 'floating' && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            'navbar-static-top':
              props.navbarType === 'static' && !props.horizontal,
            'fixed-top': props.navbarType === 'sticky' || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="navbar-left-wrapper align-items-left">
                <div className="bookmark-wrapper d-xl-none">
                  <ul className="navbar-nav ">
                    <NavItem className="mobile-menu mr-auto">
                      <NavLink
                        className="nav-menu-main menu-toggle hidden-xs is-active"
                        onClick={props.sidebarVisibility}
                      >
                        <Menu className="ficon" />
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                {props.horizontal ? (
                  <div className="logo d-flex align-items-center">
                    <div className="brand-logo mr-50">
                      <img src={logo} alt='examod-logo' height="33px" width="35px" />
                    </div>
                    <h2 className="text-primary brand-text mb-0">
                      {themeConfig.name}
                    </h2>
                  </div>
                ) : null}
                <div
                  className="navbar-organization-info"
                  onClick={() =>
                    onOpenOrgSite(state?.user?.organization?.websiteUrl)
                  }
                >
                  {state?.user?.organization?.logoPath ? (
                    <img
                      className="navbar-organization-logo"
                      src={state.user.organization.logoPath}
                      alt='navbar-organization-logo'
                    />
                  ) : null}
                  {state?.user?.organization?.brandName ? (
                    <p className="navbar-organization-name">
                      {getTextWithLength(state.user.organization.brandName, 40)}
                    </p>
                  ) : null}
                </div>
              </div>

              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userInfo={state?.user}
                avatar={AvatarLetters()}
                userImg={userImg}
                handleLanguageChange={props.handleLanguageChange}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
}

export default ThemeNavbar;
