export default {
  'home.activeUsers': 'Active Users in the Organization',
  'home.pendingUsers': 'Invitation Pending Users',
  'home.userGroupsCreated': 'User Groups Created',
  'home.rolesDefined': 'Roles Defined in the System',
  'home.examsPublished': 'Exams Published',
  'home.ongoingExams': 'Ongoing Exams',
  'home.questionCreated': 'Questions Created',
  'home.updatedNow': 'Updated Now',
  'home.actions': 'Actions',
  'home.edit': 'Edit',
  'home.usersInvited': 'Users Invited',
  'home.welcome': 'Welcome,',
  'home.quickAccess': 'Quick Access',
  'home.upcomingExams': 'Upcoming and Current Assessments',
  'home.upcomingExamDetails': 'Details',
  'home.systemUpdates': 'Release Notes and System Updates',
  'home.supportRequests': 'Support Requests',
  'home.supportRequestSubject': 'Subject',
  'home.supportRequestStatus': 'Status',
  'home.supportRequestSolved': 'Solved',
  'home.supportRequestNotSolved': 'Not Solved',
  'home.supportRequestButtonRequests': 'Requests',
  'home.supportRequestButtonCreate': 'Create Request',
  'home.newsAndEvents': 'News And Events',
  'home.newsBadge': 'New',
  'home.eventsBadge': 'Event',
  'home.examodOverview': ' Overview of Examod',
  'home.mainUnit': 'Active main unit',
  'home.totalCourse': 'Active course',
  'home.totalActiveAuthorized': 'Active authorized users',
  'home.totalActiveGroup': 'Active group',
  'home.activeLearningObj': 'Active learning objectives',
  'home.activeAssessmentCategory': 'Active assessment category',
  'home.totalActiveCandidate': 'Active candidates',
  'home.approvalPendingUsers': 'Approval pending user',
  'home.assessmentStatistics': 'Overall Assessment Statistics',
  'home.EXAM_COUNT': 'Exam',
  'home.CANDIDATES_ASSESSED': 'Evaluated candidate',
  'home.QUESTION_COUNT': 'Question',
  'home.QUESTIONS_USED': 'Questions used',
  'home.examSuccess': 'Avg. success rate of exams',
  'home.questionDiscrimination': 'Avg. question discrimination',
  'home.questionDifficulty': 'Avg. question difficulty',
  'home.correctAnswers': 'Correct answer rate',
  'home.COURSE_COUNT': 'Active course',
  'home.userexamSuccess': 'Avg. success rate of your exams',
  'home.userquestionDiscrimination': 'Avg. discrimination of your questions',
  'home.userquestionDifficulty': 'Avg. difficulty of your questions',
  'home.usercorrectAnswers': "The rate of your questions' correct answers",
  'home.userStatistics': 'Your Statistics ({userName})',
  'home.noUpcomingExams': 'There are no upcoming exams',
  'home.ticketText':
    'You can create a technical support request regarding the problems you experience with Examod. We will try to get back to you as soon as possible.',
  'home.EXAM_COUNT_tooltip':
    'The number of exams does not include canceled and deleted exams.',
  'home.QUESTION_COUNT_tooltip':
    'The number of questions does not include draft and deprecated questions.',
  'home.ROOT_UNIT_COUNT_tooltip':
    'The number of main units does not include hidden units.',
  'home.COURSE_COUNT_tooltip':
    'The number of courses does not include hidden courses.',
};
