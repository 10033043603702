export default {
  'generalSettings.header': 'Genel Ayarlar',

  'generalSettings.organizationName': 'Organizasyon Adı',
  'generalSettings.website': 'Web Sitesi',
  'generalSettings.language': 'Sistem Dili (Varsayılan)',
  'generalSettings.locale': 'Sistem Saat Dilimi (Varsayılan)',
  'generalSettings.settings': 'Ayarlar',
  'generalSettings.icon': 'Organizasyon Logosu',
  'generalSettings.upload': 'Logo Yükle',
  'generalSettings.reset': 'Sıfırla',
  'generalSettings.dropzoneInfo1': "Ek 800 kb'yi geçmemelidir.",
  'generalSettings.dropzoneInfo2':
    'Desteklenen dosya türleri: JPEG, GIF veya PNG',
  'generalSettings.info':
    'Organizasyon logonuz, sınav sisteminde görsel olarak tanımlamanız için bir yoldur. Kontrol panelinizde, sınav düzenlerinde ve sınav mobil uygulamanızda kullanılır.',
  'generalSettings.textTitle': 'İyi bir simge seçmeye ilişkin bazı ipuçları:',
  'generalSettings.text1': 'Düz bir arka plan rengi kullanın.',
  'generalSettings.text2': 'Metin yerine grafik logo veya resim kullanın.',
  'generalSettings.text3': 'Simgenizin etrafında biraz boşluk bırakın.',
  'generalSettings.text4':
    '132 piksel, kare şeklinde veya daha büyük bir resim yükleyin.',
  'generalSettings.organizationNameHint':
    'örnek: Acme Graduate School OR Acme Inc.',
  'generalSettings.websiteHint': 'örnek: https://www.örnek.com',
  'generalSettings.invalidMessage': 'Web sitesi geçersiz.',
  'generalSettings.requiredMessage': 'Bu alan boş bırakılamaz.',
  'generalSettings.orgNameMaxMessage':
    'Kuruluş adı 100 karakterden kısa olmalıdır.',
  'generalSettings.orgNameMinMessage':
    'Kuruluş adı en az 2 karakter olmalıdır.',
  'generalSettings.orgNameCharMessage':
    'Kuruluş adı yalnızca harf, rakam ve - @ / . & sembolleri içerebilir.',
  'generalSettings.success': 'Ayarlar başarıyla güncellendi!',

  'generalSettings.title': 'Organizasyon Ayarları',
  'generalSettings.subtitle': 'Organizasyonunuzun genel ayarlarını belirleyin',
  'generalSettings.orgInfo': 'Organizasyon Bilgileri',
  'generalSettings.orgInfoSubtitle': 'Bilgileri güncelleyin',
  'generalSettings.orgLogo': 'Organizasyon Logosu',
  'generalSettings.orgLogoSubtitle': 'Logoyu güncelleyin',
  'generalSettings.preview': 'Önizle',
  'generalSettings.previewSubtitle': 'Organizasyon logo önizleme',
  'generalSettings.file': 'Dosya',
  'generalSettings.fileSubtitle':
    'Eklemek istediğiniz organizasyon logosunu sürükleyin veya seçin',
  'generalSettings.saveConfirmMessage':
    'Değişiklikleri kaydetmek istediğinize emin misiniz? Devam ederseniz, yaptığınız değişiklikler tüm organizasyonunuzda geçerli olacaktır.',
  'generalSettings.orgLogoTooltip':
    'Organizasyon logonuz, sınav sisteminde görsel olarak tanımlamanız için bir yoldur. Kontrol panelinizde, sınav düzenlerinde ve sınav mobil uygulamanızda kullanılır.',

  'generalSettings.supportInfo': 'Destek Bilgileri',
  'generalSettings.supportInfoSubtitle': 'Destek için e-posta tanımlayın',
  'generalSettings.supportInfoHint':
    'Destek bilgileri, organizasyonunuza gönderilen destek taleplerinin (Yeni Kayıt) yönlendirileceği yetkili bir kullanıcı tanımlamanıza veya güncellemenize olanak tanır..',
  'generalSettings.supportInfoUser': 'Kullanıcı',
};
