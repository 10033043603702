export default {
  'assessmentResults.title': 'Değerlendirme Sonuçları',
  'assessmentResults.subtitle':
    'Değerlendirme sonuçlarına ve detaylarına genel bakış',
  'assessmentResults.assessmentName': '{assessmentName} özeti',
  'assessmentResults.assessmentDesc': 'Değerlendirme Açıklaması',
  'assessmentResults.unit': 'Birim / Ders',
  'assessmentResults.examiner': 'Sınav Sorumlusu: {name}',
  'assessmentResults.assessmentType': 'Değerlendirme türü',
  'assessmentResults.creationDate': 'Eklenme tarihi',
  'assessmentResults.startDate': 'Başlangıç tarihi',
  'assessmentResults.endDate': 'Bitiş tarihi',
  'assessmentResults.candidate': 'Aday',
  'assessmentResults.timeOver': 'Süre dolduğunda',
  'assessmentResults.validAnswers':
    'Önceki yanıtlar gönderilsin ve sınav tamamlansın',
  'assessmentResults.invalidAnswers':
    'Verilen cevaplar geçersiz sayılsın ve sınav tamamlansın',
  'assessmentResults.resultType': 'Sonuçların ilanı',
  'assessmentResults.MANUALLY_BY_OWNER':
    'Değerlendirme yaratıcısı tarafından elle ilan edilsin',
  'assessmentResults.INSTANT_PRELIMINARY_RESULT':
    'Değerlendirme sonucunu adayın uygulaması tamamlandığında göster.',
  'assessmentResults.PRELIMINARY_RESULT':
    'Değerlendirme sonucunu uygulama tarihi bitiminde göster.',
  'assessmentResults.process': 'Sınav yürütme seçenekleri',
  'assessmentResults.assessmentCategory': 'Değerlendirme kategorisi',
  'assessmentResults.duration': 'Süre',
  'assessmentResults.proctoringType': 'Gözetim türü',
  'assessmentResults.unproctored': 'Gözetimsiz',
  'assessmentResults.lockdownReview': 'Kilitle ve İncele',
  'assessmentResults.recordReview': 'Kaydet ve İncele',
  'assessmentResults.highestGrade': 'En yüksek puan',
  'assessmentResults.correctionGuessing': 'Rastgele tahmin düzeltmesi',
  'assessmentResults.yes': 'Evet',
  'assessmentResults.no': 'Hayır',
  'assessmentResults.gradingMethod': 'Geçme ölçütü',
  'assessmentResults.passPoint': 'Geçme puanı',
  'assessmentResults.search': 'Ara',
  'assessmentResults.assessmentStats': 'Değerlendirme İstatistikleri',
  'assessmentResults.showResults': 'Soruların sonuçlarını göster',
  'assessmentResults.showCriterias': 'Ölçütlerin sonuçlarını göster',
  'assessmentResults.name': 'İSİM',
  'assessmentResults.surname': 'SOYİSİM',
  'assessmentResults.start': 'başlangıç',
  'assessmentResults.end': 'BİTİŞ',
  'assessmentResults.methodType': 'Dereceli puanlama anahtarı ({name})',
  'assessmentResults.assessmentMethod': 'Değerlendirme yöntemi',
  'assessmentResults.status': 'durum',
  'assessmentResults.netScore': 'Net Puan',
  'assessmentResults.rawScore': 'Ham Puan',
  'assessmentResults.question': 'S',
  'assessmentResults.apply': 'Uygula',
  'assessmentResults.selectedApplications': 'Seçili uygulamalar',
  'assessmentResults.invalidate': 'Geçersiz kıl',
  'assessmentResults.reset': 'Oturumu Sıfırla',
  'assessmentResults.average': 'Genel ortalama',
  'assessmentResults.median': 'Medyan',
  'assessmentResults.announceResults': 'Sonuçları Duyurun',
  'assessmentResults.delete': 'Sil',
  'assessmentResults.cancel': 'İptal',
  'assessmentResults.invalidAlert': `{name} {Surname} adayının sınavı geçersiz sayılacak ve bu sınava tekrar giremeyecektir. Aday için sınavı geçersiz kılmak istediğinize emin misiniz?`,
  'assessmentResults.invalidAlertMulti':
    'Seçilen {count} adayın sınavları geçersiz sayılacak ve bu sınava tekrar giremeyeceklerdir. Adaylar için sınavı geçersiz kılmak istediğinize emin misiniz?',
  'assessmentResults.announceAlert':
    '{name} {Surname} adayının sınav sonuçları ilan edilecektir. Onaylıyor musunuz?',
  'assessmentResults.announceAlertMulti':
    'Seçili {count} adayın sınav sonuçları ilan edilecektir. Onaylıyor musunuz?',
  'assessmentResults.deleteAlert':
    '{name} {Surname} adayının sınav uygulaması silinecektir. Onaylıyor musunuz?',
  'assessmentResults.deleteAlertMulti':
    'Seçili {count} adayın sınav uygulamaları silinecektir. Onaylıyor musunuz?',
  'assessmentResults.finished': 'Tamamlandı',
  'assessmentResults.onGoing': 'Devam ediyor',
  'assessmentResults.notStarted': 'Başlamadı',
  'assessmentResults.invalidated': 'Geçersiz',
  'assessmentResults.notImported': 'Aktarılmadı',
  'assessmentResults.unknown': 'Bilinmiyor',
  'assessmentResults.emptySelectedList': 'Lütfen bir aday seçiniz!',
  'assessmentResults.emptySelectedApp': 'Lütfen bir uygulama seçiniz!',
  'assessmentResults.select': 'Seçiniz...',
  'assessmentResults.relative': 'Bağıl (Çan Eğrisi)',
  'assessmentResults.absolute': 'Mutlak',
  'assessmentResults.EVALUATED': 'Değerlendirildi',
  'assessmentResults.DID_NOT_ATTEND': 'Katılmadı',
  'assessmentResults.NOT_EVALUATED': 'Değerlendirilmedi',
  'assessmentResults.review': 'İncele',
  'assessmentResults.actions': 'İşlemler',
  'assessmentResults.report': 'Rapor',
  'assessmentResults.announced': 'Duyuruldu',
  'assessmentResults.highRisk': 'Yüksek Risk',
  'assessmentResults.mediumRisk': 'Orta Risk',
  'assessmentResults.lowRisk': 'Düşük Risk',
  'assessmentResults.noRisk': 'Risk Yok',
  'assessmentResults.errorInvalideResult':
    'Seçilen aday(lar) için değerlendirme geçersiz kılma uygulaması gerçekleştirilemedi.',
  'assessmentResults.hasAnnouncedCandidate':
    'Seçilen aday(lar)ın değerlendirmesi daha önceden duyurulduğu için bu uygulama gerçekleştirilememiştir.',
  'assessmentResults.hasInvalidatedCandidate':
    'Değerlendirmesi geçersiz kılınan aday(lar) için bu uygulama gerçekleştirilemez.',
  'assessmentResults.hasNotStartedCandidate':
    'Sınava başlamayan aday(lar) için bu uygulama gerçekleştirilemez.',
  'assessmentResults.hasOngoingCandidate':
    'Aktif oturumu bulunan aday(lar) için bu uygulama gerçekleştirilemez.',
  'assessmentResults.hasCanceledCandidate':
    'İptal edilen değerlendirme için bu uygulama gerçekleştirilemez.',
  'assessmentResults.hasNotEvaluatedCandidate':
    'Değerlendirilmeyen aday(lar) için bu uygulama gerçekleştirilemez.',
  'assessmentResults.hasNotAttendedCandidate':
    'Sınava katılmayan aday(lar) için bu uygulama gerçekleştirilemez.',
  'assessmentResults.SUPERVISOR_DECISION':
    'Bu adayın değerlendirmesi yetkili tarafından geçersiz kılınmıştır.',
  'assessmentResults.ID_MISMATCH':
    'Adayın sınav öncesi yüz tanıma verileri ile sistemde onaylı fotoğrafı sınav sorumlusu tarafından incelenerek, aralarında benzerlik olmadığına karar verilmiştir.',
  'assessmentResults.ASSESSMENT_OVERDUE':
    'Bu adayın değerlendirmesi sınav süresi dolduğunda yanıtları göndermediği için geçersiz kılınmıştır.',
  'assessmentResults.cancelledAlert':
    'Bu değerlendirme {cancelDate} tarihinde {nameSurname} tarafından iptal edilmiştir.',
  'assessmentResults.cancelledAlertReason': 'Gerekçe: {cancelReason}',
  'assessmentResults.schedule': 'Süre ve takvim',
  'assessmentResults.assessmentDetails': 'Değerlendirme detayları',
  'assessmentResults.candidateResults': 'Aday Sonuçları',
  'assessmentResults.candidateResultsSubtitle':
    'Aday değerlendirme sonuçlarına genel bakış',
  'assessmentResults.candDisplayOpt':
    'Sınav Sonrası Soru ve Yanıt Gösterim Seçenekleri',
  'assessmentResults.reportDisplayOpt': 'Adayların Sınav İçgörü Analizleri',
  'assessmentResults.candDisplayOptTitle':
    'Sınav Sonrası Soru ve Yanıt Gösterim Seçenekleri',
  'assessmentResults.candDisplayExplanation':
    'Adayların sınav oturumu incelemesinde görüntüleyecekleri parametreleri özelleştirin',
  'assessmentResults.reportDisplayOptTitle':
    'Adayların Sınav İçgörü Analizleri',
  'assessmentResults.reportDisplayExplanation':
    'Adayların raporlarında görünecek parametreleri özelleştirin',
  'assessmentResults.candDisplayOptUpdateSuccess':
    'Soru ve yanıt gösterim seçenekleri başarıyla güncellendi.',
  'assessmentResults.reportDisplayOptUpdateSuccess':
    'Sonuç raporu gösterim seçenekleri başarıyla güncellendi.',
  'assessmentResults.options': 'Detaylar',
  'assessmentResults.active': 'Aktif',
  'assessmentResults.passive': 'Pasif',
  'assessmentResults.none': 'Yok',
  'assessmentResults.update': 'Güncelle',
  'assessmentResults.resetStatusError':
    'Bu uygulama yalnızca sınava devam etmekte olan adaylar için gerçekleştirilebilir.',
  'assessmentResults.resetCandCountError':
    'Birden fazla seçili aday için bu uygulama gerçekleştirilemez.',
};
