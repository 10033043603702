/* eslint-disable react/no-children-prop */
import clsx from 'clsx';
import { RichUtils } from 'draft-js';
import React from 'react';

export default function createInlineStyleButton({ style, children }) {
  return function InlineStyleButton(props) {
    const toggleStyle = (event) => {
      event.preventDefault();
      props.setEditorState(
        RichUtils.toggleInlineStyle(props.getEditorState(), style)
      );
    };

    const preventBubblingUp = (event) => {
      event.preventDefault();
    };

    // we check if this.props.getEditorstate is undefined first in case the button is rendered before the editor
    const styleIsActive = () =>
      props.getEditorState &&
      props.getEditorState().getCurrentInlineStyle().has(style);

    const { theme, buttonProps = {} } = props;
    const className = styleIsActive()
      ? clsx(theme.button, theme.active)
      : theme.button;

    return (
      <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
        <button
          children={children}
          {...buttonProps}
          className={className}
          onClick={toggleStyle}
          type="button"
        />
      </div>
    );
  };
}
