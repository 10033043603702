export default {
  'objectives.title': 'Learning Objectives',
  'objectives.titleName': 'Learning Objectives',
  'objectives.subtitle': 'Manage the learning objectives you want to measure',
  'objectives.emptySubtitle': 'Create the first learning objective for this course',
  'objectives.obj': 'Learning Objective',
  'objectives.status': 'Status',
  'objectives.actions': 'Actions',
  'objectives.search': 'Search',
  'objectives.add': 'Add Learning Objective',
  'objectives.delete': 'Delete',
  'objectives.edit': 'Edit',
  'objectives.deleteSuccess': 'Learning objective deleted successfully!',
  'objectives.deleteConfirmationTitle': 'Delete objective',
  'objectives.deleteConfirmationMessage':
    'Are you sure to delete the objective? This operation cannot be reversed.',
  'objectives.deleteConfirmationConfirmButton': 'Yes, delete objective',
  'objectives.questionCount': 'Number of questions',
  'objectives.showPassiveObjectives': 'Show passive learning objectives',
  'objectives.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'objectives.messageInformation': 'There is no {titleValue} in this course of your organization yet.',
  'objectives.messageInformationAdd': 'Get started by adding the {titleValue} you want to measure!',  
};
