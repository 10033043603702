import React, { useEffect, useRef, useState } from 'react';

import { X } from 'react-feather';
import classnames from 'classnames';
import { Modal } from 'reactstrap';

import '../../../assets/scss/components/image-zoom-modal.scss';

const ImageZoomModal = ({ isOpen, toggle, src, width, height }) => {
  const imgRef = useRef();

  const [isWider, setIsWider] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const imageRatio =
          imgRef?.current?.clientWidth / imgRef?.current?.clientHeight;
        setIsWider(!(imageRatio < 16 / 9));
        setIsLoading(false);
      }, 500);
    } else {
      setIsLoading(true);
    }
  }, [isOpen]);

  return (
    <Modal
      centered
      className="image-zoom-dialog"
      contentClassName={classnames('image-zoom-content', {
        'is-loading': isLoading,
      })}
      modalClassName="image-zoom-modal"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="close-image-btn" onClick={toggle}>
        <X className="close-image-icon" />
      </div>
      <div>
        <img
          ref={imgRef}
          className={classnames('zoomed-img', {
            wider: isWider,
            longer: !isWider,
          })}
          // height={height}
          // width={width}
          src={src}
          alt="question root attachment"
          onClick={toggle}
        />
      </div>
    </Modal>
  );
};

export default ImageZoomModal;
