export default {
  'learningObjPopup.title': '{param} Öğrenme Hedefleri Ayarları',
  'learningObjPopup.nameHint': 'Öğrenme hedefi ismini giriniz...',
  'learningObjPopup.subTitle': 'ÖĞRENME HEDEFİ',
  'learningObjPopup.subTitleActions': 'İŞLEMLER',
  'learningObjPopup.foundAlert': 'Herhangi bir öğrenme hedefi bulunamadı',
  'learningObjPopup.moveButton': 'Taşı',
  'learningObjPopup.editButton': 'Düzenle',
  'learningObjPopup.deleteButton': 'Sil',
  'learningObjPopup.deleteConfirmationTitle': 'Öğrenme hedefini sil',
  'learningObjPopup.deleteConfirmationMessage':
    'Öğrenme hedefini silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'learningObjPopup.deleteConfirmationConfirmButton':
    'Evet, Öğrenme hedefini sil',
  'learningObjPopup.deleteConfirmationCancelButton': 'İptal',
  'learningObjPopup.minMessage':
    'Öğrenme hedefi adı en az 5 karakter olmalıdır.',
  'learningObjPopup.maxMessage':
    'Öğrenme hedefi adı en fazla 30 karakter olmalıdır.',
  'learningObjPopup.requiredMessage': 'zzzzz.',
  'learningObjPopup.deleteAlert': 'Öğrenme hedefi başarıyla silindi.',
  'learningObjPopup.updateAlert': 'Öğrenme hedefi başarıyla güncellendi.',
  'learningObjPopup.moveAlert': 'Öğrenme hedefleri başarıyla taşındı.',
  'learningObjPopup.reset': 'Sıfırla',
};
