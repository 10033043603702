export default {
  'report.assessmentOverview': 'Assessment Overview',
  'report.score': 'Candidate Score',
  'report.candidateOverview': 'Candidate Overview',
  'report.unitCourse': 'Unit / Course',
  'report.examiner': 'Examiner',
  'report.type': 'Assessment type',
  'report.category': 'Assessment category',
  'report.highestScore': 'The highest score',
  'report.highestScore-tooltip':
    'The highest score that can be obtained from this assessment.',
  'report.randomGuessing': 'Random guessing fix',
  'report.randomGuessing-tooltip':
    'To discourage random guessing, each incorrect answer given by candidates in multiple-choice questions is scored negatively based on the number of choices for that question. For example, if a question with five options and worth 10 points is answered incorrectly by making a guess; The scores of the question are divided into 5-1=4 slices, and one slice, namely -2.5 negative points is applied. ',
  'report.passingCriteria': 'Passing criteria',
  'report.passingCriteria-tooltip':
    'It is a type of evaluation that defines the success of the candidate according to a specified passing score.',
  'report.passingCriteriaNone-tooltip':
    'There are no passing criteria for this assessment.',
  'report.passingScore': 'Passing score',
  'report.rank': 'Rank',
  'report.quartile': 'Rank quartile',
  'report.duration': 'Duration',
  'report.examStart': 'Exam start date',
  'report.examEnd': 'Exam end date',
  'report.questionCount': 'Questions',
  'report.correctAnswers': 'Correct answers',
  'report.incorrectAnswers': 'Incorrect answers',
  'report.unansweredQuestions': 'Unanswered questions',
  'report.unviewedQuestions': 'Unviewed questions',
  'report.netScore-tooltip': "Candidate's assessment score",
  'report.averageScore': 'Overall net score avarage',
  'report.resultMainTitle': 'Candidate Result Report',
  'report.resultMainSubtitle': "Review the candidate's result report",
  'report.topicTitle': 'Topics',
  'report.objTitle': 'Learning Objectives',
  'report.performanceTitleByTopics': 'Candidate Performance by Topics',
  'report.performanceTitleByLO': 'Candidate Performance by Learning Objectives',
  'report.performanceSubtitleByTopics':
    "Review candidate's exam performance by topics",
  'report.performanceSubtitleByLO':
    "Review candidate's exam performance by learning objectives",
  'report.topicName': 'topic name',
  'report.objName': 'learning objective name',
  'report.totalQuestion': 'correct answer / total question',
  'report.totalQuestionQa': 'CORRECT ANSWER / TOTAL QUESTION BY ASSOCIATED QA',
  'report.weight': 'weight (%)',
  'report.weightQa': 'WEIGHT (%) BY ASSOCIATED QA ',
  'report.scoreRange': 'Score Range (%)',
  'report.avgScore': 'Average Score',
  'report.yourScore': 'Candidate Score',
  'report.radarTopicTitle': 'Radar Chart by Topics',
  'report.radarObjTitle': 'Radar Chart by Learning Objectives',
  'report.resultReport': '{nameSurname} Assessment Result Report',
  'report.topicSubtitle': 'ALL TOPICS',
  'report.objSubtitle': 'ALL LEARNING OBJECTIVES',
  'report.yes': 'Yes',
  'report.rawAndNet': 'Raw Score / Net Score',
  'report.netScore': 'Net score',
  'report.per': '{per}%',
  'report.radarTopicTitleAlert':
    'Since the number of topic is less than 3, statistical calculations can be misleading.',
  'report.radarObjTitleAlert':
    'Since the number of learning objectives is less than 3, statistical calculations can be misleading.',
  'report.assessmentMethod': 'Assessment method',
  'report.rubricName': 'Rubric name',
  'report.criteria': 'Criteria',
  'report.allCriteria': 'All Criteria',
  'report.level': 'Candidate / Overall Level',
  'report.examinerFeedbacks': "Examiner's Feedbacks",
  'report.feedback-tooltip':
    'Written and oral feedbacks from the examiner for the assessment.',
  'report.criteriaTitle': 'Candidate Performance by Criteria',
  'report.criteriaSubtitle': 'Review candidate performance by criteria',
  'report.RUBRIC': 'Rubric',
  'report.assessmentStatus': 'Assessment status',
  'report.proctoringResult': 'Proctoring result',
  'report.unproctored': 'Unproctored',
  'report.lockdownReview': 'Lockdown & Review',
  'report.recordReview': 'Record & Review',
  'report.proctoringType': 'Proctoring type',
  'report.proctoringTypeButton': 'Report',
  'report.recordReview-tooltip':
    'In this type of proctoring, video and screen recording of the candidate is taken during the exam. In this way, the examiner can complete the entire exam security assessment in a very short time by watching only the marked sections of certain candidates, instead of watching the video recordings of many candidates that can take hours. This proctoring type currently covers all the features of the Lockdown & Review proctoring type. Recommended for exams of high importance.',
  'report.lockdownReview-tooltip':
    'In this type of proctoring, the device that the candidate connects to the exam is subject to various restrictions such as full-screen forcing and not being able to print. Some types of violations that cannot be restricted, such as turning off screen sharing, are also reported for this type of proctoring. This proctoring type produces a risk score for the candidates after the exam, allowing the examiner to evaluate the violations committed by the designated candidates on the window and device. All features of this type are also available for the Record & Review proctoring type. Recommended for exams of low to medium importance.',
  'report.unproctored-tooltip':
    'It is a type of proctoring in which no supervision or restriction is made during the exam. A report is not produced after the exam. It can be used in low-importance exams or quizzes.',
  'report.candidateName': 'Name surname',
  'report.assessmentName': 'Assessment name',
  'report.status': 'Status',
  'report.description': 'DESCRIPTION',
  'report.result': 'RESULT',
  'report.candidateOverviewTitle': 'CANDIDATE OVERVIEW',
  'report.assessmentOverviewTitle': 'ASSESSMENT DETAILS',
  'report.turkish': 'Turkish',
  'report.english': 'English',
  'report.none': 'English',
  'report.numberOfTopicsAlert':
    'Since the number of topic is more than 15, it is not appropriate to create a radar chart.',
  'report.numberOfLOAlert':
    'Since the number of learning objective is more than 15, it is not appropriate to create a radar chart.',
  'report.resetAlertInfo':
    'The previous assessment of this candidate has been reset by the authorized.',
  'report.reason': '<b>Reason:</b> {reason}',
  'report.unspecified': '<b>Reason:</b> Unspecified',
  'report.update': 'Update',
  'report.performanceTitleByAttribute':
    'Candidate Performance by Question Attributes ({attributeName})',
  'report.performanceSubtitleByAttribute':
    "Review the candidate's exam performance against the options was associated with this question attribute.",
  'report.attributeName': 'Attribute Option Name',
  'report.attributeSubtitle': 'All options of the attribute',
  'report.attributeTitle': 'Statistics by Question Attribute Options',
  'report.radarAttributeTitle': 'Radar Chart by Question Attribute Options',
  'report.radarAttributeTitleAlert':
    'Since the number of question attribute is less than 3, statistical calculations can be misleading.',
  'report.numberOfAttributeAlert':
    'Since the number of question attribute is more than 15, it is not appropriate to create a radar chart.',

  'report.performanceTitleByCourse': 'Candidate Performance by Courses',
  'report.performanceSubtitleByCourse':
    'Review candidate performance by courses',
  'report.courseName': 'Course Name',
  'report.courseSubtitle': 'All courses',
  'report.courseTitle': 'Courses',
  'report.radarCourseTitle': 'Radar Chart by Courses',
  'report.radarCourseTitleAlert':
    'Since the number of courses is less than 3, statistical calculations can be misleading.',
  'report.numberOfCourseAlert':
    'Since the number of courses is more than 15, it is not appropriate to create a radar chart.',
  'report.reportTotalQuestionTooltip':
    'The data in this column is calculated based only on the question attribute associated with the questions. If a question in the exam is not associated with this question attribute, it is not included in this field.',
  'report.reportWeightTooltip':
    'The data in this column is calculated based only on the question attribute associated with the questions. If a question in the exam is not associated with this question attribute, it is not included in this field.',
};
