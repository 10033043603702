export default {
  'import.title': 'İçe aktarma',
  'import.subtitle': 'CSV dosyasını içe aktarın',
  'import.template': 'Şablon',
  'import.templateInfo':
    'Dosyanızı aşağıdaki şablonu kullanarak gönderebilirsiniz.',
  'import.convert': "XLSX dosyasını CSV'ye dönüştür",
  'import.convertInfo':
    "Düzenlediğiniz şablonunuzu CSV'ye dönüştürmek için adımları izleyin",
  'import.reminding': 'Hatırlatma',
  'import.remindingInfo':
    'Lütfen aşağıdaki sayfa yapısına ve açıklamalara uyduğunuzdan emin olun.',
  'import.download': 'İndir',
  'import.browseFiles': 'Dosyalara göz atın',
  'import.file': 'Dosya',
  'import.fileInfo':
    'Yapılan kağıt tabanlı sınavın OMR çıktılarını sürükleyin veya seçin.',
  'import.dragDrop': 'Dosyaları buraya sürükleyip bırakın',
  'import.or': 'VEYA',
  'import.attachment': "Dosya 100 mb'yi geçmemelidir",
  'import.supported': 'Desteklenen dosya türü: CSV',
  'import.fileName': 'Dosya adı:',
  'import.creationDate': 'Oluşturma tarihi:',
  'import.bookletName': 'Kitapçık Adı',
  'import.answers': 'Cevaplar',
  'import.steps':
    'Microsoft Office Excel > Dosya > Farklı kaydet > Kayıt türü > CSV',
  'import.acceptedTemplateInfo':
    'Kabul edilen şablon aşağıdaki başlıklarla eşleşmeli ve ayırıcı olarak noktalı virgül (;) kullanılmalıdır.',
  'import.validSample': 'Geçerli bir örnek şöyle görünmelidir',
  'import.emptyAnswerInfo':
    "Cevaplar için boş bir cevap olabilir, bu durumda 'X' ile işaretlenmeli veya boşluk ' ' olmalıdır. Örn.",
  'import.uploadError': 'Yalnızca .csv uzantılı dosyaları yükleyebilirsiniz!',
  'import.discardChanges': 'Değişiklikleri Sil',
  'import.deleteFileConfirmationText':
    'Dosyayı silmek istediğinizden emin misiniz?',
  'import.delete': 'Evet, Sil',
  'import.cancel': 'İptal',
};
