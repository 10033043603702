export default {
  'userAdd.title': 'Add User',
  'userAdd.firstName': 'First Name',
  'userAdd.firstNameHint': 'First name here..',
  'userAdd.lastName': 'Last Name',
  'userAdd.lastNameHint': 'Last name here..',
  'userAdd.unit': 'Unit',
  'userAdd.userName': 'Username',
  'userAdd.userNameHint': 'Username here..',
  'userAdd.role': 'User Role',
  'userAdd.roleHint': '(Assigned roles are applied system-wide)',
  'userAdd.email': 'Email',
  'userAdd.emailValidationText': 'You can use letters, numbers & periods',
  'userAdd.emailHint': 'hello@example.com',
  'userAdd.type': 'User Type',
  'userAdd.group': 'User Group',
  'userAdd.systemId': 'System ID',
  'userAdd.systemIdHint': 'System ID here..',
  'userAdd.add': 'Add User',
  'userAdd.select': 'Select',
  'userAdd.selectRole': 'Select Role',
  'userAdd.unitSelectRequiredMessage': 'Please select a unit',
  'userAdd.success': 'User created successfully!',
  'userEdit.success': 'User updated successfully!',
  'userAdd.maxMessageFirstName': 'First name should be max 70 characters.',
  'userAdd.maxMessageLastName': 'Last name should be max 70 characters.',
  'userAdd.maxMessageUserName': 'Username should be max 70 characters.',
  'userAdd.maxMessageEmail': 'Email should be max 70 characters.',
  'userAdd.maxMessageSystemId': 'System ID should be max 20 characters.',
  'userAdd.minMessageSystemId': 'System ID should be at least 6 characters.',
  'userAdd.selectAlert': 'Please select a user type',
  'userAdd.CANDIDATE': 'Candidate',
  'userAdd.AUTHORIZED': 'Authorized',
};
