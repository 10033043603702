export default {
  'menu.dashboard': 'Genel Bakış',
  'menu.dashboards': 'Yönetim Panelleri',
  'menu.home': 'Genel Bakış',
  'menu.planDetails': 'Plan Detayları',
  'menu.settings': 'Ayarlar',
  'menu.globalSettings': 'Global Ayarlar',
  'menu.generalSettings': 'Genel Ayarlar',
  'menu.structuralSettings': 'Yapısal Ayarlar',
  'menu.general': 'Genel',
  'menu.structural': 'Yapısal',
  'menu.units': 'Birimler',
  'menu.periods': 'Dönemler',
  'menu.exams': 'Sınavlar',
  'menu.questionFields': 'Soru Özellikleri',
  'menu.accounts': 'Hesaplar',
  'menu.users': 'Kullanıcılar',
  'menu.groups': 'Gruplar',
  'menu.roles': 'Roller',
  'menu.assessment': 'Değerlendirme',
  'menu.myUnits': 'Birimlerim',
  'menu.myCourses': 'Birimlerim / Derslerim',
  'menu.help': 'Yardım',
  'menu.newTicket': 'Yeni Kayıt',
  'menu.raiseSupport': 'Kullanıcı Destek',
  'menu.faq': 'Sıkça Sorulan Sorular',
  'menu.about': 'Hakkımızda',
  'menu.welcome': 'Hoşgeldiniz',
  'menu.quickActions': 'Hızlı İşlemler',
  'menu.addQuestion': 'Soru oluştur',
  'menu.addMcq': 'Çoktan Seçmeli Soru',
  'menu.addGroupedQ': 'Gruplu Soru',
  'menu.addOpenEndedQ': 'Açık Uçlu Soru',
};
