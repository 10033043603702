export default {
  'ticket.subject': 'Konu',
  'ticket.type': 'Kayıt Türü',
  'ticket.message': 'Mesaj',
  'ticket.send': 'Gönder',
  'ticket.help': 'Yardım',
  'ticket.bug': 'Hata Raporu',
  'ticket.suggestion': 'Öneri',
  'ticket.subMinMessage': 'Konu en az 6 karakter olmalıdır.',
  'ticket.subMaxMessage': 'Konu en fazla 25 karakter olmalıdır.',
  'ticket.messageMinMessage': 'Mesaj en az 10 karakter olmalıdır.',
  'ticket.messageMaxMessage': 'Mesaj en fazla 250 karakter olmalıdır.',
  'ticket.requiredMessage': 'Bu alan boş bırakılamaz.',
  'ticket.sendingCompletedMessage': 'Kaydınız başarıyla gönderildi!',
};
