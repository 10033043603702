export default {
  'assessmentResults.title': 'Assessment Results',
  'assessmentResults.subtitle': 'Overview of assessment results and details',
  'assessmentResults.assessmentName': 'Summary of {assessmentName}',
  'assessmentResults.assessmentDesc': 'Assessment Description',
  'assessmentResults.unit': 'Unit / Course',
  'assessmentResults.examiner': 'Examiner: {name}',
  'assessmentResults.assessmentType': 'Assessment type',
  'assessmentResults.creationDate': 'Creation date',
  'assessmentResults.startDate': 'Start date',
  'assessmentResults.endDate': 'End date',
  'assessmentResults.candidate': 'Candidate',
  'assessmentResults.timeOver': 'When time is over',
  'assessmentResults.validAnswers': 'Send given answers and complete the exam',
  'assessmentResults.invalidAnswers':
    'Consider given answers invalid and complete the exam',
  'assessmentResults.resultType': 'Announcement of results',
  'assessmentResults.INSTANT_PRELIMINARY_RESULT':
    'Show result after the candidate finishes the exam.',
  'assessmentResults.MANUALLY_BY_OWNER':
    'Results are announced by the creator manually',
  'assessmentResults.PRELIMINARY_RESULT': 'Show result after the exam ends.',
  'assessmentResults.process': 'Exam execution options',
  'assessmentResults.assessmentCategory': 'Assessment category',
  'assessmentResults.duration': 'Duration',
  'assessmentResults.unproctored': 'Unproctored',
  'assessmentResults.proctoringType': 'Proctoring type',
  'assessmentResults.highestGrade': 'The highest score',
  'assessmentResults.correctionGuessing': 'Random guessing fix',
  'assessmentResults.yes': 'Yes',
  'assessmentResults.no': 'No',
  'assessmentResults.gradingMethod': 'Passing criteria',
  'assessmentResults.passPoint': 'Passing score',
  'assessmentResults.search': 'Search',
  'assessmentResults.assessmentStats': 'Assessment Stats',
  'assessmentResults.showResults': 'Show the results of the questions',
  'assessmentResults.showCriterias': 'Show the results of the criterias',
  'assessmentResults.name': 'name',
  'assessmentResults.surname': 'surname',
  'assessmentResults.start': 'start',
  'assessmentResults.end': 'end',
  'assessmentResults.methodType': 'Rubric ({name})',
  'assessmentResults.assessmentMethod': 'Assessment method',
  'assessmentResults.status': 'status',
  'assessmentResults.netScore': 'Net Score',
  'assessmentResults.rawScore': 'Raw Score',
  'assessmentResults.question': 'Q',
  'assessmentResults.apply': 'Apply',
  'assessmentResults.selectedApplications': 'Selected applications',
  'assessmentResults.invalidate': 'Invalidate',
  'assessmentResults.reset': 'Reset Session',
  'assessmentResults.average': 'Overall average',
  'assessmentResults.median': 'Median',
  'assessmentResults.announceResults': 'Announce Results',
  'assessmentResults.delete': 'Delete',
  'assessmentResults.cancel': 'Cancel',
  'assessmentResults.invalidAlert': `{name} {Surname}'s exam will be invalidated and  the candidate will not be able to retake this exam. Are you sure you want to invalidate the exam for the candidate?`,
  'assessmentResults.invalidAlertMulti':
    'The exam of the {count} selected candidates will be invalidated and they will not be able to take this exam again. Are you sure you want to invalidate the exam for the candidates?',
  'assessmentResults.announceAlert':
    "The {name} {Surname} candidate's exam results will be announced. Do you approve?",
  'assessmentResults.announceAlertMulti':
    'The exam results of the selected {count} candidates will be announced. Do you approve?',
  'assessmentResults.deleteAlert':
    "The {name} {Surname} candidate's exam application will be deleted. Do you approve?",
  'assessmentResults.deleteAlertMulti':
    "The selected {count} candidates' exam applications will be deleted. Do you approve?",
  'assessmentResults.finished': 'Finished',
  'assessmentResults.onGoing': 'On going',
  'assessmentResults.notStarted': 'Not started',
  'assessmentResults.invalidated': 'Invalidated',
  'assessmentResults.notImported': 'Not Imported',
  'assessmentResults.unknown': 'Unknown',
  'assessmentResults.emptySelectedList': 'Please select a candidate!',
  'assessmentResults.emptySelectedApp': 'Please select an application!',
  'assessmentResults.select': 'Select...',
  'assessmentResults.absolute': 'Absolute',
  'assessmentResults.relative': 'Relative (Bell Curve)',
  'assessmentResults.EVALUATED': 'Evaluated',
  'assessmentResults.DID_NOT_ATTEND': 'Did not attend',
  'assessmentResults.NOT_EVALUATED': 'Not Evaluated',
  'assessmentResults.review': 'Review',
  'assessmentResults.actions': 'Actions',
  'assessmentResults.report': 'Report',
  'assessmentResults.announced': 'Announced',
  'assessmentResults.highRisk': 'High Risk',
  'assessmentResults.mediumRisk': 'Medium Risk',
  'assessmentResults.lowRisk': 'Low Risk',
  'assessmentResults.noRisk': 'No Risk',
  'assessmentResults.recordReview': 'Record & Review',
  'assessmentResults.lockdownReview': 'Lockdown & Review',
  'assessmentResults.errorInvalideResult':
    'The assessment invalidate application could not be performed for the selected candidate(s).',
  'assessmentResults.hasAnnouncedCandidate':
    'This application cannot be performed as the assessment of the selected candidate(s) has already been announced.',
  'assessmentResults.hasInvalidatedCandidate':
    'This application cannot be performed for the candidate(s) whose assessment has been invalidated.',
  'assessmentResults.hasNotStartedCandidate':
    'This application cannot be performed for candidate(s) who have not started the exam.',
  'assessmentResults.hasOngoingCandidate':
    'This application cannot be performed for candidate(s) who have an active session.',
  'assessmentResults.hasCanceledCandidate':
    'This application cannot be performed for the canceled assessment.',
  'assessmentResults.hasNotEvaluatedCandidate':
    'This application cannot be performed for candidate(s) who have not evaluated.',
  'assessmentResults.hasNotAttendedCandidate':
    'This application cannot be performed for candidate(s) who have not attended the exam.',
  'assessmentResults.SUPERVISOR_DECISION':
    'The assessment of this candidate has been invalidated by the authorized.',
  'assessmentResults.ID_MISMATCH':
    "The candidate's before exam face recognition data and the approved photo in the system were examined by the examiner and it was decided that there was no similarity between them.",
  'assessmentResults.ASSESSMENT_OVERDUE':
    'The assessment of this candidate has been invalidated for not submitting responses within the exam period.',
  'assessmentResults.cancelledAlert':
    'This assessment was canceled by {nameSurname} on {cancelDate}',
  'assessmentResults.cancelledAlertReason': 'Reason: {cancelReason}',
  'assessmentResults.schedule': 'Time limits and schedule',
  'assessmentResults.assessmentDetails': 'Assessment details',
  'assessmentResults.candidateResults': 'Candidate Results',
  'assessmentResults.candidateResultsSubtitle':
    'Overview of candidate assessment results',
  'assessmentResults.candDisplayOpt':
    'Post Exam Question and Answer Display Options',
  'assessmentResults.reportDisplayOpt': "Candidates' Exam Insight Analysis",
  'assessmentResults.candDisplayOptTitle':
    'Post Exam Question and Answer Display Options',
  'assessmentResults.candDisplayExplanation':
    'Customize the parameters that candidates view in the exam session review',
  'assessmentResults.reportDisplayOptTitle':
    "Candidates' Exam Insight Analysis",
  'assessmentResults.reportDisplayExplanation':
    "Customize the parameters that will appear in candidates' reports",
  'assessmentResults.candDisplayOptUpdateSuccess':
    'Question and answer display options have been successfully updated.',
  'assessmentResults.reportDisplayOptUpdateSuccess':
    'Result report display options have been successfully updated.',
  'assessmentResults.options': 'Details',
  'assessmentResults.active': 'Active',
  'assessmentResults.passive': 'Passive',
  'assessmentResults.none': 'None',
  'assessmentResults.update': 'Update',
  'assessmentResults.resetStatusError':
    'This application is only available for candidates who are currently on going the exam.',
  'assessmentResults.resetCandCountError':
    'This application cannot be performed for more than one selected candidate.',
};
