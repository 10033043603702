export default {
  'assessmentType.title': 'Assessment Type Settings',
  'assessmentType.subtitle': 'Set organization-wide rules',
  'assessmentType.paperBased': 'Paper-based Exams Settings',
  'assessmentType.preview': 'Preview',
  'assessmentType.logo': 'Exam booklet logo preview',
  'assessmentType.upload': 'Upload Logo',
  'assessmentType.reset': 'Reset',
  'assessmentType.imgInfo':
    'Attachment should not exceed 800 kb{br}Supported file type: JPEG, GIF or PNG',
  'assessmentType.RESTRICTED_IPS': "Allow restricted IP's",
  'assessmentType.WHITELISTS': 'Allow whitelists',
  'assessmentType.ID_VERIFICATION': 'Allow ID verification',
  'assessmentType.PROCTORING_OPTIONS': 'Allow proctoring options',
  'assessmentType.INVITE_USER': 'Allow invite candidate',
  'assessmentType.onsite': 'Onsite / Test Center Exams Settings',
  'assessmentType.online': 'Online Exams Settings',
  'assessmentType.RESTRICTED_IPS_Info':
    'If you allow, candidates can only take the exams with devices connected to the Internet from a certain IP range.',
  'assessmentType.WHITELISTS_Info':
    'If you allow, the candidate will be able to access the whitelist URLs.',
  'assessmentType.ID_VERIFICATION_Info':
    'If you allow, ID verification will be done to the candidate.',
  'assessmentType.PROCTORING_OPTIONS_Info':
    'If you allow, it will be possible to specify proctoring options for the exams.',
  'assessmentType.INVITE_USER_Info':
    'If you allow, candidates who are not registered in the system can be invited to the exams by email.',
  'assessmentType.successMessage':
    'Assessment type settings saved successfully!',
  'assessmentType.bookletsName': 'Choose booklets names:',
  'assessmentType.performance': 'Performance Exams Settings',
  'assessmentType.generalSizeError':
    "The file you're attaching is bigger than the system allows.",
  'assessmentType.file': 'File',
  'assessmentType.fileSubtitle':
    'Drag or select the logo to use in the exam booklet',
};
