import React from 'react';
import createInlineStyleButton from '../utils/createInlineStyleButton.js';

export default createInlineStyleButton({
  style: 'BOLD',
  children: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16947 5.82857C8.5813 5.57301 8.92885 5.22293 9.1844 4.80625C9.43996 4.38957 9.59645 3.9178 9.64141 3.42857C9.64921 2.98619 9.57141 2.54657 9.41246 2.13483C9.25351 1.72309 9.01652 1.34729 8.71502 1.0289C8.41353 0.710518 8.05344 0.455778 7.65532 0.279237C7.2572 0.102695 6.82885 0.00780949 6.39474 0H0.935974V12H6.82371C7.23682 11.9955 7.64501 11.9082 8.02499 11.7429C8.40497 11.5777 8.74928 11.3377 9.03827 11.0369C9.32726 10.736 9.55526 10.3801 9.70926 9.98941C9.86326 9.59874 9.94024 9.18099 9.93579 8.76V8.65714C9.93608 8.06324 9.77003 7.48159 9.45714 6.98053C9.14426 6.47948 8.69754 6.07984 8.16947 5.82857ZM2.61818 1.71429H6.15082C6.50928 1.70298 6.86255 1.80367 7.16324 2.00285C7.46393 2.20202 7.69765 2.49015 7.83303 2.82857C7.97003 3.28096 7.92572 3.77022 7.7098 4.18942C7.49387 4.60862 7.12389 4.92367 6.68072 5.06571C6.50861 5.11711 6.33016 5.14309 6.15082 5.14286H2.61818V1.71429ZM6.48726 10.2857H2.61818V6.85714H6.48726C6.84572 6.84584 7.19899 6.94653 7.49968 7.1457C7.80037 7.34488 8.03409 7.63301 8.16947 7.97143C8.30647 8.42382 8.26216 8.91308 8.04624 9.33227C7.83032 9.75147 7.46034 10.0665 7.01716 10.2086C6.84506 10.26 6.66661 10.2859 6.48726 10.2857Z"
        fill="#5E5873"
      />
    </svg>
  ),
});
