export default {
  'periodList.header': 'Dönemler',
  'periodList.name': 'Dönem Adı',
  'periodList.title': 'Dönem Yönetimi',
  'periodList.subtitle': 'Organizasyonunuzun dönem yapısını tasarlayın',
  'periodList.emptySubtitle': 'Organizasyonunuzun dönem yapısını tasarlayın',
  'periodList.unit': 'Ana Birim',
  'periodList.start': 'BAŞLANGIÇ TARİHİ',
  'periodList.end': 'BİTİŞ TARİHİ',
  'periodList.elapsedTime': 'Geçen Zaman',
  'periodList.status': 'Durum',
  'periodList.actions': 'İşlemler',
  'periodList.add': 'Dönem Ekle',
  'periodList.edit': 'Düzenle',
  'periodList.delete': 'Sil',
  'periodList.search': 'Ara',
  'periodList.visible': 'Görünür',
  'periodList.invisible': 'Gizli',
  'periodList.deleteConfirmationTitle': 'Dönemi sil',
  'periodList.deleteConfirmationMessage':
    'Dönemi silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'periodList.deleteConfirmationConfirmButton': 'Evet, dönemi sil',
  'periodList.deleteAlert': 'Dönem başarıyla silindi.',
  'periodList.showPassiveStatus': 'Tamamlanan dönemleri göster',
  'periodList.progress': 'Devam ediyor',
  'periodList.completed': 'Tamamlandı',
  'periodList.startAndEnd': 'Başlangıç & Bitiş Tarihi',
  'periodList.titleInformation':
    'Hay Aksi! Henüz {titleValue} bulunmamaktadır.',
  'periodList.messageInformation':
    'Organizasyonunuzun bu dersinde henüz {titleValue} bulunmamaktadır.',
  'periodList.messageInformationAdd':
    'Dönem eklemek için {messageValue} düğmesine tıklayın.',
  'periodList.obj': 'Dönem',
  'periodList.addPeriod': '"Dönem Ekle"',
};
