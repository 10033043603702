import { getRemoteConfig, getString } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";

export const setupRemoteConfig = async (app) => {
  const remoteConfig = getRemoteConfig(app);
  let rcDefaults = require('../assets/resources/remote_config_defaults.json');
  remoteConfig.defaultConfig = rcDefaults;
  fetchAndActivate(remoteConfig)
};

export const getValueFromRemoteConfig = (key) => {
  const remoteConfig = getRemoteConfig();
  return getString(remoteConfig, key);
};
