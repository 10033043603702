export default {
  'plan.standart': 'Examod Standart SaaS',
  'plan.standartInfo':
    'You are currently subscribed {param2} plan with {param1}',
  'plan.param1': 'annual billing.',
  'plan.features': 'Features',
  'plan.billedCredits': 'Billed Credits',
  'plan.subscription': 'Subscription',
  'plan.panel': 'Administration Panel',
  'plan.management': 'User & Role Management',
  'plan.bank': 'Question Bank',
  'plan.blueprints': 'Setup Exam via Blueprints',
  'plan.paperBased': 'Paper Based or On-site Exam',
  'plan.level': 'Level 1 Proctoring w/extension',
  'plan.comprehensive': 'Comprehensive Assessment',
  'plan.candidate': 'Detailed Candidate Interface',
  'plan.application': 'Mobile Application',
  'plan.verification': 'ID Verification : {per} / {percent} {param}',
  'plan.proctoring': 'AI Proctoring : {per} / {percent} {param}',
  'plan.annually': '(annually)',
  'plan.perUsage': '(Per usage)',
  'plan.perUsageWith': '(Per usage with 90 min sessions)',
  'plan.start': 'Start Date : {start}',
  'plan.end': 'End Date : {end}',
  'plan.active': 'Active',
  'plan.planFeatures': 'Plan features you have',
  'plan.userLicenceFeature': 'Features Available with User Lisence',
  'plan.branding': 'Institutional <b>branding</b>',
  'plan.paperBasedResult': '<b>Paper-based exams</b> with digitizing results',
  'plan.onlineOnsiteExam':
    '<b>Online</b> and <b>onsite</b> <span>e-exams</span>',
  'plan.performanceExam':
    '<b>Performance</b> <span>e-exams</span> with rubrics',
  'plan.faceRecognition':
    '<b>Candidate face recognition</b> with artificial intelligence',
  'plan.proctoringWithExtension':
    '<b>Exam proctoring</b> with Examod Chrome extension (w/o video)',
  'plan.analysis': 'Comprehensive <b>exam, question and candidate analysis</b>',
  'plan.mobileApps': '<b>Examod IOS</b> and <b>Android</b> mobile apps',
  'plan.mediaStorage': '<b>Media storage</b> for institutional Examod data',
  'plan.rrCreditsFeature': 'Features Available with R&R Credits',
  'plan.videoRecording':
    '<b>Video recording</b> of candidates in online and onsite <span>e-exams</span>',
  'plan.violationReview': 'Watching videos later and <b>review violations</b>',
  'plan.RandomiseVideoRec': '<b>Randomised video recording</b> to reduce cost',
  'plan.storingVideoRec': 'Storing video recordings <b>for one month</b>',
  'plan.planDetails': 'Plan Details',
  'plan.planDetailsSubtitle':
    'Your billed products and usage amounts of your plan',
  'plan.billedProducts': 'Billed Products',

  'plan.users': 'Active User Licence : <b>{per} / {percent}</b> {param}',
  'plan.storage': 'Media Storage : <b>{per} GB / {percent} GB</b> {param}',
  'plan.reviewSession':
    'Record & Review Session Credit : <b>{per} / {percent}</b> {param}',
  'plan.faq': 'FAQ',
  'plan.faqSubtitle': 'Frequently asked question about your plan',
  'plan.per': '{per}%',
};
