export default {
  'proctoringReport.examSessionOverview': 'Exam Session Overview',
  'proctoringReport.subTitle': `Review the candidate's exam session information`,
  'proctoringReport.assessmentName': 'Assessment name',
  'proctoringReport.systemId': 'System ID',
  'proctoringReport.email': 'E-mail',
  'proctoringReport.examStartData': 'Exam start time',
  'proctoringReport.assessmentType': 'Assessment type',
  'proctoringReport.submissionTime': 'Submission time',
  'proctoringReport.proctoringType': 'Proctoring type',
  'proctoringReport.proctoringTypes': 'Proctoring Types',
  'proctoringReport.sessionDuration': 'Session duration',
  'proctoringReport.lockdownReview': 'Lockdown & Review',
  'proctoringReport.systemInformation': 'System Information',
  'proctoringReport.operatingSystem': 'Operating System',
  'proctoringReport.browser': 'Browser',
  'proctoringReport.recordReview': 'Record & Review',
  'proctoringReport.unproctored': 'Unproctored',
  'proctoringReport.recordReviewTitle': 'Record & Review Proctoring Results',
  'proctoringReport.lockdownReviewTitle':
    'Lockdown & Review Proctoring Results',
  'proctoringReport.recordLockdownSubTitle':
    'Review candidate proctoring results with data obtained by Examod',
  'proctoringReport.invalidate': `Invalidate`,
  'proctoringReport.candidateExamRecordTitle': `Candidate's Exam Session Records`,
  'proctoringReport.candidateExamRecordSubTitle': `Review the video and screen recording of the candidate during the exam`,
  'proctoringReport.totalViolations': `Total Violations`,
  'proctoringReport.totalViolationsChartHeader': `Total Violation Time`,

  'proctoringReport.suspiciousLevel': `Suspicious Level`,
  'proctoringReport.beforeExamFaceRecognition': `Before Exam Face Recognition`,
  'proctoringReport.online': `ONLINE`,
  'proctoringReport.minutes': `minutes`,
  'proctoringReport.in': `in`,
  'proctoringReport.netScore': `Net / Max Score`,
  'proctoringReport.mismatched': `Mismatched`,
  'proctoringReport.approve': `Approve`,
  'proctoringReport.approved': `Approved`,
  'proctoringReport.reviewNeeded': `Review Needed`,
  'proctoringReport.window': `Window`,
  'proctoringReport.device': `Device`,
  'proctoringReport.behavioral': `Behavioral`,
  'proctoringReport.violations': `Violations`,
  'proctoringReport.violationInLogTitle': `Violation`,
  'proctoringReport.violationInLogSec': `sec`,
  'proctoringReport.violation': `Violation`,
  'proctoringReport.helpVideoConstruct': `Markers on the video timeline show violations and their types.`,
  'proctoringReport.logsEvents': `Logs & Events`,
  'proctoringReport.filter': `Filter`,
  'proctoringReport.min': `min`,
  'proctoringReport.sec': `sec`,
  'proctoringReport.hr': `hr`,
  'proctoringReport.fileInfo': `File Information`,
  'proctoringReport.createdDate': `Creation Date`,
  'proctoringReport.videoLength': 'Video Length',
  'proctoringReport.details': `Details`,
  'proctoringReport.recordReview-tooltip': `In this type of proctoring, video and screen recording of the candidate is taken during the exam. In this way, the examiner can complete the entire exam security assessment in a very short time by watching only the marked sections of certain candidates, instead of watching the video recordings of many candidates that can take hours. This proctoring type currently covers all the features of the Lockdown & Review proctoring type. Recommended for exams of high importance.`,
  'proctoringReport.lockdownReview-tooltip': `In this type of proctoring, the device that the candidate connects to the exam is subject to various restrictions such as full-screen forcing and not being able to print. Some types of violations that cannot be restricted, such as turning off screen sharing, are also reported for this type of proctoring. This proctoring type produces a risk score for the candidates after the exam, allowing the examiner to evaluate the violations committed by the designated candidates on the window and device. All features of this type are also available for the Record & Review proctoring type. Recommended for exams of low to medium importance.`,
  'proctoringReport.unproctored-tooltip': `It is a type of proctoring in which no supervision or restriction is made during the exam. A report is not produced after the exam. It can be used in low-importance exams or quizzes.`,
  'proctoringReport.duration-warn-tooltip':
    'An assessment setup with the Record & Review proctoring type, the exam duration must be defined. Return to the Assessment Settings step to define the exam duration.',
  'proctoringReport.acceptableViolation-status': `Acceptable violations detected`,
  'proctoringReport.lowRisk-status': `Low Risk violations detected`,
  'proctoringReport.midRisk-status': `Medium Risk violations detected`,
  'proctoringReport.notAcceptableViolation-status': `Violations detected beyond tolerable limit`,
  'proctoringReport.invalid-info': `Invalidate candidate's exam by suspicious level`,

  'proctoringReport.faceRecognitionSubHeaderModal': `Review the candidate's Face ID identification processes`,
  'proctoringReport.DEVICE_VIOLATION-subHeaderModal':
    'Review suspicious device violations obtained by Examod',
  'proctoringReport.WINDOW_VIOLATION-subHeaderModal':
    'Review suspicious window violations obtained by Examod',
  'proctoringReport.BEHAVIORAL_VIOLATION-subHeaderModal':
    'Review suspicious behavioral violations obtained by Examod',

  'proctoringReport.warning': 'Warning!',
  'proctoringReport.candidateInformations': 'Candidate Informations',
  'proctoringReport.total': 'Total',
  'proctoringReport.totalViolationDifferentTypes':
    'violations of different types',
  'proctoringReport.types': 'Types',

  'proctoringReport.examodDetected': 'Examod detected',
  'proctoringReport.warning-WINDOW_VIOLATION':
    'different types of window violations during the exam.',
  'proctoringReport.warning-DEVICE_VIOLATION':
    'different types of device violations during the exam.',
  'proctoringReport.warning-BEHAVIORAL_VIOLATION':
    'different types of behavioral violations during the exam.',
  'proctoringReport.warning-beforeExamFaceRecognition':
    'Examod could not match your system photo at the time of your exam!',
  'proctoringReport.warning-faceIdInvalidated':
    "The candidate's before exam face recognition data and the approved photo in the system were examined by the examiner and it was decided that there was no similarity between them.",

  'proctoringReport.vm': 'Virtual Machine',
  'proctoringReport.video_recording': 'Video Recording',
  'proctoringReport.audio_recording': 'Audio Recording',
  'proctoringReport.screen_share': 'Screen Share',
  'proctoringReport.multiple_face': 'Multiple Face',
  'proctoringReport.unrecognized_face': 'Unrecognized Face',
  'proctoringReport.no_face': 'No Face',
  'proctoringReport.vm-violation': 'Virtual machine detected',
  'proctoringReport.video_recording-violation': 'Camera not found',
  'proctoringReport.audio_recording-violation': 'Microphone not found',
  'proctoringReport.full_screen-violation': 'Full screen mode turned off',
  'proctoringReport.screen_share-violation': 'Screen sharing turned off',
  'proctoringReport.multiple_face-violation': 'Multiple face detected',
  'proctoringReport.unrecognized_face-violation': 'Unrecognized face detected',
  'proctoringReport.no_face-violation': 'No face detected',
  'proctoringReport.timesFor': 'times for',
  'proctoringReport.times': 'times',
  'proctoringReport.followStepsToVerify': `Follow the steps below to verify the candidate's Face ID. Approve or Invalidate candidate's Face ID after your reviews.`,
  'proctoringReport.faceId': `FaceID`,
  'proctoringReport.faceStatusId': 'Face Status ID',
  'proctoringReport.candidateInfoTitle': 'Candidate Information',
  'proctoringReport.candidateSystemPhoto': `Candidate's System Photo`,
  'proctoringReport.candidateReviewPhoto': `Review the candidate's photo registered in the system`,

  'proctoringReport.candidateVideoCapture': `Video Capture`,
  'proctoringReport.candidateReviewVideoCapture': `Review the candidate's video capture before the exam`,
  'proctoringReport.minSession': `min session`,
  'proctoringReport.secIn': `sec in`,

  'proctoringReport.RECORD_REVIEW': 'Record & Review',
  'proctoringReport.LOCKDOWN_REVIEW': 'Lockdown & Review',
  'proctoringReport.null': 'Not Available',

  'proctoringReport.WINDOW_VIOLATION': 'Window',
  'proctoringReport.DEVICE_VIOLATION': 'Device',
  'proctoringReport.BEHAVIORAL_VIOLATION': 'Behavioral',

  'proctoringReport.WINDOW_VIOLATION-title': 'Window Violation',
  'proctoringReport.DEVICE_VIOLATION-title': 'Device Violation',
  'proctoringReport.BEHAVIORAL_VIOLATION-title': 'Behavioral Violation',

  'proctoringReport.FULL_SCREEN_OFF': 'Full screen mode turned off',
  'proctoringReport.SCREEN_SHARING_OFF': 'Screen sharing turned off',
  'proctoringReport.MULTIPLE_FACE_DETECTED': 'Multiple face detected',
  'proctoringReport.NO_FACE_DETECTED': 'No face detected',
  'proctoringReport.UNRECOGNIZED_FACE_DETECTED': 'Unrecognized face detected',
  'proctoringReport.CAMERA_NOT_FOUND': 'Camera not found',
  'proctoringReport.MIC_NOT_FOUND': 'Microphone not found',
  'proctoringReport.VIRTUAL_MACHINE_DETECTED': 'Virtual machine detected',
  'proctoringReport.NEW_TAB': 'Multiple tabs detected',
  'proctoringReport.MULTIPLE_SCREEN': 'Multiple screen detected',

  'proctoringReport.FULL_SCREEN_OFF-title': 'Full Screen',
  'proctoringReport.SCREEN_SHARING_OFF-title': 'Screen Share',
  'proctoringReport.MULTIPLE_FACE_DETECTED-title': 'Multiple Face',
  'proctoringReport.NO_FACE_DETECTED-title': 'No Face',
  'proctoringReport.UNRECOGNIZED_FACE_DETECTED-title': 'Unrecognized Face',
  'proctoringReport.CAMERA_NOT_FOUND-title': 'Video Recording',
  'proctoringReport.MIC_NOT_FOUND-title': 'Audio Recording',
  'proctoringReport.VIRTUAL_MACHINE_DETECTED-title': 'Virtual machine',
  'proctoringReport.NEW_TAB-title': 'Multiple Tabs',
  'proctoringReport.MULTIPLE_SCREEN-title': 'Multiple Screen',

  'proctoringReport.continue': 'Continue',
  'proctoringReport.cancel': 'Cancel',

  'proctoringReport.cancelAlert-message': `Are you sure, you want to leave candidate's proctoring results process?If you cancel the process, you will not make any assessments`,
  'proctoringReport.cancelAlert-title': 'Discard changes',

  'proctoringReport.wantToContinueAlert-title':
    'Are you sure you want to continue?',
  'proctoringReport.invalidateAlert-message': `Are you sure, you want to invalidate candidate's proctoring results? If you continue, the candidate's assessment will be invalidate.`,
  'proctoringReport.invalidateFaceIdAlert-message': `Are you sure, you want to invalidate candidate's Face ID? If you continue, the candidate's assessment will be invalidate.`,
  'proctoringReport.totalViolationDurationWithHour':
    'for {hr} hr {min} min {sec} sec in {total} session',
  'proctoringReport.totalViolationDurationWithMinute':
    'for {min} min {sec} sec in {total} session',
  'proctoringReport.totalViolationDurationWithSecond':
    'for {sec} sec in {total} session',
  'proctoringReport.totalViolationDurationWithZeroSecond':
    'for 0 sec in {total} session',
  'proctoringReport.detailTitle': 'DETAILS',
  'proctoringReport.description': 'DESCRIPTION',
  'proctoringReport.action': 'ACTIONS',
  'proctoringReport.numberOfViolations': 'NUMBER OF VIOLATIONS',
  'proctoringReport.emptyViolationTitle': 'No Violation Detected',
  'proctoringReport.emptyViolationDescription':
    "No violation was detected by Examod in the candidate's {value} session",
  'proctoringReport.examSessionRecord': 'Candidate’s Exam Session Records',
  'proctoringReport.examSessionRecordSubtitle':
    'Review the video and screen recording of the candidate during the exam',
  'proctoringReport.logsAndEvents': 'Logs & Events',
  'proctoringReport.camera': 'Camera',
  'proctoringReport.screen': 'Screen',
  'proctoringReport.fileName': 'File name',
  'proctoringReport.creationDate': 'Creation date',
  'proctoringReport.length': 'Length',
  'proctoringReport.recordInfo':
    'It may take time to create video recordings of the candidate. Please check back shortly.',
  'proctoringReport.logsAndEventsTooltip':
    "Timestamp information refers to the candidate's violation time during the session. The candidate's total camera or screen recording times may differ from the session duration and timestamp information.",
};
