import { httpClient } from '../utility/networkUtil';
import { onApiError } from '../utility/apiUtil';

const API_PREFIX = 'groupedQuestion';

/*
 * Gets Question
 */
export const getGroupedQuestion = async (id) =>
  httpClient
    .get(`${API_PREFIX}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Adds Question
 */
export const addGroupedQuestion = async (body) =>
  httpClient
    .post(API_PREFIX, body)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Updates Question
 */
export const updateGroupedQuestion = async (id, body) =>
  httpClient
    .put(`${API_PREFIX}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Deletes Question
 */
export const deleteGroupedQuestion = async (id) =>
  httpClient
    .delete(`${API_PREFIX}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 *  Deprecates the approved question
 */
export const approveGroupedQuestion = async (questionId) =>
  httpClient
    .put(`${API_PREFIX}/${questionId}/approve`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 *  Deprecates the approved question
 */
export const deprecateGroupedQuestion = async (questionId) =>
  httpClient
    .put(`${API_PREFIX}/${questionId}/deprecate`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
/*
 *  Reuses the approved question
 */
export const reuseGroupedQuestion = async (questionId) =>
  httpClient
    .put(`${API_PREFIX}/${questionId}/reuse`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Upload Grouped Question Attachment
 */
export const uploadGroupAttachement = async (fileExtension, name, formData) => {
  const config = {
    params: {
      fileExtension: fileExtension,
      name: name,
    },
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return httpClient
    .post(`${API_PREFIX}/attachment`, formData, config)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};
