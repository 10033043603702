import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { stopPropagation } from '../../../utils/common';
import Option from '../../../components/Option';
import './styles.css';

class LayoutComponent extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    config: PropTypes.object,
    currentState: PropTypes.object,
    translations: PropTypes.object,
  };

  state = {
    currentStyle: 'color',
  };

  componentDidUpdate(prevProps) {
    const { expanded } = this.props;
    if (expanded && !prevProps.expanded) {
      this.setState({
        currentStyle: 'color',
      });
    }
  }

  onChangeColor = (color) => {
    const { onChange } = this.props;
    // const { currentStyle } = this.state;
    onChange('color', color);
  };

  onChangeHighlight = (color) => {
    const { onChange } = this.props;
    // const { currentStyle } = this.state;
    onChange('bgcolor', color);
  };

  setCurrentStyleColor = () => {
    this.setState({
      currentStyle: 'color',
    });
  };

  setCurrentStyleBgcolor = () => {
    this.setState({
      currentStyle: 'bgcolor',
    });
  };

  renderModal = () => {
    const {
      config: { popupClassName, colors, highlight },
      currentState: { color, bgColor },
      translations,
    } = this.props;
    const { currentStyle } = this.state;
    const currentSelectedColor = currentStyle === 'color' ? color : bgColor;
    return (
      <div
        className={classNames('rdw-colorpicker-modal', popupClassName)}
        onClick={stopPropagation}
      >
        <div>
          <div
            className={classNames(
              'rdw-colorpicker-modal-style-label-customized',
              {
                'rdw-colorpicker-modal-style-label-active':
                  currentStyle === 'color',
              }
            )}
            onClick={this.setCurrentStyleColor}
          >
            {translations['components.controls.colorpicker.text']}
          </div>
        </div>
        <span className="rdw-colorpicker-modal-options">
          {colors.map((c, index) => {
            return (
              <Option
                value={c}
                key={index}
                className="rdw-colorpicker-option"
                activeClassName="rdw-colorpicker-option-active"
                active={currentSelectedColor === c}
                onClick={this.onChangeColor}
              >
                <span
                  style={{ backgroundColor: c }}
                  className="rdw-colorpicker-cube"
                />
              </Option>
            );
          })}
        </span>
        <div
          className={classNames(
            'rdw-colorpicker-modal-style-label-customized',
            {
              'rdw-colorpicker-modal-style-label-active':
                currentStyle === 'bgcolor',
            }
          )}
          onClick={this.setCurrentStyleBgcolor}
        >
          {translations['components.controls.colorpicker.background']}
        </div>

        <span className="rdw-colorpicker-highlight-options">
          {highlight.map((c, index) => {
            return (
              <div className="rdw-colorpicker-highlight-text-color-wrapper">
                <Option
                  value={c.color}
                  key={index}
                  withStyle={false}
                  className="rdw-colorpicker-option rdw-colorpicker-option-custom"
                  activeClassName="rdw-colorpicker-option-active"
                  active={currentSelectedColor === c.color}
                  onClick={this.onChangeHighlight}
                >
                  <span
                    style={{ backgroundColor: c.color }}
                    className="rdw-colorpicker-cube d-block"
                  />
                  <span className="rdw-colorpicker-cube-text">
                    {translations[c.name]}
                  </span>
                </Option>
              </div>
            );
          })}
        </span>
      </div>
    );
  };

  render() {
    const {
      config: { icon, className, title },
      expanded,
      onExpandEvent,
      translations,
    } = this.props;
    return (
      <div
        className="rdw-colorpicker-wrapper"
        aria-haspopup="true"
        aria-expanded={expanded}
        aria-label="rdw-color-picker"
        title={
          title || translations['components.controls.colorpicker.colorpicker']
        }
      >
        <Option onClick={onExpandEvent} className={classNames(className)}>
          <img src={icon} alt="" />
        </Option>
        {expanded ? this.renderModal() : undefined}
      </div>
    );
  }
}

export default LayoutComponent;
