import React from 'react';
import createInlineStyleButton from '../utils/createInlineStyleButton.js';

export default createInlineStyleButton({
  style: 'UNDERLINE',
  children: (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6354 13.3529V15H0.435364V13.3529H11.6354ZM9.23536 7.76529C9.20902 8.30881 9.05228 8.83714 8.77916 9.30308C8.50603 9.76901 8.12502 10.158 7.67018 10.4354C7.21534 10.7127 6.70084 10.8697 6.17265 10.8924C5.64447 10.9151 5.11905 10.8027 4.64336 10.5653C4.09508 10.3211 3.63009 9.91399 3.30798 9.39607C2.98587 8.87815 2.8213 8.27303 2.83536 7.65824V1.00412H1.23536V7.76529C1.26243 8.54057 1.46645 9.2984 1.8309 9.97742C2.19535 10.6565 2.71 11.2376 3.33318 11.6738C3.95636 12.1101 4.67057 12.3891 5.41798 12.4884C6.1654 12.5877 6.92502 12.5044 7.63536 12.2453C8.58025 11.9211 9.40005 11.2951 9.97555 10.4583C10.5511 9.62146 10.8523 8.61749 10.8354 7.59235V1.00412H9.23536V7.76529ZM9.23536 1H10.8354H9.23536ZM2.83536 1H1.23536H2.83536Z"
        fill="#5E5873"
      />
    </svg>
  ),
});
