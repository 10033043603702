import React from 'react';
import * as Icon from 'react-feather';
import { PATHS } from '../navigation/Paths';
import {
  SETTINGS_PERMISSIONS,
  UNIT_PERMISSIONS,
  PERIODS_PERMISSIONS,
  EXAM_SETTINGS_PERMISSIONS,
  QUESTION_ATTRIBUTE_PERMISSIONS,
  USER_PERMISSIONS,
  USER_GROUP_PERMISSIONS,
  ROLE_PERMISSIONS,
  ASSESSMENT_PERMISSIONS,
} from './permissions';

const horizontalMenuConfig = [
  {
    type: 'groupHeader',
    groupTitle: 'menu.welcome',
  },
  {
    id: 'home',
    title: 'menu.home',
    type: 'item',
    icon: <Icon.Home size={20} />,
    navLink: PATHS.dashboard,
  },
  {
    id: 'plan',
    title: 'menu.planDetails',
    type: 'item',
    navLink: PATHS.plan,
    icon: <Icon.Info size={20} />,
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.globalSettings',
  },
  {
    id: 'generalSettings',
    title: 'menu.general',
    type: 'item',
    navLink: PATHS.settings,
    icon: <Icon.Tool size={20} />,
    permissions: [SETTINGS_PERMISSIONS.BROWSE],
  },
  {
    id: 'structuralSettings',
    title: 'menu.structural',
    type: 'collapse',
    navLink: PATHS.structural,
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: 'units',
        title: 'menu.units',
        type: 'item',
        navLink: PATHS.units,
        icon: <Icon.Share2 size={20} />,
        permissions: [UNIT_PERMISSIONS.BROWSE],
      },
      {
        id: 'periods',
        title: 'menu.periods',
        type: 'item',
        navLink: PATHS.periods,
        icon: <Icon.Calendar size={20} />,
        permissions: [PERIODS_PERMISSIONS.BROWSE],
      },
      {
        id: 'examSettings',
        title: 'menu.exams',
        type: 'item',
        navLink: PATHS.examRules,
        icon: <Icon.Layers size={20} />,
        permissions: [EXAM_SETTINGS_PERMISSIONS.BROWSE],
      },
      {
        id: 'questionFields',
        title: 'menu.questionFields',
        type: 'item',
        navLink: PATHS.attributes,
        icon: <Icon.Tag size={20} />,
        permissions: [QUESTION_ATTRIBUTE_PERMISSIONS.BROWSE],
      },
    ],
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.accounts',
  },
  {
    id: 'users',
    title: 'menu.users',
    type: 'item',
    navLink: PATHS.users,
    icon: <Icon.User size={20} />,
    permissions: [USER_PERMISSIONS.BROWSE],
  },
  {
    id: 'userGroups',
    title: 'menu.groups',
    type: 'item',
    navLink: PATHS.userGroups,
    icon: <Icon.Users size={20} />,
    permissions: [USER_GROUP_PERMISSIONS.BROWSE],
  },
  {
    id: 'roles',
    title: 'menu.roles',
    type: 'item',
    navLink: PATHS.roles,
    icon: <Icon.UserCheck size={20} />,
    permissions: [ROLE_PERMISSIONS.BROWSE],
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.assessment',
  },
  /*
  {
    id: "myUnits",
    title: "menu.myUnits",
    type: "item",
    navLink: PATHS.myUnits,
    icon: <Icon.GitPullRequest size={20} />,
    //permissions: [PERMISSIONS.QUESTION_BROWSE]
  },
  */
  {
    id: 'myCourses',
    title: 'menu.myCourses',
    type: 'item',
    navLink: PATHS.myCourses,
    icon: <Icon.Book size={20} />,
    permissions: [ASSESSMENT_PERMISSIONS.BROWSE],
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.help',
  },
  {
    id: 'newTicket',
    title: 'menu.newTicket',
    type: 'item',
    icon: <Icon.Send size={20} />,
    navLink: PATHS.newTicket,
  },
  {
    id: 'raiseSupport',
    title: 'menu.raiseSupport',
    icon: <Icon.LifeBuoy size={20} />,
    type: 'external-link',
    newTab: true,
    navLink: 'https://examod.com/',
  },
  {
    id: 'faq',
    title: 'menu.faq',
    type: 'external-link',
    newTab: true,
    icon: <Icon.HelpCircle size={20} />,
    navLink: 'https://examod.com/resources/faq-administrator/',
  },
];

export default horizontalMenuConfig;
