export default {
  'classicExam.next': 'Sonraki',
  'classicExam.previous': 'Önceki',
  'classicExam.score': 'Puan',
  'classicExam.questionRoot': 'Soru Kökü',
  'classicExam.totalScore': 'Toplam Puan',
  'classicExam.save': 'Değişiklikleri Kaydet',
  'classicExam.reset': 'Sıfırla',
  'classicExam.notAttend': 'Katılmadı',
  'classicExam.complete': 'Değerlendirmeyi Tamamla',
  'classicExam.question': 'Soru {index}',
  'classicExam.name': 'İsim',
  'classicExam.surname': 'Soyisim',
  'classicExam.email': 'E-mail',
  'classicExam.NOT_ATTENDED': 'Katılmadı',
  'classicExam.CANDIDATES': 'Adaylar',
  'classicExam.QUESTIONS': 'Sorular',
  'classicExam.cancel': 'İptal',
  'classicExam.finish': 'Sınavı Bitir',
  'classicExam.confirmMessage':
    'Bu adayın değerlendirmesiyle ilgili yaptığınız değişiklikleri kaydetmelisiniz yoksa yaptığınız son değişiklikleriniz kaybolacaktır.',
  'classicExam.discard': 'Değişikliklerden vazgeç',
  'classicExam.continueWithoutSave': 'Devam Et',
  'classicExam.wantToContinue': 'Devam etmek istediğinize emin misiniz?',
  'classicExam.notAttendAlertInfo':
    'Adayı "Katılmadı" olarak işaretlemek istediğinize emin misiniz? Devam ederseniz adayın mevcut puanları silinecektir. Bu işlem geri alınamaz.',
  'classicExam.attendanceStatusError':
    'Sonuçları girmek için lütfen adayın sınava katılma durumunu güncelleyiniz.',
  'classicExam.saveSuccess': 'Sonuçlar başarıyla güncellenmiştir.',
  'classicExam.notAttendedTooltip':
    'Adayı değerlendirmek için "Adaylar" görünümünden değerlendirmeye katılma durumunu güncelleyin.',
  'classicExam.noPointsWarningToast':
    'Değerlendirmeyi kaydetmek için lütfen puan giriniz.',
  'classicExam.finishExamContent':
    'Değerlendirmeyi bitirmek istediğinize emin misiniz? Değerlendirilen aday veya sorular kaydedilecektir. ',
};
