import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { IntlProviderWrapper } from './utility/context/Internationalization';
import { Layout } from './utility/context/Layout';
import * as serviceWorker from './serviceWorker';
import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import './index.scss';
import ErrorBoundary from './ErrorBoundry';
import { isRemoteLoggingEnabled } from './configs/systemConfig';

const LazyApp = lazy(() => import('./App'));

if (isRemoteLoggingEnabled()) {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <Layout>
        <IntlProviderWrapper>
          <ErrorBoundary>
            <LazyApp />
          </ErrorBoundary>
        </IntlProviderWrapper>
      </Layout>
    </Suspense>
  );
}
const rootNode = document.getElementById('root');
ReactDOM.render(<App />, rootNode);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
