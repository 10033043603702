import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { HelpCircle } from 'react-feather';
import classnames from 'classnames';
import '../../../assets/scss/components/help-option.scss';

const HelpOption = ({
  wrapperClassName,
  className,
  tooltipPrefix,
  tooltipContent,
  placement = 'right',
  showIcon = true,
  size = 20,
}) => {
  return (
    <div
      className={classnames('custom-help-tooltip', wrapperClassName, {
        'd-none': !showIcon,
      })}
    >
      {showIcon ? (
        <HelpCircle
          className={`help-icon`}
          size={size}
          id={`${tooltipPrefix}-tooltip`}
        />
      ) : null}
      <UncontrolledTooltip
        className={className}
        placement={placement}
        target={`${tooltipPrefix}-tooltip`}
      >
        {tooltipContent}
      </UncontrolledTooltip>
    </div>
  );
};

export default HelpOption;
