export default {
  'reset.title': 'Şifre Sıfırlama',
  'reset.info':
    'Güvenliğiniz için yeni şifreniz daha önce kullandığınız şifrelerden farklı olmalıdır.',
  'reset.newPassLabel': 'Yeni Şifre',
  'reset.newPass': 'Yeni şifrenizi girin',
  'reset.confirmPassLabel': 'Şifreyi Onayla',
  'reset.confirmPass': 'Yeni şifrenizi onaylayın',
  'reset.setNewPassword': 'Yeni Şifre Belirle',
  'reset.setPassword': 'Şifre Belirle',
  'reset.minLengthError': 'Şifre en az 6 karakterden oluşmalıdır',
  'reset.validateError': 'Şifreler aynı olmalıdır',
  'reset.patternError':
    'Şifre en az 6 karakter olmalıdır ve en az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter içermelidir',
  'reset.setPassInfo': 'Güvenliğiniz için yeni bir şifre belirleyin.',
};
