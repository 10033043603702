import { httpClient } from '../utility/networkUtil';
import { onApiError } from '../utility/apiUtil';

const API_PREFIX = '/notification';

export const getNotifications = async (pageSize, signal, page) =>
  httpClient
    .get(API_PREFIX, {
      signal: signal,
      params: {
        page: page,
        pageSize: pageSize,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

export const markNotificationRead = async (notificationIds) => {
  return httpClient
    .put(`${API_PREFIX}/mark/read`, null, {
      params: {
        notificationIds: notificationIds,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};

export const markAllNotificationRead = async () => {
  return httpClient
    .put(`${API_PREFIX}/mark/read/all`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};
