import { httpClient } from '../utility/networkUtil';
import { onApiError } from '../utility/apiUtil';

const API_PREFIX = '/topic';

/*
 * Gets Topics For Given Unit
 */
export const getTopicsByUnitId = async (unitId) => httpClient
  .get(`${API_PREFIX}/search`, {
    params: {
      unitId,
    },
  })
  .then((response) => response.data)
  .catch((err) => {
    onApiError(err);
  });

/*
 * Adds new topic
 */
export const addTopic = async (name, description, unitId) => httpClient
  .post(API_PREFIX, {
    name,
    description,
    unitId,
  })
  .then((response) => response.data)
  .catch((err) => {
    onApiError(err);
  });

/*
 * Deletes topic with given id
 */
export const deleteTopicById = async (id) => httpClient
  .delete(`${API_PREFIX}/${id}`)
  .then((response) => response.data)
  .catch((err) => {
    onApiError(err);
  });

/*
 * Updates topic with given id
 */
export const updateTopicById = async (id, name, description, unitId) => httpClient
  .put(`${API_PREFIX}/${id}`, {
    name,
    description,
    unitId,
  })
  .then((response) => response.data)
  .catch((err) => {
    onApiError(err);
  });

/*
 * Changes order of topics
 */
export const changeOrderOfTopics = async (unitId, topics) => httpClient
  .put(`${API_PREFIX}/order?unitId=` + `${unitId}`, topics)
  .then((response) => response.data)
  .catch((err) => {
    onApiError(err);
  });
