export default {
  'userImport.importBtn': 'Import',
  'userImport.import': 'Import',
  'userImport.importCSV': 'Import CSV file',
  'userImport.preview': 'Preview',
  'userImport.previewData': 'Preview of data',
  'userImport.confirmation': 'Confirmation & Summary',
  'userImport.confirmationInfo': 'Confirmation, summary and informations',
  'userImport.previous': 'Previous Step',
  'userImport.next': 'Next Step',
  'userImport.uploadType': 'Upload Type',
  'userImport.uploadTypeSubtitle':
    'The upload type specifies how the users to be imported will be handled',
  'userImport.APPEND': 'Add new users only',
  'userImport.APPEND_Explanation':
    'A new record is created for each user in the uploaded file. If there is an existing registered user in the uploaded file, this user is ignored.',
  'userImport.OVERWRITE': 'Add new users and update existing users',
  'userImport.OVERWRITE_Explanation':
    'A new record is created for each user in the uploaded file. If there is an existing registered user in the uploaded file, this user information is updated.',
  'userImport.REFRESH': 'Update only existing users',
  'userImport.REFRESH_Explanation':
    'If there is an existing registered user in the uploaded file, this user information is updated. Unregistered users are ignored.',
  'userImport.template': 'Template',
  'userImport.templateSubtitle':
    'You can send your file using the template below',
  'userImport.fileName': 'File Name: ',
  'userImport.fileSize': 'File Size: ',
  'userImport.creationDate': 'Creation Date: ',
  'userImport.download': 'Download',
  'userImport.convertXLSX': 'Convert XLSX file to CSV',
  'userImport.convertXLSX_Subtitle':
    'Follow the steps to convert your edited template to CSV',
  'userImport.reminding': 'Reminding',
  'userImport.remindingSubtitle':
    'Please ensure that you adhere to the following sheet structure and descriptions ',
  'userImport.remindingExplanation1':
    'Accepted template must macth the following headers and as a seperator a semicolon (;) should be used.',
  'userImport.remindingExplanation2':
    'The group name and system ID identification can be left blank for the user data to be imported. In this case, the relevant field should be marked with a space (" "). Group assignment and to users can also be performed after import.',
  'userImport.remindingExplanation3':
    'There are two different login methods for Auth Type (auth_type) in the imported user data. Auth Type information can be defined for authorized users and candidates. The user whose Auth Type (auth_type) is not defined defaults to "Password".',
  'userImport.password': 'Password',
  'userImport.remindingExplanation3_password':
    'With this auth type, users access Examod through a password.',
  'userImport.oauth2': 'OAuth_2',
  'userImport.remindingExplanation3_oauth2':
    'With this auth type, users access Examod with the credentials of the organization to which they belong.',
  'userImport.file': 'File',
  'userImport.fileSubtitle':
    'Drag or select the CSV file containing the users you want to import',
  'userImport.dragDrop': 'Drag & Drop files here',
  'userImport.or': 'or',
  'userImport.browseFiles': 'Browse Files',
  'userImport.attachmentHint':
    'Attachment should not exceed 100 mb{br}Supported file type: CSV',
  'userImport.fileNumberError': 'Only one file can be uploaded.',
  'userImport.invalidFileType': 'You can only upload .csv files!',

  // preview phase
  'userImport.ROW': 'ROW',
  'userImport.STATUS': 'STATUS',
  'userImport.SYSTEM_ID': 'SYSTEM ID',
  'userImport.NAME': 'NAME',
  'userImport.LAST_NAME': 'LAST NAME',
  'userImport.EMAIL': 'EMAIL',
  'userImport.TYPE': 'TYPE',
  'userImport.GROUP': 'GROUP',
  'userImport.AUTH_TYPE': 'AUTH TYPE',
  'userImport.CANDIDATE': 'Candidate',
  'userImport.AUTHORIZED': 'Authorized',
  'userImport.OAUTH_2': 'OAuth2',
  'userImport.PASSWORD': 'Password',
  'userImport.APPROPRIATE': 'Appropriate',
  'userImport.UNAPPROPRIATE': 'Unappropriate',
  'userImport.fileInfoPreview': 'File Information and Preview',
  'userImport.importedListPreview': 'Imported Data List Preview',
  'userImport.fileNoError': 'There is no error in the file you uploaded.',
  'userImport.warning': '{errorName}',

  //confirmation phase
  'userImport.confirmationWarning':
    'If you continue, the file will be sent with the errors below!',
  'userImport.IMPORTED': 'Imported',
  'userImport.UPDATED': 'Updated',
  'userImport.OPERATION_FAILED': 'Operation Failed',
  'userImport.complete': 'Complete',
  'userImport.summaryInfo': 'Summary and informations',
  'userImport.totalUser': 'Total user',
  'userImport.importedUsers': 'Imported users',
  'userImport.updatedUsers': 'Updated users',
  'userImport.unappropriateRows': 'Unappropriate rows',

  //popups
  'userImport.wantToContinue': 'Are you sure you want to continue?',
  'userImport.deleteFile': 'Are you sure you want to delete the file?',
  'userImport.fileHasError': 'There are errors in the file you uploaded.',
  'userImport.onCompleteWithError':
    'Selected upload type: {uploadType}{br}There are errors in the file you uploaded. If you continue, your file will be sent.',
  'userImport.onComplete':
    'Selected upload type: {uploadType}{br}If you continue, your file will be sent.',

  'userImport.discardTitle': 'Discard changes',
  'userImport.discardContent':
    'The changes you make will not be saved. Are you sure you want to discard all changes?',

  'userImport.continue': 'Continue',
  'userImport.cancel': 'Cancel',
  'userImport.yesDelete': 'Yes, Delete',
  'userImport.discard': 'Discard',
};
