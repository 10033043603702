export default {
  'assessmentType.title': 'Değerlendirme Türü Ayarları',
  'assessmentType.subtitle': 'Organizasyon genelinde kurallar belirleyin',
  'assessmentType.paperBased': 'Kağıt-tabanlı Sınav Ayarları',
  'assessmentType.preview': 'Önizleme',
  'assessmentType.logo': 'Sınav kitapçığı logo ön izlemesi',
  'assessmentType.upload': 'Logo Yükle',
  'assessmentType.reset': 'Sıfırla',
  'assessmentType.imgInfo':
    "Ek 800 kb'yi geçmemelidir.{br}Desteklenen dosya türleri: JPEG, GIF veya PNG",
  'assessmentType.RESTRICTED_IPS': "Kısıtlandırılmış IP'lere izin ver",
  'assessmentType.WHITELISTS': "Beyaz liste URL'lerine izin ver",
  'assessmentType.ID_VERIFICATION': 'Kimlik doğrulamaya izin ver',
  'assessmentType.PROCTORING_OPTIONS': 'Gözetim seçeneklerine izin ver',
  'assessmentType.INVITE_USER': 'Aday davet etmeye izin ver',
  'assessmentType.onsite': 'Yerinde / Test Merkezinde Sınav Ayarları',
  'assessmentType.online': 'Çevrimiçi Sınav Ayarları',
  'assessmentType.RESTRICTED_IPS_Info':
    'İzin verirseniz, adaylar sınavlara yalnızca belirli bir IP aralığından internete bağlı cihazlarla girebilirler.',
  'assessmentType.WHITELISTS_Info':
    "İzin verirseniz, aday beyaz liste URL'lerine giriş sağlayabilecektir.",
  'assessmentType.ID_VERIFICATION_Info':
    'İzin verirseniz, adaya kimlik doğrulaması yapılacaktır.',
  'assessmentType.PROCTORING_OPTIONS_Info':
    'İzin verirseniz, sınavlar için gözetim seçeneklerini belirlemek mümkün olur.',
  'assessmentType.INVITE_USER_Info':
    'İzin verirseniz, sınavlara sistemde kaydı olmayan adaylar e-posta ile davet edilebilir.',
  'assessmentType.successMessage':
    'Değerlendirme türü ayarları başarıyla kaydedildi!',
  'assessmentType.bookletsName': 'Kitapçık adlarını seçin:',
  'assessmentType.performance': 'Performans Sınavı Ayarları',
  'assessmentType.generalSizeError':
    'Eklediğiniz dosya sistemin izin verdiğinden daha büyük.',
  'assessmentType.file': 'Dosya',
  'assessmentType.fileSubtitle':
    'Sınav kitapçığında kullanılacak logoyu sürükleyin veya seçin',
};
