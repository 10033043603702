export default {
  'roles.addHeader': 'Yeni Rol Oluştur',
  'roles.UNIT_addHeader': 'Yeni Birim Rolü Oluştur',
  'roles.SYSTEM_addHeader': 'Yeni Sistem Rolü Oluştur',
  'roles.editHeader': 'Rolü Düzenle',
  'roles.subtitle': 'Rol izinlerini ayarlayın',
  'roles.name': 'Rolün Adı',
  'roles.desc': 'Rolün Tanımı',
  'roles.permissions': 'İzinler',
  'roles.add': 'Rol Ekle',
  'roles.switchText': 'Aktif / Pasif',
  'roles.parentRole': 'Ana Rol',
  'roles.editSuccess': 'Rol başarıyla güncellendi!',
  'roles.success': 'Rol başarıyla oluşturuldu!',
  'roles.permissionWarning': 'Rolün yetkilerini seçiniz!',
  'roles.minLengthName': 'Rolün adı boş bırakılamaz.',
  'roles.maxLengthName': 'Rolün adı en fazla 100 karakter olmalıdır.',
  'roles.maxLengthDesc': 'Rolün tanımı en fazla 500 karakter olmalıdır.',
  'roles.statusError': 'Rol durumu değiştirilemedi.',
  'roles.select': 'Seçiniz',
  'roles.global': 'Genel Ayarlar İzinleri',
  'roles.account': 'Hesap Ayarları İzinleri',
  'roles.assessment': 'Birim / Ders İzinleri',
  'roles.administrative': 'Yönetim Kontrol Paneli',
  'roles.viewHeader': 'Rolü Görüntüle',
  'roles.viewSubtitle': 'Rol izinlerini görüntüleyin',

  // Tooltips
  'roles.parentRole_tooltip': '-',
};
