export default {
  'questionStat.title': 'Advanced Analysis',
  'questionStat.titleInfo':
    'Check out the advanced analysis results and system comment (if applicable) for the question',
  'questionStat.questionRoot': 'Question root',
  'questionStat.objective': 'Learning objective',
  'questionStat.total': 'Total answers',
  'questionStat.unanswered': 'Unanswered',
  'questionStat.unviewed': 'Unviewed',
  'questionStat.totalUnanswered': 'Total Unanswered',
  'questionStat.totalUnviewed': 'Total Unviewed',
  'questionStat.highScoringGroup': "High scored group's answers",
  'questionStat.lowScoringGroup': "Low scored group's answers",
  'questionStat.allGroups': 'All answers of high and low scored groups',
  'questionStat.correctAnswer': 'Correct answer',
  'questionStat.chartTitle': 'Multi-group answer graph of question options',
  'questionStat.allAnswers': 'All answers',
  'questionStat.chartMinAlert':
    'The graph cannot be created because the number of answers given to the question is less than 3.',

  // Tooltips
  'questionStat.highScoringGroup_tooltip':
    'The 27% group with the highest score among total respondents',
  'questionStat.lowScoringGroup_tooltip':
    'The 27% group with the lowest score among total respondents',
  'questionStat.allAnswers_tooltip':
    "All respondents' answers without any exclusion",
  'questionStat.allGroups_tooltip':
    'The answers given by the respondents of the two groups constitute 54% of all answers. The excluded middle group (46%) does not affect disraction of question.',
};
