export default {
  'report.assessmentOverview': 'Değerlendirmeye Genel Bakış',
  'report.score': 'Aday Puanı',
  'report.candidateOverview': 'Adaya Genel Bakış',
  'report.unitCourse': 'Birim / Ders',
  'report.examiner': 'Sınav sorumlusu',
  'report.type': 'Değerlendirme türü',
  'report.category': 'Değerlendirme kategorisi',
  'report.highestScore': 'En yüksek puan',
  'report.highestScore-tooltip':
    'Bu değerlendirmeden alınabilecek en yüksek puan değeri.',
  'report.randomGuessing': 'Rastgele tahmin düzeltmesi',
  'report.randomGuessing-tooltip':
    'Rastgele tahmin etmeyi caydırmak için, adayların çoktan seçmeli sorularda verdikleri her bir yanlış yanıt için o sorunun seçenek sayısına göre negatif puanlama yapılır. Örneğin, beş seçenekli ve 10 puan değerindeki bir soruya tahmin yapılarak yanlış yanıt verildiğinde; sorunun puanları 5-1=4 dilime ayrılır ve bir dilim yani -2.5 negatif puan uygulanır.',
  'report.passingCriteria': 'Geçme ölçütü',
  'report.passingCriteria-tooltip':
    'Belirlenmiş bir geçme puanına göre adayın başarılı olma durumunu tanımlayan değerlendirme türüdür.',
  'report.passingCriteriaNone-tooltip':
    'Bu değerlendirme için herhangi bir geçme kriteri bulunmamaktadır.',
  'report.passingScore': 'Geçme puanı',
  'report.rank': 'Sıralama',
  'report.quartile': 'Çeyrek sıralama',
  'report.duration': 'Süre',
  'report.examStart': 'Sınav başlangıç tarihi',
  'report.examEnd': 'Sınav bitiş tarihi',
  'report.questionCount': 'Sorular',
  'report.correctAnswers': 'Doğru yanıtlar',
  'report.incorrectAnswers': 'Yanlış yanıtlar',
  'report.unansweredQuestions': 'Yanıtlanmayan sorular',
  'report.unviewedQuestions': 'Görüntülenememiş sorular',
  'report.netScore-tooltip': 'Adayın değerlendirme puanı',
  'report.averageScore': 'Genel net puan ortalaması',
  'report.resultMainTitle': 'Aday Sonuç Raporu',
  'report.resultMainSubtitle': 'Adayın sonuç raporunu inceleyin',
  'report.topicTitle': 'Konular',
  'report.objTitle': 'Öğrenme Hedefleri',
  'report.performanceTitleByTopics': 'Konulara Göre Aday Performansı',
  'report.performanceTitleByLO': 'Öğrenme Hedeflerine Göre Aday Performansı',
  'report.performanceSubtitleByTopics':
    'Konulara göre adayın sınav performansını gözden geçirin',
  'report.performanceSubtitleByLO':
    'Öğrenme hedeflerine göre adayın sınav performansını gözden geçirin',
  'report.topicName': 'konu adı',
  'report.objName': 'öğrenme hedefi adı',
  'report.totalQuestion': ' doğru yanıt / toplam soru',
  'report.totalQuestionQa':
    "İlişkilendirilmiş SÖ'ye göre Doğru Yanıt / Soru Sayısı",
  'report.weight': 'ağırlık (%)',
  'report.weightQa': "İlişkilendirilmiş SÖ'ye Göre Ağırlık (%)",
  'report.scoreRange': 'Puan Aralığı (%)',
  'report.avgScore': 'Ortalama Puan',
  'report.yourScore': 'Aday Puanı',
  'report.radarTopicTitle': 'Konulara Göre Radar Grafiği',
  'report.radarObjTitle': 'Öğrenme Hedeflerine Göre Radar Grafiği',
  'report.resultReport': '{nameSurname} Değerlendirme Sonuç Raporu',
  'report.topicSubtitle': 'TÜM KONULAR',
  'report.objSubtitle': 'TÜM ÖĞRENME HEDEFLERİ',
  'report.yes': 'Evet',
  'report.netScore': 'Net puan',
  'report.rawAndNet': 'Ham Puan / Net Puan',
  'report.per': '%{per}',
  'report.radarTopicTitleAlert':
    "Konu sayısı 3'ün altında olduğu için istatistiksel hesaplamalar yanıltıcı olabilir.",
  'report.radarObjTitleAlert':
    "Öğrenme hedefi sayısı 3'ün altında olduğu için istatistiksel hesaplamalar yanıltıcı olabilir.",
  'report.assessmentMethod': 'Değerlendirme yöntemi',
  'report.rubricName': 'Dereceli puanlama anahtarı',
  'report.criteria': 'Ölçütler',
  'report.allCriteria': 'Tüm Ölçütler',
  'report.level': 'Aday Düzeyi / Genel Düzey',
  'report.examinerFeedbacks': 'Değerlendiricinin Geribildirimleri',
  'report.feedback-tooltip':
    'Sınav görevlisinin değerlendirmeye yönelik yazılı ve sözlü geri bildirimleri.',
  'report.criteriaTitle': 'Ölçütlere Göre Aday Performansı',
  'report.criteriaSubtitle': 'Ölçütlere göre aday performansını gözden geçirin',
  'report.RUBRIC': 'Dereceli puanlama anahtarı',
  'report.assessmentStatus': 'Değerlendirme durumu',
  'report.proctoringResult': 'Gözetim Sonuçları',
  'report.proctoringType': 'Gözetim türü',
  'report.unproctored': 'Denetimsiz',
  'report.lockdownReview': 'Kilitle ve İncele',
  'report.recordReview': 'Kaydet ve İncele',
  'report.proctoringTypeButton': 'Rapor',
  'report.recordReview-tooltip':
    'Bu gözetim türünde, sınav sırasında adayın video ve ekran kaydı alınır. Bu sayede sınav gözetmeni birçok adayın saatlerce sürebilecek video kayıtlarını izlemek yerine belirli adayların sadece işaretli kesitlerini izleyerek çok az zamanda tüm sınav güvenlik değerlendirmesini tamamlayabilir. Bu gözetim türü halihazırda Kilitle ve İncele gözetim türünün tüm özelliklerini kapsamaktadır. Yüksek önem düzeyine sahip sınavlar için önerilir.',
  'report.lockdownReview-tooltip':
    'Bu gözetim türünde adayın sınava bağlandığı cihaz, tam ekran zorlama, çıktı alamama gibi çeşitli kısıtlamalara tabi tutulur. Ekran paylaşımının kapatılması gibi kısıtlama yapılamayan bazı ihlal türleri de bu gözetim türü için raporlanmaktadır. Bu gözetim türü, sınav sonrasında adaylarla ilgili bir risk puanı üreterek sınav sorumlusuna belirlenmiş adayların pencere ve cihaz üzerinde yaptığı ihlalleri değerlendirme olanağı tanır. Bu türün tüm özellikleri Kaydet ve İncele gözetim türü için de kullanılabilir. Düşük ve orta önem düzeyine sahip sınavlar için önerilir.',
  'report.unproctored-tooltip':
    'Sınav sırasında herhangi bir gözetim ve kısıtlamanın yapılmadığı gözetim türüdür. Sınav sonrasında bir rapor üretilmez. Düşük önem düzeyine sahip sınavlar veya quiz sınavlarında kullanılabilir.',
  'report.candidateName': 'İsim soyisim',
  'report.assessmentName': 'Değerlendirme adı',
  'report.status': 'Durum',
  'report.description': 'AÇIKLAMA',
  'report.result': 'SONUÇ',
  'report.candidateOverviewTitle': 'ADAYA GENEL BAKIŞ',
  'report.assessmentOverviewTitle': 'DEĞERLENDİRME DETAYLARI',
  'report.turkish': 'Türkçe',
  'report.english': 'İngilizce',
  'report.numberOfTopicsAlert':
    "Konu sayısı 15'in üstünde olduğu için radar grafiği oluşturulması uygun değildir.",
  'report.numberOfLOAlert':
    "Öğrenme hedefi sayısı 15'in üstünde olduğu için radar grafiği oluşturulması uygun değildir.",
  'report.resetAlertInfo':
    'Bu adayın bir önceki değerlendirmesi yetkili tarafından sıfırlanmıştır. ',
  'report.reason': '<b>Gerekçe:</b> {reason}',
  'report.unspecified': '<b>Gerekçe:</b> Belirtilmemiş',
  'report.update': 'Güncelle',
  'report.performanceTitleByAttribute':
    'Soru Özelliklerine Göre Aday Performansı ({attributeName})',
  'report.performanceSubtitleByAttribute':
    'Bu soru özelliğinin, sorularla ilişkilendirilmiş seçeneklerine göre adayın sınav performansını inceleyin.',
  'report.attributeName': 'Özellik Seçeneği Adı',
  'report.attributeSubtitle': 'Tüm soru özelliği seçenekleri',
  'report.attributeTitle': 'Soru Özelliği Seçeneklerine Göre İstatistikler',
  'report.radarAttributeTitle': 'Soru Özelliği Seçeneklerine Göre Radar Grafik',
  'report.radarAttributeTitleAlert':
    "Soru özelliği sayısı 3'ün altında olduğu için istatistiksel hesaplamalar yanıltıcı olabilir.",
  'report.numberOfAttributeAlert':
    "Soru özelliği sayısı 15'in üstünde olduğu için radar grafiği oluşturulması uygun değildir.",

  'report.performanceTitleByCourse': 'Derslere Göre Aday Performansı',
  'report.performanceSubtitleByCourse':
    'Derslere göre aday performansını gözden geçirin',
  'report.courseName': 'Ders Adı',
  'report.courseSubtitle': 'Tüm Dersler',
  'report.courseTitle': 'Dersler',
  'report.radarCourseTitle': 'Derslere Göre Radar Grafiği',
  'report.radarCourseTitleAlert':
    "Ders sayısı 3'ün altında olduğu için istatistiksel hesaplamalar yanıltıcı olabilir.",
  'report.numberOfCourseAlert':
    "Ders sayısı 15'in üstünde olduğu için radar grafiği oluşturulması uygun değildir.",
  'report.reportTotalQuestionTooltip':
    'Bu sütunda yer alan veriler, sadece sorularla ilişkilendirilen soru özelliği baz alınarak hesaplanmıştır. Sınavda yer alan bir soru bu soru özelliği ile ilişkilendirilmemişse bu alanda hesaba katılmamıştır.',
  'report.reportWeightTooltip':
    'Bu sütunda yer alan veriler, sadece sorularla ilişkilendirilen soru özelliği baz alınarak hesaplanmıştır. Sınavda yer alan bir soru bu soru özelliği ile ilişkilendirilmemişse bu alanda hesaba katılmamıştır.',
};
