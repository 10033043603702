// ** React Imports
import React from 'react';

// ** Custom Components
import NotificationDropdown from './Notification/Notifications';
import UserDropdown from './user/UserDropdown';
import LanguageDropdown from './language/LanguageDropdown';

class NavbarUser extends React.PureComponent {
  state = {
    langDropdown: false,
    changeLanguageConfirmation: false,
  };

  handleLangDropdown = () => {
    this.setState({ langDropdown: !this.state.langDropdown });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <LanguageDropdown
          handleLanguageChange={this.props.handleLanguageChange}
          isOnNavbar
        />
        <NotificationDropdown />
        <UserDropdown {...this.props} />
      </ul>
    );
  }
}

export default NavbarUser;
