import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Option from '../../../components/Option';
import Spinner from '../../../components/Spinner';
import './styles.css';
import { UploadCloud } from 'react-feather';

class LayoutComponent extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    doCollapse: PropTypes.func,
    onChange: PropTypes.func,
    config: PropTypes.object,
    translations: PropTypes.object,
    disabled: PropTypes.bool
  };

  state = {
    imgSrc: '',
    dragEnter: false,
    uploadHighlighted:
      this.props.config.uploadEnabled && !!this.props.config.uploadCallback,
    showImageLoading: false,
    height: this.props.config.defaultSize.height,
    width: this.props.config.defaultSize.width,
    scale: 100,
    alt: '',
  };

  componentDidUpdate(prevProps) {
    const { config } = this.props;
    if (prevProps.expanded && !this.props.expanded) {
      this.setState({
        imgSrc: '',
        dragEnter: false,
        uploadHighlighted: config.uploadEnabled && !!config.uploadCallback,
        showImageLoading: false,
        height: config.defaultSize.height,
        width: config.defaultSize.width,
        scale: 100,
        alt: '',
      });
    } else if (
      config.uploadCallback !== prevProps.config.uploadCallback ||
      config.uploadEnabled !== prevProps.config.uploadEnabled
    ) {
      this.setState({
        uploadHighlighted: config.uploadEnabled && !!config.uploadCallback,
      });
    }
  }

  onDragEnter = (event) => {
    this.stopPropagation(event);
    this.setState({
      dragEnter: true,
    });
  };

  onImageDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      dragEnter: false,
    });

    // Check if property name is files or items
    // IE uses 'files' instead of 'items'
    let data;
    let dataIsItems;
    if (event.dataTransfer.items) {
      data = event.dataTransfer.items;
      dataIsItems = true;
    } else {
      data = event.dataTransfer.files;
      dataIsItems = false;
    }
    for (let i = 0; i < data.length; i += 1) {
      if (
        (!dataIsItems || data[i].kind === 'file') &&
        data[i].type.match('^image/')
      ) {
        const file = dataIsItems ? data[i].getAsFile() : data[i];
        this.uploadImage(file);
      }
    }
  };

  showImageUploadOption = () => {
    this.setState({
      uploadHighlighted: true,
    });
  };

  addImageFromState = () => {
    const { imgSrc, alt } = this.state;
    let { scale } = this.state;
    const { onChange } = this.props;
    if (!isNaN(scale)) {
      scale = `${scale}%`;
    }
    onChange(imgSrc, scale, scale, alt);
  };

  showImageURLOption = () => {
    this.setState({
      uploadHighlighted: false,
    });
  };

  toggleShowImageLoading = () => {
    const showImageLoading = !this.state.showImageLoading;
    this.setState({
      showImageLoading,
    });
  };

  updateValue = (event) => {
    this.setState({
      [`${event.target.name}`]: event.target.value,
    });
  };

  selectImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.uploadImage(event.target.files[0]);
    }
  };

  uploadImage = (file) => {
    this.toggleShowImageLoading();
    const { uploadCallback } = this.props.config;
    uploadCallback(file)
      .then(({ data }) => {
        this.setState({
          showImageLoading: false,
          dragEnter: false,
          imgSrc: data.link || data.url,
        });
        this.fileUpload = false;
      })
      .catch(() => {
        this.setState({
          showImageLoading: false,
          dragEnter: false,
        });
      });
  };

  fileUploadClick = (event) => {
    this.fileUpload = true;
    event.stopPropagation();
  };

  stopPropagation = (event) => {
    if (!this.fileUpload) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.fileUpload = false;
    }
  };

  renderAddImageModal() {
    const {
      imgSrc,
      uploadHighlighted,
      showImageLoading,
      dragEnter,
      scale,
      alt,
    } = this.state;
    const {
      config: {
        popupClassName,
        uploadCallback,
        uploadEnabled,
        urlEnabled,
        previewImage,
        inputAccept,
        alt: altConf,
      },
      doCollapse,
      translations,
    } = this.props;
    return (
      <div className="rdw-image-modal-overlay">
        <div
          className={classNames('rdw-image-modal', popupClassName)}
          onClick={this.stopPropagation}
        >
          <div className="image-modal-sidebar">
            <div className="rdw-image-modal-header">
              {uploadEnabled && uploadCallback && (
                <span
                  onClick={this.showImageUploadOption}
                  className={classNames('rdw-image-modal-header-option', {
                    'rdw-image-modal-header-label-highlighted':
                      uploadHighlighted,
                  })}
                >
                  {translations['components.controls.image.fileUpload']}
                </span>
              )}
              {urlEnabled && (
                <span
                  onClick={this.showImageURLOption}
                  className={classNames('rdw-image-modal-header-option', {
                    'rdw-image-modal-header-label-highlighted':
                      !uploadHighlighted,
                  })}
                >
                  {translations['components.controls.image.byURL']}
                </span>
              )}
            </div>
          </div>
          <div className="image-modal-main-image-uploader">
            <div className="image-modal-main-image-uploader-container">
              <div className="image-uploader-headline">
                <h3 className="image-uploader-main-header">
                  {uploadHighlighted
                    ? translations['components.controls.image.modal.title']
                    : translations[
                        'components.controls.image.modal.link.title'
                      ]}
                </h3>
                <p className="image-uploader-subhead">
                  {uploadHighlighted
                    ? translations[
                        'components.controls.image.modal.description'
                      ]
                    : translations[
                        'components.controls.image.modal.link.description'
                      ]}
                </p>
              </div>

              {uploadHighlighted ? (
                <div onClick={this.fileUploadClick}>
                  <div
                    onDragEnter={this.onDragEnter}
                    onDragOver={this.stopPropagation}
                    onDrop={this.onImageDrop}
                    className={classNames('rdw-image-modal-upload-option', {
                      'rdw-image-modal-upload-option-highlighted': dragEnter,
                    })}
                  >
                    <label
                      htmlFor="file"
                      className="rdw-image-modal-upload-option-label"
                    >
                      {!(previewImage && imgSrc) && (
                        <UploadCloud className="upload-icon emerald-upload-icon" size={46} />
                      )}
                      {previewImage && imgSrc ? (
                        <img
                          src={imgSrc}
                          alt={imgSrc}
                          className="rdw-image-modal-upload-option-image-preview"
                        />
                      ) : (
                        imgSrc ||
                        translations['components.controls.image.dropFileText']
                      )}
                      {!(previewImage && imgSrc) && (
                        <>
                          <br />

                          <span className="image-uploader-or-element">
                            {
                              translations[
                                'components.controls.image.modal.content.image.or'
                              ]
                            }
                          </span>
                          <br />
                          <span
                            // onClick={this.fileUploadClick}
                            className="image-uploader-file-upload-button"
                          >
                            {
                              translations[
                                'components.controls.image.modal.content.image.btn'
                              ]
                            }
                          </span>
                          <div className="pt-2">
                            <p>
                              {
                                translations[
                                  'components.controls.image.modal.content.image.attachment'
                                ]
                              }
                              <span className="d-block">
                                {
                                  translations[
                                    'components.controls.image.modal.content.image.supported'
                                  ]
                                }
                              </span>
                            </p>
                          </div>
                        </>
                      )}
                    </label>
                  </div>
                  <input
                    type="file"
                    id="file"
                    accept={inputAccept}
                    onChange={this.selectImage}
                    className="rdw-image-modal-upload-option-input"
                  />
                </div>
              ) : (
                <div className="rdw-image-modal-url-section">
                  <label className="image-modal-url-label">
                    <span className="rdw-image-mandatory-sign">*</span>
                    {
                      translations[
                        'components.controls.image.modal.contnet.url.address.label'
                      ]
                    }
                  </label>
                  <input
                    className="rdw-image-modal-url-input"
                    // placeholder={
                    //   translations['components.controls.image.enterlink']
                    // }
                    placeholder="https://"
                    name="imgSrc"
                    onChange={this.updateValue}
                    onBlur={this.updateValue}
                    value={imgSrc}
                  />
                </div>
              )}
              <div className="image-modal-input-wrapper">
                {altConf.present && (
                  <div className="rdw-image-modal-size image-modal-size-alt ">
                    <label className="rdw-image-modal-alt-lbl">
                      {
                        translations[
                          'components.controls.image.modal.content.input.alt.label'
                        ]
                      }
                    </label>
                    <input
                      onChange={this.updateValue}
                      onBlur={this.updateValue}
                      value={alt}
                      name="alt"
                      className="rdw-image-modal-alt-input"
                      placeholder={
                        translations[
                          'components.controls.image.modal.content.input.alt.placeholder'
                        ]
                      }
                    />
                    <span className="rdw-image-mandatory-sign">
                      {altConf.mandatory && '*'}
                    </span>
                  </div>
                )}
                <div className="rdw-image-modal-size">
                  <label>
                    <span className="rdw-image-mandatory-sign">*</span>
                    {
                      translations[
                        'components.controls.image.modal.content.input.scale.label'
                      ]
                    }
                  </label>
                  <input
                    onChange={this.updateValue}
                    onBlur={this.updateValue}
                    value={scale}
                    type="number"
                    name="scale"
                    className="rdw-image-modal-size-input"
                    placeholder="Scale"
                  />
                </div>
                {/* <div className="rdw-image-modal-size">
                <label>
                  Image Width<span className="rdw-image-mandatory-sign">*</span>
                </label>
                <input
                  onChange={this.updateValue}
                  onBlur={this.updateValue}
                  value={width}
                  name="width"
                  className="rdw-image-modal-size-input"
                  placeholder="Width"
                />
              </div> */}
              </div>
            </div>
            <div className="rdw-image-modal-btn-section">
              <button
                className="rdw-image-modal-btn-add"
                onClick={this.addImageFromState}
                disabled={!imgSrc || !scale || (altConf.mandatory && !alt)}
              >
                {translations['generic.add']}
              </button>
              <button className="rdw-image-modal-btn" onClick={doCollapse}>
                {translations['generic.cancel']}
              </button>
            </div>
            {showImageLoading ? (
              <div className="rdw-image-modal-spinner">
                <Spinner />
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      config: { icon, className, title },
      expanded,
      onExpandEvent,
      translations,
      disabled
    } = this.props;

    return (
      <div
        className="rdw-image-wrapper"
        aria-haspopup="true"
        aria-expanded={expanded}
        aria-label="rdw-image-control"
      >
        <Option
          className={classNames(className)}
          value="unordered-list-item"
          disabled={disabled}
          onClick={onExpandEvent}
          title={title || translations['components.controls.image.image']}
        >
          <img src={icon} alt="icon" />
        </Option>
        {expanded ? this.renderAddImageModal() : undefined}
      </div>
    );
  }
}

export default LayoutComponent;
