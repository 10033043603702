export default {
  // Generic
  'generic.add': 'Ekle',
  'generic.cancel': 'İptal',

  // BlockType
  'components.controls.blocktype.h1': 'Heading 1',
  'components.controls.blocktype.h2': 'Heading 2',
  'components.controls.blocktype.h3': 'Heading 3',
  'components.controls.blocktype.h4': 'Heading 4',
  'components.controls.blocktype.h5': 'Heading 5',
  'components.controls.blocktype.h6': 'Heading 6',
  'components.controls.blocktype.blockquote': 'Alıntı',
  'components.controls.blocktype.code': 'Kod',
  'components.controls.blocktype.codeblock': 'Kod Bloğu',
  'components.controls.blocktype.blocktype': 'Block Type',
  'components.controls.blocktype.normal': 'Normal',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Renk',
  'components.controls.colorpicker.text': 'Metin',
  'components.controls.colorpicker.background': 'Vurgu',

  // Embedded
  'components.controls.embedded.embedded': 'Embedded',
  'components.controls.embedded.embeddedlink': 'Embedded Link',
  'components.controls.embedded.enterlink': 'Enter link',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Font',

  // FontSize
  'components.controls.fontsize.fontsize': 'Font Size',

  // History
  'components.controls.history.history': 'History',
  'components.controls.history.undo': 'Undo',
  'components.controls.history.redo': 'Redo',

  // Image
  'components.controls.image.image': 'Görsel',
  'components.controls.image.fileUpload': 'Görsel Yükleme',
  'components.controls.image.byURL': 'Bağlantı',
  'components.controls.image.dropFileText':
    'Dosyaları buraya sürükleyip bırakın',

  // Inline
  'components.controls.inline.bold': 'Kalın',
  'components.controls.inline.italic': 'İtalik',
  'components.controls.inline.underline': 'Altı Çizili',
  'components.controls.inline.strikethrough': 'Üstü Çizili',
  'components.controls.inline.monospace': 'Kod',
  'components.controls.inline.superscript': 'Üst Simge',
  'components.controls.inline.subscript': 'Alt Simge',

  // Link
  'components.controls.link.linkTitle': 'Bağlantı Başlığı',
  'components.controls.link.linkTarget': 'Bağlantı Hedefi',
  'components.controls.link.linkTargetOption': 'Bağlantıyı yeni pencerede aç',
  'components.controls.link.link': 'Bağlantı',
  'components.controls.link.unlink': 'Bağlantıyı kaldır',

  // List
  'components.controls.list.list': 'Liste',
  'components.controls.list.unordered': 'Madde İşaretli',
  'components.controls.list.ordered': 'Numaralandırılmış',
  'components.controls.list.indent': 'Girintiyi Artır',
  'components.controls.list.outdent': 'Girintiyi Azalt',

  // Remove
  'components.controls.remove.remove': 'Tüm Biçimlendirmeyi Temizle',

  // TextAlign
  'components.controls.textalign.textalign': 'Text Align',
  'components.controls.textalign.left': 'Sola Hizalı',
  'components.controls.textalign.center': 'Ortaya Hizalı',
  'components.controls.textalign.right': 'Sağa Hizalı',
  'components.controls.textalign.justify': 'Yaslı',

  // New Examod localizations
  'components.controls.image.modal.title': 'Görsel Yükleyici',
  'components.controls.image.modal.description':
    'Yüklemek istediğiniz görsele göz atın veya sürükleyin',
  'components.controls.image.modal.content.image.or': 'veya',
  'components.controls.image.modal.content.image.btn': 'Dosyalara Göz Atın',
  'components.controls.image.modal.content.image.attachment':
    "Dosya boyutu 2 mb'yi geçmemelidir",
  'components.controls.image.modal.content.image.supported':
    'Desteklenen dosya türü: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt metin',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Görsel alt metni',
  'components.controls.image.modal.content.input.scale.label': 'Ölçek (%)',
  'components.controls.image.modal.contnet.url.address.label': 'URL (Adres)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Adresten görsel ekleme',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Vurgu Yok',
  'components.controls.color.picker.highlight.black': 'Siyah Vurgu',
  'components.controls.color.picker.highlight.grey': 'Gri Vurgu',
  'components.controls.color.picker.highlight.red': 'Kırmızı Vurgu',
  'components.controls.color.picker.highlight.green': 'Yeşil Vurgu',
  'components.controls.color.picker.highlight.blue': 'Mavi Vurgu',
  'components.controls.color.picker.highlight.yellow': 'Sarı Vurgu',
  'components.controls.color.picker.highlight.examod': 'Examod Vurgu',
  'components.controls.color.picker.highlight.purple': 'Mor Vurgu',
  'components.controls.color.picker.highlight.pink': 'Pembe Vurgu',
};
