export default {
  'groupDetails.header': 'Grubu Düzenle',
  'groupDetails.groupName': 'Grup Adı',
  'groupDetails.groupDescription': 'Grup Açıklaması',
  'groupDetails.edit': 'Grubu Düzenle',
  'groupDetails.delete': 'Grubu Sil',
  'groupDetails.saveChanges': 'Değişiklikleri Kaydet',
  'groupDetails.updateSuccess': 'Grup başarıyla güncellendi!',
  'groupDetails.nameMax': 'Grup adı en fazla 100 karakter olmalıdır.',
  'groupDetails.descMax': 'Grup açıklaması en fazla 200 karakter olmalıdır.',
  'groupDetails.nameSpecial': 'Grup adı özel karakter içermemelidir.',
};
