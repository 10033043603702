export default {
  'preview.title': 'Önizleme',
  'preview.subtitle': 'Verilerin önizlemesi',
  'preview.information': 'Dosya bilgisi ve önizleme',
  'preview.successMessage': 'Yüklediğiniz dosyada hata bulunamadı.',
  'preview.success': 'Başarılı',
  'preview.warning': 'Uyarı!',
  'preview.fileName': 'Dosya adı:',
  'preview.creationDate': 'Oluşturma tarihi:',
  'preview.invalidColumn': 'Geçersiz sütun boyutu',
  'preview.invalidQuestionSize': 'Soru cevap boyutu aşıldı',
  'preview.invalidId': 'Kullanıcı sistem kimliği bulunamadı',
  'preview.empty': 'Boş',
  'preview.nextStepConfirmationTitle':
    'Devam etmek istediğinizden emin misiniz?',
  'preview.nextStepConfirmationMessage':
    'Yüklediğiniz dosyada hatalar var. Devam ederseniz dosyanız gönderilecektir.',
  'preview.nextStepConfirmationConfirmButton': 'Onaylıyorum',
  'preview.nextStepConfirmationCancelButton': 'İptal',
  'preview.error': 'Hata',
  'preview.imported': 'Aktarılan',
  'preview.notImported': 'Aktarılmayan',
  'preview.systemId': 'SİSTEM KİMLİĞİ',
  'preview.bookletName': 'KİTAPÇIK ADI',
  'preview.status': 'DURUM',
  'preview.importedListTitle': 'İçe aktarılan veri listesi önizlemesi',
  'preview.name': 'AD',
  'preview.surname': 'SOYAD',
};
