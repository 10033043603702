import React from 'react';

// ** 3rd party
import { Col, Label } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

// ** Utility
import { selectThemeColors } from '../../utility/themeUtil';

// ** Components
import NoOption from '../../components/examod/emptyList/NoOption';
import HelpOption from '../../components/examod/helpOption/HelpOption';

const QuestionAttributes = ({
  selectedAttributes,
  isSubmitted,
  selectedFields,
  onChangeFieldOption,
  isReset,
  setIsReset,
  setInputsTouched,
}) => {
  const intl = useIntl();

  const getAttributeValue = (field) => {
    const value = selectedFields.find((x) => x?.fieldId === field.value);
    if (!value) {
      return null;
    } else {
      return value;
    }
  };

  const getSortedOptions = (field) => {
    const sortedOpt = field?.optionData?.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    return sortedOpt?.map((option) => ({
      value: option.id,
      label: option.name,
      fieldId: field.value,
    }));
  };

  return selectedAttributes.map((field, index) => (
    <Col key={field.value} className={index === 0 ? 'm-0 p-0' : 'm-0 p-0 pt-2'}>
      <div className="add-question-question-attribute-label-wrapper">
        <Label className="add-question-question-attribute-label">
          <span className="text-danger">*</span>
          {field.label}
        </Label>
        {field.description ? (
          <HelpOption
            tooltipPrefix={`field-label-${field.value}`}
            tooltipContent={field.description}
          />
        ) : null}
      </div>
      <Select
        size="sm"
        theme={selectThemeColors}
        className={classnames('basic-single mr-1 react-select', {
          'is-invalid':
            isSubmitted &&
            !selectedFields.map((x) => x?.fieldId).includes(field.value),
        })}
        classNamePrefix="select"
        value={getAttributeValue(field)}
        onChange={(option) => {
          onChangeFieldOption(option, field.value, index);
          isReset && setIsReset(false);
          setInputsTouched && setInputsTouched(true);
        }}
        placeholder={intl.formatMessage({
          id: 'questionAdd.select',
        })}
        options={getSortedOptions(field)}
        noOptionsMessage={NoOption}
      />
      {isSubmitted &&
      !selectedFields.map((x) => x?.fieldId).includes(field.value) ? (
        <span className="text-error">
          {intl.formatMessage({
            id: 'questionAdd.requiredMessage',
          })}
        </span>
      ) : null}
    </Col>
  ));
};

export default QuestionAttributes;
