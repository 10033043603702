import { initializeApp } from 'firebase/app';
import { firebaseConfig, isTrackingEnabled } from '../configs/firebaseConfig';
import { getPerformance } from 'firebase/performance';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { setupRemoteConfig } from '../configs/remoteConfig';
export const initialize = () => {
  if (!isTrackingEnabled()) {
    return;
  }

  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  getPerformance(app);
  setupRemoteConfig(app);
};

export const trackEvent = (name, params = null) => {
  if (!isTrackingEnabled()) {
    return;
  }

  const analytics = getAnalytics();
  logEvent(analytics, name, params);
};

export const trackScreenView = (name) => {
  if (!isTrackingEnabled()) {
    return;
  }

  const analytics = getAnalytics();
  logEvent(analytics, 'screen_view', { screen_name: name });
};

export const trackApiError = (err) => {
  if (!isTrackingEnabled()) {
    return;
  }

  if (!err || !err.response) {
    trackUnknownApiError();
    return;
  }

  const analytics = getAnalytics();
  logEvent(analytics, 'api_error', {
    status: err.response.status,
    request: err.request,
    response: err.response,
  });
};

const trackUnknownApiError = () => {
  if (!isTrackingEnabled()) {
    return;
  }

  const analytics = getAnalytics();
  logEvent(analytics, 'api_error', {
    status: 'unknown',
  });
};
