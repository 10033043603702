export default {
  'fieldAdd.addAttribute': 'Add Question Attribute',
  'fieldAdd.addSubtitle':
    'Add qualitative question attribute that you can assign to your questions',
  'fieldAdd.editAttribute': 'Edit Question Attribute',
  'fieldAdd.editSubtitle':
    'Edit qualitative question attribute that you can assign to your questions',
  'fieldAdd.info': 'Attribute Information',
  'fieldAdd.defineInfo': 'Define the information',
  'fieldAdd.name': 'Attribute Name',
  'fieldAdd.description': 'Attribute Description',
  'fieldAdd.details': 'Attribute Details',
  'fieldAdd.detailsDesc': 'Define the details',
  'fieldAdd.unit': 'Main Unit',
  'fieldAdd.type': 'Question Type',
  'fieldAdd.options': 'Attribute Options',
  'fieldAdd.questionAttribute': 'Question Attribute Options',
  'fieldAdd.cancel': 'Cancel',
  'fieldAdd.sortModeASCENDING': 'A to Z',
  'fieldAdd.sortModeDESCENDING': 'Z to A',
  'fieldAdd.sortModeCUSTOM': 'Custom',
  'fieldAdd.sortBy': 'Sort by: {sortMode}',
  'fieldAdd.emptyQuestionType': 'Question type cannot be blank',

  'fieldAdd.requirement': 'Requirement',
  'fieldAdd.add': 'Add',
  'fieldAdd.edit': 'Edit',
  'fieldAdd.saveChanges': 'Save Changes',
  'fieldAdd.multipleChoice': 'Multiple Choice Question',
  'fieldAdd.shortAnswer': 'Short Answer Question',
  'fieldAdd.longAnswer': 'Long Answer Question',
  'fieldAdd.openEnded': 'Open Ended Question',
  'fieldAdd.hotspot': 'Hotspot Question',
  'fieldAdd.mandatory': 'Mandatory',
  'fieldAdd.optional': 'Optional',
  'fieldAdd.availability': 'Active / Passive',
  'fieldAdd.addField': 'Add Question Attribute',

  'fieldAdd.required': ' ',
  'fieldAdd.minOption': 'There must be at least 2 attribute options.',
  'fieldAdd.nameMax': 'Attribute name should be max 200 characters.',
  'fieldAdd.optionMax': 'Attribute option should be max 100 characters.',
  'fieldAdd.successMessage': 'Question attribute added successfully!',
  'fieldAdd.select': 'Select',
  'fieldAdd.sameOptionAlert': 'Attribute options cannot be the same!',
  'fieldAdd.descMax': 'Attribute description should be max 1000 characters.',
  'fieldAdd.optionMinChar': 'Attribute name cannot be blank',
  'fieldAdd.descPatternError':
    "Attribute description can only contain letters, digits and - _ . , ; : ' @ / \\ ( ) [ ] '{ }' symbols.",
  'fieldAdd.namePatternError':
    "Attribute name can only contain letters, digits and - _ . , ; : ' @ / \\ ( ) [ ] '{ }' symbols.",
};
