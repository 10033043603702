export default {
  'groupDetails.header': 'Edit Group',
  'groupDetails.groupName': 'Group Name',
  'groupDetails.groupDescription': 'Group Description',
  'groupDetails.edit': 'Edit Group',
  'groupDetails.delete': 'Delete Group',
  'groupDetails.saveChanges': 'Save Changes',
  'groupDetails.updateSuccess': 'Group updated successfully!',
  'groupDetails.nameSpecial': "Group name shouldn't contain special character.",
  'groupDetails.nameMax': 'Group name should be max 100 characters.',
  'groupDetails.descMax': 'Group description should be max 200 characters.',
};
