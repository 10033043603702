export default {
    "groupAdd.header": "Add Group",
    "groupAdd.groupName": "Group Name",
    "groupAdd.groupDescription": "Group Description",
    "groupAdd.add": "Add Group",
    "groupAdd.placeholderName": "Enter group name",
    "groupAdd.placeholderDesc": "Group description goes here",
    "groupAdd.nameSpecial": "Group name shouldn't contain special character.",
    "groupAdd.nameMin": "Group name should be at least 5 characters.",
    "groupAdd.nameMax": "Group name should be max 25 characters.",
    "groupAdd.descMin": "Group description should be at least 10 characters.",
    "groupAdd.descMax": "Group description should be max 200 characters.",
    "groupAdd.requiredMessage": " ",
    "groupAdd.addSuccess": "Group added successfully!",
    "groupAdd.status": "Active / Passive"
};
