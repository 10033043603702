export default {
  'questionDetail.title': 'Soru Detayları',
  'questionDetail.owner': 'Sahibi: ',
  'questionDetail.dateAdded': 'Eklenme Tarihi: ',
  'questionDetail.approvalAdded': 'Onay Tarihi: ',
  'questionDetail.learningObjective': 'Öğrenme Hedefi: ',
  'questionDetail.preview': 'Önizleme',
  'questionDetail.assessments': 'Değerlendirmeler',
  'questionDetail.performance': 'Performans',
  'questionDetail.reviews': 'İncelemeler',
  'questionDetail.activities': 'Faaliyetler',
  'questionDetail.root': 'Soru Kökü',
  'questionDetail.expectedAnswer': 'Beklenen Yanıt',
  'questionDetail.groupContent': 'Gruplandırılmış Soru İçeriği',
  'questionDetail.answerInfo': 'Soru Seçenekleri',
  'questionDetail.true': 'doğru',
  'questionDetail.assessmentName': 'DEĞERLENDİRME ADI',
  'questionDetail.category': 'DEĞERLENDİRME KATEGORİSİ',
  'questionDetail.date': 'EKLENME TARİHİ',
  'questionDetail.attempt': 'uygulama',
  'questionDetail.answerTime': 'ORT. YANIT SÜRESİ',
  'questionDetail.correctAnswer': 'doğru cevap (%)',
  'questionDetail.per': '%{count}',
  'questionDetail.allAssessments': 'genel',
  'questionDetail.difficulty': 'güçlük (%)',
  'questionDetail.discrimination': 'Ayırt Edicilik',
  'questionDetail.general': 'Genel',
  'questionDetail.details': 'Soru Detayları',
  'questionDetail.videos': 'Video(lar)',
  'questionDetail.audios': 'Ses(ler)',
  'questionDetail.examPreview': 'Sınav Görünümü',
};
