export default {
  'myCourses.view': 'Dersi Görüntüle',
  'myCourses.viewUnit': 'Birimi Görüntüle',
  'myCourses.users': 'Yetkili Kullanıcılar',
  'myCourses.title': 'Birimlerim / Derslerim',
  'myCourses.titleInfo': 'Yetkili olduğunuz birimleri ve dersleri yönetin',
  'myCourses.assessment': 'Değerlendirme',
  'myCourses.questions': 'Sorular',
  'myCourses.learningObj': 'Öğrenme Hedefleri',
  'myCourses.settings': 'Ders Ayarları',
  'myCourses.report': 'Raporlar',
  'myCourses.rubrics': 'Dereceli Puanlama Anahtarı',
  'myCourses.notFoundCourse': 'Yetkili olduğunuz ders bulunamamıştır.',
  'myCourses.friendlyId': 'Ders Kodu',
  'myCourses.name': 'Ders Adı',
  'myCourses.description': 'Ders Açıklaması',
  'myCourses.search': 'Ara',
  'myCourses.nodesc': 'Açıklama Yok',
  'myCourses.more': 've dahası...',
  'myCourses.showHiddenUnit': 'Gizli birim veya dersleri göster',
  'myCourses.updatedCoursesTitle': 'Son Görüntülenen Dersler',
  'myCourses.updatedCoursesTitleInfo':
    'Son görüntülenen dört dersinize hızlı erişim sağlayın',
  'myCourses.noAuthorized': 'Yetkili Kullanıcı Atanmamış',
  'myCourses.clearRecentlyViewed': 'Son Görüntülenen Dersleri Temizle',
};
