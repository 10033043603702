export default {
  'ticket.subject': 'Subject',
  'ticket.type': 'Ticket Type',
  'ticket.message': 'Messsage',
  'ticket.send': 'Send',
  'ticket.help': 'Help',
  'ticket.bug': 'Bug Report',
  'ticket.suggestion': 'Suggestion',
  'ticket.subMinMessage': 'Subject should be at least 6 characters.',
  'ticket.subMaxMessage': 'Subject should be max 25 characters.',
  'ticket.messageMinMessage': 'Message should be at least 10 characters.',
  'ticket.messageMaxMessage': 'Message should be max 250 characters.',
  'ticket.requiredMessage': 'This field cannot be blank',
  'ticket.sendingCompletedMessage':
    'Your ticket has been submitted successfully!',
};
