export default {
  'questionField.title': 'Soru Özellikleri',
  'questionField.subtitle':
    'Organizasyonunuzun soru özellikleri yapısını tasarlayın',
  'questionField.emptySubtitle':
    'Organizasyonunuzun soru özellikleri yapısını tasarlayın',
  'questionField.addField': 'Soru Özelliği Ekle',
  'questionField.search': 'Ara',
  'questionField.questionField': 'Soru Özelliği',
  'questionField.parentUnit': 'ANA BİRİM',
  'questionField.type': 'SORU TİPİ',
  'questionField.status': 'Durum',
  'questionField.actions': 'İşlemler',
  'questionField.edit': 'Düzenle',
  'questionField.delete': 'Sil',
  'questionField.multipleChoice': 'Çoktan Seçmeli Soru',
  'questionField.multipleChoiceShortcut': 'ÇSS',
  'questionField.trueFalse': 'Doğru / Yanlış Soru',
  'questionField.trueFalseShortcut': 'D / Y',
  'questionField.shortAnswer': 'Kısa Cevaplı Soru',
  'questionField.shortAnswerShortcut': 'KYS',
  'questionField.longAnswer': 'Uzun Cevaplı Soru',
  'questionField.openEnded': 'Açık Uçlu Soru',
  'questionField.longAnswerShortcut': 'UYS',
  'questionField.openEndedShortcut': 'AUS',
  'questionField.hotspot': 'Nokta Seçim Sorusu',
  'questionField.hotspotShortcut': 'NSS',
  'questionField.veryShortAnswer': 'Çok Kısa Cevaplı Soru',
  'questionField.veryShortAnswerShortcut': 'ÇKYS',
  'questionField.unknown': 'Bilinmiyor',
  'questionField.unknownShortcut': '-',
  'questionField.deleteConfirmationTitle': 'Soru özelliğini sil',
  'questionField.deleteConfirmationMessage':
    'Soru özelliğini silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'questionField.deleteConfirmationConfirmButton': 'Evet, soru özelliğini sil',
  'questionField.deleteConfirmationCancelButton': 'İptal',
  'questionField.parentUnitNone': 'Yok',
  'questionField.parentUnits': 'Tüm Ana Birimler',
  'questionField.showPassiveStatus': 'Pasif özellikleri göster',
  'questionField.titleInformation':
    'Hay aksi! Henüz {titleValue} bulunmamaktadır.',
  'questionField.messageInformation':
    'Organizasyonunuz için henüz bir {titleValue} tanımlanmamıştır.',
  'questionField.messageInformationAdd':
    'Soru özelliği eklemek için {messageValue} düğmesine tıklayın.',
  'questionField.obj': 'Soru Özelliği',
  'questionField.addQuestionField': '"Soru Özelliği Ekle"',
};
