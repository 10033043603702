import menu from './general/menu';
import invite from './pages/invite';
import login from './pages/login';
import forgotPassword from './pages/forgotPassword';
import resetPassword from './pages/resetPassword';
import lockScreen from './pages/lockScreen';
import users from './pages/users';
import userDetails from './pages/userDetails';
import profile from './pages/profile';
import groups from './pages/groups';
import groupUsers from './pages/groupUsers';
import groupDetails from './pages/groupDetails';
import groupAdd from './pages/groupAdd';
import home from './pages/home';
import generalSettings from './pages/generalSettings';
import faq from './pages/faq';
import invitationResult from './pages/invitationResult';
import maintenace from './pages/maintenance';
import notAuthorized from './pages/notAuthorized';
import passwordReset from './pages/passwordReset';
import resetPasswordEmailSent from './pages/resetPasswordEmailSent';
import ticket from './pages/ticket';
import rolesList from './pages/rolesList';
import periodAdd from './pages/periodAdd';
import periodList from './pages/periodList';
import userAdd from './pages/userAdd';
import userInvite from './pages/userInvite';
import periodEdit from './pages/periodEdit';
import about from './pages/about';
import quesitonFieldAdd from './pages/quesitonFieldAdd';
import quesitonField from './pages/questionField';
import unitsList from './pages/unitsList';
import unitAdd from './pages/unitAdd';
import unitDelete from './pages/unitDelete';
import questionFieldEdit from './pages/questionFieldEdit';
import userDetailsEdit from './pages/userDetailsEdit';
import questionAdd from './pages/questionAdd';
import openEndedQuestion from './pages/openEndedQuestion';
import buttons from './general/buttons';
import permissionAction from './permissions/permissionAction';
import permissionGroup from './permissions/permissionGroup';
import internalError from './pages/internalError';
import unknownError from './pages/unknownError';
import feBasedError from './pages/feBasedError';
import notFound from './pages/notFound';
import unitsUsers from './pages/unitsUsers';
import questions from './pages/questions';
import myCourses from './pages/myCourses';
import routes from './general/routes';
import assessments from './pages/assessments';
import assessmentSetup from './pages/assessmentSetup';
import assessmentContent from './pages/assessmentContent';
import assessmentContext from './pages/assessmentContext';
import assessmentAddDelivery from './pages/assessmentAddDelivey';
import topicPopup from './pages/topicPopup';
import learningObjPopup from './pages/learningObjPopup';
import questionEdit from './pages/questionEdit';
import assessmentTypeSettings from './pages/assessmentType';
import examRules from './pages/examRules';
import proctoringRules from './pages/proctoringRules';
import learningObjectives from './pages/learningObjectives';
import objectiveAdd from './pages/objectiveAdd';
import status from './general/status';
import assessmentResults from './pages/assessmentResults';
import messages from './general/messages';
import planDetails from './pages/planDetails';
import assessmentStatistics from './pages/assessmentStatistics';
import userStatus from './general/userStatus';
import dataTable from './general/dataTable';
import assessmentBlueprint from './pages/assessmentBlueprint';
import rubrics from './pages/rubrics';
import examBookletPrint from './pages/examBookletPrint';
import questionDetail from './pages/questionDetail';
import time from './general/time';
import courseReport from './pages/courseReport';
import performanceExam from './pages/performanceExam';
import candidateReport from './pages/candidateReport';
import assessmentStatuses from './general/assessmentStatuses';
import rolesAddOrUpdate from './pages/rolesAddOrUpdate';
import questionStatistics from './pages/questionStat';
import omrProcessImport from './pages/omrProcessImport';
import omrProcessPreview from './pages/omrProcessPreview';
import omrProcessSummary from './pages/omrProcessSummary';
import userVerification from './pages/userVerification';
import notification from './general/notification';
import proctoringReport from './pages/proctoringReport';
import pageTitle from './general/pageTitle';
import toasts from './general/toasts';
import exportDropdown from './general/exportDropdown';
import userImport from './pages/userImport';
import reactDraftjs from './general/reactDraftjs';
import groupedQuestion from './pages/groupedQuestion';
import footer from './general/footer';
import assessmentPreview from './pages/assessmentPreview';
import resetSession from './pages/resetSession';
import alertsTitle from './pages/alertsTitle';
import classicExam from './pages/classicExam.js';

export default {
  ...menu,
  ...invite,
  ...login,
  ...forgotPassword,
  ...resetPassword,
  ...lockScreen,
  ...users,
  ...userDetails,
  ...profile,
  ...groups,
  ...groupUsers,
  ...groupDetails,
  ...groupAdd,
  ...home,
  ...generalSettings,
  ...faq,
  ...invitationResult,
  ...maintenace,
  ...notAuthorized,
  ...passwordReset,
  ...resetPasswordEmailSent,
  ...ticket,
  ...rolesAddOrUpdate,
  ...rolesList,
  ...periodAdd,
  ...userAdd,
  ...periodEdit,
  ...periodList,
  ...userAdd,
  ...userInvite,
  ...about,
  ...quesitonFieldAdd,
  ...quesitonField,
  ...unitsList,
  ...unitAdd,
  ...unitDelete,
  ...questionFieldEdit,
  ...userDetailsEdit,
  ...questionAdd,
  ...openEndedQuestion,
  ...buttons,
  ...permissionAction,
  ...permissionGroup,
  ...internalError,
  ...unknownError,
  ...feBasedError,
  ...notFound,
  ...unitsUsers,
  ...questions,
  ...myCourses,
  ...routes,
  ...topicPopup,
  ...learningObjPopup,
  ...assessments,
  ...assessmentSetup,
  ...assessmentContent,
  ...assessmentContext,
  ...assessmentAddDelivery,
  ...questionEdit,
  ...assessmentTypeSettings,
  ...examRules,
  ...proctoringRules,
  ...learningObjectives,
  ...objectiveAdd,
  ...status,
  ...assessmentResults,
  ...messages,
  ...planDetails,
  ...assessmentStatistics,
  ...userStatus,
  ...dataTable,
  ...assessmentBlueprint,
  ...rubrics,
  ...examBookletPrint,
  ...questionDetail,
  ...time,
  ...courseReport,
  ...performanceExam,
  ...candidateReport,
  ...assessmentStatuses,
  ...questionStatistics,
  ...omrProcessImport,
  ...omrProcessPreview,
  ...omrProcessSummary,
  ...userVerification,
  ...notification,
  ...proctoringReport,
  ...pageTitle,
  ...toasts,
  ...exportDropdown,
  ...userImport,
  ...reactDraftjs,
  ...groupedQuestion,
  ...footer,
  ...assessmentPreview,
  ...resetSession,
  ...alertsTitle,
  ...classicExam,
};
