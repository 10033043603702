export default {
  'myCourses.view': 'View',
  'myCourses.viewUnit': 'View Unit',
  'myCourses.title': 'My Units / Courses',
  'myCourses.titleInfo': 'Manage units and courses you are authorized to',
  'myCourses.users': 'Authorised Users',
  'myCourses.assessment': 'Assessment',
  'myCourses.questions': 'Questions',
  'myCourses.learningObj': 'Learning Objectives',
  'myCourses.settings': 'Course Settings',
  'myCourses.report': 'Reports',
  'myCourses.rubrics': 'Rubrics',
  'myCourses.notFoundCourse':
    'The course you are authorized for could not be found.',
  'myCourses.friendlyId': 'Course ID',
  'myCourses.name': 'Course Name',
  'myCourses.description': 'Course Description',
  'myCourses.search': 'Search',
  'myCourses.nodesc': 'No Description',
  'myCourses.more': 'and more...',
  'myCourses.showHiddenUnit': 'Show hidden units or courses',
  'myCourses.updatedCoursesTitle': 'Recently Viewed Courses',
  'myCourses.updatedCoursesTitleInfo':
    'Get quick access to your four recently viewed courses',
  'myCourses.noAuthorized': 'No Authorized User Assigned',
  'myCourses.clearRecentlyViewed': 'Clear Recently Viewed Courses',
};
