import React from 'react';

export const getLink = (item) => {
  return JSON.parse(item.serializedContent).extraMap.redirectUrl;
};

export const onSrollBottom = (
  notificationsLength,
  pageSize,
  totalRecord,
  dropdownOpen,
  setPageSize
) => {
  const notificationsEqualsSize = notificationsLength === pageSize;
  const hasMoreNotification = pageSize < totalRecord;

  if (notificationsEqualsSize && dropdownOpen && hasMoreNotification) {
    setPageSize(pageSize + 5);
  }
};

export const hasUnreadNotifications = (unreadNotifications) => {
  return unreadNotifications > 0;
};

export const calculateListHeight = (listItemRef) => {
  let totalHeight = 0;
  listItemRef.current.forEach((item) => {
    totalHeight += item?.current?.clientHeight;
  });
  return (totalHeight - 10) * (5 / 4);
};

export const reviseNotificationCount = (count) => {
  if (count > 99) {
    return (
      <>
        99<span style={{ fontSize: '0.8rem' }}>+</span>
      </>
    );
  } else {
    return count;
  }
};
