import { httpClient } from '../utility/networkUtil';
import { onApiError } from '../utility/apiUtil';

const API_PREFIX = '/my/courses';

/*
 * Gets User's Courses
 */
export const fetchCourseList = async () =>
  httpClient
    .get(`${API_PREFIX}/course/list`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
