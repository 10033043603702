export const EventNames = {
    addUserButtonUsers: "add_user_button_users",
    searchUsers: "search_users",
    addRoleButtonRoles: "add_role_button_roles",
    editRoleRoles: "edit_role_roles",
    searchRoles: "search_roles",
    logoButtonMenu: "logo_button_menu",
    pinButtonMenu: "pin_button_menu",
    homeButtonMenu: "home_button_menu",
    planDetailsButtonMenu: "plan_details_button_menu",
    generalButtonMenu: "general_button_menu",
    structuralButtonMenu: "structural_button_menu",
    unitsButtonMenu: "units_button_menu",
    periodsButtonMenu: "periods_button_menu",
    examsButtonMenu: "exams_button_menu",
    questionFieldButtonMenu: "question_fields_button_menu",
    usersButtonMenu: "users_button_menu",
    groupsButtonMenu: "groups_button_menu",
    rolesButtonMenu: "roles_button_menu",
    myUnitsButtonMenu: "my_units_button_menu",
    myCoursesButtonMenu: "my_courses_button_menu",
    newTicketButtonMenu: "new_ticket_button_menu",
    raiseSupportButtonMenu: "raise_support_button_menu",
    faqButtonMenu: "faq_button_menu",
    searchUnits: "search_units",
    addNewUnitButtonUnits: "add_new_unit_button_units",
    moveButtonUnits: "move_button_units",
    unitVisibilityButtonUnits: "unit_visibility_button_units",
    changeUnitPlacesButtonUnits: "change_unit_places_button_units",
    editUnitUnits: "edit_unit_units",
    manageUsersUnits: "manage_users_units",
    addNewChildUnitUsers: "add_new_child_unit_users",
    sortUnits: "sort_units",
    deleteUnits: "delete_units",
    infoButtonProfile: "info_button_profile",
    userRolesButtonProfile: "user_roles_button_profile",
    saveChangesButtonInfoProfile: "save_changes_button_info_profile",
    cancelButtonInfoProfile: "cancel_button_info_profile",
    changePasswordButtonProfile: "change_password_button_profile",
    saveChangesButtonChangePasswordProfile:
        "save_changes_button_change_password_profile",
    cancelButtonChangePasswordProfile: "cancel_button_change_password_profile",
    proctoringRulesButtonExams: "proctoring_rules_button_exams",
    assessmentTypeSettingsButtonExams: "assessment_type_settings_button_exams",
    assessmentCategoriesButtonExams: "assessment_categories_button_exams",
    editButtonUserDetails: "edit_button_user_details",
    deleteButtonUserDetails: "delete_button_user_details",
    deleteQuestionBlueprintViewAssessmentContent:
        "delete_question_blueprint_view_assessment_content",
    resetBlueprintBlueprintViewAssessmentContent:
        "reset_blueprint_blueprint_view_assessment_content",
    addQuestionBlueprintViewAssessmentContent:
        "add_question_blueprint_view_assessment_content",
    sendButtonNewTicket: "send_button_new_ticket",
    cancelButtonNewTicket: "cancel_button_new_ticket",
    saveChangesButtonAssessmentTypeSettings:
        "save_changes_button_assessment_type_settings",
    cancelButtonAssessmentTypeSettings:
        "cancel_button_assessment_type_settings",
    uploadLogoButtonAssessmentTypeSettings:
        "upload_logo_button_assessment_type_settings",
    resetButtonAssessmentTypeSettings: "reset_button_assessment_type_settings",
    saveChangesButtonProctoringRules: "save_changes_button_proctoring_rules",
    cancelButtonProctoringRules: "cancel_button_proctoring_rules",
    searchLearningObjectives: "search_learning_objectives",
    addLearningObjectiveButtonLearningObjectives:
        "add_learning_objective_button_learning_objectives",
    editButtonLearningObjectives: "edit_button_learning_objectives",
    deleteButtonLearningObjectives: "delete_button_learning_objectives",
    moveLearningObjectives: "move_learning_objectives",
    deleteQuestionButtonEditQuestion: "delete_question_button_edit_question",
    cancelButtonEditQuestion: "cancel_button_edit_question",
    saveChangesButtonEditQuestion: "save_changes_button_edit_question",
    addRoleButtonAddRole: "add_role_button_add_role",
    cancelButtonAddRole: "cancel_button_add_role",
    addQuestionButtonAddQuestion: "add_question_button_add_question",
    cancelButtonAddQuestion: "cancel_button_add_question",
    resetButtonAddQuestion: "reset_button_add_question",
    saveChangesButtonEditRole: "save_changes_button_edit_role",
    deleteRoleButtonEditRole: "delete_role_button_edit_role",
    cancelButtonEditRole: "cancel_button_edit_role",
    addPeriodButtonAddPeriod: "add_period_button_add_period",
    cancelButtonAddPeriod: "cancel_button_add_period",
    closeButtonAddPeriod: "close_button_add_period",
    searchPeriods: "search_periods",
    addPeriodButtonPeriods: "add_period_button_periods",
    editPeriodPeriods: "edit_period_periods",
    deletePeriods: "delete_periods",
    rowsPerPagePeriods: " rows_per_page_periods",
    inviteUserButtonInviteUser: "invite_user_button_invite_user",
    cancelButtonInviteUser: "cancel_button_invite_user",
    closeButtonInviteUser: "close_button_invite_user",
    cancelButtonAddUser: "cancel_button_add_user",
    addUserButtonAddUser: "add_user_button_add_user",
    closeButtonAddUser: "close_button_add_user",
    closeButtonEditUser: "close_button_edit_user",
    addQuestionFieldButtonAddQuestionField:
        "add_question_field_button_add_question_field",
    cancelButtonAddEditQuestionField: "cancel_button_add_edit_question_field",
    closeButtonAddEditQuestionField: "close_button_add_edit_question_field",
    deleteButtonEditPeriod: "delete_button_edit_period",
    cancelButtonEditPeriod: "cancel_button_edit_period",
    closeButtonEditPeriod: "close_button_edit_period",
    saveChangeButtonEditPeriod: "save_changes_button_edit_period",
    assessmentDescriptionButtonAssessmentContent:
        "assessment_description_button_assessment_content",
    addNewQuestionButtonAssessmentContent:
        "add_new_question_button_assessment_content",
    selectFromBankButtonAssessmentContent:
        "select_from_bank_button_assessment_content",
    previousStepButtonAssessmentContent:
        "previous_step_button_assessment_content",
    cancelButtonAssessmentContent: "cancel_button_assessment_content",
    nextStepButtonAssessmentContent: "next_step_button_assessment_content",
    moveQuestionAssessmentContent: "move_question_assessment_content",
    deleteQuestionAssessmentContent: "delete_question_assessment_content",
    listViewButtonAssessmentContent: "list_view_button_assessment_content",
    blueprintViewAssessmentContent: "blueprint_view_assessment_content",
    clickBuildBlueprintAssessmentContent:
        "click_build_blueprint_assessment_content",
    closeButtonBuildBlueprint: "close_button_build_blueprint",
    buildButtonBuildBlueprint: "build_button_build_blueprint",
    resetButtonBuildBlueprint: "reset_button_build_blueprint",
    addLearningObjectiveButtonAddLearningObjective:
        "add_learning_objective_button_add_learning_objective",
    resetButtonAddEditLearningObjective:
        "reset_button_add_edit_learning_objective",
    closeButtonAddEditLearningObjective:
        "close_button_add_edit_learning_objective",
    saveChangesButtonEditQuestionField:
        "save_changes_button_edit_question_field",
    deleteButtonEditQuestionField: "delete_button_edit_question_field",
    addGroupButtonAddGroup: "add_group_button_add_group",
    cancelButtonAddEditGroup: "cancel_button_add_edit_group",
    closeButtonAddEditGroup: "close_button_add_edit_group",
    searchGroups: "search_groups",
    addGroupButtonGroups: "add_group_button_groups",
    editGroups: "edit_groups",
    usersGroups: "users_groups",
    rowsPerPageGroups: "rows_per_page_groups",
    deleteGroups: "delete_groups",
    searchAssessmentResults: "search_assessment_results",
    assessmentStatsButtonAssessmentResults:
        "assessment_stats_button_assessment_results",
    showQuestionResultsToggleAssessmentResults:
        "show_question_results_toggle_assessment_results",
    selectAllCheckboxAssessmentResults:
        "select_all_checkbox_assessment_results",
    applyButtonAssessmentResults: "apply_button_assessment_results",
    assessmentButtonCourse: "assessment_button_course",
    questionsButtonCourse: "questions_button_course",
    learningObjectivesButtonCourse: "learning_objectives_button_course",
    rubricsButtonCourse: "rubrics_button_course",
    reportsButtonCourse: "reports_button_course",
    closeButtonTopicsSettings: "close_button_topics_settings",
    saveChangesButtonTopicsSettings: "save_changes_button_topics_settings",
    resetButtonTopicsSettings: "reset_button_topics_settings",
    moveButtonTopicsSettings: "move_button_topics_settings",
    editButtonTopicsSettings: "edit_button_topics_settings",
    deleteButtonTopicsSettings: "delete_button_topics_settings",
    previousStepButtonDeliveryOptions: "previous_step_button_delivery_options",
    cancelButtonDeliveryOptions: "cancel_button_delivery_options",
    completeSetupButtonDeliveryOptions:
        "complete_setup_button_delivery_options",
    rowsPerPageDeliveryOptions: "rows_per_page_delivery_options",
    viewProfileButtonDeliveryOptions: "view_profile_button_delivery_options",
    reSendInvitationDeliveryOptions: "re_send_invitation_delivery_options",
    editDescriptionButtonAssessmentDescriptionPreview:
        "edit_description_button_assessment_description_preview",
    closeButtonAssessmentDescriptionPreview:
        "close_button_assessment_description_preview",
    clickOverallStatisticsAssessmentStats:
        "click_overall_statistics_assessment_stats",
    rowsPerPageAssessmentStats: "rows_per_page_assessment_stats",
    cancelButtonAssessmentSetup: "cancel_button_assessment_setup",
    nextButtonAssessmentSetup: "next_button_assessment_setup",
    addLearningObjectiveButtonEditLearningObjective:
        "add_learning_objective_button_edit_learning_objective",
    searchCourseMyCourses: "search_course_my_courses",
    clickCourseMyCourses: "click_course_my_courses",
    createAssessmentButtonAssessment: "create_assessment_button_assessment",
    searchAssessmentAssessment: "search_assessment_assessment",
    deleteButtonAssessment: "delete_button_assessment",
    resultsAssessment: "results_assessment",
    rowsPerPageAssessment: "rows_per_page_assessment",
    saveChangesButtonEditGroup: "save_changes_button_edit_group",
    deleteGroupButtonEditGroup: "delete_group_button_edit_group",
    selectFilterUserTypeUsers: "select_filter_user_type_users",
    selectFilterUnitUsers: "select_filter_unit_users",
    selectFilterRoleUsers: "select_filter_role_users",
    selectFilterUserGroupUsers: "select_filter_user_group_users",
    selectFilterStatusUsers: "select_filter_status_users",
    editUserUsers: "edit_user_users",
    deleteUserUsers: "delete_user_users",
    inviteUserButtonUsers: "invite_user_button_users",
    rowsPerPageUsers: "rows_per_page_users",
    searchQuestionFields: "search_question_fields",
    addFieldButtonQuestionFields: "add_field_button_question_fields",
    editQuestionFields: "edit_question_fields",
    deleteQuestionFields: "delete_question_fields",
    rowsPerPageQuestionFields: "rows_per_page_question_fields",
    assignSelectedUsersToUnitButtonManageUnitUsers:
        "assign_selected_users_to_unit_button_manage_unit_users",
    resetButtonManageUnitUsers: "reset_button_manage_unit_users",
    cancelButtonManageUnitUsers: "cancel_button_manage_unit_users",
    saveChangesButtonEditUnit: "save_changes_button_edit_unit",
    cancelButtonAddEditUnit: "cancel_button_add_edit_unit",
    closeButtonAddEditUnit: "close_button_add_edit_unit",
    saveChangesButtonEditCategory: "save_changes_button_edit_category",
    resetButtonEditCategory: "reset_button_edit_category",
    closeButtonEditAssessmentCategory: "close_button_edit_assessment_category",
    selectAllSelectQuestionsFromQuestionBank:
        "select_all_select_questions_from_question_bank",
    selectQuestionSelectQuestionsFromQuestionBank:
        "select_question_select_questions_from_question_bank",
    searchQuestionQuestionsBank: "search_question_questions_bank",
    addSelectedQuestionsButtonSelectQuestionsFromQuestionBank:
        "add_selected_questions_button_select_questions_from_question_bank",
    openFiltersSelectQuestionsFromQuestionBank:
        "open_filters_select_questions_from_question_bank",
    closeFiltersSelectQuestionsFromQuestionBank:
        "close_filters_select_questions_from_question_bank",
    selectTopicSelectQuestionsFromQuestionBank:
        "select_topic_select_questions_from_question_bank",
    selectLearningObjectiveSelectQuestionsFromQuestionBank:
        "select_learning_objective_select_questions_from_question_bank",
    closeButtonSelectQuestionsFromQuestionBank:
        "close_button_select_questions_from_question_bank",
    cancelSelectQuestionsFromQuestionBank:
        "cancel_select_questions_from_question_bank",
    addQuestionButtonQuestions: "add_question_button_questions",
    selectAllMoveQuestionButtonQuestions:
        "select_all_move_question_button_questions",
    selectAllDeleteQuestionButtonQuestions:
        "select_all_delete_question_button_questions",
    editQuestionButtonQuestions: "edit_question_button_questions",
    duplicateQuestionButtonQuestions: "duplicate_question_button_questions",
    deleteQuestionButtonQuestions: "delete_question_button_questions",
    searchQuestionQuestions: "search_question_questions",
    topicsSettingsButtonQuestions: "topics_settings_button_questions",
    selectTopicQuestions: "select_topic_questions",
    selectLearningObjectiveQuestions: "select_learning_objectives_questions",
    openFiltersQuestions: "open_filters_questions",
    closeFiltersQuestions: "close_filters_questions",
    addCategoryButtonAddCategory: "add_category_button_add_category",
    resetButtonAddCategory: "reset_button_add_category",
    closeButtonAddAssessmentCategory: "close_button_add_assessment_category",
    addCategoryButtonAssessmentCategories:
        "add_category_button_assessment_categories",
    searchAssessmentCategories: "search_assessment_categories",
    editButtonAssessmentCategories: "edit_button_assessment_categories",
    deleteButtonAssessmentCategories: "delete_button_assessment_categories",
    rowsPerPageAssessmentCategories: "rows_per_page_assessment_categories",
    addButtonAddUnit: "add_button_add_unit",
    browseFileButtonEditUser:'browse_file_button_edit_user',
};

export const ScreenNames = {
  users: 'users',
};
