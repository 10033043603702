import moment from 'moment';
import momentTz from 'moment-timezone';

import 'moment/locale/tr';

// Default date format used in the app
export const DEFAULT_DATE_FORMAT = 'd/m/Y';

// Default date format used in the calendar
export const CALENDAR_DATE_FORMAT = 'DD/MM/YYYY';

// Default date time format
export const DEFAULT_DATETIME_FORMAT = 'd/m/Y H:i';
export const BOOKLET_DATE_FORMAT = 'DD MMMM YYYY';
export const BOOKLET_TIME_FORMAT = 'HH:mm';

// Default Duration Time format
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const ASSESSMENT_DATE_FORMAT = 'DD/MM/YYYY - HH:mm';
export const UPCOMING_EXAM_DATE_FORMAT = 'DD.MM.YYYY, HH:mm';
export const REPORT_DATE_FORMAT = 'DD/MM/YYYY HH:mm';

export const setDefaultTimezone = (timezone) => {
  momentTz.tz.setDefault(timezone);
};

// Gets the date format as calender date format
export const getCalendarFormat = (date) => {
  if (date) {
    const newFormat = momentTz(date).format(CALENDAR_DATE_FORMAT);
    return newFormat;
  }
  return null;
};

// Gets the date format as booklet cover date format
export const getAssessmentDateFormat = (date) => {
  return momentTz(date).format(ASSESSMENT_DATE_FORMAT);
};

// Gets the date format as dashboard upcoming exam date format
export const getUpcomingExamDateFormat = (date) => {
  return momentTz(date).format(UPCOMING_EXAM_DATE_FORMAT);
};

// Gets the date format as report exam date format
export const getReportDateFormat = (date) => {
  return momentTz(date).format(REPORT_DATE_FORMAT);
};

// Gets the date format as booklet cover date format
export const getBookletDateFormat = (date, locale) => {
  if (locale) {
    momentTz.locale(locale);
    moment.locale(locale);
    momentTz.localeData(locale);
    momentTz.defineLocale(locale, moment.localeData()._config);
  }

  return momentTz(date).format(BOOKLET_DATE_FORMAT);
};

// Gets the time format as booklet cover date format
export const getBookletTimeFormat = (date) =>
  momentTz(date).format(BOOKLET_TIME_FORMAT);

export const getDateFormat = (date, locale) => {
  if (locale) {
    momentTz.locale(locale);
    moment.locale(locale);
    momentTz.localeData(locale);
    momentTz.defineLocale(locale, moment.localeData()._config);
  }
  return `${getBookletDateFormat(date, locale)} ${getBookletTimeFormat(
    date,
    locale
  )}`;
};

// Gets the time format text
export const GetTimeFormatText = (sec, intl) => {
  // const intl = useIntl();
  if (!sec) {
    return intl.formatMessage({ id: 'time.sec' }, { sec: 0 });
  }
  const time = momentTz()
    .startOf('day')
    .seconds(sec)
    .format(DEFAULT_TIME_FORMAT);
  let timeArray = time.split(':');
  timeArray = timeArray.map((item) => parseInt(item));
  if (timeArray[0] !== 0) {
    timeArray[0] = intl.formatMessage(
      { id: 'time.hour' },
      { hour: timeArray[0] }
    );
  }
  if (timeArray[1] !== 0) {
    timeArray[1] = intl.formatMessage(
      { id: 'time.min' },
      { min: timeArray[1] }
    );
  }
  if (timeArray[2] !== 0) {
    timeArray[2] = intl.formatMessage(
      { id: 'time.sec' },
      { sec: timeArray[2] }
    );
  }
  return timeArray.filter((e) => e != 0).join(' ');
};

export const GetTimeInObject = (sec, intl) => {
  // const intl = useIntl();
  if (!sec) {
    return 0;
  }
  const time = momentTz()
    .startOf('day')
    .seconds(sec)
    .format(DEFAULT_TIME_FORMAT);
  let timeArray = time.split(':');
  let obj = {};
  timeArray = timeArray.map((item) => parseInt(item));
  if (timeArray[0] !== 0) {
    obj['hr'] = timeArray[0];
  }
  if (timeArray[1] !== 0) {
    obj['min'] = timeArray[1];
  }
  if (timeArray[2] !== 0) {
    obj['sec'] = timeArray[2];
  }
  return obj;
};

// Returns date object for given / separated date string
export const processDate = (date) => {
  var parts = date.split('/');
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

// Returns still active periods
export const isDateActive = (date) => {
  let present = getCalendarFormat(new Date());
  return processDate(date) > processDate(present);
};

// Is Expired
export const isExpired = (dateTime) => {
  return new Date(dateTime) < new Date();
};
export const clockFormat = (value, type) => {
  const duration = momentTz.duration(value);

  const milliseconds = duration.milliseconds();
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();

  const sHours = hours < 10 ? `0${hours}` : `${hours}`;
  const sMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const sSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const sMilliseconds = `${milliseconds}ms`;

  if (type == BOOKLET_TIME_FORMAT) {
    return `${sHours}:${sMinutes}`;
  } else {
    return `${hours ? sHours + ':' : ''}${sMinutes}:${sSeconds}`;
  }
};

export const getTimezoneAbbreviations = (zoneName, shortForm) => {
  let offsetMinutes;
  if (!zoneName) {
    offsetMinutes = momentTz().utcOffset();
  } else {
    offsetMinutes = momentTz.tz(zoneName).utcOffset(); // Get the UTC offset in minutes for the specified timezone
  }

  // Convert the offset to hours and minutes
  const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
  const offsetMinutesRemainder = Math.abs(offsetMinutes % 60);
  const offsetSign = offsetMinutes >= 0 ? '+' : '-';
  const formattedOffset = `${offsetSign}${String(offsetHours).padStart(
    2,
    '0'
  )}:${String(offsetMinutesRemainder).padStart(2, '0')}`;
  if (shortForm) {
    return (
      'UTC ' +
      offsetSign +
      offsetHours +
      (offsetMinutesRemainder ? ':' + offsetMinutesRemainder : '')
    );
  }
  return `UTC${formattedOffset}`;
};

export const getFullTimezone = (timezone) => {
  return `(${getTimezoneAbbreviations(timezone, true)}) ${timezone}`;
};

export const getYear = () => {
  return new Date().getFullYear();
};

export const subtractYears = (date, years) => {
  date.setFullYear(date.getFullYear() - years);
  return date;
};
