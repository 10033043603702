import React from 'react';
import '../../../assets/scss/components/app-loader.scss';
import classnames from 'classnames';

class ComponentSpinner extends React.Component {
  render() {
    return (
      <div
        className={classnames('fallback-spinner', this.props.customClassName)}
      >
        <div
          className={classnames(
            'loading',
            !this.props.centered && 'component-loader',
            this.props.customClassName + '-loading'
          )}
        >
          <div
            className={classnames(
              'effect-1 effects',
              this.props.customClassName + '-effects'
            )}
          />
          <div
            className={classnames(
              'effect-2 effects',
              this.props.customClassName + '-effects'
            )}
          />
          <div
            className={classnames(
              'effect-3 effects',
              this.props.customClassName + '-effects'
            )}
          />
        </div>
      </div>
    );
  }
}

export default ComponentSpinner;
