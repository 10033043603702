export default {
  'objectiveAdd.title': 'Öğrenme Hedefi Ekle',
  'objectiveAdd.objective': 'Öğrenme Hedefi',
  'objectiveAdd.enable': 'Aktif / Pasif',
  'objectiveAdd.add': 'Öğrenme Hedefini Ekle',
  'objectiveAdd.saveChanges': 'Değişiklikleri Kaydet',
  'objectiveAdd.addSuccess': 'Öğrenme hedefi başarıyla eklendi!',
  'objectiveAdd.required': ' ',
  'objectiveEdit.title': 'Öğrenme Hedefini Düzenle ',
  'objectiveEdit.editSuccess': 'Öğrenme hedefi başarıyla güncellendi!',
  'objectiveAdd.min': 'Öğrenme hedefi en az 1 karakter olmalıdır',
  'objectiveAdd.max': 'Öğrenme hedefi 500 karakterden fazla olamaz',
};
