import React from 'react';
import createInlineStyleButton from '../utils/createInlineStyleButton.js';

export default createInlineStyleButton({
  style: 'ITALIC',
  children: (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.46281 4.21429H4.31995L2.2771 13.5H0.419952L2.46281 4.21429ZM4.02281 0.5C3.83915 0.5 3.65963 0.55446 3.50692 0.656493C3.35422 0.758525 3.2352 0.903548 3.16492 1.07322C3.09464 1.2429 3.07625 1.4296 3.11208 1.60973C3.14791 1.78985 3.23635 1.95531 3.36621 2.08517C3.49607 2.21503 3.66153 2.30347 3.84165 2.3393C4.02178 2.37513 4.20848 2.35674 4.37816 2.28646C4.54783 2.21618 4.69286 2.09716 4.79489 1.94446C4.89692 1.79176 4.95138 1.61223 4.95138 1.42857C4.95138 1.1823 4.85355 0.946113 4.67941 0.771972C4.50527 0.597832 4.26908 0.5 4.02281 0.5Z"
        fill="#5E5873"
      />
    </svg>
  ),
});
