export default {
  'profile.header': 'Profil',
  'profile.info': 'Kullanıcı Bilgileri',
  'profile.logout': 'Çıkış Yap',
  'profile.changePassword': 'Şifre Değiştir',
  'profile.userRoles': 'Kullanıcı Rolleri',
  'profile.email': 'E-posta',
  'profile.username': 'Kullanıcı Adı',
  'profile.name': 'Ad',
  'profile.lastname': 'Soyad',
  'profile.company': 'Şirket',
  'profile.language': 'Dil',
  'profile.timezone': 'Zaman Dilimi (Varsayılan: {defaultTimezone})',
  'profile.oldPassword': 'Eski Şifre',
  'profile.newPassword': 'Yeni Şifre',
  'profile.confirmPassword': 'Şifreyi Onayla',
  'profile.emailHint': 'örnek: merhaba@examod.com',
  'profile.usernameHint': 'örnek: myAwesomeUserName',
  'profile.nameHint': 'örnek: John',
  'profile.lastnameHint': 'örnek: Snow',
  'profile.companyHint': 'örnek: Acme Ltd. Sti.',
  'profile.requiredMessage': ' ',
  'profile.passwordMessage':
    'Şifre en az 6 karakter olmalıdır ve en az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter içermelidir.',
  'profile.passMatchMessage': 'Şifreler aynı olmalıdır.',
  'profile.initialPasswordAlertTitle': 'Şifrenizi Güncelleyin!',
  'profile.initialPasswordAlertMessage':
    'Sistem tarafından atanmış geçici şifreyi kullanıyorsunuz. Şifrenizi güncellemenizi tavsiye ederiz.',
  'profile.updatePassword': '(Şifreyi güncelle)',
  'profile.changePasswordSuccess': 'Şifreniz başarıyla değiştirilmiştir.',
  'profile.updateSuccess': 'Profiliniz başarıyla güncellendi!',
};
