export default {
  // Generic
  'generic.add': '입력',
  'generic.cancel': '취소',

  // BlockType
  'components.controls.blocktype.h1': '제목1',
  'components.controls.blocktype.h2': '제목2',
  'components.controls.blocktype.h3': '제목3',
  'components.controls.blocktype.h4': '제목4',
  'components.controls.blocktype.h5': '제목5',
  'components.controls.blocktype.h6': '제목6',
  'components.controls.blocktype.blockquote': '인용',
  'components.controls.blocktype.code': 'Code',
  'components.controls.blocktype.blocktype': '블록',
  'components.controls.blocktype.normal': '표준',

  // Color Picker
  'components.controls.colorpicker.colorpicker': '색상 선택',
  'components.controls.colorpicker.text': '글꼴색',
  'components.controls.colorpicker.background': '배경색',

  // Embedded
  'components.controls.embedded.embedded': '임베드',
  'components.controls.embedded.embeddedlink': '임베드 링크',
  'components.controls.embedded.enterlink': '주소를 입력하세요',

  // Emoji
  'components.controls.emoji.emoji': '이모지',

  // FontFamily
  'components.controls.fontfamily.fontfamily': '글꼴',

  // FontSize
  'components.controls.fontsize.fontsize': '글꼴 크기',

  // History
  'components.controls.history.history': '히스토리',
  'components.controls.history.undo': '실행 취소',
  'components.controls.history.redo': '다시 실행',

  // Image
  'components.controls.image.image': '이미지',
  'components.controls.image.fileUpload': '파일 업로드',
  'components.controls.image.byURL': '주소',
  'components.controls.image.dropFileText':
    '클릭하거나 파일을 드롭하여 업로드하세요',

  // Inline
  'components.controls.inline.bold': '굵게',
  'components.controls.inline.italic': '기울임꼴',
  'components.controls.inline.underline': '밑줄',
  'components.controls.inline.strikethrough': '취소선',
  'components.controls.inline.monospace': '고정 너비',
  'components.controls.inline.superscript': '위 첨자',
  'components.controls.inline.subscript': '아래 첨자',

  // Link
  'components.controls.link.linkTitle': '링크 제목',
  'components.controls.link.linkTarget': '링크 타겟',
  'components.controls.link.linkTargetOption': '새창으로 열기',
  'components.controls.link.link': '링크',
  'components.controls.link.unlink': '링크 제거',

  // List
  'components.controls.list.list': '리스트',
  'components.controls.list.unordered': '일반 리스트',
  'components.controls.list.ordered': '순서 리스트',
  'components.controls.list.indent': '들여쓰기',
  'components.controls.list.outdent': '내어쓰기',

  // Remove
  'components.controls.remove.remove': '삭제',

  // TextAlign
  'components.controls.textalign.textalign': '텍스트 정렬',
  'components.controls.textalign.left': '왼쪽',
  'components.controls.textalign.center': '중앙',
  'components.controls.textalign.right': '오른쪽',
  'components.controls.textalign.justify': '양쪽',

  // New Examod localizations
  'components.controls.image.modal.title': 'Image Uploader',
  'components.controls.image.modal.description':
    'Browse or drag the image you want to upload',
  'components.controls.image.modal.content.image.or': 'or',
  'components.controls.image.modal.content.image.btn': 'Browse Files',
  'components.controls.image.modal.content.image.attachment':
    'Attachments should not exceed 2 mb',
  'components.controls.image.modal.content.image.supported':
    'Supported file types: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt Text',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Image alt text',
  'components.controls.image.modal.content.input.scale.label': 'Scale (%)',
  'components.controls.image.modal.contnet.url.address.label': '*URL (Adress)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Add image from address',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Clear Highlight',
  'components.controls.color.picker.highlight.black': 'Black Highlight',
  'components.controls.color.picker.highlight.grey': 'Grey Highlight',
  'components.controls.color.picker.highlight.red': 'Red Highlight',
  'components.controls.color.picker.highlight.green': 'Green Highlight',
  'components.controls.color.picker.highlight.blue': 'Blue Highlight',
  'components.controls.color.picker.highlight.yellow': 'Yellow Highlight',
  'components.controls.color.picker.highlight.examod': 'Examod Highlight',
  'components.controls.color.picker.highlight.purple': 'Purple Highlight',
  'components.controls.color.picker.highlight.pink': 'Pink Highlight',
};
