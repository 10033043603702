export default {
  // Generic
  'generic.add': '新增',
  'generic.cancel': '取消',

  // BlockType
  'components.controls.blocktype.h1': '標題1',
  'components.controls.blocktype.h2': '標題2',
  'components.controls.blocktype.h3': '標題3',
  'components.controls.blocktype.h4': '標題4',
  'components.controls.blocktype.h5': '標題5',
  'components.controls.blocktype.h6': '標題6',
  'components.controls.blocktype.blockquote': '引用',
  'components.controls.blocktype.code': '程式碼',
  'components.controls.blocktype.blocktype': '樣式',
  'components.controls.blocktype.normal': '正文',

  // Color Picker
  'components.controls.colorpicker.colorpicker': '選色器',
  'components.controls.colorpicker.text': '文字',
  'components.controls.colorpicker.background': '背景',

  // Embedded
  'components.controls.embedded.embedded': '內嵌',
  'components.controls.embedded.embeddedlink': '內嵌網頁',
  'components.controls.embedded.enterlink': '輸入網頁地址',

  // Emoji
  'components.controls.emoji.emoji': '表情符號',

  // FontFamily
  'components.controls.fontfamily.fontfamily': '字體',

  // FontSize
  'components.controls.fontsize.fontsize': '字體大小',

  // History
  'components.controls.history.history': '歷史紀錄',
  'components.controls.history.undo': '復原',
  'components.controls.history.redo': '重做',

  // Image
  'components.controls.image.image': '圖片',
  'components.controls.image.fileUpload': '檔案上傳',
  'components.controls.image.byURL': '網址',
  'components.controls.image.dropFileText': '點擊或拖曳檔案上傳',

  // Inline
  'components.controls.inline.bold': '粗體',
  'components.controls.inline.italic': '斜體',
  'components.controls.inline.underline': '底線',
  'components.controls.inline.strikethrough': '刪除線',
  'components.controls.inline.monospace': '等寬字體',
  'components.controls.inline.superscript': '上標',
  'components.controls.inline.subscript': '下標',

  // Link
  'components.controls.link.linkTitle': '超連結',
  'components.controls.link.linkTarget': '輸入連結位址',
  'components.controls.link.linkTargetOption': '在新視窗打開連結',
  'components.controls.link.link': '連結',
  'components.controls.link.unlink': '刪除連結',

  // List
  'components.controls.list.list': '列表',
  'components.controls.list.unordered': '項目符號',
  'components.controls.list.ordered': '編號',
  'components.controls.list.indent': '增加縮排',
  'components.controls.list.outdent': '減少縮排',

  // Remove
  'components.controls.remove.remove': '清除格式',

  // TextAlign
  'components.controls.textalign.textalign': '文字對齊',
  'components.controls.textalign.left': '文字向左對齊',
  'components.controls.textalign.center': '文字置中',
  'components.controls.textalign.right': '文字向右對齊',
  'components.controls.textalign.justify': '兩端對齊',

  // New Examod localizations
  'components.controls.image.modal.title': 'Image Uploader',
  'components.controls.image.modal.description':
    'Browse or drag the image you want to upload',
  'components.controls.image.modal.content.image.or': 'or',
  'components.controls.image.modal.content.image.btn': 'Browse Files',
  'components.controls.image.modal.content.image.attachment':
    'Attachments should not exceed 2 mb',
  'components.controls.image.modal.content.image.supported':
    'Supported file types: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt Text',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Image alt text',
  'components.controls.image.modal.content.input.scale.label': 'Scale (%)',
  'components.controls.image.modal.contnet.url.address.label': '*URL (Adress)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Add image from address',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Clear Highlight',
  'components.controls.color.picker.highlight.black': 'Black Highlight',
  'components.controls.color.picker.highlight.grey': 'Grey Highlight',
  'components.controls.color.picker.highlight.red': 'Red Highlight',
  'components.controls.color.picker.highlight.green': 'Green Highlight',
  'components.controls.color.picker.highlight.blue': 'Blue Highlight',
  'components.controls.color.picker.highlight.yellow': 'Yellow Highlight',
  'components.controls.color.picker.highlight.examod': 'Examod Highlight',
  'components.controls.color.picker.highlight.purple': 'Purple Highlight',
  'components.controls.color.picker.highlight.pink': 'Pink Highlight',
};
