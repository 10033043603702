import React from "react";

import { Label, Input } from "reactstrap";

const AnswerCheckbox = (props) => {
    return (
        <Label className="answer-checkbox-container">
            <Input className="answer-checkbox" type="checkbox" {...props} />
            <span className="answer-choice-char">{props.text}</span>
        </Label>
    );
};

export default AnswerCheckbox;
