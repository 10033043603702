// ** React Imports
import React, { useState, useEffect } from 'react';
// ** Third Party Components
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Spinner from '../../../components/@vuexy/spinner/Loading-spinner';
import { Trash, MoreVertical, Plus } from 'react-feather';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { EditorState, ContentState } from 'draft-js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useParams } from 'react-router';
import htmlToDraft from 'html-to-draftjs';
import Dropzone from 'react-dropzone';

// ** Custom Components
import { MiniEditor } from '../../../components/react-draft/index';
import PrimaryButton from '../../../components/examod/buttons/primaryButton';
import SecondaryButton from '../../../components/examod/buttons/secondaryButton';
import CancelButton from '../../../components/examod/buttons/custom/cancelButton';
import ExamodEditor from '../../../components/examod/editor/ExamodEditor';
import NoOption from '../../../components/examod/emptyList/NoOption';
import Sidebar from '../../../components/@vuexy/sidebar';
import AnswerCheckbox from '../../../components/examod/checkbox/AnswerCheckbox';
import ConfirmAlert from '../../../components/examod/alert/ConfirmAlert';
import ProgressBar from '../../../components/examod/progress/ProgressBar';
import QuestionAttributes from '../QuestionAttributes';
import DropzoneContent from '../attachment/DropzoneContent';
import AttachmentFileList from '../attachment/AttachmentFileList';
import CourseSelection from './CourseSelection';

// ** Utility imports
import { selectThemeColors } from '../../../utility/themeUtil';
import { checkResult } from '../../../utility/resultHandler';
import { toastSuccess, toastError } from '../../../utility/toastUtil';
import { isNullOrEmpty } from '../../../utility/collectionUtil';
import {
  htmlToText,
  isNullOrEmpty as isStrNullOrEmpty,
} from '../../../utility/stringUtil';

// ** Domain Imports
import {
  acceptedAttachmentExtensions,
  attachmentUploadingContinue,
  getAttachmentName,
  getOptionError,
  getSeperatedFileName,
  getTotalAttachmentSize,
  getUploadedFiles,
  handleZoomOptionImg,
  isOptionEmpty,
  isOptionExceedLimit,
  isQuestionOptionInvalid,
  maxAttachementFileSize,
  onDropRejected,
  setAllOptionsFocused,
  durationOptions,
  orderAnswers,
  attachementFileTypes,
  MAX_NUMBER_OF_QUESTION_OPTION,
} from '../../../domain/Question';
import { hasQuestionContentError } from '../../../domain/GroupedQuestion';
import { history } from '../../../history';

// ** Repository
import {
  getQuestionMeta,
  addQuestion,
  getQuestion,
  uploadAttachement,
} from '../../../data/QuestionRepository';
import { addTopic } from '../../../data/TopicRepository';
import { fetchCourseList } from '../../../data/CoursesRepository';

// ** Styles
import '../../../assets/scss/react/libs/react-select/_react-select.scss';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import '../../../assets/scss/plugins/extensions/editor.scss';
import '../../../assets/scss/pages/add-question.scss';
import '../../../assets/scss/components/editor.scss';

// ** Analytics Imports
import { trackEvent } from '../../../analytics/analyticsTracker';
import { EventNames } from '../../../analytics/analyticsConstants';
import { generalStatuses, getActiveItems } from '../../../domain/General';

// ** Config Imports
import { MAX_UPLOAD_LIMIT_MB } from '../../../configs/systemConfig';
import ImageZoomModal from '../../../components/examod/sessionPreview/ImageZoomModal';
import { REGEX_IMG_TAG } from '../../../constants/validation';
import { navigateToCourse } from '../../../Navigator';

const CreateMcQuestion = ({
  toggleSidebar,
  isOpen,
  questionId,
  isDuplicate,
  setUpdateQuestionList,
  setEdittedQuestionId,
  isQuickAcion,
}) => {
  const intl = useIntl();
  const { register, errors, handleSubmit, reset, formState, trigger } =
    useForm();
  const { isSubmitted } = formState;
  let { unitId } = useParams();

  // ** Default states
  const defaultAnswers = [
    { id: 0, content: '', onlyImageAnswer: false },
    { id: 1, content: '', onlyImageAnswer: false },
    { id: 2, content: '', onlyImageAnswer: false },
    { id: 3, content: '', onlyImageAnswer: false },
    { id: 4, content: '', onlyImageAnswer: false },
  ];

  const state = EditorState.createEmpty();
  const QUESTION_MAX_LEN = 5000;

  // ** State vars
  const [isLoading, setIsLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);
  const [editorState, setEditorState] = useState(state);
  const [keywords, setKeywords] = useState([]);
  const [learningObj, setLearningObj] = useState([]);
  const [topics, setTopics] = useState([]);
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState(defaultAnswers);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [duration, setDuration] = useState(0);
  const [source, setSource] = useState('');
  const [topic, setTopic] = useState(null);
  const [answerId, setAnswerId] = useState(5);
  const [isEditorLengthValid, setIsEditorLengthValid] = useState(true);
  const [defaultQuestionData, setDefaultQuestionData] = useState();
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [attachmentUploaded, setAttachmentUploaded] = useState([]);
  const [attachmentErrors, setAtttachmentErrors] = useState([]);
  const [attachmentIndex, setAttachmentIndex] = useState(1);
  const [optionsError, setOptionsError] = useState({});
  const [optionsFocused, setOptionsFocused] = useState({});
  const [isResetAttributes, setIsResetAttributes] = useState(false);
  const [isResetAnswers, setIsResetAnswers] = useState(false);
  const [inputsTouched, setInputsTouched] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);
  const [questionInfo, setQuestionInfo] = useState({});
  const [canceledAttachments, setCanceledAttachments] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [courseList, setCourseList] = useState([]);
  const [isTouchedCouseRelated, setIsTouchedCouseRelated] = useState(false);
  const [openImageZoomModal, setOpenImageZoomModal] = useState(false);
  const [zoomedImg, setZoomedImg] = useState('');
  const needCourseSelection = isQuickAcion && !selectedCourse;
  const [touched, setTouched] = useState(false);
  const [rootTouched, setRootTouched] = useState(false);
  const [IsstructuralSettings, setIsStructuralSettings] = useState(false);
  const [loForce, setLOForce] = useState(false);
  const [isOptionsInvalid, setIsOptionsInvalid] = useState(false);

  // ** Hooks
  useEffect(() => {
    if (isOpen && !needCourseSelection) {
      setUpdateQuestionList && setUpdateQuestionList(false);
      getCreateQuestionMeta(unitId);
    } else if (isOpen && needCourseSelection) {
      getCourseList();
    } else {
      setIsLoading(true);
    }
    resetFields();
  }, [isOpen]);

  useEffect(() => {
    handleZoomOptionImg(onClickQuestionImage, setOpenImageZoomModal);
  }, [answers, question]);

  // ** Gets Add Quesiton Meta
  const getCreateQuestionMeta = async (unitId) => {
    setIsLoading(true);
    let result = await getQuestionMeta('CREATE', unitId, 'MULTIPLE_CHOICE');
    if (checkResult(result)) {
      setMetaData(result.data);
      setTopics(
        mapOptions(
          result.data.topics.sort(
            (firstItem, secondItem) => firstItem.order - secondItem.order
          )
        )
      );
      setAttributes(getActiveItems(result.data.attributes));
      setKeywords(mapOptions(result.data.keywords));
      setLOForce(result.data.loForce);
      let activeObjectives = getActiveItems(result.data.learningObjectives);
      setLearningObj(
        mapOptions(
          activeObjectives.sort(
            (firstItem, secondItem) => firstItem.order - secondItem.order
          )
        )
      );

      getDefaultAttributes(result.data.attributes);
    }
    if (questionId) {
      setIsLoading(true);
      let result = await getQuestion(questionId);
      if (checkResult(result)) {
        let options = result.data.answers.map((ans) => ({
          ...ans,
          onlyImageAnswer: !isNullOrEmpty(ans.links),
        }));
        options = orderAnswers(options);
        setDefaultQuestionData(result.data);
        setQuestion(result.data.root);
        setAnswers(orderAnswers(options));
        setCorrectAnswer(getCorrectAnswer(result.data.answers));
        setDuration(
          durationOptions.findIndex(
            (option) => option.value === result.data.suggestedTime
          )
        );
        let attributes = result.data.attributeOptions.map(
          (option) => option.attribute
        );
        let newAttributes = attributes.map((item) => ({
          value: item.id,
          label: item.name,
          optionData: item.options,
          description: item.description,
        }));
        setSelectedAttributes(newAttributes);
        setSelectedFields(
          result.data.attributeOptions.map((option) => option.attributeOptions)
        );
        setSource(result.data.questionSource);
        setSelectedFields(mapFields(result.data.attributeOptions));
        setSelectedKeywords(mapOptions(result.data.keywords));
        if (!result.data.learningObjective.default) {
          setSelectedObjective({
            value: result.data.learningObjective.id,
            label: result.data.learningObjective.name,
          });
        }
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(htmlToDraft(result.data.root))
          )
        );
        setTopic({
          value: result.data.topic.id,
          label: result.data.topic.name,
        });
        setAnswerId(result.data.answers[0].id + 10);
        prepareAttachmentFileStates(result?.data?.attachments);
      }
    }
    setIsLoading(false);
  };

  const getCourseList = async () => {
    const result = await fetchCourseList();
    if (checkResult(result)) {
      const activeCourses = result?.data?.filter(
        (course) => course.status === generalStatuses.ACTIVE
      );
      setCourseList(activeCourses);
      setIsLoading(false);
    }
  };

  // ** Add question form submit
  const onSubmit = () => {
    let filledAnswers = getFilledAnswers();
    let isAttributeOptionsValid =
      selectedAttributes.length === selectedFields.length;
    let isFilledAnswerValid = filledAnswers?.some(
      (answer) => answer.correctAnswer
    );
    let isRequiredFieldsValid =
      question && !isNullOrEmpty(topic) && isEditorLengthValid;
    let loForceRequire = loForce && isNullOrEmpty(selectedObjective);
    setAllOptionsFocused(answers, setOptionsFocused);

    if (
      isAttributeOptionsValid &&
      !isNullOrEmpty(filledAnswers) &&
      isRequiredFieldsValid &&
      !hasQuestionContentError(question) &&
      !loForceRequire
    ) {
      if (isFilledAnswerValid) {
        createQuestion(filledAnswers);
      } else {
        toastError(intl.formatMessage({ id: 'questionAdd.answerWarning' }));
      }
    } else {
      toastError(intl.formatMessage({ id: 'questionAdd.warning' }));
    }
  };

  const isDuplicatedQuestionSame = (body) => {
    if (body.root !== defaultQuestionData.root) {
      return false;
    }
    if (body.topicId !== defaultQuestionData.topic.id) {
      return false;
    }
    if (body.learningObjectiveId !== defaultQuestionData.learningObjective.id) {
      return false;
    }
    if (body.questionSource !== defaultQuestionData.questionSource) {
      return false;
    }
    if (body.suggestedTime !== defaultQuestionData.suggestedTime) {
      return false;
    }
    if (!keywordsIsSame(defaultQuestionData.keywords, body.keywords)) {
      return false;
    }
    if (
      !fieldsIsSame(defaultQuestionData.attributeOptions, body.attributeOptions)
    ) {
      return false;
    }
    if (!answersIsSame(defaultQuestionData.answers, body.answers)) {
      return false;
    }

    return true;
  };

  const answersIsSame = (defaultData, newData) => {
    let optionIsSame = true;

    defaultData.sort(
      (firstItem, secondItem) =>
        firstItem.displayOrder - secondItem.displayOrder
    );
    newData.sort((firstItem, secondItem) => firstItem.order - secondItem.order);
    defaultData.forEach((element, index) => {
      if (
        element.content !== newData[index].content ||
        element.correctAnswer !== newData[index].correctAnswer
      ) {
        optionIsSame = false;
        return;
      }
    });
    return optionIsSame;
  };

  const fieldsIsSame = (defaultData, newData) => {
    let optionIsSame = true;
    newData.forEach((element) => {
      if (
        defaultData.find((x) => x?.attribute?.id === element?.fieldId)
          .attributeOption.id !== element.fieldOptionId
      ) {
        optionIsSame = false;
        return;
      }
    });
    return optionIsSame;
  };

  const keywordsIsSame = (defaultData, newData) => {
    if (defaultData.length !== newData.length) {
      return false;
    }

    let optionIsSame = true;
    let newDataIds = newData.map((item) => {
      return item.id;
    });
    defaultData.forEach((element) => {
      if (!newDataIds.includes(element.id)) {
        optionIsSame = false;
        return;
      }
    });
    return optionIsSame;
  };

  // ** Adds Question
  const createQuestion = (answers) => {
    let time = durationOptions[duration].value;
    let keywords = selectedKeywords
      ? selectedKeywords.map((keyword) => ({
          id: keyword.value,
          name: keyword.label,
        }))
      : [];
    let fields = selectedFields
      ? selectedFields.map((field) => ({
          attributeId: field?.fieldId,
          attributeOptionId: field?.value,
        }))
      : [];

    const attachmentIds = getUploadedFiles(
      attachmentUploaded,
      canceledAttachments,
      true
    );
    const isDuplicateAttachment =
      (!isNullOrEmpty(attachmentIds) && isDuplicate) || false;
    let body = {
      root: question,
      unitId: selectedCourse?.value || unitId,
      questionType: 'MULTIPLE_CHOICE',
      suggestedTime: time,
      answers: answers,
      attributeOptions: fields,
      keywords: keywords,
      learningObjectiveId: selectedObjective ? selectedObjective.value : null,
      status: 'ACTIVE',
      topicId: topic.value,
      questionSource: source,
      attachmentIds: attachmentIds,
      duplicateExistingAttachments: isDuplicateAttachment,
    };
    if (defaultQuestionData && isDuplicatedQuestionSame(body)) {
      toastError(intl.formatMessage({ id: 'questionAdd.duplicateWarning' }));
      return;
    }
    const isOptionExceedLimit = Object.entries(optionsError).some((option) =>
      option.includes(true)
    );
    if (isOptionExceedLimit) {
      toastError(intl.formatMessage({ id: 'questionAdd.maxOptionCharError' }));
      return;
    }
    if (
      attachmentUploadingContinue(
        attachments,
        attachmentUploaded,
        attachmentErrors,
        canceledAttachments
      ) ||
      !isNullOrEmpty(attachmentErrors)
    ) {
      toastError(intl.formatMessage({ id: 'questionAdd.attachmentError' }));
      return;
    }

    setQuestionInfo(body);
    setShowSaveConfirmation(true);
  };

  const saveQuestion = async () => {
    setIsLoading(true);
    setShowSaveConfirmation(false);
    let result = await addQuestion(questionInfo);
    if (checkResult(result)) {
      if (setEdittedQuestionId) {
        setEdittedQuestionId(result.data.id);
      }
      toggleSidebar('updateQuestions');
      setShowDiscard(false);
      toastSuccess(intl.formatMessage({ id: 'questionAdd.success' }));
      if (isQuickAcion) {
        navigateToCourse(selectedCourse?.value, 'questions');
        history.go(0); //reloads page
      }
    }
    setIsLoading(false);
  };

  const getDefaultAttributes = (attributes) => {
    if (attributes) {
      const defaultAttributes = [];
      attributes.forEach((attr) => {
        if (!isNullOrEmpty(attr.units)) {
          if (!isNullOrEmpty(attr)) {
            const attributeOption = {
              value: attr.id,
              label: attr.name,
              optionData: attr.options,
              description: attr.description,
            };
            defaultAttributes.push(attributeOption);
          }
        }
      });
      setSelectedAttributes(defaultAttributes);
      setAttributes(defaultAttributes);
    }
  };

  const mapOptions = (items) => {
    if (!isNullOrEmpty(items)) {
      return items.map((item) => ({ value: item.id, label: item.name }));
    }

    return [];
  };

  const mapFields = (items) => {
    if (!isNullOrEmpty(items)) {
      return items.map((item) => ({
        label: item.attributeOption.name,
        value: item.attributeOption.id,
        fieldId: item.attribute.id,
      }));
    }
    return [];
  };

  const getCorrectAnswer = (items) => {
    let [correct] = items.filter((item) => item.correctAnswer);
    return correct.id;
  };

  // ** Gets filled answers
  const getFilledAnswers = () => {
    let filledAnswers = answers.map((answer, index) => ({
      content: answer.content?.trim(),
      correctAnswer: correctAnswer === answer.id,
      order: index,
      onlyImageAnswer: REGEX_IMG_TAG.test(answer.content),
    }));
    filledAnswers = filledAnswers.filter(
      (answer) =>
        !isStrNullOrEmpty(htmlToText(answer.content)) || answer.onlyImageAnswer
    );
    if (filledAnswers.length < answers.length) {
      let emptyAnswers = answers.filter((answer) => !answer.content); // TODO: emre: this will be checked
      emptyAnswers = emptyAnswers.slice(0, 3 - filledAnswers.length);
      return null;
    }
    return filledAnswers;
  };

  // ** Adds new option
  const addOption = () => {
    if (answers.length == MAX_NUMBER_OF_QUESTION_OPTION) {
      setIsOptionsInvalid(true);
      toastError(intl.formatMessage({ id: 'questionAdd.addOptionError' }));
      return;
    }
    setAnswers((answers) => [...answers, { id: answerId, content: '' }]);
    setAnswerId(answerId + 1);
  };

  // ** Deletes the option
  const deleteOption = (index) => {
    let newOptions = JSON.parse(JSON.stringify(answers));
    newOptions.splice(index, 1);
    setAnswers(newOptions);
    setOptionsError((prev) => ({ ...prev, [answers[index].id]: false }));
    setIsOptionsInvalid(false);
  };

  // ** Reorders
  const reorder = (result) => {
    if (!result.destination) {
      return;
    }
    let newAnswers = JSON.parse(JSON.stringify(answers));
    const [removed] = newAnswers.splice(result.source.index, 1);
    newAnswers.splice(result.destination.index, 0, removed);
    setAnswers(newAnswers);
  };

  // ** Runs when option created
  const onCreateOption = (newOption, set, setSelected) => {
    set((options) => [...options, { label: newOption }]);
    setSelected((options) => [...options, { label: newOption }]);
  };

  const onCreateTopic = async (option) => {
    let result = await addTopic(
      option,
      option,
      selectedCourse?.value || unitId
    );
    if (checkResult(result)) {
      let newTopic = { value: result.data.id, label: result.data.name };
      setTopics((prev) => [...prev, newTopic]);
      setTopic(newTopic);
      setInputsTouched(true);
    }
  };

  // ** Chnges the answer
  const onChangeAnswer = (value, index, isImage = false) => {
    let newAnswers = JSON.parse(JSON.stringify(answers));
    newAnswers[index].content = value;
    newAnswers[index].onlyImageAnswer = isImage;
    setAnswers(newAnswers);
    setOptionsError((prev) => ({
      ...prev,
      ...getOptionError(newAnswers, index, optionsFocused),
    }));
  };

  // ** Sort data alphabetically
  const sortAlphabetically = (data) => {
    return data.sort((a, b) => a.label.localeCompare(b.label));
  };

  // ** Reset all fields
  const resetFields = () => {
    reset({}, { keepIsSubmitted: false });
    setEditorState(state);
    setQuestion('');
    setAnswers(defaultAnswers);
    setSource('');
    setSelectedFields([]);
    setSelectedKeywords([]);
    setSelectedObjective(null);
    setDuration(0);
    setTopic([]);
    setCorrectAnswer(null);
    setOptionsError({});
    setOptionsFocused({});
    setInputsTouched(false);
    setAttachmentIndex(1);
    setAttachments([]);
    setAttachmentUploaded([]);
    setAtttachmentErrors([]);
    setCanceledAttachments([]);
    setIsOptionsInvalid(false);
    setSelectedAttributes([]);
    getDefaultAttributes(metaData?.attributes);
  };

  const resetCourseRelatedFields = () => {
    setSelectedCourse(null);
    setKeywords([]);
    setLearningObj([]);
    setTopics([]);
    setAttributes([]);
    setTopic([]);
    setSelectedAttributes([]);
    setSelectedFields([]);
    setSelectedKeywords([]);
    setSelectedObjective(null);
    setIsTouchedCouseRelated(false);
  };

  const isClearCourseRelatedFields = () => {
    return (
      isNullOrEmpty(topic) &&
      isNullOrEmpty(selectedAttributes) &&
      isNullOrEmpty(selectedFields) &&
      isNullOrEmpty(selectedKeywords) &&
      isNullOrEmpty(selectedObjective)
    );
  };

  const onChangeFieldOption = (option, fieldId, index) => {
    let fields = [...selectedFields];
    if (defaultQuestionData) {
      let fieldIndex = fields.findIndex(
        (option) => option?.fieldId === fieldId
      );
      if (fieldIndex < 0) {
        fields[index] = option;
      } else {
        fields[fieldIndex] = option;
      }
    } else {
      fields[index] = option;
    }
    setSelectedFields(fields);
  };

  const onAttributeChange = (options, data) => {
    if (data.action === 'remove-value') {
      if (data.removedValue) {
        let newSelected = selectedFields.filter(
          (field) => field.fieldId !== data.removedValue.value
        );
        setSelectedFields(newSelected);
      } else {
        setSelectedFields([]);
      }
    } else if (data.action === 'clear') {
      setSelectedFields([]);
    }
    setSelectedAttributes(options ? options : []);
  };

  const formatCreateLabel = (inputValue) => (
    <>
      <FormattedMessage id={'questionAdd.create'} /> "{inputValue}"
    </>
  );

  const handleToggleSidebar = () => {
    if (inputsTouched) {
      setShowDiscard(true);
    } else {
      toggleSidebar();
      setShowDiscard(false);
    }
  };

  const showConfirmation = () => {
    return showDiscard || showSaveConfirmation;
  };

  const cancelConfirmation = () => {
    setShowDiscard(false);
    setShowSaveConfirmation(false);
  };

  const onConfirmConfirmation = () => {
    if (showDiscard) {
      toggleSidebar();
      setShowDiscard(false);
    } else if (showSaveConfirmation) {
      saveQuestion();
    }
  };

  const confirmationMessage = () => {
    if (showDiscard) {
      return <FormattedMessage id={'questionAdd.discardConfirmationMessage'} />;
    } else if (showSaveConfirmation) {
      return <FormattedMessage id={'questionAdd.saveConfirmationMessage'} />;
    }
  };

  const confirmationTitle = () => {
    if (showDiscard) {
      return <FormattedMessage id={'questionAdd.discardConfirmationTitle'} />;
    } else if (showSaveConfirmation) {
      return <FormattedMessage id={'questionAdd.continueTitle'} />;
    }
  };

  const onFocusOption = (index) => {
    const questionId = answers[index].id;
    setOptionsFocused((prev) => ({ ...prev, [questionId]: true }));
  };

  const prepareAttachmentFileStates = (attachments) => {
    if (!isNullOrEmpty(attachments)) {
      let index = attachmentIndex;
      const revisedAttachments = attachments.map((attch) => ({
        id: attch.id,
        tempId: index++,
        name: attch.name,
        type: `${attch.fileType}/${attch.extension}`.toLowerCase(),
        size: attch.size,
        generalType: attch.fileType,
      }));
      setAttachments(revisedAttachments);
      setAttachmentUploaded(revisedAttachments);
      setAttachmentIndex(index);
    }
  };

  const handleAttachmentUpload = async (files, retriedId) => {
    let tempId = attachmentIndex;
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      const [fileName, extension] = getSeperatedFileName(file.name);
      const result = await uploadAttachement(
        extension.toUpperCase(),
        getAttachmentName(fileName),
        formData
      );
      if (checkResult(result)) {
        const uploadResult = { ...result.data, tempId: retriedId || tempId };
        setAttachmentUploaded((prev) => [...prev, { ...uploadResult }]);
      } else {
        setAtttachmentErrors((prev) => [
          ...prev,
          { file: file, tempId: retriedId || tempId },
        ]);
      }
      tempId++;
    }
  };

  // ** On file drop to the attachment dropzone
  const onFileDrop = (files) => {
    const droppedFilesSize = files.reduce(
      (prev, current) => prev + current.size,
      0
    );
    if (
      getTotalAttachmentSize(attachments) + droppedFilesSize >
      maxAttachementFileSize
    ) {
      toastError(
        intl.formatMessage({ id: 'questionAdd.attachmentSizeWarning' })
      );
      return;
    }
    let tempId = attachmentIndex;
    for (const file of files) {
      const [fileName, extension] = getSeperatedFileName(file.name);
      const generalType = attachementFileTypes[file.type.split('/')[0]];
      const fileInfo = {
        name: getAttachmentName(fileName),
        size: file.size,
        generalType: generalType,
        type: `${generalType}/${extension}`.toLowerCase(),
        tempId: tempId,
      };
      setAttachments((prev) => [...prev, fileInfo]);
      tempId++;
    }
    handleAttachmentUpload(files);
    setAttachmentIndex(tempId);
    setInputsTouched(true);
  };

  // ** Removes attachment item from all related arrays
  const removeAttachmentItem = (file) => {
    setAttachments((prev) =>
      prev.filter((attc) => attc.tempId !== file.tempId)
    );
    setAttachmentUploaded((prev) =>
      prev.filter((attc) => attc.tempId !== file.tempId)
    );
    setAtttachmentErrors((prev) =>
      prev.filter((attc) => attc.tempId !== file.tempId)
    );
  };

  const cancelAttachmentUpload = (file) => {
    setCanceledAttachments((prev) => [...prev, file]);
    setAttachments((prev) =>
      prev.filter((attc) => attc.tempId !== file.tempId)
    );
    setAtttachmentErrors((prev) =>
      prev.filter((attc) => attc.tempId !== file.tempId)
    );
  };

  const onClickUnitRelatedFields = () => {
    if (needCourseSelection) {
      toastError(intl.formatMessage({ id: 'questionAdd.selectCourseWarn' }));
      return;
    }
  };

  const onClickQuestionImage = (imgInfo) => {
    setZoomedImg(imgInfo);
    setOpenImageZoomModal(true);
  };

  const toggleImageZoom = () => {
    setOpenImageZoomModal((prev) => !prev);
  };
  return (
    <React.Fragment>
      <Sidebar
        size="xl"
        open={isOpen}
        title={
          isDuplicate
            ? intl.formatMessage({
                id: 'questionAdd.duplicateTitle',
              })
            : intl.formatMessage({ id: 'questionAdd.title' })
        }
        headerClassName="bg-info rounded-0 mb-1"
        contentClassName="add-question-content pt-0"
        toggleSidebar={handleToggleSidebar}
      >
        <Form action="/" onSubmit={handleSubmit(onSubmit)}>
          {isQuickAcion ? (
            <CourseSelection
              courses={courseList}
              isSubmitted={isSubmitted}
              setSelectedCourse={setSelectedCourse}
              selectedCourse={selectedCourse}
              getCreateQuestionMeta={getCreateQuestionMeta}
              resetCourseRelatedFields={resetCourseRelatedFields}
              disabledCourseSelection={
                isTouchedCouseRelated && !isClearCourseRelatedFields()
              }
              setInputTouched={setInputsTouched}
            />
          ) : null}
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Card className="add-question-question-content-card h-100">
                <CardBody className="pt-0">
                  <CardHeader className="d-block pl-0 mb-2">
                    <CardTitle>
                      <FormattedMessage
                        id={'questionAdd.questionContentTitle'}
                      />
                    </CardTitle>
                    <CardSubtitle className="mt-0">
                      <FormattedMessage
                        id={'questionAdd.questionContentSubtitle'}
                      />
                    </CardSubtitle>
                  </CardHeader>
                  <div id="user-profile">
                    <div id="profile-info">
                      <Row className="d-flex justify-content-between">
                        <Col sm="12">
                          <FormGroup onClick={onClickUnitRelatedFields}>
                            <Label>
                              <span className="text-danger">*</span>
                              <FormattedMessage
                                id={'questionAdd.topics'}
                              />{' '}
                            </Label>
                            <CreatableSelect
                              formatCreateLabel={formatCreateLabel}
                              isClearable
                              theme={selectThemeColors}
                              placeholder={intl.formatMessage({
                                id: 'questionAdd.topicPlaceholder',
                              })}
                              className={`basic-single, react-select ${
                                isSubmitted && isNullOrEmpty(topic)
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              classNamePrefix="select"
                              noOptionsMessage={NoOption}
                              isDisabled={needCourseSelection}
                              options={topics}
                              value={topic}
                              onChange={(option) => {
                                setTopic(option);
                                setInputsTouched(true);
                                setIsTouchedCouseRelated(true);
                              }}
                              onCreateOption={(newOption) =>
                                onCreateTopic(newOption)
                              }
                            />
                            {isSubmitted && isNullOrEmpty(topic) ? (
                              <span className="text-error">
                                {intl.formatMessage({
                                  id: 'questionAdd.requiredMessage',
                                })}
                              </span>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label>
                              <span className="text-danger"> *</span>
                              <FormattedMessage id={'questionAdd.root'} />
                            </Label>
                            <ExamodEditor
                              handleZoomImg={() =>
                                handleZoomOptionImg(
                                  onClickQuestionImage,
                                  setOpenImageZoomModal
                                )
                              }
                              innerRef={register({
                                required: true,
                              })}
                              editorClassName={classnames({
                                'examod-editor': true,
                                'border-error':
                                  rootTouched &&
                                  (htmlToText(question).length >=
                                    QUESTION_MAX_LEN ||
                                    !isEditorLengthValid),
                                invalid:
                                  rootTouched &&
                                  isSubmitted &&
                                  hasQuestionContentError(question),
                              })}
                              setText={setQuestion}
                              editorState={editorState}
                              setIsEditorLengthValid={setIsEditorLengthValid}
                              setEditorState={(state) => {
                                setRootTouched(true);
                                setEditorState(state);
                              }}
                            />
                            {htmlToText(question).length >= QUESTION_MAX_LEN ||
                            !isEditorLengthValid ? (
                              <span className="text-error">
                                {intl.formatMessage({
                                  id: 'questionAdd.editorMaxMessage',
                                })}
                              </span>
                            ) : null}
                            {isSubmitted &&
                            hasQuestionContentError(question, 'min') ? (
                              <span className="text-error">
                                {intl.formatMessage({
                                  id: 'questionAdd.editorMinMessage',
                                })}
                              </span>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="9">
                          <FormGroup>
                            <Label>
                              <span className="text-danger"> *</span>
                              <FormattedMessage id={'questionAdd.options'} />
                              <span>
                                <i>
                                  <FormattedMessage
                                    id={'questionAdd.correctOption'}
                                  />
                                </i>
                              </span>
                            </Label>
                            <DragDropContext onDragEnd={reorder}>
                              <Droppable droppableId="droppable">
                                {(provided) => (
                                  <div ref={provided.innerRef}>
                                    {answers.map((answer, index) => (
                                      <div key={answer.id} className="mb-50">
                                        <Draggable
                                          key={answer.id}
                                          draggableId={answer.id.toString()}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              className="option align-items-center"
                                            >
                                              <InputGroup className="option-group">
                                                <InputGroupAddon addonType="prepend">
                                                  <AnswerCheckbox
                                                    checked={
                                                      correctAnswer ===
                                                      answer.id
                                                    }
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        setCorrectAnswer(
                                                          answer.id
                                                        );
                                                      } else {
                                                        setCorrectAnswer(null);
                                                      }
                                                    }}
                                                    bsSize="medium"
                                                    text={String.fromCharCode(
                                                      97 + index
                                                    ).toUpperCase()}
                                                  />
                                                </InputGroupAddon>
                                                <MiniEditor
                                                  innerRef={register({
                                                    required: true,
                                                  })}
                                                  index={index}
                                                  containerClassName={classnames(
                                                    {
                                                      'border-success':
                                                        correctAnswer ===
                                                        answer.id,
                                                      'is-invalid':
                                                        touched &&
                                                        ((isSubmitted &&
                                                          isQuestionOptionInvalid(
                                                            answer.content
                                                          )) ||
                                                          optionsError[
                                                            answer.id
                                                          ]),
                                                    }
                                                  )}
                                                  isImage={
                                                    answer.onlyImageAnswer
                                                  }
                                                  textValue={answer.content}
                                                  onChange={(
                                                    htmlText,
                                                    isImage
                                                  ) => {
                                                    isResetAnswers &&
                                                      setIsResetAnswers(false);
                                                    setTouched(true);
                                                    onChangeAnswer(
                                                      htmlText,
                                                      index,
                                                      isImage
                                                    );
                                                  }}
                                                  onFocus={() => {
                                                    onFocusOption(index);
                                                    setInputsTouched(true);
                                                  }}
                                                  name={index}
                                                  reset={isResetAnswers}
                                                />
                                              </InputGroup>
                                              <span
                                                className="move-icon d-flex ml-25"
                                                {...provided.dragHandleProps}
                                              >
                                                <MoreVertical size={18} />
                                                <MoreVertical size={18} />
                                              </span>
                                              {answers.length > 3 ? (
                                                <Trash
                                                  className="delete-icon ml-25"
                                                  size={18}
                                                  onClick={() =>
                                                    deleteOption(index)
                                                  }
                                                />
                                              ) : null}
                                            </div>
                                          )}
                                        </Draggable>
                                        {optionsError?.[answer.id] &&
                                          isOptionExceedLimit(
                                            answer.content
                                          ) && (
                                            <span className="text-error">
                                              {intl.formatMessage({
                                                id: 'questionAdd.maxMessage',
                                              })}
                                            </span>
                                          )}
                                        {optionsError?.[answer.id] &&
                                          isOptionEmpty(answer.content) && (
                                            <span className="text-error">
                                              {intl.formatMessage({
                                                id: 'questionAdd.minMessage',
                                              })}
                                            </span>
                                          )}
                                      </div>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                            <InputGroup className="mb-50 question-add-area">
                              <InputGroupAddon addonType="prepend">
                                <AnswerCheckbox
                                  bsSize="medium"
                                  text={<Plus />}
                                  onClick={addOption}
                                  disabled={isOptionsInvalid}
                                />
                              </InputGroupAddon>
                              <Input
                                className={classnames('add-input-area', {
                                  wider: answers.length <= 3,
                                })}
                                defaultValue={intl.formatMessage({
                                  id: 'questionAdd.addOption',
                                })}
                                disabled
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="question-details-card">
                <CardBody className="pt-0">
                  <CardHeader className="d-block pl-0 mb-2">
                    <CardTitle>
                      <FormattedMessage
                        id={'questionAdd.questionDetailsTitle'}
                      />
                    </CardTitle>
                    <CardSubtitle className="mt-0">
                      <FormattedMessage
                        id={'questionAdd.questionDetailsSubtitle'}
                      />
                    </CardSubtitle>
                  </CardHeader>
                  <div id="user-profile">
                    <div id="profile-info">
                      <Row className="question-tools mb-2">
                        {/* <Col md="6">
                        <FormGroup>
                          <Label>
                            <FormattedMessage
                              id={'questionAdd.addQuestionTools'}
                            />
                          </Label>
                          <div className="d-flex align-items-center mb-1">
                            <ExamodCheckbox disabled />
                            <span>
                              <FormattedMessage
                                id={'questionAdd.scientificCalculator'}
                              />
                            </span>
                          </div>
                          <Label>
                            <div className="text-nowrap">
                              <FormattedMessage id={'questionAdd.duration'} />
                            </div>
                          </Label>
                          <NumberInput
                            set={setDuration}
                            durationOptions={durationOptions}
                            index={duration}
                          />
                        </FormGroup>
                      </Col> */}
                        <Col md="6">
                          <Label className="question-attachment-dropzone-label">
                            <div className="text-nowrap">
                              <FormattedMessage
                                id={'questionAdd.attachments'}
                              />{' '}
                              <span className="attachment-uploaded-file-size-info">
                                (
                                {getTotalAttachmentSize(
                                  getUploadedFiles(
                                    attachmentUploaded,
                                    canceledAttachments
                                  ),
                                  true
                                )}{' '}
                                MB / {MAX_UPLOAD_LIMIT_MB} MB)
                              </span>
                            </div>
                          </Label>
                          <ProgressBar
                            end={maxAttachementFileSize}
                            progress={getTotalAttachmentSize(
                              getUploadedFiles(
                                attachmentUploaded,
                                canceledAttachments
                              )
                            )}
                          />
                          <Dropzone
                            onDrop={onFileDrop}
                            noClick
                            accept={acceptedAttachmentExtensions}
                            maxSize={maxAttachementFileSize}
                            onDropRejected={(files) =>
                              onDropRejected(files, intl)
                            }
                          >
                            {({ getRootProps, getInputProps, open }) => (
                              <div>
                                <div
                                  {...getRootProps({
                                    className: 'dropzone',
                                  })}
                                >
                                  <input {...getInputProps()} />
                                  <DropzoneContent open={open} />
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                        <Col md="6">
                          <AttachmentFileList
                            attachments={attachments}
                            attachmentUploaded={attachmentUploaded}
                            attachmentErrors={attachmentErrors}
                            canceledAttachments={canceledAttachments}
                            removeAttachmentItem={removeAttachmentItem}
                            cancelAttachmentUpload={cancelAttachmentUpload}
                          />
                        </Col>
                      </Row>
                      <Row className="attributes-section mx-n2 px-50 mb-2 pt-1">
                        <Col md="6" sm="12">
                          <Label>
                            <div className="text-nowrap">
                              <FormattedMessage
                                id={'questionAdd.attributeInfo'}
                              />
                            </div>
                          </Label>
                          <FormGroup onClick={onClickUnitRelatedFields}>
                            <Select
                              isMulti={true}
                              theme={selectThemeColors}
                              className="select basic-single"
                              classNamePrefix="select"
                              placeholder={intl.formatMessage({
                                id: 'questionAdd.select',
                              })}
                              isDisabled={needCourseSelection}
                              value={selectedAttributes}
                              options={attributes}
                              onChange={(options, data) => {
                                onAttributeChange(options, data);
                                setInputsTouched(true);
                                setIsTouchedCouseRelated(true);
                              }}
                              noOptionsMessage={NoOption}
                            />
                          </FormGroup>
                        </Col>
                        <Col
                          md="6"
                          sm="12"
                          className="pr-0 pr-md-1"
                          onClick={onClickUnitRelatedFields}
                        >
                          <FormGroup>
                            <QuestionAttributes
                              isSubmitted={isSubmitted}
                              selectedAttributes={selectedAttributes}
                              selectedFields={selectedFields}
                              onChangeFieldOption={onChangeFieldOption}
                              isReset={isResetAttributes}
                              setIsReset={setIsResetAttributes}
                              setInputsTouched={setInputsTouched}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup onClick={onClickUnitRelatedFields}>
                            <Label>
                              <FormattedMessage id={'questionAdd.keywords'} />
                            </Label>
                            <CreatableSelect
                              formatCreateLabel={formatCreateLabel}
                              isMulti
                              theme={selectThemeColors}
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder={intl.formatMessage({
                                id: 'questionAdd.select',
                              })}
                              isDisabled={needCourseSelection}
                              options={sortAlphabetically(keywords)}
                              value={selectedKeywords}
                              onChange={(options) => {
                                setSelectedKeywords(options);
                                setInputsTouched(true);
                                setIsTouchedCouseRelated(true);
                              }}
                              onCreateOption={(newOption) => {
                                onCreateOption(
                                  newOption,
                                  setKeywords,
                                  setSelectedKeywords
                                );
                                setInputsTouched(true);
                              }}
                              noOptionsMessage={NoOption}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup onClick={onClickUnitRelatedFields}>
                            <Label>
                              {loForce ? (
                                <span className="text-danger"> *</span>
                              ) : null}
                              <FormattedMessage id={'questionAdd.objectives'} />
                            </Label>
                            <Select
                              isClearable
                              theme={selectThemeColors}
                              className={classnames(
                                'basic-single react-select',
                                {
                                  'is-invalid':
                                    loForce &&
                                    isSubmitted &&
                                    isNullOrEmpty(selectedObjective),
                                }
                              )}
                              classNamePrefix="select"
                              placeholder={intl.formatMessage({
                                id: loForce
                                  ? 'questionAdd.select'
                                  : 'questionAdd.uncategorized',
                              })}
                              isDisabled={needCourseSelection}
                              options={learningObj}
                              value={selectedObjective}
                              onChange={(option) => {
                                setSelectedObjective(option);
                                setInputsTouched(true);
                                setIsTouchedCouseRelated(true);
                              }}
                              noOptionsMessage={NoOption}
                            />
                            {loForce &&
                            isSubmitted &&
                            isNullOrEmpty(selectedObjective) ? (
                              <span className="text-error">
                                {intl.formatMessage({
                                  id: 'questionAdd.requiredMessage',
                                })}
                              </span>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label>
                              <FormattedMessage id={'questionAdd.source'} />
                            </Label>
                            <Input
                              type="textarea"
                              name="answerSource"
                              value={source}
                              innerRef={register({
                                maxLength: 200,
                              })}
                              onChange={(e) => {
                                setSource(e.target.value);
                                setInputsTouched(true);
                              }}
                              className={classnames({
                                'is-invalid': errors['answerSource'],
                              })}
                              onInput={() => trigger('answerSource')}
                            />
                            {errors?.answerSource?.type === 'maxLength' && (
                              <span className="text-error">
                                {intl.formatMessage({
                                  id: 'questionAdd.answerSourceMax',
                                })}
                              </span>
                            )}
                            <div className="mt-75">
                              <i>
                                <FormattedMessage id={'questionAdd.required'} />
                              </i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="add-question-btns" sm="12">
                          <PrimaryButton
                            className="btn-accent"
                            textId="questionAdd.save"
                            onClick={() =>
                              trackEvent(
                                EventNames.addQuestionButtonAddQuestion
                              )
                            }
                          />
                          <SecondaryButton
                            textId="buttons.reset"
                            className="btn-danger-ba"
                            color="primary"
                            onClick={() => {
                              resetFields();
                              setIsResetAttributes(true);
                              setIsResetAnswers(true);
                              trackEvent(EventNames.resetButtonAddQuestion);
                            }}
                          />
                          <CancelButton
                            className="btn-outline-ba"
                            onClick={() => {
                              handleToggleSidebar();
                              trackEvent(EventNames.cancelButtonAddQuestion);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </>
          )}
        </Form>
      </Sidebar>
      <ConfirmAlert
        isShown={showConfirmation()}
        title={confirmationTitle()}
        message={confirmationMessage()}
        confirmBtnText={
          showDiscard ? (
            <FormattedMessage
              id={'questionAdd.discardConfirmationConfirmButton'}
            />
          ) : (
            <FormattedMessage id={'questionAdd.continue'} />
          )
        }
        cancelBtnText={
          <FormattedMessage
            id={'questionAdd.discardConfirmationCancelButton'}
          />
        }
        onConfirm={onConfirmConfirmation}
        onCancel={cancelConfirmation}
        confirmBtnCssClass={
          showSaveConfirmation ? 'btn-emerald' : 'btn-danger-ba'
        }
      />
      <ImageZoomModal
        src={zoomedImg.src}
        isOpen={openImageZoomModal}
        toggle={toggleImageZoom}
        width={zoomedImg.width}
        height={zoomedImg.height}
      />
      <ConfirmAlert
        isShown={IsstructuralSettings}
        title={<FormattedMessage id={'assessmentAdd.discardChanges'} />}
        message={
          <FormattedMessage
            id={'assessmentAdd.cancelCreateAssessmentPopupMessage'}
          />
        }
        confirmBtnText={<FormattedMessage id={'assessmentAdd.discard'} />}
        cancelBtnText={<FormattedMessage id={'assessmentAdd.cancel'} />}
        onConfirm={() => saveQuestion()}
        onCancel={() => setIsStructuralSettings(false)}
      />
    </React.Fragment>
  );
};

export default CreateMcQuestion;
