import React from 'react';
import SecondaryButton from '../secondaryButton';

function CancelButton({ className = '', color = 'emerald', onClick = null, id, size = 'normal' }) {
  return (
    <SecondaryButton
      id={id}
      size={size}
      textId="buttons.cancel"
      className={`btn-outline-ba ${className}`}
      color={color}
      onClick={onClick}
    />
  );
}

export default CancelButton;
