export default {
  'groupUsers.header': "Edit Group's Users",
  'groupUsers.header2': 'Edit Users',
  'groupUsers.title': 'Users In {param} Group',
  'groupUsers.subtitle': 'View or edit members of the group',
  'groupUsers.groupMembers': 'Group Members ',
  'groupUsers.allMembers': 'All Members ',
  'groupUsers.search': 'Search',
  'groupUsers.selectAll': '(Select All)',
  'groupUsers.moveRight': 'Remove from group',
  'groupUsers.moveLeft': 'Add to group',
  'groupUsers.updateSuccess': 'Group users updated successfully!',
};
