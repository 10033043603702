export default {
  'unitsUsers.manageUsersTitle': 'Birim Kullanıcılarını Yönet',
  'unitsUsers.manageUsersText': '{unitName} Kullanıcılarını Yönet',
  'unitsUsers.assignUsersTitle':
    '{unitName} Birimine Yeni Yetkili Kullanıcı Ata',
  'unitsUsers.authorizedUsersTitle':
    '{unitName} Biriminin Yetkili Kullanıcıları',
  'unitsUsers.noUserFound': 'Kullanıcı bulunamadı.',
  'unitsUsers.selectUsers': 'Kullanıcıları Seçin',
  'unitsUsers.selectRole': 'Rol Seçin',
  'unitsUsers.assignSubmit': 'Seçili Kullanıcıları Ata',
  'unitsUsers.resetButton': 'Temizle',
  'unitsUsers.assignSuccessMessage': 'Kullanıcılar atandı.',
  'unitsUsers.revokeUserTitle': 'Kullanıcı Yetkisini Kaldır',
  'unitsUsers.revokeUserMessage':
    'Kullanıcının yetkisini kaldırmak istediğinize emin misiniz?',
  'unitsUsers.revokeUserConfirm': 'Onayla',
  'unitsUsers.revokeUserCancel': 'İptal',
  'unitsUsers.userDeauthorizedMessage': 'Kullanıcı yetkisi kaldırıldı.',
  'unitsUsers.userRoleChangedMessage': 'Kullanıcı yetkisi değiştirildi.',
  'unitsUsers.select': 'Seçiniz',
};
