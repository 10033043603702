export const PREFIX = {
  USER_ROLE: 'USER_ROLE_',
  QUESTION: 'QUESTION_',
  QUESTION_ATTRIBUTE: 'QUESTION_ATTRIBUTE_',
  LEARNING_OBJECTIVE: 'LEARNING_OBJECTIVE_',
  PERIODS: 'PERIODS_',
  UNIT: 'UNIT_',
  USER: 'USER_',
  SETTINGS: 'SETTINGS_',
  USER_GROUP: 'USER_GROUP_',
  REPORT: 'REPORT_',
  RUBRIC: 'RUBRIC_',
  ASSESSMENT: 'ASSESSMENT_',
  PLAN_DETAIL: 'PLAN_DETAILS_',
  EXAM_SETTINGS: 'EXAM_SETTINGS_',
  PROCTORING_RULES: 'ASSESSMENT_PROCTORING_RULES_',
  ASSESSMENT_CATEGORY: 'ASSESSMENT_CATEGORY_',
};

const GENERIC_PERMISSIONS = {
  CREATE: 'CREATE',
  BROWSE: 'BROWSE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  REJECT: 'REJECT',
  APPROVE: 'APPROVE',
  ADD: 'ADD',
  INVITE: 'INVITE',
  BULK_IMPORT: 'BULK_IMPORT',
};

export const ROLE_PERMISSIONS = {
  BROWSE: PREFIX.USER_ROLE + GENERIC_PERMISSIONS.BROWSE,
  CREATE: PREFIX.USER_ROLE + GENERIC_PERMISSIONS.CREATE,
  UPDATE: PREFIX.USER_ROLE + GENERIC_PERMISSIONS.UPDATE,
  DELETE: PREFIX.USER_ROLE + GENERIC_PERMISSIONS.DELETE,
  REVOKE: `${PREFIX.USER_ROLE}REVOKE`,
  GRANT: `${PREFIX.USER_ROLE}GRANT`,
};

export const PROCTORING_RULES_PERMISSIONS = {
  BROWSE: PREFIX.PROCTORING_RULES + GENERIC_PERMISSIONS.BROWSE,
  UPDATE: PREFIX.PROCTORING_RULES + GENERIC_PERMISSIONS.UPDATE,
};

export const CATEGORY_PERMISSIONS = {
  BROWSE: PREFIX.ASSESSMENT_CATEGORY + GENERIC_PERMISSIONS.BROWSE,
  CREATE: PREFIX.ASSESSMENT_CATEGORY + GENERIC_PERMISSIONS.CREATE,
  UPDATE: PREFIX.ASSESSMENT_CATEGORY + GENERIC_PERMISSIONS.UPDATE,
  DELETE: PREFIX.ASSESSMENT_CATEGORY + GENERIC_PERMISSIONS.DELETE,
};

export const RUBRIC_PERMISSIONS = {
  BROWSE: PREFIX.RUBRIC + GENERIC_PERMISSIONS.BROWSE,
  CREATE: PREFIX.RUBRIC + GENERIC_PERMISSIONS.CREATE,
  UPDATE: PREFIX.RUBRIC + GENERIC_PERMISSIONS.UPDATE,
  DELETE: PREFIX.RUBRIC + GENERIC_PERMISSIONS.DELETE,
};

export const QUESTION_PERMISSIONS = {
  BROWSE: PREFIX.QUESTION + GENERIC_PERMISSIONS.BROWSE,
  CREATE: PREFIX.QUESTION + GENERIC_PERMISSIONS.CREATE,
  REJECT: PREFIX.QUESTION + GENERIC_PERMISSIONS.REJECT,
  UPDATE: PREFIX.QUESTION + GENERIC_PERMISSIONS.UPDATE,
  DELETE: PREFIX.QUESTION + GENERIC_PERMISSIONS.DELETE,
  APPROVE: PREFIX.QUESTION + GENERIC_PERMISSIONS.APPROVE,
};

export const QUESTION_ATTRIBUTE_PERMISSIONS = {
  CREATE: PREFIX.QUESTION_ATTRIBUTE + GENERIC_PERMISSIONS.CREATE,
  DELETE: PREFIX.QUESTION_ATTRIBUTE + GENERIC_PERMISSIONS.DELETE,
  BROWSE: PREFIX.QUESTION_ATTRIBUTE + GENERIC_PERMISSIONS.BROWSE,
  UPDATE: PREFIX.QUESTION_ATTRIBUTE + GENERIC_PERMISSIONS.UPDATE,
};

export const LEARNING_OBJECTIVE_PERMISSIONS = {
  CREATE: PREFIX.LEARNING_OBJECTIVE + GENERIC_PERMISSIONS.CREATE,
  UPDATE: PREFIX.LEARNING_OBJECTIVE + GENERIC_PERMISSIONS.UPDATE,
  BROWSE: PREFIX.LEARNING_OBJECTIVE + GENERIC_PERMISSIONS.BROWSE,
  DELETE: PREFIX.LEARNING_OBJECTIVE + GENERIC_PERMISSIONS.DELETE,
};

export const PERIODS_PERMISSIONS = {
  BROWSE: PREFIX.PERIODS + GENERIC_PERMISSIONS.BROWSE,
  CREATE: PREFIX.PERIODS + GENERIC_PERMISSIONS.CREATE,
  DELETE: PREFIX.PERIODS + GENERIC_PERMISSIONS.DELETE,
  UPDATE: PREFIX.PERIODS + GENERIC_PERMISSIONS.UPDATE,
};

export const UNIT_PERMISSIONS = {
  BROWSE: PREFIX.UNIT + GENERIC_PERMISSIONS.BROWSE,
  CREATE: PREFIX.UNIT + GENERIC_PERMISSIONS.CREATE,
  DELETE: PREFIX.UNIT + GENERIC_PERMISSIONS.DELETE,
  UPDATE: PREFIX.UNIT + GENERIC_PERMISSIONS.UPDATE,
};

export const USER_PERMISSIONS = {
  DELETE: PREFIX.USER + GENERIC_PERMISSIONS.DELETE,
  CREATE: PREFIX.USER + GENERIC_PERMISSIONS.CREATE,
  BROWSE: PREFIX.USER + GENERIC_PERMISSIONS.BROWSE,
  INVITE: PREFIX.USER + GENERIC_PERMISSIONS.INVITE,
  UPDATE: PREFIX.USER + GENERIC_PERMISSIONS.UPDATE,
};

export const SETTINGS_PERMISSIONS = {
  BROWSE: PREFIX.SETTINGS + GENERIC_PERMISSIONS.BROWSE,
  UPDATE: PREFIX.SETTINGS + GENERIC_PERMISSIONS.UPDATE,
};

export const USER_GROUP_PERMISSIONS = {
  BROWSE: PREFIX.USER_GROUP + GENERIC_PERMISSIONS.BROWSE,
  CREATE: PREFIX.USER_GROUP + GENERIC_PERMISSIONS.CREATE,
  UPDATE: PREFIX.USER_GROUP + GENERIC_PERMISSIONS.UPDATE,
  DELETE: PREFIX.USER_GROUP + GENERIC_PERMISSIONS.DELETE,
};

export const REPORT_PERMISSIONS = {
  BROWSE: PREFIX.REPORT + GENERIC_PERMISSIONS.BROWSE,
};

export const ASSESSMENT_PERMISSIONS = {
  BROWSE: PREFIX.ASSESSMENT + GENERIC_PERMISSIONS.BROWSE,
  CREATE: PREFIX.ASSESSMENT + GENERIC_PERMISSIONS.CREATE,
  UPDATE: PREFIX.ASSESSMENT + GENERIC_PERMISSIONS.UPDATE,
  DELETE: PREFIX.ASSESSMENT + GENERIC_PERMISSIONS.DELETE,
  ASSESS: `${PREFIX.ASSESSMENT}ASSESS`,
  ANNOUNCE: `${PREFIX.ASSESSMENT}ANNOUNCE`,
};

export const PLAN_DETAIL_PERMISSIONS = {
  BROWSE: PREFIX.PLAN_DETAIL + GENERIC_PERMISSIONS.BROWSE,
  BILLING: PREFIX.PLAN_DETAIL + 'BILLING',
  PAY: PREFIX.PLAN_DETAIL + 'PAY',
  ORDER: PREFIX.PLAN_DETAIL + 'ORDER',
};

export const EXAM_SETTINGS_PERMISSIONS = {
  BROWSE: PREFIX.EXAM_SETTINGS + GENERIC_PERMISSIONS.BROWSE,
  UPDATE: PREFIX.EXAM_SETTINGS + GENERIC_PERMISSIONS.UPDATE,
};
