export default {
  'questionAdd.title': 'Create Question',
  'questionAdd.duplicateTitle': 'Duplicate Question',
  'questionAdd.topics': 'Topics',
  'questionAdd.id': 'ID',
  'questionAdd.root': 'Question root',
  'questionAdd.options': 'Answer options',
  'questionAdd.addOption': 'Add option',
  'questionAdd.addOptionError': 'The number of options can be maximum 10.',
  'questionAdd.categories': 'Question Categories',
  'questionAdd.keywords': 'Keywords',
  'questionAdd.duration': 'Recommended duration',
  'questionAdd.objectives': 'Learning objective',
  'questionAdd.source': 'The source of the correct answer',
  'questionAdd.required': ' ',
  'questionAdd.save': 'Save',
  'questionAdd.lock': 'Approve & Lock',
  'questionAdd.none': 'None',
  'questionAdd.30s': '30s',
  'questionAdd.1m': '1m',
  'questionAdd.2m': '2m',
  'questionAdd.3m': '3m',
  'questionAdd.4m': '4m',
  'questionAdd.5m': '5m',
  'questionAdd.10m': '10m',
  'questionAdd.correctOption': '(The correct option should be selected)',
  'questionAdd.warning': 'Warning! Please fill in the required fields.',
  'questionAdd.answerWarning': 'You have to choose one correct answer!',
  'questionAdd.duplicateWarning':
    'No new question has been added because no change has been made on the question.',
  'questionAdd.success': 'Question Created Successfully!',
  'questionAdd.successLock': 'Question Locked Successfully!',
  'questionAdd.requiredMessage': 'This field cannot be blank.',
  'questionAdd.editorMaxMessage':
    'Question root must be maximum 5000 characters.',
  'questionAdd.expectedAnswerMaxMessage':
    'Expected answer must be maximum 5000 characters.',
  'questionAdd.editorMinMessage':
    'Question root should be at least 15 characters.',
  'questionAdd.topicPlaceholder': 'Create new or select',
  'questionAdd.select': 'Select',
  'questionAdd.maxMessage': 'Option should be max 400 characters.',
  'questionAdd.minMessage': 'Option should be at least 1 character.',
  'questionAdd.create': 'Create ',
  'questionAdd.answerSourceMax': 'Answer source should be max 200 characters.',
  'questionAdd.attributeInfo': 'Question attributes',
  'questionAdd.uncategorized': 'Uncategorized',
  'questionAdd.questionDetailsTitle': 'Question Details',
  'questionAdd.questionDetailsSubtitle':
    'Define all the details of the question',
  'questionAdd.questionContentTitle': 'Question Content',
  'questionAdd.questionContentSubtitle': 'Prepare the content of the question',
  'questionAdd.attachments': 'Attachments',
  'questionAdd.scientificCalculator': 'Scientific calculator',
  'questionAdd.addQuestionTools': 'Add Question Tools',
  'questionAdd.attachmentBrowse': 'Browse files',
  'questionAdd.attachmentDropHere': 'Drag & drop files here',
  'questionAdd.attachmentOr': 'OR',
  'questionAdd.attachmentRestrictionSize':
    'Attachments should not exceed 64 mb.',
  'questionAdd.attachmentRestrictionType': 'Supported file types: MP4, MP3',
  'questionAdd.attachmentTypeWarning':
    'You can only upload MP4, MP3 file types.',
  'questionAdd.attachmentSizeWarning':
    "The file you're attaching is bigger than the system allows.",
  'questionAdd.attachmentUploadError': 'Error',
  'questionAdd.discardConfirmationConfirmButton': 'Discard',
  'questionAdd.discardConfirmationCancelButton': 'Cancel',
  'questionAdd.discardConfirmationMessage':
    'The changes you make will not be saved. Are you sure you want to discard all changes?',
  'questionAdd.saveConfirmationMessage':
    'Are you sure you want to save this question? If you continue, the question will be saved as a draft.',
  'questionAdd.continue': 'Continue',
  'questionAdd.discardConfirmationTitle': 'Discard changes',
  'questionAdd.continueTitle': 'Are you sure you want to continue?',
  'questionAdd.lockConfirmationCancelButton': 'Cancel',
  'questionAdd.lockConfirmationMessage':
    'Are you sure you want to approve & lock this question? If you continue, this question will be available for assessments.',
  'questionAdd.maxOptionCharError':
    ' The answer to the question can be up to 400 characters.',
  'questionAdd.attachmentError':
    'There are attachment(s) that have not been uploaded.',
  'questionAdd.courseInfoTitle': 'Course Information',
  'questionAdd.courseInfoSubtitle': 'Select the course to add the question',
  'questionAdd.course': 'Course',
  'questionAdd.selectCourseWarn':
    'Select a course to define the question content and details.',
  'questionAdd.loSelectionError':
    'Warning! Please associate the question with a learning objective.',
  'questionAdd.lockErrorToast':
    'The changes you make will not be saved. Save changes before approve & lock question.',
};
