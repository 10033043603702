import React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

const PrimaryButton = ({
  //button icon
  iconLeft = '',
  iconRight = '',

  //button label
  textId = '',

  //buton label param
  textParam = '',

  //buton label full
  text = null,

  //button color
  className = '',

  //button size
  size = 'normal', // Default value, you can change it to whatever you need

  // is disabled
  disabled = false,

  //runs on click event
  onClick = null,
  type = 'submit',
  color = 'emerald-color',

}) => {
  const getText = () => {
    if (textId !== '') {
      return (
        <React.Fragment>
          {iconLeft}
          <span
            className={classnames('align-middle', {
              'ml-50': iconLeft !== '',
            })}
          >
            <FormattedMessage id={textId} values={{ param: textParam }} />
          </span>
          {iconRight}
        </React.Fragment>
      );
    } else {
      return (
        <span>
          {iconLeft}
          {text ? text : null}
          {iconRight}
        </span>
      );
    }
  };

  const sizeClass = size === 'large' ? 'btn-large' : size === 'small' ? 'btn-small' : 'btn-normal';

  return (
    <Button.Ripple
      className={`btn-filled-ba ${sizeClass} ${className}`}
      color={color}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {getText()}
    </Button.Ripple>
  );
};
export default PrimaryButton;
