import React from 'react';
/*
 *  Convert ENUM_TYPE values to human readable format. USER_ROLE => User Role
 */
export const enumToHumanReadable = (enumValue) => {
  const noUnderScore = enumValue.replace('_', ' ');
  return textCapitalizeEach(noUnderScore);
};

/*
 * Capitalize string
 */
export const textCapitalize = (text) => {
  if (!text) return null;
  const lowerCase = text.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

/*
 * Capitalize each word of string
 */
export const textCapitalizeEach = (text) => {
  if (!text) return null;
  const words = text.split(' ');
  return words.map((word) => textCapitalize(word)).join(' ');
};

/*
 * Capitalize only first word of string
 */
export const textCapitalizeFirstWordOnly = (text) => {
  if (!text) return null;
  const words = text.split(' ');
  words[0] = textCapitalize(words[0]);
  words.forEach((element, index) => {
    if (index !== 0) {
      words[index] = element.toLowerCase();
    }
  });
  return words.join(' ');
};

/*
 * Checks if string is null or empty
 */
export const isNullOrEmpty = (str) => !str || str == '';

// ** Creates span title
export const createSpanTitle = (title, length) => {
  if (!title) {
    return null;
  }
  return title.length > length ? title : null;
};

// ** Get short text that might overflow
export const getShortText = (text, length) => {
  if (!text) {
    return null;
  }
  return text.length > length ? `${text.substring(0, length)}...` : text;
};

// ** Get short text that might overflow
export const getTextWithLength = (text, length) => (
  <span title={createSpanTitle(text, length)}>
    {getShortText(text, length)}
  </span>
);

export const htmlToText = (html) => {
  const divContainer = document.createElement('div');
  divContainer.innerHTML = html;
  return divContainer.textContent.trim() || divContainer.innerText.trim() || '';
};

// array of Extract numbers from string
export const extractNumberFromString = (str) => {
  return str?.match(/\d+/g)?.map((num) => parseInt(num));
};

const turkishCharMap = {
  Ç: 'C',
  Ö: 'O',
  Ş: 'S',
  İ: 'I',
  I: 'i',
  Ü: 'U',
  Ğ: 'G',
  ç: 'c',
  ö: 'o',
  ş: 's',
  ı: 'i',
  ü: 'u',
  ğ: 'g',
};

export const turkishToEng = (str) => {
  const arrStr = str.split('');
  for (let i = 0; i < arrStr.length; i++) {
    arrStr[i] = turkishCharMap[arrStr[i]] || arrStr[i];
  }
  return arrStr.join('');
};

export const localeIncludes = (mainString, substring) => {
  if (!substring) {
    return true;
  }
  if (!substring || !mainString) {
    return false;
  }
  if (substring.length > mainString.length) {
    return false;
  }
  const ascii = (s) =>
    s
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  return ascii(mainString).includes(ascii(substring));
};
