export default {
  'resetMail.title': 'E-Postanızı Kontrol Edin',
  'resetMail.subtitle':
    '<b>{userMail}</b> adresine şifre sıfırlama talimatlarını gönderdik.',
  'resetMail.login': "Examod'a Giriş Yap",
  'resetMail.emailNotSent': '',
  'resetMail.support':
    'E-postayı almadınız mı? <b>Spam</b> posta klasörünüzü kontrol edin veya <send>e-postayı tekrar gönderin</send>.{br}Yardıma mı ihtiyacınız var? <contact>support@examod.com</contact> adresinden bize ulaşın.',
  'resetMail.emailSendAgain': 'Şifre sıfırlama talimatları tekrar gönderildi.',
};
