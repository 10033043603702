export default {
  'resetSession.resetSession': 'Reset Session',
  'resetSession.resetSessionSubt': "Reset the candidate's session",
  'resetSession.resetAlert':
    'All assessment data of the candidate(s) whose session is reset will be deleted. This application cannot be reversed.',
  'resetSession.resetReason': 'Reason',
  'resetSession.resetReasonSubtl':
    'Please provide a reason for resetting the candidate(s) session',
  'resetSession.selectedCand': 'Selected candidate: ',
  'resetSession.updateReason': 'Update the Reason',
  'resetSession.updateReasonSubt':
    "Update the candidate's session reset reason",
  'resetSession.updateSuccess':
    "The reason for resetting the candidate's exam session has been updated.",
  'resetSession.resetSuccess':
    'The session of the selected candidate(s) has been successfully reset.',
  'resetSession.resetFail':
    'The session of the selected candidate(s) could not be reset.',
  'resetSession.update': 'Update',
};
