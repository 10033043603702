export default {
  'routes.profile': 'Profile',
  'routes.addPeriod': 'Add Period',
  'routes.editPeriod': 'Edit Period',
  'routes.manageUnitUsers': 'Manage Unit Users',
  'routes.manageUnitUsers_unitName': 'Manage {unitName} Users',
  'routes.viewUser': 'User Detail',
  'routes.viewUser_userName': '{userName} Detail',
  'routes.editUser': 'Edit User',
  'routes.importUsers': 'Import Users',
  'routes.addUserGroup': 'Add User Group',
  'routes.editUserGroup': 'Edit User Group',
  'routes.listUsersInGroup': 'List Group Users',
  'routes.listUsersInGroup_groupName': 'List {groupName} Users',
  'routes.addRole': 'Add Role',
  'routes.editRole': 'Edit Role',
  'routes.course': 'Course',
  'routes.addQuestion': 'Add Question',
  'routes.editQuestion': 'Edit Question',
  'routes.addAssessment': 'Create Assessment',
  'routes.editAssessment': 'Edit Assessment',
  'routes.assessmentResults': 'Assessment Results',
  'routes.assessmentResults_assessmentName': '{assessmentName} Results',
  'routes.assessmentStatistics': 'Assessment Statistics',
  'routes.assessmentStatistics_assessmentName': '{assessmentName} Statistics',
  'routes.assessments': 'Assessments',
  'routes.candidateReport': 'Candidate Result Report',
  'routes.candidateReport_candidateName': '{candidateName} Result Report',
  'routes.proctoring?': 'Proctoring Rules',
  'routes.typeSettings?': 'Assessment Type Settings',
  'routes.categories?': 'Assessment Categories',
  'routes.assessment': 'Assessment',
  'routes.questions': 'Questions',
  'routes.rubrics': 'Rubrics',
  'routes.reports': 'Reports',
  'routes.learning-objectives': 'Learning Objectives',
  'routes.omrProcess': 'OMR Answers',
  'routes.omrProcess_assessmentName': '{assessmentName} OMR Answers',
  'routes.addRubric': 'Create Rubric',
  'routes.editRubric': 'Edit Rubric',
  'routes.editRubric_rubricName': 'Edit {rubricName}',
  'routes.copyRubric': 'Copy Rubric',
  'routes.copyRubric_rubricName': 'Copy {rubricName}',
  'routes.proctoringResult': 'Proctoring Results',
  'routes.previewQuestion': 'Preview Question',
  'routes.addQuestionAttribute': 'Add Question Attribute',
  'routes.editQuestionAttribute': 'Edit Question Attribute',
};
