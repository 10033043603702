import React from 'react';
import * as Icon from 'react-feather';
import { PATHS } from '../navigation/Paths';
import {
  SETTINGS_PERMISSIONS,
  UNIT_PERMISSIONS,
  PERIODS_PERMISSIONS,
  EXAM_SETTINGS_PERMISSIONS,
  QUESTION_ATTRIBUTE_PERMISSIONS,
  USER_PERMISSIONS,
  USER_GROUP_PERMISSIONS,
  ROLE_PERMISSIONS,
  ASSESSMENT_PERMISSIONS,
  PLAN_DETAIL_PERMISSIONS,
  PROCTORING_RULES_PERMISSIONS,
  CATEGORY_PERMISSIONS,
  QUESTION_PERMISSIONS,
} from './permissions';

const navigationConfig = [
  {
    type: 'groupHeader',
    groupTitle: 'menu.welcome',
  },
  {
    id: 'home',
    title: 'menu.home',
    type: 'item',
    icon: <Icon.Home size={20} />,
    navLink: PATHS.dashboard,
  },
  {
    id: 'plan',
    title: 'menu.planDetails',
    type: 'item',
    navLink: PATHS.plan,
    permissions: [PLAN_DETAIL_PERMISSIONS.BROWSE],
    icon: <Icon.Info size={20} />,
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.globalSettings',
    needSystemPerm: true,
    permissions: [
      SETTINGS_PERMISSIONS.BROWSE,
      UNIT_PERMISSIONS.BROWSE,
      PERIODS_PERMISSIONS.BROWSE,
      EXAM_SETTINGS_PERMISSIONS.BROWSE,
      QUESTION_ATTRIBUTE_PERMISSIONS.BROWSE,
    ],
  },
  {
    id: 'generalSettings',
    title: 'menu.general',
    type: 'item',
    navLink: PATHS.settings,
    icon: <Icon.Tool size={20} />,
    permissions: [SETTINGS_PERMISSIONS.BROWSE],
    needSystemPerm: true,
  },
  {
    id: 'structuralSettings',
    title: 'menu.structural',
    type: 'collapse',
    navLink: PATHS.structural,
    icon: <Icon.Settings size={20} />,
    permissions: [
      UNIT_PERMISSIONS.BROWSE,
      PERIODS_PERMISSIONS.BROWSE,
      EXAM_SETTINGS_PERMISSIONS.BROWSE,
      QUESTION_ATTRIBUTE_PERMISSIONS.BROWSE,
    ],
    needSystemPerm: true,
    children: [
      {
        id: 'units',
        title: 'menu.units',
        type: 'item',
        navLink: PATHS.units,
        icon: <Icon.Share2 size={20} />,
        permissions: [UNIT_PERMISSIONS.BROWSE],
        needSystemPerm: true,
      },
      {
        id: 'periods',
        title: 'menu.periods',
        type: 'item',
        navLink: PATHS.periods,
        icon: <Icon.Calendar size={20} />,
        permissions: [PERIODS_PERMISSIONS.BROWSE],
        needSystemPerm: true,
      },
      {
        id: 'exams',
        title: 'menu.exams',
        type: 'item',
        navLink: PATHS.examsTab,
        icon: <Icon.Layers size={20} />,
        permissions: [
          EXAM_SETTINGS_PERMISSIONS.BROWSE,
          PROCTORING_RULES_PERMISSIONS.BROWSE,
          CATEGORY_PERMISSIONS.BROWSE,
        ],
        needSystemPerm: true,
      },
      {
        id: 'questionFields',
        title: 'menu.questionFields',
        type: 'item',
        navLink: PATHS.attributes,
        icon: <Icon.Tag size={20} />,
        permissions: [QUESTION_ATTRIBUTE_PERMISSIONS.BROWSE],
        needSystemPerm: true,
      },
    ],
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.accounts',
    permissions: [
      USER_PERMISSIONS.BROWSE,
      USER_GROUP_PERMISSIONS.BROWSE,
      ROLE_PERMISSIONS.BROWSE,
    ],
    needSystemPerm: true,
  },
  {
    id: 'users',
    title: 'menu.users',
    type: 'item',
    navLink: PATHS.users,
    icon: <Icon.User size={20} />,
    permissions: [USER_PERMISSIONS.BROWSE],
    needSystemPerm: true,
  },
  {
    id: 'userGroups',
    title: 'menu.groups',
    type: 'item',
    navLink: PATHS.userGroups,
    icon: <Icon.Users size={20} />,
    permissions: [USER_GROUP_PERMISSIONS.BROWSE],
    needSystemPerm: true,
  },
  {
    id: 'roles',
    title: 'menu.roles',
    type: 'item',
    navLink: PATHS.roles,
    icon: <Icon.UserCheck size={20} />,
    permissions: [ROLE_PERMISSIONS.BROWSE],
    needSystemPerm: true,
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.quickActions',
    permissions: [QUESTION_PERMISSIONS.CREATE],
  },
  {
    id: 'addQuestions',
    title: 'menu.addQuestion',
    type: 'collapse',
    icon: <Icon.List size={20} />,
    permissions: [QUESTION_PERMISSIONS.CREATE],
    children: [
      {
        id: 'mcq',
        title: 'menu.addMcq',
        type: 'item',
        isModal: true,
        icon: <Icon.ChevronRight size={20} />,
        permissions: [QUESTION_PERMISSIONS.CREATE],
      },
      {
        id: 'oeq',
        title: 'menu.addOpenEndedQ',
        type: 'item',
        isModal: true,
        icon: <Icon.ChevronRight size={20} />,
        permissions: [QUESTION_PERMISSIONS.CREATE],
      },
      {
        id: 'groupedQ',
        title: 'menu.addGroupedQ',
        type: 'item',
        isModal: true,
        icon: <Icon.ChevronRight size={20} />,
        permissions: [QUESTION_PERMISSIONS.CREATE],
      },
    ],
  },
  {
    type: 'groupHeader',
    groupTitle: 'menu.assessment',
    permissions: [ASSESSMENT_PERMISSIONS.BROWSE],
  },
  /*
  {
    id: "myUnits",
    title: "menu.myUnits",
    type: "item",
    navLink: PATHS.myUnits,
    icon: <Icon.GitPullRequest size={20} />,
    //permissions: [PERMISSIONS.QUESTION_BROWSE]
  },
  */
  {
    id: 'myCourses',
    title: 'menu.myCourses',
    type: 'item',
    navLink: PATHS.myCourses,
    icon: <Icon.Book size={20} />,
    permissions: [ASSESSMENT_PERMISSIONS.BROWSE],
  },

  {
    type: 'groupHeader',
    groupTitle: 'menu.help',
  },
  {
    id: 'newTicket',
    title: 'menu.newTicket',
    type: 'item',
    icon: <Icon.Send size={20} />,
    navLink: PATHS.newTicket,
  },
  {
    id: 'raiseSupport',
    title: 'menu.raiseSupport',
    icon: <Icon.LifeBuoy size={20} />,
    type: 'external-link',
    newTab: true,
    navLink: 'https://examod.com/',
  },
];

export default navigationConfig;
