import { REGEX_IMG_TAG } from '../constants/validation';
import { isEmpty, isNullOrEmpty } from '../utility/collectionUtil';
import { subtractYears } from '../utility/dateUtil';
import {
  htmlToText,
  isNullOrEmpty as isStrNullOrEmpty,
} from '../utility/stringUtil';
import { toastError } from '../utility/toastUtil';

/*
 * Difficulty options for questions
 */
export const difficultyOptions = [
  { id: 1, name: 'Kolay' },
  { id: 2, name: 'Orta' },
  { id: 3, name: 'Zor' },
];

/*
 * Questions statuses
 */
export const questionStatuses = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  DEPRECATED: 'DEPRECATED',
};

/*
 * Affinity types
 */
export const affinityType = {
  SINGLE: 'SINGLE',
  GROUPED: 'GROUPED',
};

/*
 * Operation modes
 */
export const operationMode = {
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
};

/*
 * Approved question operations
 */
export const approvedQuestionActions = {
  DEPRECATE: 'DEPRECATE',
  REUSE: 'REUSE',
};

/*
 * Duration options for MC questions
 */
export const durationOptions = [
  { option: 'questionAdd.none', value: 0 },
  { option: 'questionAdd.30s', value: 30 },
  { option: 'questionAdd.1m', value: 60 },
  { option: 'questionAdd.2m', value: 120 },
  { option: 'questionAdd.3m', value: 180 },
  { option: 'questionAdd.4m', value: 240 },
  { option: 'questionAdd.5m', value: 300 },
  { option: 'questionAdd.10m', value: 600 },
];

/*
 * Duration options for MC questions
 */
export const attachementFileTypes = {
  audio: 'AUDIO',
  video: 'VIDEO',
};

export const acceptedAttachmentExtensions = '.mp3,.mp4';

export const maxAttachementFileSize = 64000000; //byte

export const attachmentErrorTypes = {
  fileSize: 'file-too-large',
  invalidType: 'file-invalid-type',
};

export const MAX_NUMBER_OF_QUESTION_OPTION = 10;

export const questionTypes = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  LONG_ANSWER: 'LONG_ANSWER',
  // TRUE_FALSE: 'TRUE_FALSE',
};

// ** Last used date periods
export const nearestUsageDatePeriods = [
  {
    value: {
      neverUsed: true,
    },
    label: 'neverUsed',
  },
  {
    value: {
      endDate: null,
      startDate: subtractYears(new Date(), 1),
    },
    label: 'last1Year',
  },
  {
    value: {
      endDate: subtractYears(new Date(), 1),
      startDate: subtractYears(new Date(), 2),
    },
    label: 'between1And2Years',
  },
  {
    value: {
      startDate: subtractYears(new Date(), 2),
      endDate: subtractYears(new Date(), 3),
    },
    label: 'between2And3Years',
  },
  {
    value: {
      startDate: subtractYears(new Date(), 3),
      endDate: null,
    },
    label: 'before3Years',
  },
];

export const approvalDatePeriods = [
  { value: '30', label: 'oneMonth' },
  { value: '180', label: 'sixMonth' },
  { value: '360', label: 'oneYear' },
  { value: '720', label: 'twoYear' },
  { value: '', label: 'all' },
];

export const questionFilterTypes = {
  status: 'status',
  type: 'type',
  affinityType: 'affinityType',
  owner: 'owner',
  keyword: 'keyword',
  approvalDate: 'approvalDate',
  nearestDate: 'nearestDate',
};

export const QUESTION_OPTION_CHARS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
];

/*
 * Orders answers
 */
export const orderAnswers = (items) => {
  const orderedAnswers = new Array(items.length);
  items.forEach((item) => (orderedAnswers[item.displayOrder - 1] = item));
  return orderedAnswers;
};

/*
 * Difficulty classification ranges of questions
 */
export const getClassificationDifficulty = (range) => {
  if (range <= 100 && range > 60) {
    return 'green-badge';
  }
  if (range <= 60 && range > 40) {
    return 'orange-badge';
  }
  if (range <= 40 && range >= 0) {
    return 'pink-badge';
  }
  return 'pink-badge';
};

/*
 * Discrimination classification ranges of questions
 */
export const getClassificationDiscrimination = (range) => {
  if (range <= 1 && range >= 0.4) {
    return 'green-badge';
  }
  if (range <= 0.39 && range >= 0.3) {
    return 'cyan-badge';
  }
  if (range <= 0.29 && range >= 0.2) {
    return 'orange-badge';
  }
  if (range <= 0.19 && range > -1) {
    return 'pink-badge';
  }
  return 'pink-badge';
};

/*
 * Reliability classification ranges of questions
 */
export const getClassificationReliability = (range) => {
  if (range <= 1 && range >= 0.81) {
    return 'green-badge';
  }
  if (range <= 0.8 && range >= 0.61) {
    return 'cyan-badge';
  }
  if (range <= 0.6 && range >= 0.41) {
    return 'yellow-badge';
  }
  if (range <= 0.4 && range >= 0.21) {
    return 'orange-badge';
  }
  if (range <= 0.2 && range >= 0) {
    return 'pink-badge';
  }
  return 'pink-badge';
};

export const isQuestionOptionInvalid = (option) => {
  return isOptionEmpty(option) || isOptionExceedLimit(option);
};

export const isOptionExceedLimit = (option) => {
  const questionOption = htmlToText(option);
  return questionOption.length > 400;
};

export const isOptionEmpty = (option) => {
  const questionOption = htmlToText(option);
  return isStrNullOrEmpty(questionOption) && !option.match(REGEX_IMG_TAG);
};

export const getOptionError = (answers, index, optionsFocused) => {
  const questionId = answers[index].id;
  if (
    isQuestionOptionInvalid(answers[index].content) &&
    optionsFocused[questionId]
  ) {
    return { [questionId]: true };
  } else {
    return { [questionId]: false };
  }
};

export const setAllOptionsFocused = (answers, setOptionsFocused) => {
  if (answers) {
    answers.forEach((_, index) =>
      setOptionsFocused((prev) => ({ ...prev, [index]: true }))
    );
  }
};

export const getTotalAttachmentSize = (attachments, asMb) => {
  if (isNullOrEmpty(attachments)) {
    return 0;
  }
  const totalSize = attachments.reduce(
    (prev, current) => prev + current.size,
    0
  );
  if (asMb) {
    return getFileSizeAsMb(totalSize, true);
  }
  return totalSize;
};

// ** Gets attachment file size as megabyte
export const getFileSizeAsMb = (size, noUnit) => {
  return (size / 10 ** 6).toFixed(2) + (noUnit ? '' : 'mb');
};

// checks array of wrong files and array of successfully uploaded files for target file
export const fileIsUploaded = (
  fileTempId,
  attachmentUploaded,
  attachmentErrors,
  attachmentCanceled
) => {
  const uploadedSuccessIds = attachmentUploaded.map((attch) => attch.tempId);
  const attachmentErrorIds = attachmentErrors.map((err) => err.tempId);
  const attachmentCanceledIds = attachmentCanceled?.map(
    (attch) => attch.tempId
  );
  const allUploaded = [
    ...uploadedSuccessIds,
    ...attachmentErrorIds,
    ...attachmentCanceledIds,
  ];
  return allUploaded.includes(fileTempId);
};

export const getUploadedFiles = (
  attachmentUploaded,
  canceledAttachments,
  getIds
) => {
  const canceledAttchmentIds = canceledAttachments.map((attc) => attc.tempId);
  const uploaded = attachmentUploaded.filter(
    (attachment) => !canceledAttchmentIds.includes(attachment.tempId)
  );
  if (getIds) {
    return uploaded.map((el) => el.id);
  }
  return uploaded;
};

export const attachmentUploadingContinue = (
  attachments,
  attachmentUploaded,
  attachmentErrors,
  canceledAttachments
) => {
  for (let i = 0; i < attachments.length; i++) {
    const attachment = attachments[i];
    if (
      !fileIsUploaded(
        attachment.tempId,
        attachmentUploaded,
        attachmentErrors,
        canceledAttachments
      )
    ) {
      return true;
    }
  }
  return false;
};

export const fileHasError = (fileTempId, attachmentErrors) => {
  if (isNullOrEmpty(attachmentErrors)) {
    return false;
  }
  const errorIds = attachmentErrors.map((e) => e.tempId);
  return errorIds.includes(fileTempId);
};

// ** Gets attachment file name
export const getAttachmentName = (fileName) => {
  const acceptedLength = 40;
  let name = fileName;
  if (name.length > acceptedLength) {
    name = name.substring(0, acceptedLength);
  }
  return name;
};

export const getSeperatedFileName = (fullName) => {
  const extensionSeperatorInd = fullName.lastIndexOf('.');
  const fileName = fullName.substring(0, extensionSeperatorInd);
  const fileExtension = fullName.substring(extensionSeperatorInd + 1);
  return [fileName, fileExtension];
};

// ** On file drop rejected
export const onDropRejected = (files, intl) => {
  const errorCodes = files.map((file) => file.errors[0].code);
  if (errorCodes.includes(attachmentErrorTypes.invalidType)) {
    toastError(intl.formatMessage({ id: 'questionAdd.attachmentTypeWarning' }));
  }
  if (errorCodes.includes(attachmentErrorTypes.fileSize)) {
    toastError(intl.formatMessage({ id: 'questionAdd.attachmentSizeWarning' }));
  }
};

export const handleZoomOptionImg = (
  onClickQuestionImage,
  setOpenImageZoomModal
) => {
  let rootImages = document.querySelectorAll('.editor-body img');
  if (!isEmpty(rootImages)) {
    for (let i = 0; i < rootImages.length; i++) {
      const img = rootImages[i];
      var width = img.clientWidth;
      var height = img.clientHeight;
      img.addEventListener('click', (e) => {
        let isBorder =
          e.offsetX < 5 ||
          e.offsetY < 5 ||
          e.offsetX >= width - 50 ||
          e.offsetY >= height - 50;
        if (!isBorder) {
          onClickQuestionImage(img);
        } else {
          setOpenImageZoomModal(false);
        }
      });
    }
  }
};

export const extractImageTags = (htmlString) => {
  const matches = htmlString.match(REGEX_IMG_TAG) || [];
  return matches;
};

export const optionHasImage = (opt) => {
  return !isNullOrEmpty(extractImageTags(opt));
};
