import { generalStatuses } from './General';

/*
 * Checks if role is active or not
 */
export const isActive = (state) => state === generalStatuses.ACTIVE;

// ** Supported Role Contexts
export const roleContexts = {
  UNIT: 'UNIT',
  SYSTEM: 'SYSTEM',
};

export const ROLE_TYPES = {
  ADMIN: 'ADMIN',
  ANCHOR: 'ANCHOR',
  USER_MANAGED: 'USER_MANAGED',
};
