export default {
  'courseReport.title': 'Course Performance Reports',
  'courseReport.unitTitle': 'Unit Performance Reports',
  'courseReport.subTitle': 'Generate and export comprehensive reports',
  'courseReport.customize': 'Customize Reports',
  'courseReport.topics': 'Topics',
  'courseReport.allCourses': 'All Courses',
  'courseReport.courses': 'Courses',
  'courseReport.categories': 'Assessment Category',
  'courseReport.obj': 'Learning Objectives',
  'courseReport.OBJ': 'Learning Objectives',
  'courseReport.assessments': 'Assessments',
  'courseReport.periods': 'Periods',
  'courseReport.field': 'Question Attribute',
  'courseReport.select': 'Select',
  'courseReport.apply': 'Apply Filters',
  'courseReport.reset': 'Reset',
  'courseReport.filter': 'Filter by assessment',
  'courseReport.share': 'Share with Candidates',
  'courseReport.fieldPerformance': 'Question Attribute {param} Performance',
  'courseReport.courseName': 'Course name',
  'courseReport.topicName': 'Topic name',
  'courseReport.question': 'Question',
  'courseReport.weight': 'Weight (%)',
  'courseReport.all': 'All {param}',
  'courseReport.selected': 'Selected {param}',
  'courseReport.options': 'Options',
  'courseReport.export': 'Export Selected Reports as Excel (.xlsx)',
  'courseReport.scoreRange': 'Score Range (%)',
  'courseReport.avgScore': 'Average Score (%)',
  'courseReport.share-tooltip': '-',
  'courseReport.radarTopicTitle': 'Radar Chart by Topics',
  'courseReport.radarObjTitle': 'Radar Chart by Learning Objectives',
  'courseReport.radarCourseTitle': 'Radar Chart by Courses',
  'courseReport.uncategorized': 'Uncategorized',
  'courseReport.periodUndefined': 'Period undefined',
  'courseReport.parentUnit': 'Parent Unit',
  'courseReport.units': 'All Subunits',
  'courseReport.unusedQuestionTooltip':
    'The reports do not include questions that are not used in any exam.',
};
