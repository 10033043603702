export default {
  'groups.title': 'Group Management',
  'groups.subtitle': 'Identify groups and users across the organization',
  'groups.name': 'Group Name',
  'groups.description': 'Group Description',
  'groups.userCount': 'User Count',
  'groups.status': 'Status',
  'groups.actions': 'Actions',
  'groups.addGroup': 'Add Group',
  'groups.search': 'Search',
  'groups.show': 'Show',
  'groups.entries': 'Group',
  'groups.users': 'Users',
  'groups.details': 'Edit',
  'groups.delete': 'Delete',
  'groups.prevButton': 'Previous',
  'groups.nextButton': 'Next',
  'groups.deleteConfirmationTitle': 'Delete group',
  'groups.deleteConfirmationMessage':
    'Are you sure to delete the group? This operation cannot be reversed.',
  'groups.deleteConfirmationConfirmButton': 'Yes, delete group',
  'groups.deleteConfirmationCancelButton': 'Cancel',
  'groups.deleteSuccess': 'Group deleted successfully!',
  'groups.editConfirmationTitle': 'Edit group',
  'groups.editConfirmationMessage':
    'Are you sure to edit the group? This operation cannot be reversed.',
  'groups.editConfirmationConfirmButton': 'Yes, edit group',
  'groups.showPassiveStatus': 'Show passive groups',
  'groups.obj': 'group',
  'groups.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'groups.messageInformation':
    'There is no {titleValue} definition for your organization yet.',
  'groups.messageInformationAdd':
    'Click the {messageValue} button to add {titleValue}.',
};
