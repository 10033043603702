export default {
  'periodEdit.header': 'Dönemi Düzenle',
  'periodEdit.name': 'Dönem Adı',
  'periodEdit.unit': 'Ana Birim',
  'periodEdit.status': 'Göster / Gizle',
  'periodEdit.start': 'Başlangıç Tarihi',
  'periodEdit.end': 'Bitiş Tarihi',
  'periodEdit.delete': 'Sil',
  'periodEdit.update': 'Değişiklikleri Kaydet',
  'periodEdit.success': 'Dönem başarıyla güncellendi!',
  'periodEdit.deleteSuccess': 'Dönem başarıyla silindi!',
  'periodEdit.allUnits': 'Tüm Ana Birimler',
  'periodEdit.timerAlert':
    'Dönem güncelleme başarısız. Başlangıç tarihi bitiş tarihine eşit veya bitiş tarihinden büyük olmamalı!',
};
