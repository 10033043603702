export default {
  'resetSession.resetSession': 'Oturumu Sıfırla',
  'resetSession.resetSessionSubt': 'Adayın oturumunu sıfırla',
  'resetSession.resetAlert':
    'Oturumu sıfırlanan aday(lar)ın tüm değerlendirme verileri silinir. Bu uygulama geri alınamaz.',
  'resetSession.resetReason': 'Gerekçe',
  'resetSession.resetReasonSubtl':
    'Lütfen aday(lar)ın oturumunu sıfırlamak için bir gerekçe belirtin.',
  'resetSession.selectedCand': 'Seçilen aday: ',
  'resetSession.updateReason': 'Gerekçeyi Güncelle',
  'resetSession.updateReasonSubt':
    'Adayın oturum sıfırlama gerekçesini güncelleyin',
  'resetSession.updateSuccess':
    'Adayın sınav oturumu sıfırlama gerekçesi güncellenmiştir.',
  'resetSession.resetSuccess':
    'Seçili aday(lar)ın oturumu başarıyla sıfırlanmıştır.',
  'resetSession.resetFail': 'Seçili aday(lar)ın oturumu sıfırlanamamıştır.',
  'resetSession.update': 'Güncelle',
};
