export default {
  'userDetails.header': 'Kullanıcı Detayı',
  'userDetails.detail': "{name} {surname}'nin Detayları",
  'userDetails.title': 'Kullanıcı Özeti',
  'userDetails.username': 'Kullanıcı Adı',
  'userDetails.name': 'Ad',
  'userDetails.email': 'E-posta',
  'userDetails.status': 'Durum',
  'userDetails.role': 'Rol',
  'userDetails.company': 'Şirket',
  'userDetails.delete': 'Sil',
  'userDetails.type': 'Kullanıcı Türü',
  'userDetails.group': 'Kullanıcı Grupları',
  'userDetails.noGroup': 'Grup yok',
  'userDetails.edit': 'Düzenle',
  'userDetails.verified': 'Doğrulanmış',
  'userDetails.not_verified': 'Doğrulanmamış',
  'userDetails.system': 'Sistem Bağlamı',
  'userDetails.unit': 'Birim Bağlamı',
  'userDetails.rolesTitle': 'Kullanıcının Rolleri',
  'userDetails.noSystemRole':
    'Bu kullanıcı için sistem bağlamında bir rol bulunmamaktadır.',
  'userDetails.noUnitRole':
    'Bu kullanıcı için birim bağlamında bir rol bulunmamaktadır.',
  'userDetails.deleteConfirmationTitle': 'Kullanıcıyı sil',
  'userDetails.deleteConfirmationMessage':
    'Kullanıcıyı silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'userDetails.deleteConfirmationConfirmButton': 'Evet, kullanıcıyı sil',
  'userDetails.deleteConfirmationCancelButton': 'İptal',
  'userDetails.unit_course': 'Birim/Ders',
  'userDetails.course': 'Ders',
  'userDetails.passiveUserAlert':
    'Pasif kılınmış kullanıcılar için rol atama işlemi gerçekleştirilememektedir. Bu türde kullanıcıların atandıkları roller, birimler veya dersler içerisinde listelenmemektedir.',
  'userDetails.undefined': 'Tanımlanmamış',
  'userDetails.none': 'Yok',
  'userDetails.profileButton': 'Profil',
  'userDetails.language': 'Tercih Edilen Dil',
  'userDetails.systemId': 'Sistem Kimliği',
};
