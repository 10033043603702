/* eslint no-underscore-dangle: "off" */
import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from '../../react-draft';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getLang } from '../../../data/UserLocalDataSource';
import { uploadImage } from '../../../data/QuestionRepository';

import { isNullOrEmpty } from '../../../utility/collectionUtil';
import { toastError } from '../../../utility/toastUtil';
import { FormattedMessage } from 'react-intl';
import { checkResult } from '../../../utility/resultHandler';

function ExamodEditor({
  placeHolder,
  editorState,
  wrapperClassName = 'examod-wrapper',
  editorClassName = 'examod-editor',
  setText,
  maxCharLength = 5000,
  setIsEditorLengthValid,
  setEditorState,
  toolbar,
  onChangeText,
  onFocus,
  disabled = false,
  isBoxDisable = false,
  handleZoomImg,
}) {
  const [isSourceActive, setIsSourceActive] = useState(false); // TODO: emre: this will be checked
  const [editorHTML, setEditorHTML] = useState('');

  useEffect(() => {
    if (isSourceActive) {
      setEditorState(editorStateToHtml(editorState));
    } else if (!isNullOrEmpty(editorHTML)) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(editorHTML))
        )
      );
    }
  }, [isSourceActive]);

  useEffect(() => {
    if (isNullOrEmpty(editorHTML)) {
      const editorHTML = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      setEditorHTML(editorHTML);
    }
  }, []);

  // ** Image upload
  const _uploadImageCallBack = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const mbAsByte = 2000000;
    if (file.size > mbAsByte) {
      toastError(
        <FormattedMessage
          id="toasts.fileSizeError"
          values={{
            maxSize: 2,
          }}
        />
      );
      return;
    }
    const imageURL = await uploadImage(formData);
    if (imageURL?.apiError) {
      checkResult(imageURL);
    } else {
      const imageObject = {
        file,
        localSrc: imageURL,
      };

      return new Promise((resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      });
    }
  };

  // ** text without html tags
  const getText = (html) => {
    if (html) {
      const divContainer = document.createElement('div');
      divContainer.innerHTML = html;
      return divContainer.textContent || divContainer.innerText || '';
    }
    let text = '';
    convertToRaw(editorState.getCurrentContent()).blocks.map((item, index) => {
      if (item.type !== 'atomic' && item.text !== '')
        text += index === 0 ? item.text : ` ${item.text}`;
    });

    return text;
  };

  // ** state to html
  const editorStateToHtml = (editorState) =>
    EditorState.createWithContent(
      ContentState.createFromText(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    );

  // gets html from content blocks
  const getHTMLFromBlocks = () => {
    let editorHTML = '';
    convertToRaw(editorState.getCurrentContent()).blocks.map((item, index) => {
      editorHTML += item.text;
    });
    return editorHTML;
  };

  return (
    <Editor
      isBoxDisable={isBoxDisable}
      locale={getLang().toLowerCase() || 'en'}
      placeholder={placeHolder}
      wrapperClassName={
        isSourceActive ? 'edit-source-wrapper' : wrapperClassName
      }
      editorClassName={isSourceActive ? 'edit-source-editor' : editorClassName}
      editorState={editorState}
      onFocus={onFocus}
      onEditorStateChange={(state) => {
        if (!disabled) {
          if (!isSourceActive) {
            const editorHTML = draftToHtml(
              convertToRaw(state.getCurrentContent())
            );
            setEditorHTML(editorHTML);
            setText(editorHTML);
          } else {
            setEditorHTML(getHTMLFromBlocks());
            setText(getHTMLFromBlocks());
          }
          setEditorState(state);
        }
      }}
      onContentStateChange={(state) => {
        if (handleZoomImg) {
          handleZoomImg();
        }
        if (!disabled) {
          if (!isSourceActive) {
            if (maxCharLength && setIsEditorLengthValid) {
              if (getText().length <= maxCharLength) {
                setIsEditorLengthValid(true);
              } else {
                setIsEditorLengthValid(false);
              }
            }
            onChangeText && onChangeText(getText());
          } else if (maxCharLength && setIsEditorLengthValid) {
            if (getText(getHTMLFromBlocks()).length <= maxCharLength) {
              setIsEditorLengthValid(true);
            } else {
              setIsEditorLengthValid(false);
            }
          }
        }
      }}
      toolbar={{
        ...toolbar?.options,
        image: {
          uploadCallback: _uploadImageCallBack,
          previewImage: true,
          alt: { present: true, mandatory: false },
          popupClassName: 'upload-image-popup',
        },
      }}
      // toolbarCustomButtons={source ? [<Source />] : null}
    />
  );
}

export default ExamodEditor;
