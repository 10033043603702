import React from 'react';
import { IntlProvider } from 'react-intl';
import { getLang, saveLang } from '../../data/UserLocalDataSource';
import messages_en from '../../assets/data/locales/en/index';
import messages_tr from '../../assets/data/locales/tr/index';
import { setLanguage } from '../networkUtil';

export const supportedLanguages = [
  {
    key: 'en',
    name: 'English',
    locale: 'EN',
    menu_messages: messages_en,
  },
  {
    key: 'tr',
    name: 'Türkçe',
    locale: 'TR',
    menu_messages: messages_tr,
  },
];

export const supportedLocales = [
  {
    key: 'tr',
    name: 'Türkiye',
    locale: 'TSI',
    localeRepr: 'tr-TR',
    timeZone: 'Europe/Istanbul',
  },
];
export const SYSTEM_LANGUAGES = {
  TR: 'TR',
  EN: 'EN',
};

export const getSupportedLanguageByLocale = (locale) => {
  let lang = supportedLanguages.find((lang) => lang.locale == locale);
  if (!lang) {
    lang = supportedLanguages[0];
  }
  return lang;
};

export const getSupportedLocale = (locale) => {
  let foundLocale = supportedLocales.find(
    (loc) => loc.locale == locale.toLowerCase()
  );
  if (!foundLocale) {
    foundLocale = supportedLocales[0];
  }

  return foundLocale;
};

export const getSupportedTimezone = (locale) => {
  let foundLocale = supportedLocales.find(
    (loc) => loc.timeZone == locale.toLowerCase()
  );
  if (!foundLocale) {
    foundLocale = supportedLocales[0];
  }

  return foundLocale;
};

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  state = {
    lang: getSupportedLanguageByLocale(getLang()),
  };

  render() {
    const { children } = this.props;
    const { lang } = this.state;
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: (language) => {
            this.setState({
              lang: getSupportedLanguageByLocale(language),
            });
            saveLang(language);
            setLanguage(language);
          },
        }}
      >
        <IntlProvider
          key={lang.key}
          locale={lang.locale}
          messages={lang.menu_messages}
          defaultLocale={lang.locale}
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
