export default {
  'preview.title': 'Preview',
  'preview.subtitle': 'Preview of data',
  'preview.information': 'File information and preview',
  'preview.successMessage': 'There is no error in the file you uploaded.',
  'preview.success': 'Success',
  'preview.warning': 'Warning!',
  'preview.fileName': 'File name:',
  'preview.creationDate': 'Creation date:',
  'preview.invalidColumn': 'Invalid column size',
  'preview.invalidQuestionSize': 'Question answer size exceeded',
  'preview.invalidId': 'User system id not found',
  'preview.empty': 'Empty',
  'preview.nextStepConfirmationTitle': 'Are you sure to want to continue?',
  'preview.nextStepConfirmationMessage':
    'There are errors in the file you uploaded. If you continue, your file will be sent.',
  'preview.nextStepConfirmationConfirmButton': 'I approve',
  'preview.nextStepConfirmationCancelButton': 'Cancel',
  'preview.error': 'Error',
  'preview.imported': 'Imported',
  'preview.notImported': 'Not Imported',
  'preview.systemId': 'SYSTEM ID',
  'preview.bookletName': 'BOOKLET NAME',
  'preview.status': 'STATUS',
  'preview.importedListTitle': 'Imported data list preview',
  'preview.name': 'NAME',
  'preview.surname': 'SURNAME',
};
