export default {
  'assessment.title': 'Assessments List',
  'assessment.obj': 'Assessment',
  'assessment.subtitle': 'Manage your assessments',
  'assessment.emptySubtitle':
    'Create the first assessment for this unit or course',
  'assessment.name': 'Assessment Name',
  'assessment.dateCreated': 'Creation Date',
  'assessment.type': 'Type',
  'assessment.category': 'Category',
  'assessment.status': 'Status',
  'assessment.duration': 'Duration',
  'assessment.dates': 'Start & End Time',
  'assessment.actions': 'Actions',
  'assessment.results': 'Results',
  'assessment.edit': 'Edit',
  'assessment.preview': 'Exam View',
  'assessment.booklets': 'Download all booklets',
  'assessment.downloadClassicPaper': 'Download booklet',
  'assessment.performPaperBasedClassic': 'Assess',
  'assessment.cancel': 'Cancel the Exam',
  'assessment.reasonInfo':
    'Please enter a reason why you want to cancel the exam. This reason will be used to announce to the candidates who will take the exam.',
  'assessment.delete': 'Delete the Exam',
  'assessment.importOmr': "Import OMR Answers' .csv file",
  'assessment.search': 'Search',
  'assessment.add': 'Create Assessment',
  'assessment.takeExam': 'Perform the exam',
  'assessment.switchInfo': 'Show also expired and invalidated assessments',
  'assessment.deleteConfirmationTitle': 'Delete assessment',
  'assessment.deleteConfirmationMessage':
    'Are you sure to delete the assessment? This operation cannot be reversed.',
  'assessment.deleteConfirmationConfirmButton': 'Yes, delete assessment',
  'assessment.deleteConfirmationCancelButton': 'Cancel',
  'assessment.languageConfirmationTitle': 'Change language',
  'assessment.languageConfirmationMessage':
    'Are you sure to change the language? The changes you made will be lost.',
  'assessment.languageConfirmationConfirmButton': 'Yes, change language',
  'assessment.addSuccess': 'Assessment created successfully!',
  'assessment.editSuccess': 'Assessment edited successfully!',
  'assessment.deleteSuccessAlert': 'Assessment deleted successfully!',
  'assessment.cancelSuccessAlert': 'Assessment canceled successfully!',
  'assessment.quitMessage':
    'Your changes will be lost. Are you sure you want to quit?',
  'assessment.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'assessment.messageInformation':
    'There is no {titleValue} in this unit or course of your organization yet.',
  'assessment.messageInformationAdd':
    'Get started with the {titleValue} types available on Examod!',
  'assessment.PAPER_BASED': 'Paper-based',
  'assessment.PAPER_BASEDsubtitle': 'Assess your candidates via paper-based',
  'assessment.PAPER_BASEDtooltip':
    "With the Paper-Based assessment type, paper-based exams can be created in different booklet types using multiple choice question types. Candidates' optical sheets are transferred to Examod and the results are digitized.",
  'assessment.ON_SITE': 'Onsite / Test Center',
  'assessment.ON_SITEsubtitle': 'Assess your candidates onsite/test center',
  'assessment.ON_SITEtooltip':
    'With the Onsite / Test Center assessment type, exams can be created using the different question types available in Examod. Candidates can be securely assessed remotely with the proctoring types Record & Review, Lockdown & Review or Unproctored.',
  'assessment.PERFORMANCE_EXAM': 'Performance',
  'assessment.PERFORMANCE_EXAMsubtitle': 'Assess candidates via e-rubrics',
  'assessment.PERFORMANCE_EXAMtooltip':
    'With the performance assessment type, candidate performances can be assessed using e-rubrics. Candidate performances can be given oral and written feedback in addition to the e-rubric.',
  'assessment.ONLINE': 'Online',
  'assessment.ONLINEsubtitle': 'Assess your candidates from distance',
  'assessment.ONLINEtooltip':
    'With the Online assessment type, exams can be created using the different question types available in Examod. Candidates can be securely assessed remotely with the proctoring types Record & Review, Lockdown & Review or Unproctored.',
};
