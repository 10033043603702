export default {
  'recover.title': 'Şifrenizi Kurtarın',
  'recover.info':
    'Lütfen <b>Examod</b> hesabıyla ilişkili e-postanızı girin, size şifrenizi sıfırlamak için talimatlar göndereceğiz.',
  'recover.email': 'Email',
  'recover.recover': 'Şifre Kurtarma',
  'recover.toLogin': 'Geri dön',
  'recover.forgotPassword': 'Şifremi Unuttum?',
  'recover.sendReset': 'Sıfırlama Bağlantısı Gönder',
  'recover.next': 'Sonraki',
  'recover.emailPatternError': 'Lütfen geçerli bir e-posta girin',
  'recover.requiredError': 'Bu alan boş bırakılamaz.',
  'recover.emailNotFound': ' E-posta adresi bulunamadı.',
  'recover.notDefinedInExamodError': "{email} Examod'da tanımlı değildir.",
};
