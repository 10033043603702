export default {
  'userAdd.title': 'Kullanıcı Ekle',
  'userAdd.firstName': 'İsim',
  'userAdd.firstNameHint': 'İsim buraya..',
  'userAdd.lastName': 'Soyisim',
  'userAdd.lastNameHint': 'Soyisim buraya..',
  'userAdd.userName': 'Kullanıcı Adı',
  'userAdd.userNameHint': 'Kullanıcı adı buraya..',
  'userAdd.role': 'Kullanıcı Rolü',
  'userAdd.roleHint': '(Atanmış roller sistem geneli için uygulanır)',
  'userAdd.email': 'Eposta',
  'userAdd.emailValidationText': 'Harf, sayı ve nokta kullanabilirsiniz',
  'userAdd.emailHint': 'hello@example.com',
  'userAdd.type': 'Kullanıcı Tipi',
  'userAdd.unit': 'Birim',
  'userAdd.group': 'Kullanıcı Grubu',
  'userAdd.systemId': 'Sistem Kimliği',
  'userAdd.systemIdHint': 'Sistem ID buraya..',
  'userAdd.add': 'Kullanıcı Ekle',
  'userAdd.select': 'Seçiniz',
  'userAdd.selectRole': 'Rol Seçiniz',
  'userAdd.unitSelectRequiredMessage': 'Lütfen bir birim seçin!',
  'userAdd.success': 'Kullanıcı başarıyla oluşturuldu!',
  'userEdit.success': 'Kullanıcı başarıyla güncellendi!',
  'userAdd.maxMessageFirstName': 'İsim en fazla 70 karakter olmalıdır.',
  'userAdd.maxMessageLastName': 'Soyisim en fazla 70 karakter olmalıdır.',
  'userAdd.maxMessageUserName': 'Kullanıcı adı en fazla 70 karakter olmalıdır.',
  'userAdd.maxMessageEmail': 'Eposta en fazla 70 karakter olmalıdır.',
  'userAdd.maxMessageSystemId': 'Sistem ID en fazla 20 karakter olmalıdır.',
  'userAdd.minMessageSystemId': 'Sistem Id en az 6 karakter olmalıdır.',
  'userAdd.selectAlert': 'Lütfen bir kullanıcı türü seçin',
  'userAdd.CANDIDATE': 'Aday',
  'userAdd.AUTHORIZED': 'Yetkili',
};
