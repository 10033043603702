export default {
    "userVerification.browseFile":" Browse File",
    "userVerification.candidateFaceId":" Candidate Face ID",
    "userVerification.previewFaceId":" Preview Face ID",
    "userVerification.drop&DragPhotoHere":" Drop & Drag Photo Here",
    "userVerification.or":"OR",
    "userVerification.photoInstruction":
       "The photo must be 51x51 mm(2x2inch) and min in 150 dpi for the front view",
    "userVerification.supportFileTypes":" Support File Types:JPEG,JPG,PNG,TIFF Max upload size: 2 mb",

}
