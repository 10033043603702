export default {
  'unitAdd.titleAdd': 'Birim Ekle',
  'unitAdd.titleUpdate': 'Birimi Düzenle',
  'unitAdd.name': 'Birim Adı',
  'unitAdd.description': 'Açıklama',
  'unitAdd.parentUnitId': 'Birim',
  'unitAdd.parentUnitIdHint': 'Ana/Üst birim seçiniz',
  'unitAdd.questionAddAvailable': 'Soru bankası (Birim derse dönüşür)',
  'unitAdd.examDoneAvailable': 'Sınav yapmaya uygun',
  'unitAdd.onlyForCategorization': 'Sadece kategoriler için',
  'unitAdd.requireUseOfLO': 'Öğrenme hedefi kullanımını zorunlu kıl',
  'unitAdd.add': 'Birim Ekle',
  'unitAdd.update': 'Değişiklikleri Kaydet',
  'unitAdd.id': 'Birim ID',
  'unitAdd.visible': 'Aktif',
  'unitAdd.invisible': 'Pasif',
  'unitAdd.successMessage': 'Birim başarıyla eklendi!',
  'unitAdd.editSuccess': 'Birim başarıyla güncellendi!',
  'unitAdd.maxLengthID': 'Birim ID en fazla 50 karakter olmalıdır.',
  'unitAdd.minLengthID': 'Birim ID en az 1 karakter olmalıdır.',
  'unitAdd.maxLengthName': 'Birim adı adı en fazla 200 karakter olmalıdır.',
  'unitAdd.minLengthName': 'Birim adı adı en az 1 karakter olmalıdır.',
  'unitAdd.patternName':
    "Birim adı yalnızca harf, rakam ve - _ . , ; : ' @ / \\ ( ) [ ] '{ }' sembolleri içerebilir.",
  'unitAdd.maxLengthDesc':
    'Birim açıklaması adı en fazla 500 karakter olmalıdır.',
  'unitAdd.minLengthDesc': 'Birim açıklaması adı en az 1 karakter olmalıdır.',
  'unitAdd.patternDesc':
    "Birim açıklaması yalnızca harf, rakam ve - _ . , ; : ' @ / \\ ( ) [ ] '{ }' sembolleri içerebilir.",
  'unitAdd.title': 'Birimi Güncelle',
  'unitAdd.popupTitle': 'Birim güncelle',
  'unitAdd.message':
    'Birimi güncellemek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'unitAdd.confirm': 'Evet, birimi güncelle',
  'unitAdd.cancel': 'İptal',

  //Tooltips
  'unitAdd.requiresUseOfLo-tooltip':
    'Bu özellik ana birimde yer alan tüm derslere soru eklenirken öğrenme hedefi kullanımını zorunlu tutar.',
};
