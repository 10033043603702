import React, { useState, useEffect } from 'react';

// ** Third party
import { FormattedMessage } from 'react-intl';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import Dropzone from 'react-dropzone';

// ** Components
import ExamodEditor from '../../../components/examod/editor/ExamodEditor';
import DropzoneContent from '../attachment/DropzoneContent';
import ProgressBar from '../../../components/examod/progress/ProgressBar';
import AttachmentFileList from '../attachment/AttachmentFileList';
import CourseSelection from '../add/CourseSelection';

// ** Domain
import { hasQuestionContentError } from '../../../domain/GroupedQuestion';
import {
  acceptedAttachmentExtensions,
  attachementFileTypes,
  getAttachmentName,
  getSeperatedFileName,
  getTotalAttachmentSize,
  getUploadedFiles,
  handleZoomOptionImg,
  maxAttachementFileSize,
  onDropRejected,
} from '../../../domain/Question';

// ** Utility
import { checkResult } from '../../../utility/resultHandler';
import { toastError } from '../../../utility/toastUtil';

// ** Repo
import { uploadGroupAttachement } from '../../../data/GroupedQuestionRepository';

import { MAX_UPLOAD_LIMIT_MB } from '../../../configs/systemConfig';
import ImageZoomModal from '../../../components/examod/sessionPreview/ImageZoomModal';

const MainContent = ({
  setGroupContentEditorState,
  groupContentEditorState,
  groupContent,
  setGroupContent,
  mainContentAttachmentInfo,
  setMainContentAttachmentInfo,
  showQuestionErrors,
  setQuestionHasChange,
  questionHasChange,
  groupId,
  isQuickAction,
  courseList,
  setSelectedCourse,
  selectedCourse,
  getCreateQuestionMeta,
  questionAdded,
}) => {
  const { register, errors, trigger } = useForm();
  const intl = useIntl();

  const [isContentTouched, setIsContentTouched] = useState(false);
  const [openImageZoomModal, setOpenImageZoomModal] = useState(false);
  const [zoomedImg, setZoomedImg] = useState('');

  useEffect(() => {
    if (!questionHasChange) {
      setIsContentTouched(false);
    }
  }, [questionHasChange]);

  useEffect(() => {
    handleZoomOptionImg(onClickQuestionImage, setOpenImageZoomModal);
  }, [groupContent]);

  const onClickQuestionImage = (imgInfo) => {
    setZoomedImg(imgInfo);
    setOpenImageZoomModal(true);
  };

  const toggleImageZoom = () => {
    setOpenImageZoomModal((prev) => !prev);
  };

  const handleAttachmentUpload = async (files, id, retriedId) => {
    let tempId = id;
    const uploadedFiles = [];
    const errors = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      const [fileName, extension] = getSeperatedFileName(file.name);
      const result = await uploadGroupAttachement(
        extension.toUpperCase(),
        getAttachmentName(fileName),
        formData
      );
      if (checkResult(result)) {
        const uploadResult = { ...result.data, tempId: retriedId || tempId };
        uploadedFiles.push(uploadResult);
      } else {
        errors.push({ file: file, tempId: retriedId || tempId });
      }
      tempId++;
    }
    setMainContentAttachmentInfo((prev) => ({
      ...prev,
      uploaded: [...prev.uploaded, ...uploadedFiles],
      errors: [...prev.errors, ...errors],
    }));
  };

  // ** Removes attachment item from all related arrays
  const removeAttachmentItem = (file) => {
    const attachments = mainContentAttachmentInfo.files.filter(
      (attc) => attc.tempId !== file.tempId
    );
    const uploadedFiles = mainContentAttachmentInfo.uploaded.filter(
      (attc) => attc.tempId !== file.tempId
    );
    const errors = mainContentAttachmentInfo.errors.filter(
      (attc) => attc.tempId !== file.tempId
    );
    setMainContentAttachmentInfo((prev) => ({
      ...prev,
      files: attachments,
      uploaded: uploadedFiles,
      errors: errors,
    }));
  };

  const retryUpload = async (fileTempId) => {
    const attachmentErrors = mainContentAttachmentInfo.errors;
    const retriedFile = attachmentErrors.find((err) => {
      return err.tempId === fileTempId;
    });
    setMainContentAttachmentInfo((prev) => ({
      ...prev,
      errors: attachmentErrors.filter((err) => err.tempId !== fileTempId),
    }));
    handleAttachmentUpload([retriedFile.file], null, retriedFile.id);
  };

  const cancelAttachmentUpload = (file) => {
    const attachments = mainContentAttachmentInfo.files.filter(
      (attc) => attc.tempId !== file.tempId
    );
    const uploadedFiles = mainContentAttachmentInfo.uploaded.filter(
      (attc) => attc.tempId !== file.tempId
    );
    const canceled = [...mainContentAttachmentInfo.canceled, file];
    setMainContentAttachmentInfo((prev) => ({
      ...prev,
      files: attachments,
      uploaded: uploadedFiles,
      canceled: canceled,
    }));
  };

  // ** On file drop to the attachment dropzone
  const onFileDrop = (files) => {
    const droppedFilesSize = files.reduce(
      (prev, current) => prev + current.size,
      0
    );
    if (
      getTotalAttachmentSize(mainContentAttachmentInfo.files) +
        droppedFilesSize >
      maxAttachementFileSize
    ) {
      toastError(
        intl.formatMessage({ id: 'questionAdd.attachmentSizeWarning' })
      );
      return;
    }
    let tempId = mainContentAttachmentInfo.tempId;
    const tempAttachments = [];
    for (const file of files) {
      const [fileName, extension] = getSeperatedFileName(file.name);
      const generalType = attachementFileTypes[file.type.split('/')[0]];
      const fileInfo = {
        name: getAttachmentName(fileName),
        size: file.size,
        generalType: generalType,
        type: `${generalType}/${extension}`.toLowerCase(),
        tempId: tempId,
      };
      tempAttachments.push(fileInfo);
      tempId++;
    }
    setMainContentAttachmentInfo((prev) => ({
      ...prev,
      tempId: tempId,
      files: [...mainContentAttachmentInfo.files, ...tempAttachments],
    }));
    setQuestionHasChange(true);
    handleAttachmentUpload(files, tempId - tempAttachments.length);
  };

  return (
    <>
      {isQuickAction ? (
        <CourseSelection
          courses={courseList}
          getCreateQuestionMeta={getCreateQuestionMeta}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
          isSubmitted={showQuestionErrors}
          disabledCourseSelection={questionAdded}
          setInputTouched={setQuestionHasChange}
        />
      ) : null}
      <Card className="main-content-container">
        <div className="main-content-header-wrapper">
          <div className="main-content-header">
            <CardTitle>
              <FormattedMessage id="groupedQuestion.contentTitle" />
            </CardTitle>
            <CardSubtitle>
              <FormattedMessage id="groupedQuestion.prepareContent" />
            </CardSubtitle>
          </div>
        </div>
        <CardBody>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label>
                  <span className="text-danger"> *</span>
                  <FormattedMessage id={'groupedQuestion.content'} />
                </Label>
                <ExamodEditor
                  handleZoomImg={() =>
                    handleZoomOptionImg(
                      onClickQuestionImage,
                      setOpenImageZoomModal
                    )
                  }
                  innerRef={register({
                    required: true,
                  })}
                  editorClassName={classnames({
                    'examod-editor': true,
                    invalid:
                      hasQuestionContentError(groupContent, 'max') ||
                      (showQuestionErrors &&
                        hasQuestionContentError(groupContent, 'min')),
                  })}
                  setText={setGroupContent}
                  editorState={groupContentEditorState}
                  setEditorState={(state) => {
                    if (isContentTouched) {
                      setQuestionHasChange(true);
                    }
                    setIsContentTouched(true);
                    setGroupContentEditorState(state);
                  }}
                />
                {hasQuestionContentError(groupContent, 'max') ? (
                  <span className="text-error">
                    {intl.formatMessage({
                      id: 'questionAdd.editorMaxMessage',
                    })}
                  </span>
                ) : null}
                {showQuestionErrors &&
                hasQuestionContentError(groupContent, 'min') ? (
                  <span className="text-error">
                    {intl.formatMessage({
                      id: 'questionAdd.editorMinMessage',
                    })}
                  </span>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="question-details-card">
        <CardBody className="pt-0">
          <CardHeader className="d-block pl-0 mb-2">
            <CardTitle>
              <FormattedMessage id={'questionAdd.questionDetailsTitle'} />
            </CardTitle>
            <CardSubtitle className="mt-0">
              <FormattedMessage id={'questionAdd.questionDetailsSubtitle'} />
            </CardSubtitle>
          </CardHeader>
          <Row className="question-tools mb-2">
            <Col md="6">
              <Label className="question-attachment-dropzone-label">
                <div className="text-nowrap">
                  <FormattedMessage id={'questionAdd.attachments'} />{' '}
                  <span className="attachment-uploaded-file-size-info">
                    (
                    {getTotalAttachmentSize(
                      getUploadedFiles(
                        mainContentAttachmentInfo.uploaded,
                        mainContentAttachmentInfo.canceled
                      ),
                      true
                    )}{' '}
                    MB / {MAX_UPLOAD_LIMIT_MB} MB)
                  </span>
                </div>
              </Label>
              <ProgressBar
                end={maxAttachementFileSize}
                progress={getTotalAttachmentSize(
                  getUploadedFiles(
                    mainContentAttachmentInfo.uploaded,
                    mainContentAttachmentInfo.canceled
                  )
                )}
              />
              <Dropzone
                onDrop={onFileDrop}
                noClick
                accept={acceptedAttachmentExtensions}
                maxSize={maxAttachementFileSize}
                onDropRejected={(files) => onDropRejected(files, intl)}
              >
                {({ getRootProps, getInputProps, open }) => (
                  <div>
                    <div
                      {...getRootProps({
                        className: 'dropzone',
                      })}
                    >
                      <input {...getInputProps()} />
                      <DropzoneContent open={open} />
                    </div>
                  </div>
                )}
              </Dropzone>
            </Col>
            <Col md="6">
              <AttachmentFileList
                attachments={mainContentAttachmentInfo.files}
                attachmentUploaded={mainContentAttachmentInfo.uploaded}
                attachmentErrors={mainContentAttachmentInfo.errors}
                canceledAttachments={mainContentAttachmentInfo.canceled}
                removeAttachmentItem={removeAttachmentItem}
                cancelAttachmentUpload={cancelAttachmentUpload}
                retryUpload={retryUpload}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ImageZoomModal
        src={zoomedImg.src}
        isOpen={openImageZoomModal}
        toggle={toggleImageZoom}
        width={zoomedImg.width}
        height={zoomedImg.height}
      />
    </>
  );
};

export default MainContent;
