export default {
  'topicPopup.title': '{param} Topic Settings',
  'topicPopup.nameHint': 'Type topic name...',
  'topicPopup.subTitle': 'TOPIC NAME',
  'topicPopup.subTitleActions': 'ACTIONS',
  'topicPopup.moveButton': 'Move',
  'topicPopup.editButton': 'Edit',
  'topicPopup.deleteButton': 'Delete',
  'topicPopup.foundAlert': "Couldn't found any topics",
  'topicPopup.deleteAlert': 'Topic has been successfully deleted.',
  'topicPopup.updateAlert': 'Topic has been successfully updated.',
  'topicPopup.moveAlert': 'Topics has been successfully moved.',
  'topicPopup.reset': 'Reset',
  'topicPopup.discardConfirmationTitle': 'Discard changes',
  'topicPopup.discardConfirmationMessage':
    'The changes you make will not be saved. Are you sure you want to discard all changes?',
  'topicPopup.discardConfirmationConfirmButton': 'Discard',
  'topicPopup.discardConfirmationCancelButton': 'Cancel',
  'topicPopup.minMessage': 'Topic should be at least 5 characters.',
  'topicPopup.maxMessage': 'Topic should be max 50 characters.',
  'topicPopup.requiredMessage': 'This field cannot be blank.',
  'topicPopup.drag': 'drag',
  'topicPopup.save': 'Save',
};
