import React from 'react'
import { FormattedMessage } from 'react-intl';


const ToastMessage = ({message, imgSrc, titleId}) => {
    return (
      <div className="toast-message-container">
        <img className="container-img" src={imgSrc} alt='triangle-exclamation-icon' height={32} width={32}></img>
        <div>
          <h6>
            <FormattedMessage id={titleId} />
          </h6>
          <p>{message}</p>
        </div>
      </div>
    );
  };


export default ToastMessage;