export default {
  'faq.title': 'Have Any Questions?',
  'faq.subtitle':
    'Please feel free to contact with us anythime if you cannot find the answers to help you in Frequently Asked Questions page.',
  'faq.table': 'Table of Content',
  'faq.supporters': 'Supporters',
  'faq.tübitak': 'Tübitak',
  'faq.arge': 'Ar-Ge Support',
  'faq.biggEge': 'Bigg Ege',
  'faq.acceleration': 'Acceleration Support',
};
