export default {
  'groups.title': 'Grup Yönetimi',
  'groups.subtitle':
    'Organizasyon genelindeki grupları ve kullanıcıları belirleyin',
  'groups.name': 'Grup Adı',
  'groups.description': 'Grup Açıklaması',
  'groups.userCount': 'Kullanıcı Sayısı',
  'groups.status': 'Durum',
  'groups.actions': 'İşlemler',
  'groups.addGroup': 'Grup Ekle',
  'groups.search': 'Ara',
  'groups.show': 'Sayfa Başı',
  'groups.entries': 'Grup Göster',
  'groups.users': 'Kullanıcılar',
  'groups.details': 'Düzenle',
  'groups.delete': 'Sil',
  'groups.prevButton': 'Önceki',
  'groups.nextButton': 'Sonraki',
  'groups.deleteConfirmationTitle': 'Grubu sil',
  'groups.deleteConfirmationMessage':
    'Grubu silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'groups.deleteConfirmationConfirmButton': 'Evet, grubu sil',
  'groups.deleteConfirmationCancelButton': 'İptal',
  'groups.deleteSuccess': 'Grup başarıyla silindi!',
  'groups.editConfirmationTitle': 'Grubu düzenle',
  'groups.editConfirmationMessage':
    'Grubu düzenlemek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'groups.editConfirmationConfirmButton': 'Evet, grubu düzenle',
  'groups.showPassiveStatus': 'Pasif grupları göster',
  'groups.obj': 'grup',
  'groups.titleInformation': 'Hay Aksi! Henüz {titleValue} bulunmamaktadır.',
  'groups.messageInformation':
    'Organizasyonunuzun bu dersinde henüz {titleValue} bulunmamaktadır.',
  'groups.messageInformationAdd':
    'Dönem eklemek için {messageValue} düğmesine tıklayın.',
};
