import React from 'react';
import PropTypes from 'prop-types';
import EditorUtils from '@draft-js-plugins/utils';
import AddLinkForm from './AddLinkForm';

const LinkButton = ({
  ownTheme,
  placeholder,
  onOverrideContent,
  validateUrl,
  theme,
  onRemoveLinkAtSelection,
  store,
  linkButton: InnerLinkButton,
}) => {
  const onAddLinkClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const content = (contentProps) => (
      <AddLinkForm
        {...contentProps}
        placeholder={placeholder}
        theme={ownTheme}
        validateUrl={validateUrl}
      />
    );

    onOverrideContent(content);
  };

  const editorState = store.getEditorState?.();
  const hasLinkSelected = editorState
    ? EditorUtils.hasEntity(editorState, 'LINK')
    : false;

  return (
    <InnerLinkButton
      onRemoveLinkAtSelection={onRemoveLinkAtSelection}
      hasLinkSelected={hasLinkSelected}
      onAddLinkClick={onAddLinkClick}
      theme={theme}
    />
  );
};

LinkButton.propTypes = {
  placeholder: PropTypes.string,
  store: PropTypes.object.isRequired,
  ownTheme: PropTypes.object.isRequired,
  onRemoveLinkAtSelection: PropTypes.func.isRequired,
  validateUrl: PropTypes.func,
};

export default LinkButton;
