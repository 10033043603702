import React from 'react';

// ** Third party
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { RotateCw, Trash2, X } from 'react-feather';

// ** Components
import Spinner from '../../../components/@vuexy/spinner/Loading-spinner';

// ** Domain
import {
  attachementFileTypes,
  fileHasError,
  fileIsUploaded,
  getFileSizeAsMb,
} from '../../../domain/Question';

// ** Utility
import { isNullOrEmpty } from '../../../utility/collectionUtil';

// ** Assets
import { ReactComponent as AudioFileIcon } from '../../../assets/img/questions/audio-file.svg';
import { ReactComponent as VideoFileIcon } from '../../../assets/img/questions/video-file.svg';

const AttachmentFileList = ({
  attachments,
  attachmentUploaded,
  attachmentErrors,
  canceledAttachments,
  retryUpload,
  removeAttachmentItem,
  cancelAttachmentUpload,
}) => {
  return !isNullOrEmpty(attachments) ? (
    <div className="attachment-file-wrapper">
      {attachments.map((file, index) => (
        <div
          key={index}
          className={classnames('attachment-file-item', {
            'file-has-error': fileHasError(file.tempId, attachmentErrors),
          })}
        >
          <div className="attachment-file-item-info-wrapper">
            <div className="attachment-file-item-info">
              {file.generalType === attachementFileTypes.audio ? (
                <AudioFileIcon className="file-icon icon " />
              ) : (
                <VideoFileIcon className="file-icon icon" />
              )}
              {!fileIsUploaded(
                file.tempId,
                attachmentUploaded,
                attachmentErrors,
                canceledAttachments
              ) ? (
                <Spinner customClassName="attachment-spinner" />
              ) : null}
              <div className="file-info-text-wrapper">
                <p className="file-name">{file.name}</p>
                <small className="file-size">
                  {getFileSizeAsMb(file.size)} - {file.type}
                </small>
              </div>
            </div>
            {fileHasError(file.tempId, attachmentErrors) ? (
              <div
                className="file-error-info"
                onClick={() => retryUpload(file.tempId)}
              >
                <FormattedMessage id="questionAdd.attachmentUploadError" />
                <RotateCw className="retry-file-upload-icon" />
              </div>
            ) : null}
          </div>
          {fileIsUploaded(
            file.tempId,
            attachmentUploaded,
            attachmentErrors,
            canceledAttachments
          ) ? (
            <Trash2
              className="remove-icon"
              size={18}
              onClick={() => removeAttachmentItem(file)}
            />
          ) : (
            <X
              className="cancel-attachment-upload-icon"
              onClick={() => cancelAttachmentUpload(file)}
            />
          )}
        </div>
      ))}
    </div>
  ) : null;
};

export default AttachmentFileList;
