import React from 'react';
import { toast } from 'react-toastify';

import successToastImg from '../../src/assets/img/toasts/successToast.svg';
import errorToastImg from '../../src/assets/img/toasts/errorToast.svg';

import ToastMessage from '../components/examod/toast/ToastMessage';




// ** Duration constants
const SHORT_DURATION = 3000;
const LONG_DURATION = 6000;

// ** Toasts error with the given duration.
export const toastError = (message, duration = SHORT_DURATION) => {

  toast.error(<ToastMessage message= {message} imgSrc={errorToastImg} titleId={'toasts.error'}/>, {
    autoClose: duration,
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

// ** Toasts success with the given duration
export const toastInfo = (message, duration = SHORT_DURATION) => {
  toast.info(message, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

// ** Toasts success with the given duration

export const toastSuccess = (message, duration = SHORT_DURATION) => {

  toast.success( <ToastMessage message= {message} imgSrc={successToastImg} titleId={'toasts.success'}/>, {
    autoClose: duration,
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

// ** Toasts error with long duration
export const toastErrorLong = (message) => {
  toastError(message, LONG_DURATION);
};

// ** Toasts success with long duration
export const toastSuccessLong = (message) => {
  toastSuccess(message, LONG_DURATION);
};
