export default {
  'rolesList.addUNIT': 'Add Unit Role',
  'rolesList.addSYSTEM': 'Add System Role',
  'rolesList.addInfo': 'Add a new role',
  'rolesList.editRole': 'Edit',
  'rolesList.SYSTEM': 'System Role',
  'rolesList.UNIT': 'Unit Role',
  'rolesList.anchor': 'Anchor',
  'rolesList.systemRoles': 'System Roles',
  'rolesList.unitRoles': 'Unit Roles',
  'rolesList.view': 'View',

  // Tooltips
  'rolesList.systemRoles_tooltip':
    'You can create <b>system-wide roles</b> for your users by grouping the permissions you want. This role type is not available for <b>candidate-type</b> users.',
  'rolesList.unitRoles_tooltip':
    'You can create <b>unit-specific roles</b> roles for your users by grouping the permissions you want. You can assign these roles to users for a unit/course or for all units/courses in the system. This role type is not available for <b>candidate-type</b> users. ',
};
