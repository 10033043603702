export default {
  'toasts.downloadStart':
    'Lütfen bekleyin, dosya indirilmek için hazırlanıyor.',
  'toasts.downloadFailed':
    'Dosya indirilemedi, tekrar deneyin veya kurumunuz ile iletişime geçin.',
  'toasts.downloadWaiting':
    'We are preparing your exams please wait for it to download. this depends on your how many exams are being compressed and downloaded. only {file} files are left',
  'toasts.fileSizeError': "Dosya boyutu {maxSize} mb'yi geçmemelidir",
  'toasts.fileSizeErrorKb': "Dosya boyutu {maxSize} kb'yi geçmemelidir",
  'toasts.fileNumberError': 'Yalnızca bir adet dosya yüklenebilir.',
  'toasts.invalidFileTypeUpload': 'Geçersiz dosya türü',
  'questionAdd.generalSizeError':
    'Eklediğiniz dosya sistemin izin verdiğinden daha büyük.',
  'toasts.success':
    'Başarılı!',
  'toasts.error':
    'Hata!',

};
