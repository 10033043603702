export default {
  'questionEdit.delete': 'Delete Question',
  'questionEdit.editSuccess': 'Question updated successfully!',
  'questionEdit.deleteSuccess': 'Question deleted successfully!',
  'questionEdit.deleteConfirmationTitle': 'Delete question',
  'questionEdit.deleteConfirmationMessage':
    'Are you sure to delete the question? This operation cannot be reversed.',
  'questionEdit.deleteConfirmationConfirmButton': 'Yes, delete question',
  'questionEdit.title': 'Edit Question',
};
