import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

// ** Components
import Sidebar from './components/menu/vertical-menu/Sidebar';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import ConfirmAlert from '../components/examod/alert/ConfirmAlert';
import CreateMcQuestion from '../views/question/add/AddMcQuestion';
import GroupedQuestion from '../views/question/groupedQuestion/GroupedQuestion';

import themeConfig from '../configs/themeConfig';
import { getSidebarState, saveSidebarState } from '../data/UserLocalDataSource';
import CreateOpenEndedQuestion from '../views/question/add/AddOpenEndedQuestion';

class VerticalLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      sidebarState: getSidebarState(),
      layout: themeConfig.theme,
      collapsedContent: getSidebarState(),
      sidebarCollapsed: getSidebarState(),
      sidebarHidden: false,
      currentLang: 'en',
      appOverlay: false,
      currRoute: this.props.location.pathname,
      langDropdown: false,
      changeLanguageConfirmation: false,
      isMcqModal: false,
      isOEModal: false,
      isGroupedModal: false,
    };
  }

  collapsedPaths = [];

  mounted = false;

  updateWidth = () => {
    if (this.mounted) {
      this.setState((prevState) => ({
        width: window.innerWidth,
      }));
    }
  };

  componentDidMount() {
    this.mounted = true;
    const {
      location: { pathname },
    } = this.props;

    if (this.mounted) {
      if (window !== 'undefined') {
        window.addEventListener('resize', this.updateWidth, false);
      }
      if (this.collapsedPaths.includes(pathname)) {
        this.props.collapseSidebar(true);
      }

      const layout = themeConfig.theme;
      const dir = themeConfig.direction;
      if (dir === 'rtl') {
        document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      } else {
        document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      }
      return layout === 'dark'
        ? document.body.classList.add('dark-layout')
        : layout === 'semi-dark'
        ? document.body.classList.add('semi-dark-layout')
        : null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      location: { pathname },
    } = this.props;

    const layout = themeConfig.theme;
    if (this.mounted) {
      if (layout === 'dark') {
        document.body.classList.remove('semi-dark-layout');
        document.body.classList.add('dark-layout');
      }
      if (layout === 'semi-dark') {
        document.body.classList.remove('dark-layout');
        document.body.classList.add('semi-dark-layout');
      }
      if (layout !== 'dark' && layout !== 'semi-dark') {
        document.body.classList.remove('dark-layout', 'semi-dark-layout');
      }

      if (prevProps.sidebarCollapsed !== this.props.sidebarCollapsed) {
        this.setState({
          collapsedContent: this.state.sidebarCollapsed,
          sidebarState: this.state.sidebarCollapsed,
        });
      }
      if (
        prevProps.sidebarCollapsed === this.props.sidebarCollapsed &&
        pathname !== prevProps.location.pathname &&
        this.collapsedPaths.includes(pathname)
      ) {
        this.collapseSidebar(true);
      }
      if (
        prevProps.sidebarCollapsed === this.props.sidebarCollapsed &&
        pathname !== prevProps.location.pathname &&
        !this.collapsedPaths.includes(pathname)
      ) {
        this.collapseSidebar(false);
      }
    }
  }

  handleCollapsedMenuPaths = (item) => {
    const { collapsedPaths } = this;
    if (!collapsedPaths.includes(item)) {
      collapsedPaths.push(item);
      this.collapsedPaths = collapsedPaths;
    }
  };

  toggleSidebarMenu = (val) => {
    this.setState({
      sidebarState: val,
      collapsedContent: !this.state.collapsedContent,
    });
  };

  sidebarMenuHover = (val) => {
    this.setState({
      sidebarState: val,
    });
  };

  handleSidebarVisibility = () => {
    if (this.mounted) {
      if (window !== undefined) {
        window.addEventListener('resize', () => {
          if (this.state.sidebarHidden) {
            this.setState({
              sidebarHidden: !this.state.sidebarHidden,
            });
          }
        });
      }
      this.setState({
        sidebarHidden: !this.state.sidebarHidden,
      });
    }
  };

  onClickQuickAction = (id) => {
    if (id === 'mcq') {
      this.setState({ isMcqModal: true });
    } else if (id == 'oeq') {
      this.setState({ isOEModal: true });
    } else if (id == 'groupedQ') {
      this.setState({ isGroupedModal: true });
    }
  };

  toggleQuestionModal = () => {
    this.setState({
      isMcqModal: false,
      isOEModal: false,
      isGroupedModal: false,
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleCurrentLanguage = (lang) => {
    this.setState({
      currentLang: lang,
    });
  };

  handleAppOverlay = (value) => {
    if (value.length > 0) {
      this.setState({
        appOverlay: true,
      });
    } else if (value.length < 0 || value === '') {
      this.setState({
        appOverlay: false,
      });
    }
  };

  handleAppOverlayClick = () => {
    this.setState({
      appOverlay: false,
    });
  };

  collapseSidebar = (val) => {
    this.setState({ sidebarCollapsed: val });
    saveSidebarState(val);
  };

  handleLanguageChange = (context, lang) => {
    this.setState({ context, lang });
    this.showChangeLanguageConfirmation();
  };
  // ** Shows user confirmation dialog
  showChangeLanguageConfirmation = () => {
    this.setState({ changeLanguageConfirmation: true });
  };

  // ** Hides user confirmation dialog
  hideChangeLanguageConfirmation = () => {
    this.setState({ changeLanguageConfirmation: false });
  };

  // ** Change Language confirm callback
  onChangeLanguageConfirmation = () => {
    this.state.context.switchLanguage(this.state.lang.locale);
  };

  // ** Change Language cancel callback
  onChangeLanguageCancel = () => {
    this.hideChangeLanguageConfirmation();
  };

  render() {
    const menuThemeArr = [
      'primary',
      'success',
      'danger',
      'info',
      'warning',
      'dark',
    ];

    const sidebarProps = {
      toggleSidebarMenu: this.collapseSidebar,
      toggle: this.toggleSidebarMenu,
      sidebarState: this.state.sidebarState,
      sidebarHover: this.sidebarMenuHover,
      sidebarVisibility: this.handleSidebarVisibility,
      visibilityState: this.state.sidebarHidden,
      activePath: this.props.match.path,
      collapsedMenuPaths: this.handleCollapsedMenuPaths,
      currentLang: this.state.currentLang,
      activeTheme: themeConfig.menuTheme,
      collapsed: this.state.collapsedContent,
      permission: this.props.permission,
      deviceWidth: this.state.width,
      currentUser: this.props.currentUser,
      onClickQuickAction: this.onClickQuickAction,
    };

    const navbarProps = {
      toggleSidebarMenu: this.toggleSidebarMenu,
      sidebarState: this.state.sidebarState,
      sidebarVisibility: this.handleSidebarVisibility,
      currentLang: this.state.currentLang,
      changeCurrentLang: this.handleCurrentLanguage,
      handleAppOverlay: this.handleAppOverlay,
      appOverlayState: this.state.appOverlay,
      navbarColor: themeConfig.navbarColor,
      navbarType: themeConfig.navbarType,
      handleLanguageChange: this.handleLanguageChange,
    };

    const footerProps = {
      footerType: themeConfig.footerType,
      hideScrollToTop: themeConfig.hideScrollToTop,
    };

    return (
      <div
        className={classnames(
          `wrapper vertical-layout theme-${themeConfig.menuTheme}`,
          {
            'menu-collapsed':
              this.state.collapsedContent === true && this.state.width >= 1200,
            'fixed-footer': themeConfig.footerType === 'sticky',
            'navbar-static': themeConfig.navbarType === 'static',
            'navbar-sticky': themeConfig.navbarType === 'sticky',
            'navbar-floating': themeConfig.navbarType === 'floating',
            'navbar-hidden': themeConfig.navbarType === 'hidden',
            'theme-primary': !menuThemeArr.includes(themeConfig.menuTheme),
          }
        )}
      >
        <Sidebar {...sidebarProps} />
        <div
          className={classnames('app-content content', {
            'show-overlay': this.state.appOverlay === true,
          })}
          onClick={this.handleAppOverlayClick}
        >
          <Navbar {...navbarProps} />
          <div className="content-wrapper">{this.props.children}</div>
        </div>

        <Footer {...footerProps} />
        <div
          className="sidenav-overlay"
          onClick={this.handleSidebarVisibility}
        />
        <CreateMcQuestion
          isQuickAcion
          isOpen={this.state.isMcqModal}
          toggleSidebar={this.toggleQuestionModal}
        />
        <CreateOpenEndedQuestion
          isQuickAcion
          isOpen={this.state.isOEModal}
          toggleSidebar={this.toggleQuestionModal}
        />
        <GroupedQuestion
          isQuickAction
          isOpen={this.state.isGroupedModal}
          toggleSidebar={this.toggleQuestionModal}
        />

        <ConfirmAlert
          wrapperClassName="change-language-alert"
          // style={{ position: 'absolute', top: 90 }}
          isShown={this.state.changeLanguageConfirmation}
          title={<FormattedMessage id="assessment.languageConfirmationTitle" />}
          message={
            <FormattedMessage id="assessment.languageConfirmationMessage" />
          }
          confirmBtnText={
            <FormattedMessage id="assessment.languageConfirmationConfirmButton" />
          }
          cancelBtnText={<FormattedMessage id="buttons.cancel" />}
          onConfirm={this.onChangeLanguageConfirmation}
          onCancel={this.onChangeLanguageCancel}
          confirmBtnCssClass="btn-filled-ba"
        />
      </div>
    );
  }
}

export default VerticalLayout;
