export default {
  'groupUsers.header': 'Grubun Kullanıcılarını Düzenle',
  'groupUsers.header2': 'Kullanıcıları Düzenle',
  'groupUsers.title': '{param} Grubundaki Kullanıcılar',
  'groupUsers.subtitle': 'Grubun üyelerini görüntüleyin veya düzenleyin',
  'groupUsers.groupMembers': 'Grup Üyeleri ',
  'groupUsers.allMembers': 'Tüm Üyeler ',
  'groupUsers.search': 'Ara',
  'groupUsers.selectAll': '(Tümünü Seç)',
  'groupUsers.moveRight': 'Gruptan çıkar',
  'groupUsers.moveLeft': 'Gruba ekle',
  'groupUsers.updateSuccess': 'Grup kullanıcıları başarıyla güncellendi!',
};
