export default {
  'assessmentStat.assessmentStatistics': 'Assessment Statistics',
  'assessmentStat.assessmentSubtitle':
    'Overview of candidate assessment statistics',
  'assessmentStat.questionStatistics': 'Question Statistics',
  'assessmentStat.questionSubtitle':
    "Overview of assessment's question statistics",
  'assessmentStat.type': 'type',
  'assessmentStat.affinityType': 'Affinity Type',
  'assessmentStat.attempt': 'attempt',
  'assessmentStat.avg': 'Avg. answer time',
  'assessmentStat.correctAnswer': 'correct answer (%)',
  'assessmentStat.difficulty': 'difficulty (%)',
  'assessmentStat.discrimination': 'discrimination',
  'assessmentStat.reliability': 'reliability',
  'assessmentStat.details': 'details',
  'assessmentStat.ALL_GRADES': 'The average of all scores',
  'assessmentStat.ALL_GRADES-tooltip':
    'The general average of the candidates participating in the assessment.',
  'assessmentStat.HIGHEST_GRADES': 'The average of highest scores',
  'assessmentStat.HIGHEST_GRADES-tooltip':
    '27% of candidates with the highest score in the assessment.',
  'assessmentStat.LOWEST_GRADES': 'The average of lowest scores',
  'assessmentStat.LOWEST_GRADES-tooltip':
    '27% of candidates with the lowest score in the assessment.',
  'assessmentStat.candidate': 'Candidate',
  'assessmentStat.averageTime': 'Average time',
  'assessmentStat.range': 'Range (%)',
  'assessmentStat.per': '{per}%',
  'assessmentStat.avgDifficulty': 'Average difficulty',
  'assessmentStat.avgDifficulty-tooltip1': '100% - 60%: Easy question',
  'assessmentStat.avgDifficulty-tooltip2':
    '60% - 40%: Average difficulty question',
  'assessmentStat.avgDifficulty-tooltip3': '40% - 0%: Difficult question',
  'assessmentStat.standardDeviation': 'Standard deviation',
  'assessmentStat.standardDeviation-tooltip':
    'It shows the level of spread of the scores around the mean.',
  'assessmentStat.testVariance': 'Test variance',
  'assessmentStat.testVariance-tooltip':
    'The variance, as the square of the standard deviation, expresses the extent to which the assessment results are spread out.',
  'assessmentStat.consistencyCoef': "Cronbach's alpha",
  'assessmentStat.consistencyCoef-tooltip':
    "Cronbach's alpha reliability coefficient is a reliability calculation method that expresses the consistency of the questions with each other",
  'assessmentStat.overallStat': 'Overall Statistics',
  'assessmentStat.sectionTitle': 'Section {index} : {name} Statistics',
  'assessmentStat.analysis': 'Analysis',
  'assessmentStat.minAlert':
    'Since the number of candidates who took the exam is less than 3, statistical calculations can be misleading.',
  'assessmentStat.select': 'Select',
  'assessmentStat.selectedQuestion': 'Selected question(s)',
  'assessmentStat.acceptAsTrue': 'Accept as true',
  'assessmentStat.cancelQuestion': 'Cancel',
  'assessmentStat.applyRecalculate': 'Apply & Recalculate',
  'assessmentStat.cancelDropdownTooltip':
    'The action to be taken for the question(s) will only be valid for this assessment. These changes will not be included in the statistics on the "Performance" screen of the relevant question.',
  'assessmentStat.CORRECT-Tooltip':
    'The question was considered true for all respondents. Therefore, the statistics of the question are not included in the performance screen and this assessment.',
  'assessmentStat.REMOVE-Tooltip':
    'The question is canceled for this assessment. Therefore, the statistics of the question are not included in the performance screen and this assessment.',
  'assessmentStat.noSelectionWarn': 'Please select question(s)',
  'assessmentStat.confirmContinue': 'Are you sure you want to continue?',
  'assessmentStat.continue': 'Continue',
  'assessmentStat.cancel': 'Cancel',
  'assessmentStat.acceptedQuestions':
    'The selected {questionCount} ({questions}) questions will be considered true for the candidates who answered. This action cannot be reversed.',
  'assessmentStat.canceledQuestions':
    'The selected {questionCount} ({questions}) will be canceled. This action cannot be reversed.',
  'assessmentStat.acceptedSuccess':
    'The selected question(s) were changed to true for candidates.',
  'assessmentStat.canceledSuccess': 'The selected question(s) canceled.',
  'assessmentStat.invalidOperationWarn':
    'You will not be able to act on the question(s) before the assessment end date.',
  'assessmentStat.updateFreqInfo':
    'Assessment statistics are updated every 5 minutes by Examod',

  // Classification tooltips
  'assessmentStat.classificationRanges': 'Classification Ranges',
  // Difficulty
  'assessmentStat.class1_difficulty': '100% - 61%: Easy question',
  'assessmentStat.class2_difficulty': '60% - 41%: Average difficulty question',
  'assessmentStat.class3_difficulty': '40% - 0%: Difficult question',
  // Discrimination
  'assessmentStat.class1_discrimination': '1 - 0.40: Very good discriminator',
  'assessmentStat.class2_discrimination':
    '0.39 - 0.30: Good discriminator but could be improved',
  'assessmentStat.class3_discrimination':
    '0.29 - 0.20: Intermediate discriminator, needs to be improved',
  'assessmentStat.class4_discrimination':
    '0.19 - -1: Incorrect discriminator, should be removed',
  //Reliability
  'assessmentStat.class1_reliability': '1 - 0.81: Very high reliability',
  'assessmentStat.class2_reliability': '0.80 - 0.61: High reliability',
  'assessmentStat.class3_reliability': '0.60 - 0.41: Intermediate reliability',
  'assessmentStat.class4_reliability': '0.40 - 0.21: Low reliability',
  'assessmentStat.class5_reliability': '0.20 - 0: Very low reliability',
};
