export default {
  'permissionGroup.QUESTION_': 'Question management',
  'permissionGroup.QUESTION_ATTRIBUTE_': 'Question attributes',
  'permissionGroup.LEARNING_OBJECTIVE_': 'Unit / Course objectives',
  'permissionGroup.PERIODS_': 'Periods',
  'permissionGroup.UNIT_': 'Units',
  'permissionGroup.USER_': 'User',
  'permissionGroup.USER_ROLE_': 'User role',
  'permissionGroup.SETTINGS_': 'Settings',
  'permissionGroup.USER_GROUP_': 'User group',
  'permissionGroup.EXAM_EVALUATION_': 'Exam evaluation',
  'permissionGroup.REPORT_': 'Reports',
  'permissionGroup.ASSESSMENT_': 'Assessments',
  'permissionGroup.PLAN_DETAILS_': 'Account details',
  'permissionGroup.EXAM_SETTINGS_': 'Assessment settings',
  'permissionGroup.RUBRIC_': 'Rubrics',
  'permissionGroup.ASSESSMENT_CATEGORY_': 'Assessment categories',
  'permissionGroup.ASSESSMENT_PROCTORING_RULES_': 'Assessment proctoring rules',
};
