export default {
  'reactDraft.PAPER_BASED': 'Paper-based Optical',
  'reactDraft.PAPER_BASED_CLASSIC': 'Paper-based CLassic',
  'reactDraft.ON_SITE': 'Onsite / Test Center',
  'reactDraft.PERFORMANCE_EXAM': 'Performance',
  'reactDraft.ONLINE': 'Online',
  'reactDraft.NONE': 'None',
  'reactDraft.ABSOLUTE': 'Absolute',
  'reactDraft.GLOBAL_RATING': 'Global rating (Only rubrics)',
  'reactDraft.BELL_CURVE': 'Relative (Bell Curve)',
  'reactDraft.FINISHED': 'Finished',
  'reactDraft.ONGOING': 'On going',
  'reactDraft.NOT_STARTED': 'Not started',
  'reactDraft.CANCELLED': 'Canceled',
  'reactDraft.unknown': 'Unknown',
};
