// ** React imports
import React from 'react';

// ** Third Party Components
import { X } from 'react-feather';
import Proptypes from 'prop-types';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// ** Analytics Imports
import { trackEvent } from '../../../analytics/analyticsTracker';

function Sidebar(props) {
  // ** Props
  const {
    width,
    open,
    toggleSidebar,
    size,
    bodyClassName,
    contentClassName,
    wrapperClassName,
    headerClassName,
    className,
    title,
    children,
    closeBtn,
    headerLeftComponent,
    closeEvent,
    noHeader,
    ...rest
  } = props;

  // ** If user passes custom close btn render that else default close btn
  const renderCloseBtn = closeBtn || (
    <X
      className="cursor-pointer"
      size={15}
      onClick={() => {
        toggleSidebar();
        trackEvent(closeEvent);
      }}
    />
  );

  return (
    <Modal
      isOpen={open}
      toggle={toggleSidebar}
      contentClassName={classnames({
        [contentClassName]: contentClassName,
      })}
      modalClassName={classnames('modal-slide-in', {
        [wrapperClassName]: wrapperClassName,
      })}
      className={classnames({
        [className]: className,
        'sidebar-lg': size === 'lg',
        'sidebar-sm': size === 'sm',
        'sidebar-md': size === 'md',
        'sidebar-xl': size === 'xl',
      })}
      /*eslint-disable */
      {...(width !== undefined
        ? {
            style: { width: String(width) + 'px' },
          }
        : {})}
      /* eslint-enable */
      {...rest}
    >
      {!noHeader && (
        <ModalHeader
          className={classnames({
            [headerClassName]: headerClassName,
          })}
          toggle={toggleSidebar}
          close={renderCloseBtn}
          tag="div"
        >
          <h5 className="modal-title">
            {headerLeftComponent && (
              <span className="header-left-component">
                {headerLeftComponent}
              </span>
            )}
            <span className="align-middle">{title}</span>
          </h5>
        </ModalHeader>
      )}
      <ModalBody
        className={classnames('flex-grow-1', {
          [bodyClassName]: bodyClassName,
        })}
      >
        {children}
      </ModalBody>
    </Modal>
  );
}

export default Sidebar;

// ** PropTypes
Sidebar.propTypes = {
  open: Proptypes.bool.isRequired,
  toggleSidebar: Proptypes.func.isRequired,
  size: Proptypes.oneOf(['sm', 'lg', 'md']),
  className: Proptypes.string,
  bodyClassName: Proptypes.string,
  contentClassName: Proptypes.string,
  wrapperClassName: Proptypes.string,
  children: Proptypes.any.isRequired,
  width: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  closeEvent: Proptypes.string,
};
