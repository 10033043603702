export default {
  'roles.addHeader': 'Add New Role',
  'roles.UNIT_addHeader': 'Add a New Unit Role',
  'roles.SYSTEM_addHeader': 'Add a New System Role',
  'roles.editHeader': 'Edit Role',
  'roles.subtitle': 'Set role permissions',
  'roles.name': 'Role Name',
  'roles.desc': 'Role Description',
  'roles.permissions': 'Role Permissions',
  'roles.add': 'Add Role',
  'roles.switchText': 'Active / Passive',
  'roles.parentRole': 'Parent Role',
  'roles.editSuccess': 'Role updated successfully!',
  'roles.success': 'Role created successfully!',
  'roles.permissionWarning': 'Please select permissions for role!',
  'roles.minLengthName': 'Role name cannot be left blank.',
  'roles.maxLengthName': 'Role name should be max 100 characters.',
  'roles.maxLengthDesc': 'Role description should be max 500 characters.',
  'roles.statusError': "Role status couldn't changed.",
  'roles.select': 'Select',
  'roles.global': 'Global Settings Permissions',
  'roles.account': 'Accounts Settings Permissions',
  'roles.assessment': 'Unit / Course Permissions',
  'roles.administrative': 'Administrative Dashboard',
  'roles.viewHeader': 'View Role',
  'roles.viewSubtitle': 'View role permissions',

  // Tooltips
  'roles.parentRole_tooltip': '-',
};
