export default {
  'generalSettings.header': 'General Settings',

  'generalSettings.organizationName': 'Organization Name',
  'generalSettings.website': 'Web Site',
  'generalSettings.language': 'System Language (Default)',
  'generalSettings.locale': 'System Time Zone (Default)',
  'generalSettings.settings': 'Settings',
  'generalSettings.icon': 'Organization Icon',
  'generalSettings.upload': 'Upload Logo',
  'generalSettings.dropzoneInfo1': 'Attachment should not exceed 800 kb',
  'generalSettings.dropzoneInfo2': 'Supported file type: JPEG, GIF or PNG',
  'generalSettings.info':
    'Your organization logo is a way for you to visually identify in the examod system. It is used in your dashboard, exams layouts and examod mobile app.',
  'generalSettings.textTitle': 'Some tips on choosing a good icon:',
  'generalSettings.text1': 'Use a solid background color.',
  'generalSettings.text2': 'Use a graphical logo or image rather than text.',
  'generalSettings.text3': 'Leave some space around your icon.',
  'generalSettings.text4': 'Upload an image that is 132 px square or larger.',
  'generalSettings.organizationNameHint':
    'ex: Acme Graduate School OR Acme Inc.',
  'generalSettings.websiteHint': 'ex: https://www.example.com',
  'generalSettings.invalidMessage': 'Website is invalid.',
  'generalSettings.requiredMessage': 'This field cannot be blank.',
  'generalSettings.orgNameMaxMessage':
    'Organization name must be less than 100 characters long.',
  'generalSettings.orgNameMinMessage':
    'Organization name must be at least 2 characters.',
  'generalSettings.orgNameCharMessage':
    'Organization name can only contain letters, digits and - @ /. & symbols.',
  'generalSettings.success': 'Settings updated successfully!',

  'generalSettings.title': 'Organization Settings',
  'generalSettings.subtitle': "Define your organization's general settings",
  'generalSettings.orgInfo': 'Organization Information',
  'generalSettings.orgInfoSubtitle': 'Update the information',
  'generalSettings.orgLogo': 'Organization Logo',
  'generalSettings.orgLogoSubtitle': 'Update the logo',
  'generalSettings.preview': 'Preview',
  'generalSettings.previewSubtitle': 'Organization logo preview',
  'generalSettings.file': 'File',
  'generalSettings.fileSubtitle':
    'Drag or select the organization logo you want to import',
  'generalSettings.saveConfirmMessage':
    'Are you sure you want to save the changes? If you continue, the changes you make will apply to your entire organization.',
  'generalSettings.orgLogoTooltip':
    'Your organization logo is a way for you to visually identify in the examod system. It is used in your dashboard, exams layouts and examod mobile app.',

  'generalSettings.supportInfo': 'Support Information',
  'generalSettings.supportInfoSubtitle': 'Define email for support',
  'generalSettings.supportInfoHint':
    'Support information allows you to define or update an authorized user to whom requests for support (New Ticket) submitted to your organization will be directed.',
  'generalSettings.supportInfoUser': 'User',
};
