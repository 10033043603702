export default {
  'summary.title': 'Onay & Özet',
  'summary.subtitle': 'Onay, özet ve bilgiler',
  'summary.information': 'Dosya bilgisi ve önizleme',
  'summary.fileName': 'Dosya adı:',
  'summary.creationDate': 'Oluşturma tarihi:',
  'summary.summary': 'Özet ve bilgiler',
  'summary.warning': 'Uyarı!',
  'summary.total': 'Toplam adaylar: ',
  'summary.transfered': 'Transfer edilen adaylar: ',
  'summary.nonTransferable': 'Aktarılamayan adaylar: ',
  'summary.danger': 'Dikkat!',
  'summary.success': 'Başarılı',
  'summary.dangerInfo':
    'Devam ederseniz, dosya aşağıdaki hatalar ile gönderilecek!',
  'summary.successInfo': 'Yüklediğiniz dosyada hata yok.',
  'summary.nonTransferableCandidates': 'Transfer edilemez adaylar',
  'summary.questionSize': 'Soru sayısı eşleşmiyor',
  'summary.preview': 'Önizleme',
  'summary.complete': 'Tamamla',
  'summary.successMessage': 'Optik yanıtları başarıyla içe aktarıldı.',
  'summary.discardChanges': 'Değişiklikleri Sil',
  'summary.discardChangesText':
    'Tüm değişiklikleri silmek istediğinizden emin misiniz',
  'summary.cancel': 'İptal',
  'summary.discard': 'Sil',
  'summary.continueError':
    'Devam ederseniz dosya aşağıdaki hatalarla gönderilecektir!',
  'summary.importedListTitle': 'İçe aktarılan veri listesi önizlemesi',
  'summary.importedWarn':
    'İçe aktarılamayan adaylar (İçe aktarılamayan adaylar değerlendirmeye alınmayacaktır.)',
};
