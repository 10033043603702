import Cookies from 'js-cookie';

export const saveToken = (token) => {
  Cookies.set('token', token, {
    // secure : true,
    expires: 3,
  });
};

export const rememberToken = (token) => {
  localStorage.setItem('rememberToken', token);
};

export const getRememberToken = () => {
  return localStorage.getItem('rememberToken');
};

export const removeRememberToken = () => {
  localStorage.removeItem('rememberToken');
};

export const removeToken = () => {
  Cookies.remove('token');
};

export const getToken = () => Cookies.get('token');

export const saveLang = (lang) => {
  localStorage.setItem('lang', lang);
};
export const getLang = () => localStorage.getItem('lang');

export const setDeviceIdToken = (id) => Cookies.set('device-id', id);

export const getDeviceIdToken = () => Cookies.get('device-id');

export const saveDeviceInfo = (device) => {
  const data = {
    id: device.id,
    deviceId: device.deviceId,
    deviceName: device.deviceName,
    useId: device.user.id,
  };
  localStorage.setItem('device-info', JSON.stringify(data));
};

export const getLocalDeviceInfo = () => {
  const device = localStorage.getItem('device-info');
  return JSON.parse(device);
};
export const removeLang = () => {
  localStorage.removeItem('lang');
};

export const saveSwitches = (switches) => {
  localStorage.setItem('userSwitches', switches);
};
export const getSwitches = () => localStorage.getItem('userSwitches');
export const removeSwitches = () => {
  localStorage.removeItem('userSwitches');
};

export const getSearchData = (userId) => localStorage.getItem(userId);

export const saveItemToHistory = (userId, titleId, value) => {
  let storage = JSON.parse(getSearchData(userId));
  if (storage) {
    storage[titleId] = value;
  } else {
    storage = {
      [titleId]: value,
    };
  }
  localStorage.setItem(userId, JSON.stringify(storage));
};

export const getItemFromHistory = (userId, titleId) => {
  let storage = JSON.parse(getSearchData(userId));
  return storage[titleId];
};

export const getRecentCourses = (userId) => {
  const userData = localStorage.getItem(userId);
  const parsedData = JSON.parse(userData) || {};
  return parsedData.recentCourses || [];
};

export const setRecentCourses = (userId, data) => {
  const recentCourses = getRecentCourses(userId);
  const userDataStr = localStorage.getItem(userId);
  const parsedUserData = userDataStr ? JSON.parse(userDataStr) : {};
  if (recentCourses.length < 4) {
    if (recentCourses.find((course) => course.id === data.id)) return;
    recentCourses.unshift(data);
  } else {
    if (recentCourses.find((course) => course.id === data.id)) return;
    recentCourses.pop();
    recentCourses.unshift(data);
  }
  parsedUserData.recentCourses = recentCourses;
  localStorage.setItem(userId, JSON.stringify(parsedUserData));
};

export const updateAllRecentCourses = (userId, recentCourses) => {
  const userDataStr = localStorage.getItem(userId);
  const parsedUserData = userDataStr ? JSON.parse(userDataStr) : {};
  parsedUserData.recentCourses = recentCourses;
  localStorage.setItem(userId, JSON.stringify(parsedUserData));
};

export const deleteRecentlyViewedCourse = (unitId, userId, unitName) => {
  // delete course name in history if needed
  const searchData = JSON.parse(getSearchData(userId));
  let courseName = unitName;
  if (searchData && searchData?.lastSeenCourseName === unitName) {
    courseName = '';
  }
  // delete recent courses
  const recentCourses = getRecentCourses(userId);
  const newRecentCourses = recentCourses.filter(
    (course) => course.id !== unitId
  );
  saveItemToHistory(userId, 'recentCourses', newRecentCourses);
};

export const deleteAllRecentCourses = (userId) => {
  const userData = localStorage.getItem(userId);
  const parsedData = JSON.parse(userData) || {};
  parsedData.recentCourses = [];
  localStorage.setItem(userId, JSON.stringify(parsedData));
};

export const saveSidebarState = (state) => {
  localStorage.setItem('navbarState', state);
};

export const getSidebarState = () => {
  const defaultState = true;
  const navbarState = localStorage.getItem('navbarState');
  if (navbarState) {
    return navbarState === 'true';
  }
  return defaultState;
};

export const removeSidebarState = () => {
  return localStorage.removeItem('navbarState');
};
