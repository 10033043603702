import {
  EXAM_SETTINGS_PERMISSIONS,
  // PROCTORING_RULES_PERMISSIONS,
} from '../configs/permissions';
import { PATHS } from '../navigation/Paths';
import { userHasPermission } from './User';

export const routerSelectors = {
  unitName: 'unitName',
  userName: 'userName',
  groupName: 'groupName',
  courseName: 'courseName',
  rubricName: 'rubricName',
  proctoringReport: 'proctoringReport',
  candidateName: 'candidateName',
  assessmentName: 'assessmentName',
};

export const getSidebarNavLink = (navLink, user) => {
  if (navLink === PATHS.examsTab) {
    // Temporarily put in the comment line, will be opened again in the future
    // if (userHasPermission(user, PROCTORING_RULES_PERMISSIONS.BROWSE)) {
    //   return PATHS.examsProctoring;
    // } else
    if (userHasPermission(user, EXAM_SETTINGS_PERMISSIONS.BROWSE)) {
      return PATHS.examsTypeSettings;
    } else {
      return PATHS.examsCategories;
    }
  }
  return navLink;
};
