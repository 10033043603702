export default {
  'blueprint.build': 'Belirtke Tablosu Oluştur',
  'blueprint.selectedQuestionInfo':
    '{unitCount} farklı birimden {topicCount} konuyu kapsayan {questionCount} soru seçilmiştir.',
  'blueprint.notBuild': 'Belirtke Tablosu Oluşturulmamıştır',
  'blueprint.buildInfo':
    'Sınava ilişkin belirtke tablosu oluşturmak için (+) öğesine tıklayın.',
  'blueprint.buildBlueprint': 'Oluştur',
  'blueprint.reset': 'Sıfırla',
  'blueprint.select': 'Seçiniz...',
  'blueprint.row': 'Tablo Satırı',
  'blueprint.rowInfo':
    'Burada seçilen parametre, belirtke tablosu oluşturulurken satırlarda yer alacak verileri belirler.',
  'blueprint.column': 'Tablo Sütunu',
  'blueprint.columnInfo':
    'Burada seçilen parametre, belirtke tablosu oluşturulurken sütunlarda yer alacak verileri belirler.',
  'blueprint.TOPIC': 'Konular',
  'blueprint.LEARNING_OBJECTIVE': 'Öğrenme Hedefleri',
  'blueprint.unitCourse': 'Birim / Ders',
  'blueprint.questionFields': 'Soru Özellikleri',
  'blueprint.required': 'Bu alan boş bırakılamaz!',
  'blueprint.unclassified': 'Sınıflandırılmamış',
  'blueprint.total': 'Toplam Soru',
  'blueprint.percentage': 'Yüzde',
  'blueprint.per': '%{param}',
  'blueprint.questionLetter': 'S{index}',
};
