export default {
  'menu.dashboard': 'Overview',
  'menu.dashboards': 'Dashboards',
  'menu.home': 'Overview',
  'menu.planDetails': 'Plan Details',
  'menu.settings': 'Settings',
  'menu.globalSettings': 'Global Settings',
  'menu.generalSettings': 'General Settings',
  'menu.structuralSettings': 'Structural Settings',
  'menu.general': 'General',
  'menu.structural': 'Structural',
  'menu.units': 'Units',
  'menu.periods': 'Periods',
  'menu.exams': 'Exams',
  'menu.questionFields': 'Question Attributes',
  'menu.accounts': 'Accounts',
  'menu.users': 'Users',
  'menu.groups': 'Groups',
  'menu.roles': 'Roles',
  'menu.assessment': 'Assessment',
  'menu.myUnits': 'My Units',
  'menu.myCourses': 'My Units / Courses',
  'menu.help': 'Help',
  'menu.newTicket': 'New Ticket',
  'menu.raiseSupport': 'Raise Support',
  'menu.faq': 'FAQ',
  'menu.about': 'About',
  'menu.welcome': 'Welcome',
  'menu.quickActions': 'Quick Actions',
  'menu.addQuestion': 'Create Question',
  'menu.addMcq': 'Multiple Choice Question',
  'menu.addGroupedQ': 'Grouped Question',
  'menu.addOpenEndedQ': 'Open Ended Question',
};
