import React, { useState } from 'react';
import {
  IntlContext,
  supportedLanguages,
} from '../../../../utility/context/Internationalization';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import ReactCountryFlag from 'react-country-flag';

import '../../../../assets/scss/components/language-dropdown.scss';

const LanguageDropdown = ({ handleLanguageChange, isOnNavbar = false }) => {
  const [langDropdown, setLangDropdown] = useState(false);

  const handleLangDropdown = () => {
    setLangDropdown((prev) => !prev);
  };
  return (
    <IntlContext.Consumer>
      {(context) => (
        <Dropdown
          className={`dropdown-language ${
            isOnNavbar ? 'navbar-lang-dropdown' : ''
          }`}
          isOpen={langDropdown}
          toggle={handleLangDropdown}
          data-tour="language"
        >
          <DropdownToggle className="language-dropdown-toggle" tag={'div'}>
            <ReactCountryFlag
              className="country-flag"
              countryCode={
                context.state.lang.key === 'en' ? 'us' : context.state.lang.key
              }
              svg
            />
            <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
              {context.state.lang.locale}
            </span>
          </DropdownToggle>
          <DropdownMenu className="language-dropdown-menu" right>
            {supportedLanguages.map((lang) => (
              <DropdownItem
                className="language-dropdown-item"
                key={lang.key}
                onClick={() => {
                  if (
                    (window.location.href.includes('assessment/add') ||
                      window.location.href.includes('assessment/edit') ||
                      window.location.href.includes('rubrics')) &&
                    context.state.lang.locale != lang.locale
                  ) {
                    handleLanguageChange(context, lang);
                  } else {
                    context.switchLanguage(lang.locale);
                  }
                }}
              >
                <ReactCountryFlag
                  className="country-flag"
                  countryCode={lang.key === 'en' ? 'us' : lang.key}
                  svg
                />
                <span className="ml-1">{lang.name}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      )}
    </IntlContext.Consumer>
  );
};

export default LanguageDropdown;
