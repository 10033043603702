export default {
  'login.login': 'Login',
  'login.email': 'E-mail',
  'login.emailPlaceholder': 'info@examod.com',
  'login.password': 'Password',
  'login.remember': 'Remember me',
  'login.forgotPass': 'Forgot Password?',
  'login.welcome': 'Welcome to Examod!',
  'login.organizationPlaceholder': 'Choose your organization',
  'login.organization': 'Organization',
  'login.digitalAssessment': 'Digital Exam & Assessment Platform',
  'login.digitalAssessmentSubtitle': 'Smart, Secure, Sustainable',
  'login.policy':
    'Use of our service and website is subject to our <terms>Terms and Conditions</terms> and <privacy>Privacy Policy.</privacy> ',
  'login.copyright':
    '	Copyright {copyrightSymbol} {year} <b>Examod</b>, All rights reserved.',
  'login.emailPatternError': 'Please enter a valid e-mail',
  'login.requiredError': 'This field cannot be blank.',
  'login.loginError': 'The email or password you entered is incorrect.',
  'login.next': 'Next',
  'login.redirecting': 'Redirecting',
  'login.redirectingExplanation':
    "This page will automatically redirect to your organization's Examod application. Please do not refresh the page or click the <b>“Back”</b> or <b>“Close”</b> button of your browser.",
  'login.redirectManuel':
    'If the redirection does not occur, please <b>click here</b> to go to manually.',
  'login.redirectErrorTitle': 'Whoops! Something went wrong when log in.',
  'login.toLogin': 'Back to login',
};
