export default {
  'performance.search': 'Search',
  'performance.candidates': 'CANDIDATES ({count})',
  'performance.EVALUATED': 'Evaluated',
  'performance.NOT_EVALUATED': 'Not evaluated',
  'performance.NOT_ATTENDED': 'Did not attend',
  'performance.per': '({per}%)',
  'performance.totalScore': 'Total Score',
  'performance.learningObjContent': 'Learning Objective :',
  'performance.criteria': 'CRITERIA',
  'performance.total': '{param} of total grade',
  'performance.reset': 'Reset',
  'performance.comment': 'COMMENT',
  'performance.commentAlert': 'Comment should be at most 500 characters.',
  'performance.writtenFeedback': 'Written General Feedback',
  'performance.oralFeedback': 'Oral General Feedback',
  'performance.oralFeedback-tooltip1':
    'Only 1 oral feedback can be given for each candidate.',
  'performance.oralFeedback-tooltip2':
    'The duration of audio recording should not exceed 5 minutes.',
  'performance.oralFeedback-tooltip3':
    'It is automatically saved when you stop the recording.',
  'performance.cancel': 'Cancel',
  'performance.finish': 'Finish Exam',
  'performance.confirmMessage':
    "You must save any changes you have made to this candidate's assessment, otherwise your most recent changes will be lost.",
  'performance.discard': 'Discard changes',
  'performance.continueWithoutSave': 'Continue',
  'performance.levelError': 'Please select the levels of the criterias!',
  'performance.successMessage': 'Candidate result saved successfully!',
  'performance.notAttendError':
    'Please update the status to perform the results!',
  'performance.recording': 'Recording...',
  'performance.deleteRecordingMessage':
    'Are you sure you want to delete the audio recording?',
  'performance.delete': 'Yes, Delete',
  'performance.audioFeedbackLimit': 'Up to 1 oral feedback can be recorded.',
  'performance.audioFeedbackSaved':
    'The audio recording has been successfully saved.',
  'performance.audioFeedbackDeleted':
    'The audio recording named {name}.mp3 has been deleted successfully.',
  'performance.micPermissionDenied':
    'Allow your browser to access the microphone for oral feedback',
  'performance.audioNotAttendError':
    'Please update the status to record oral feedback!',
  'performance.notFound': 'Not found',
  'performance.notFoundSearch': 'Could not find a result for your search',
  'performance.clear': 'Clear',
};
