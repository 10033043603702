import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  EXAMOD_LINK,
  TERMS_AND_CONDITIONS_LINK,
  PRIVACY_POLICY_LINK,
  ABOUT_US_LINK,
} from '../../../constants/ExternalLinks';

function Footer(props) {
  const footerTypeArr = ['sticky', 'static', 'hidden'];
  return (
    <footer
      className={classnames('footer footer-light', {
        'footer-static':
          props.footerType === 'static' ||
          !footerTypeArr.includes(props.footerType),
        'd-none': props.footerType === 'hidden',
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          <FormattedMessage
            id={'footer.copyright'}
            values={{
              year: new Date().getFullYear(),
              examod: (chunks) => (
                <a href={EXAMOD_LINK} target="_blank" rel="noopener noreferrer">
                  {chunks}
                </a>
              ),
              terms: (chunks) => (
                <a
                  href={TERMS_AND_CONDITIONS_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
              privacy: (chunks) => (
                <a
                  href={PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
              about: (chunks) => (
                <a
                  href={ABOUT_US_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </span>
        <span className="float-md-right d-md-block">
          <span className="align-middle">
            <FormattedMessage id={'footer.text'} />
          </span>
        </span>
      </p>
    </footer>
  );
}

export default Footer;
