import { httpClient } from '../utility/networkUtil';
import { onApiError } from '../utility/apiUtil';

const API_PREFIX = '/question';

/*
 * Gets Add/Update Question Meta Data
 */
export const getQuestionMeta = async (mode, id, questionType) =>
  httpClient
    .get(`${API_PREFIX}/meta/${mode}`, {
      params: {
        unitId: id,
        questionType,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Gets Question By Unit Id
 */
export const getQuestionsByUnitId = async (unitId) =>
  httpClient
    .get(`${API_PREFIX}/search`, {
      params: {
        unitId,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Gets Questions
 */
export const getQuestions = async (
  page,
  pageSize,
  searchTerm,
  searchId,
  status,
  type,
  affinityType,
  topic,
  unitId,
  field,
  keyword,
  objectiveId,
  lastUsedDateAfter,
  lastUsedDateBefore,
  neverUsed,
  approvedInDays,
  ownerId,
  filterQuestions
) => {
  if (filterQuestions === undefined) {
    filterQuestions = '';
  }
  if (isNaN(page)) {
    page = 0;
  }
  return httpClient
    .get(`${API_PREFIX}/search?excludeId=${filterQuestions}`, {
      params: {
        page,
        pageSize,
        rootText: searchTerm,
        id: searchId,
        status,
        type,
        affinityType,
        topicId: topic,
        unitId,
        attributeOptionIds: field,
        keywordId: keyword,
        objectiveId,
        approvedInDays: approvedInDays,
        lastUsedDateAfter,
        lastUsedDateBefore,
        neverUsed,
        userId: ownerId,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Gets Questions includeIds
 */
export const getQuestionsIncludeIds = async (ids) => {
  return httpClient
    .get(`${API_PREFIX}/search`, {
      params: {
        id: ids,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Gets Units
 */
export const getQuestionUnits = async () =>
  httpClient
    .get(`${API_PREFIX}/units`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Adds Question
 */
export const addQuestion = async (body) =>
  httpClient
    .post(API_PREFIX, body)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Deletes Question
 */
export const deleteQuestion = async (id) =>
  httpClient
    .delete(`${API_PREFIX}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Deletes Questions
 */
export const deleteQuestions = async (id) => {
  const ids = id.join(',');
  return httpClient
    .delete(`${API_PREFIX}/delete`, {
      params: {
        id: ids,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Gets Question
 */
export const getQuestion = async (id) =>
  httpClient
    .get(`${API_PREFIX}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Gets Question Detail assessments
 */
export const getQuestionAssessments = async (id) =>
  httpClient
    .get(`${API_PREFIX}/${id}/assessments`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Gets Question Search Meta
 */
export const getQuestionSearchMeta = async (unitId) =>
  httpClient
    .get(`${API_PREFIX}/search/meta`, {
      params: {
        unitId,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Moves Question To Given Topic
 */
export const moveQuestion = async (questionId, topicId) =>
  httpClient
    .put(`${API_PREFIX}/${questionId}/move/topic`, {
      params: {
        topicId,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Moves Questions To Given Topic
 */
export const moveQuestions = async (questionIds, topicId) => {
  let ids = '';
  questionIds.forEach((element) => {
    ids = `${ids}questionId=${element.toString()}&`;
  });
  return httpClient
    .put(`${API_PREFIX}/move/topic?${ids}topicId=${topicId}`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};

/*
 * Updates Question
 */
export const updateQuestion = async (
  id,
  root,
  unitId,
  type,
  time,
  answers,
  options,
  keywords,
  learningObjective,
  status,
  topicId,
  source,
  attachmentIds,
  textAnswer
) =>
  httpClient
    .put(`${API_PREFIX}/${id}`, {
      root,
      unitId,
      questionType: type,
      suggestedTime: time,
      answers,
      attributeOptions: options,
      keywords,
      learningObjectiveId: learningObjective,
      status,
      topicId,
      questionSource: source,
      attachmentIds: attachmentIds,
      textAnswer,
    })
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Upload Image
 */
export const uploadImage = async (formData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return httpClient
    .post(`${API_PREFIX}/image`, formData, config)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};

/*
 *  Returns the assessments related to given question
 */
export const getAssessmentsRelatedQuestion = async (questionId) =>
  httpClient
    .get(`${API_PREFIX}/${questionId}/assessments`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 *  Approves the draft questions
 */
export const approveQuestion = async (questionId) =>
  httpClient
    .put(`${API_PREFIX}/${questionId}/approve`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 *  Deprecates the approved question
 */
export const deprecateQuestion = async (questionId) =>
  httpClient
    .put(`${API_PREFIX}/${questionId}/deprecate`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 *  Reuses the deprecated question
 */
export const reuseQuestion = async (questionId) =>
  httpClient
    .put(`${API_PREFIX}/${questionId}/reuse`)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });

/*
 * Upload Image
 */
export const uploadAttachement = async (fileExtension, name, formData) => {
  const config = {
    params: {
      fileExtension: fileExtension,
      name: name,
    },
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return httpClient
    .post(`${API_PREFIX}/attachment`, formData, config)
    .then((response) => response.data)
    .catch((err) => {
      onApiError(err);
    });
};
