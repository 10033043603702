export default {
  'reset.title': 'Reset Password',
  'reset.info':
    'For your security, your new password must be different from previously used passwords.',
  'reset.newPassLabel': 'New Password',
  'reset.newPass': 'Enter your new password',
  'reset.confirmPassLabel': 'Confirm Password',
  'reset.confirmPass': 'Confirm your new password',
  'reset.setNewPassword': 'Set New Password',
  'reset.setPassword': 'Set Password',
  'reset.minLengthError': 'Password should be at least 6 characters',
  'reset.validateError': 'Passwords must match',
  'reset.patternError':
    'Need minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character',
  'reset.setPassInfo': 'Set a new password for your security.',
};
