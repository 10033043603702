export default {
  'groupedQuestion.title': 'Gruplandırılmış Soru Oluştur',
  'groupedQuestion.duplicate': 'Gruplandırılmış Soru Çoğalt',
  'groupedQuestion.edit': 'Gruplandırılmış Soru Düzenle',
  'groupedQuestion.mainContent': 'Ana İçerik',
  'groupedQuestion.addQuestion': 'Soru Ekle',
  'groupedQuestion.content': 'Gruplandırılmış soru içeriği',
  'groupedQuestion.contentTitle': 'Gruplandırılmış Soru İçeriği',
  'groupedQuestion.prepareContent':
    'Gruplandırılmış sorunun ana içeriğini hazırlayın',
  'groupedQuestion.questionNumber': 'Soru {number}',
  'groupedQuestion.save': 'Kaydet',
  'groupedQuestion.editSave': 'Değişiklikleri Kaydet',
  'groupedQuestion.approveAndLock': 'Onayla ve Kilitle',
  'groupedQuestion.discardConfirmationMessage':
    'Yaptığınız değişiklikler kaydedilmeyecektir. Tüm değişiklikleri silmek istediğinize emin misiniz?',
  'groupedQuestion.confirmationContinueTitle':
    'Devam etmek istediğinize emin misiniz?',
  'groupedQuestion.saveConfirmationMessage':
    'Bu soruyu taslak olarak kaydetmek istediğinize emin misiniz? Devam ederseniz, soru taslak olarak kaydedilecektir.',
  'groupedQuestion.lockConfirmationMessage':
    'Bu gruplandırılmış soruyu onaylamak ve kilitlemek istediğinize emin misiniz? Devam ederseniz, bu soru değerlendirmelerde kullanılabilir olacaktır.',
  'groupedQuestion.deleteGroup':
    'Gruplandırılmış bir soruyu silmek istediğinize emin misiniz? Bu işlem geri alınamaz.',
  'groupedQuestion.deleteSingle':
    'Bu soruyu silmek istediğinize emin misiniz? ',
  'groupedQuestion.cancel': 'İptal',
  'groupedQuestion.continue': 'Devam',
  'groupedQuestion.minApproved':
    'Gruplu soruyu onaylamak ve kilitlemek için en az iki soru eklemeli ve değişiklikleri kaydetmelisiniz.',
  'groupedQuestion.saveToApprove':
    'Gruplu soruyu onaylamak ve kilitlemek için öncelikle değişiklikleri kaydetmelisiniz.',
  'groupedQuestion.missingLoError':
    'Uyarı! Lütfen soruları ({indexes}) bir öğrenme hedefi ile ilişkilendirin.',
};
