export default {
  'delivery.title': 'Dağıtım Kurulumu',
  'delivery.complete': 'Kurulumu Tamamla',
  'delivery.previous': 'Önceki Adım',
  'delivery.draft': 'Taslak olarak Kaydet',
  'delivery.verify': 'Kimliği Doğrula',
  'delivery.fullscreen': 'Tam ekranı zorla',
  'delivery.forceDevices': 'Bilgisayar aygıtlarını zorla',
  'delivery.oneScreen': 'Ekran uzatma yapılamaz',
  'delivery.noTabs': 'Yeni sekme yok',
  'delivery.closeTabs': 'Açık sekmeleri kapat',
  'delivery.printing': 'Yazdırmayı devre dışı bırak',
  'delivery.cache': 'Önbelleği temizle',
  'delivery.reEntry': 'Yeniden girişi engelle',
  'delivery.shortcuts': 'Kes-kopyala-yapıştır kısayollarını devre dışı bırak',
  'delivery.rightClick': 'Sağ tıklamayı devre dışı bırak',
  'delivery.userId': '#',
  'delivery.candidateDetails': 'ADAY DETAYLARI',
  'delivery.enrollmentType': 'KAYIT TÜRÜ',
  'delivery.status': 'DURUM',
  'delivery.actions': 'İŞLEMLER',
  'delivery.deliveryAndSecurity':
    'Değerlendirmenin dağıtım ve gözetim ayarlarını yönetin',
  'delivery.proctoring': 'Sınav Gözetimi Ayarları',
  'delivery.verification': 'Doğrulama Seçenekleri',
  'delivery.lockdown': 'Kilitleme Seçenekleri',
  'delivery.candidateList': 'Adaylar',
  'delivery.addGroup': 'Grup Ekle',
  'delivery.addUser': 'Kullanıcı Ekle',
  'delivery.inviteUser': 'Yeni Kullanıcıyı E-Posta ile Davet Et',
  'delivery.select': 'Seçiniz',
  'delivery.reSend': 'Davetiyeyi Yeniden Gönder',
  'delivery.viewProfile': 'Profili Görüntüle',
  'delivery.unenroll': 'Kaydı iptal et',
  'delivery.candidateWarning': 'Seçilmiş aday bulunmamaktadır',
  'delivery.invite': 'Davet et',
  'delivery.mailWarning': 'Lütfen geçerli bir mail adresi giriniz',
  'delivery.resendAlert': ' Davetiye yeniden başarıyla gönderildi!',
  'delivery.generateBooklets': 'Kitapçık oluştur',
  'delivery.generateBooklets_info':
    'Değerlendirme için oluşturulacak kitapçık sayısını belirtin.',
  'delivery.generate': 'Oluştur',
  'delivery.previewBooklets': 'Kitapçıkları önizle',
  'delivery.previewBooklets_info': 'Oluşturulan kitapçıkları önizleyin.',
  'delivery.downloadBookletGuide':
    'Oluşturulan kitapçıklar sınav kurulumu tamamlandığında <b>"Değerlendirme Listesi > İşlemler > Tüm Kitapçıkları İndir"</b> eylemi ile indirilebilir.',
  'delivery.bookletSettings': 'Kitapçık Ayarları',
  'delivery.bookletSettingsUpdate':
    'Kitapçık ayarlarının güncel olduğundan emin olunuz!',
  'delivery.booklet': 'Kitapçık',
  'delivery.bookletInfo':
    'Bu testlerin tüm hakları saklıdır. Testlerin tamamının veya bir kısmının ilgili enstitünün yazılı izni olmaksızın herhangi bir amaçla kopyalanması, fotoğraflanması, çoğaltılması, yayınlanması veya kullanılması {param}.',
  'delivery.bookletInfoParam': 'yasaktır',
  'delivery.popupTitle': 'Kitapçık {char} Önizleme',
  'delivery.description': 'Değerlendirme Açıklaması',
  'delivery.INVITED': 'E-posta ile davet',
  'delivery.GROUP': 'Grup ({groupName})',
  'delivery.SEPARATELY': 'Elle',
  'delivery.emailWarning':
    'Bu e-postaya sahip kullanıcı zaten sistemde mevcut.',
  'delivery.bookletDuration': '{minutes} dakika',
  'delivery.saveChanges': 'Değişiklikleri Kaydet',
  'delivery.unverifiedUserFaceId':
    'Bu sınavda aday yüz tanıma denetimi bulunmaktadır. Yüz kimliği olmayan doğrulanmamış adaylar sınava eklenemez. Bu adayları listeden çıkartıp tekrar deneyiniz.',
  'delivery.alertTitle': 'Devam etmek istediğinize emin misiniz?',
  'delivery.alertMessagePart1': 'Bu değerlendirme,',
  'delivery.alertMessagePart2':
    'gözetim türünde oluşturulacaktır. Devam etmek istediğinize emin misiniz?',
  'delivery.sufficient': 'Yeterli',
  'delivery.inSufficient': 'Yetersiz',
  'delivery.requiredCredit': 'Gerekli Kredi:',
  'delivery.cancel': 'İptal',
  'delivery.continue': 'Devam et',
  'delivery.needCredit': 'Kredi gerekli',
  'delivery.inSufficient-tooltip':
    'Krediniz yetersiz. Gözetim türünü değiştirin veya yetkiliniz ile iletişime geçin',
  'delivery.inSufficientToast':
    'Krediniz yetersiz. Kaydet ve İncele gözetim türündeki değerlendirmeye eklediğiniz aday sayısı {addedCand}, gerekli kredi sayısı {creditNeed}. Gözetim türünü değiştirin veya yetkiliniz ile iletişime geçin.',
  'delivery.needCredit-tooltip':
    'Kuruluşunuzun Kaydet ve İncele kredisi yok. Yetkiliniz ile iletişime geçin.',
  'delivery.omrCandidates-ADDED_VIA_OMR_IMPORT':
    'Adayları CSV dosyası aracılığıyla manuel ekle',
  'delivery.omrCandidates-ADDED_WITH_ASSESSMENT':
    'Adayları Examod aracılığıyla ekle',
  'delivery.omrCandidates-ADDED_VIA_OMR_IMPORT-tooltip':
    "Bu seçenek ile değerlendirme adaysız olarak oluşturulur. Sınav sonrasında CSV dosyasında yer alan ve Examod'da kayıtlı adayların optik yanıtları içe aktarılabilir.",
  'delivery.omrCandidates-ADDED_WITH_ASSESSMENT-tooltip':
    'Bu seçenek ile değerlendirme adaylı olarak oluşturulur. Sınav sonrasında yalnızca değerlendirme aday listesinde kayıtlı adayların optik yanıtları CSV dosyası aracılığıyla içe aktarılabilir.',
  'delivery.omrCandidates-noCandidatesToast':
    'Sınav kurulumunun tamamlanması için aday eklenmelidir.',
  'delivery.omrSkipCandidate': 'Aday Seçimini Atla',
  'delivery.omrSkipCandidateTooltip':
    "Bu seçenek ile değerlendirme adaysız olarak oluşturulur. Sınav sonrasında CSV dosyasında yer alan ve Examod'da kayıtlı adayların optik yanıtları içe aktarılabilir.",
};
