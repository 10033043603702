export default {
  // Generic
  'generic.add': '追加',
  'generic.cancel': 'キャンセル',

  // BlockType
  'components.controls.blocktype.h1': '見出し1',
  'components.controls.blocktype.h2': '見出し2',
  'components.controls.blocktype.h3': '見出し3',
  'components.controls.blocktype.h4': '見出し4',
  'components.controls.blocktype.h5': '見出し5',
  'components.controls.blocktype.h6': '見出し6',
  'components.controls.blocktype.blockquote': '引用',
  'components.controls.blocktype.code': 'コード',
  'components.controls.blocktype.blocktype': 'スタイル',
  'components.controls.blocktype.normal': '標準テキスト',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'テキストの色',
  'components.controls.colorpicker.text': 'テキスト',
  'components.controls.colorpicker.background': 'ハイライト',

  // Embedded
  'components.controls.embedded.embedded': '埋め込み',
  'components.controls.embedded.embeddedlink': '埋め込みリンク',
  'components.controls.embedded.enterlink': 'リンクを入力してください',

  // Emoji
  'components.controls.emoji.emoji': '絵文字',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'フォント',

  // FontSize
  'components.controls.fontsize.fontsize': 'フォントサイズ',

  // History
  'components.controls.history.history': '履歴',
  'components.controls.history.undo': '元に戻す',
  'components.controls.history.redo': 'やり直し',

  // Image
  'components.controls.image.image': '画像',
  'components.controls.image.fileUpload': 'ファイルをアップロード',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText':
    'ここに画像をドラッグするか、クリックしてください',

  // Inline
  'components.controls.inline.bold': '太字',
  'components.controls.inline.italic': '斜体',
  'components.controls.inline.underline': '下線',
  'components.controls.inline.strikethrough': '取り消し線',
  'components.controls.inline.monospace': '等幅フォント',
  'components.controls.inline.superscript': '上付き文字',
  'components.controls.inline.subscript': '下付き文字',

  // Link
  'components.controls.link.linkTitle': 'リンクタイトル',
  'components.controls.link.linkTarget': 'リンク対象',
  'components.controls.link.linkTargetOption': '新しいウィンドウで開く',
  'components.controls.link.link': 'リンク',
  'components.controls.link.unlink': 'リンクを解除',

  // List
  'components.controls.list.list': 'リスト',
  'components.controls.list.unordered': '箇条書き',
  'components.controls.list.ordered': '番号付き',
  'components.controls.list.indent': 'インデント増',
  'components.controls.list.outdent': 'インデント減',

  // Remove
  'components.controls.remove.remove': '書式をクリア',

  // TextAlign
  'components.controls.textalign.textalign': '整列',
  'components.controls.textalign.left': '左揃え',
  'components.controls.textalign.center': '中央揃え',
  'components.controls.textalign.right': '右揃え',
  'components.controls.textalign.justify': '両端揃え',

  // New Examod localizations
  'components.controls.image.modal.title': 'Image Uploader',
  'components.controls.image.modal.description':
    'Browse or drag the image you want to upload',
  'components.controls.image.modal.content.image.or': 'or',
  'components.controls.image.modal.content.image.btn': 'Browse Files',
  'components.controls.image.modal.content.image.attachment':
    'Attachments should not exceed 2 mb',
  'components.controls.image.modal.content.image.supported':
    'Supported file types: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt Text',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Image alt text',
  'components.controls.image.modal.content.input.scale.label': 'Scale (%)',
  'components.controls.image.modal.contnet.url.address.label': '*URL (Adress)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Add image from address',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Clear Highlight',
  'components.controls.color.picker.highlight.black': 'Black Highlight',
  'components.controls.color.picker.highlight.grey': 'Grey Highlight',
  'components.controls.color.picker.highlight.red': 'Red Highlight',
  'components.controls.color.picker.highlight.green': 'Green Highlight',
  'components.controls.color.picker.highlight.blue': 'Blue Highlight',
  'components.controls.color.picker.highlight.yellow': 'Yellow Highlight',
  'components.controls.color.picker.highlight.examod': 'Examod Highlight',
  'components.controls.color.picker.highlight.purple': 'Purple Highlight',
  'components.controls.color.picker.highlight.pink': 'Pink Highlight',
};
