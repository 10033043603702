export default {
  'userDetails.header': 'User Details',
  'userDetails.detail': "{name} {surname}'s Details",
  'userDetails.title': 'User Summary',
  'userDetails.username': 'Username',
  'userDetails.name': 'Name',
  'userDetails.email': 'Email',
  'userDetails.status': 'Status',
  'userDetails.role': 'Role',
  'userDetails.company': 'Company',
  'userDetails.type': 'User Type',
  'userDetails.group': 'User Groups',
  'userDetails.noGroup': 'No Groups',
  'userDetails.delete': 'Delete',
  'userDetails.edit': 'Edit',
  'userDetails.verified': 'Verified',
  'userDetails.not_verified': 'Not Verified',
  'userDetails.system': 'System Context',
  'userDetails.unit': 'Unit Context',
  'userDetails.rolesTitle': "User's Roles",
  'userDetails.noSystemRole': 'There is no system context role for this user.',
  'userDetails.noUnitRole': 'There is no unit context role for this user.',
  'userDetails.deleteConfirmationTitle': 'Delete user',
  'userDetails.deleteConfirmationMessage':
    'Are you sure to delete the user? This operation cannot be reversed.',
  'userDetails.deleteConfirmationConfirmButton': 'Yes, delete user',
  'userDetails.deleteConfirmationCancelButton': 'Cancel',
  'userDetails.unit_course': 'Unit/Course',
  'userDetails.course': 'Course',
  'userDetails.passiveUserAlert':
    'Role assignment cannot be performed for passive users. The roles to which such users are assigned are not listed in the units or courses.',
  'userDetails.undefined': 'Not Defined',
  'userDetails.none': 'None',
  'userDetails.profileButton': 'Profile',
  'userDetails.language': 'Preferred Language',
  'userDetails.systemId': 'System ID',
};
