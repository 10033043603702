// ** React Imports
import React, { useEffect, useState, useRef, createRef } from 'react';

// ** Custom Components
import Avatar from '../../../../components/@vuexy/avatar/AvatarComponent';
import Spinner from '../../../../components/@vuexy/spinner/Loading-spinner';
import NotificationSubtitle from './NotificationSubtitle';
import RoleBadge from './RoleBadge';

// ** Third Party Components
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Bell,
  GitPullRequest,
  Globe,
  Layers,
  ExternalLink,
} from 'react-feather';
import {
  Badge,
  Media,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// ** Utility
import { checkResult } from '../../../../utility/resultHandler';
import { isEmpty } from '../../../../utility/collectionUtil';

// ** Domain
import {
  calculateListHeight,
  getLink,
  hasUnreadNotifications,
  reviseNotificationCount,
  onSrollBottom,
} from '../../../../domain/Notification';

// ** Repository
import {
  getNotifications,
  markAllNotificationRead,
  markNotificationRead,
} from '../../../../data/NotificationRepository';

// ** Style
import '../../../../assets/scss/components/notification-dropdown.scss';

const NotificationDropdown = () => {
  const notificationIcons = {
    ASSESSMENT_COMPLETED: <Layers />,
    UNIT_ROLE_ASSIGNED: <GitPullRequest />,
    SYSTEM_ROLE_ASSIGNED: <Globe />,
  };
  const defaultPageSize = 5;

  const listItemRef = useRef([]);

  const [isLoading, setIsLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalRecord, setTotalRecord] = useState(0);
  const [notificationsHeight, setNotificationsHeight] = useState(null);

  useEffect(() => {
    if (dropdownOpen && listItemRef.current.length && !notificationsHeight) {
      const notificationListHeight = calculateListHeight(listItemRef);
      setNotificationsHeight(notificationListHeight);
    }
  }, [listItemRef?.current?.length, dropdownOpen]);

  useEffect(() => {
    const controller = new AbortController();
    fetchNotifications(controller.signal);
    createNotificationRef();
    return () => {
      controller.abort();
    };
  }, [pageSize]);

  const markAsRead = async (notificationIds, url) => {
    const ids = [notificationIds];
    setIsLoading(true);
    const result = await markNotificationRead(ids.join(','));
    if (checkResult(result)) {
      openInNewTab(url);
      fetchNotifications();
    }
    setIsLoading(false);
  };

  const fetchNotifications = async (signal) => {
    setIsLoading(true);
    const result = await getNotifications(pageSize, signal);
    if (checkResult(result)) {
      setNotifications(result.data.items);
      setTotalRecord(result.data.totalRecords);
      setUnreadNotifications(result.data.unreadNotifications);
    }
    setIsLoading(false);
  };

  const createNotificationRef = () => {
    if (defaultPageSize >= pageSize) {
      for (let i = 0; i < defaultPageSize; i++) {
        listItemRef.current.push(listItemRef.current[i] ?? createRef());
      }
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const clearNotifications = async () => {
    const result = await markAllNotificationRead();
    if (checkResult(result)) {
      fetchNotifications();
    }
  };

  const openInNewTab = (url) => {
    const pathName = new URL(url).pathname;
    window.open(pathName, '_blank', 'noopener,noreferrer');
  };

  return (
    <Dropdown
      tag="li"
      className="dropdown-notification nav-item mr-25"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        tag="a"
        className="nav-link notification-toggle"
        href="/"
        onClick={(e) => e.preventDefault()}
      >
        <Bell size={20} />
        {hasUnreadNotifications(unreadNotifications) && (
          <Badge
            pill
            className={classnames('emerald-badge badge-up', {
              'overmuch-notification-badge': unreadNotifications > 99,
            })}
          >
            {reviseNotificationCount(unreadNotifications)}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu
        tag="ul"
        right
        className="dropdown-menu-media notifications-menu mt-0"
      >
        <li className="dropdown-menu-header">
          <DropdownItem className="d-flex" tag="div" header>
            <h4 className="notification-title mb-0 mr-auto">
              <FormattedMessage id="notification.notifications" />
            </h4>
            {hasUnreadNotifications(unreadNotifications) && (
              <p
                className="clear-notifications text-emerald cursor-pointer"
                onClick={() => clearNotifications()}
              >
                <FormattedMessage id="notification.clear" />
              </p>
            )}

            {hasUnreadNotifications(unreadNotifications) && (
              <Badge
                className="notification-count emerald-badge"
                tag="div"
                pill
              >
                <FormattedMessage
                  id="notification.count"
                  values={{
                    count: unreadNotifications,
                  }}
                />
              </Badge>
            )}
          </DropdownItem>
        </li>
        <PerfectScrollbar
          component="li"
          style={{ height: `${notificationsHeight}px` }}
          className={classnames(
            'media-list notification-list scrollable-container',
            {
              'is-empty': isEmpty(notifications),
            }
          )}
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
          }}
          onYReachEnd={() =>
            onSrollBottom(
              notifications.length,
              pageSize,
              totalRecord,
              dropdownOpen,
              setPageSize
            )
          }
        >
          {isEmpty(notifications) && !isLoading && (
            <p className="notifications-empty">
              <FormattedMessage id="notification.noNotifications" />
            </p>
          )}
          {notifications.map((item, index) => {
            return (
              <a
                key={item.id}
                className={classnames('list-item d-flex', {
                  'is-read': item.read,
                })}
                onClick={() => markAsRead(item.id, getLink(item))}
                ref={listItemRef.current[index]}
              >
                <Media className="d-flex align-items-start">
                  <React.Fragment>
                    <Media
                      className="notification-list-item-avatar-container"
                      left
                    >
                      <Avatar
                        icon={notificationIcons[item.origin]}
                        className="notification-avatar-circle-img"
                      />
                    </Media>
                    <Media body>
                      <p className="notification-text">
                        <FormattedMessage
                          id={`notification.${item.origin}`}
                          values={{
                            role: (
                              <RoleBadge
                                item={item}
                                notificationIcons={notificationIcons}
                              />
                            ),
                            unitName: (
                              <span className="text-emerald">
                                {
                                  JSON.parse(item.serializedContent).extraMap
                                    .unitName
                                }
                              </span>
                            ),
                            examName: (
                              <span className="text-emerald">
                                {
                                  JSON.parse(item.serializedContent).extraMap
                                    .assessmentName
                                }
                              </span>
                            ),
                          }}
                        />
                      </p>
                      <small className="notification-subtitle">
                        <NotificationSubtitle
                          type={item.origin}
                          timeStamp={item.date * 1000} // to ms
                        />
                      </small>
                    </Media>
                  </React.Fragment>
                </Media>
                <div className="read-info d-flex align-items-center">
                  <ExternalLink className="new-tab-icon" size={15} />
                  {!item.read && <div className="new-notification-warn"></div>}
                </div>
              </a>
            );
          })}
          {isLoading && (
            <div className="list-item-spinner">
              <Spinner />
            </div>
          )}
        </PerfectScrollbar>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
