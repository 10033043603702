export default {
  'recover.title': 'Recover your password',
  'recover.info':
    'Please enter your email associated with <b>Examod</b> account and we willl send you instructions to reset your password.',
  'recover.email': 'Email',
  'recover.recover': 'Recover Password',
  'recover.toLogin': 'Back to login',
  'recover.forgotPassword': 'Forgot Password?',
  'recover.sendReset': 'Send Reset Link',
  'recover.next': 'Next',
  'recover.emailPatternError': 'Please enter a valid e-mail',
  'recover.requiredError': 'This field cannot be blank.',
  'recover.emailNotFound': ' Email address was not found.',
  'recover.notDefinedInExamodError': '{email} is not defined in Examod.',
};
