export default {
  'examRules.verification': 'Candidate Verification Rules',
  'examRules.proctoring': 'Proctoring Rules',
  'examRules.typeSettings': 'Assessment Type Settings',
  'examRules.categories': 'Assessment Categories',
  'examRules.categoriesInfo':
    'Design the assessment categories structure of your organization',
  'examRules.deleteConfirmationTitle': 'Delete category',
  'examRules.deleteConfirmationMessage':
    'Are you sure to delete the category? This operation cannot be reversed.',
  'examRules.deleteConfirmationConfirmButton': 'Yes, delete category',
  'examRules.deleteConfirmationCancelButton': 'Cancel',
  'examRules.search': 'Search',
  'examRules.addCategory': 'Add Assessment Category',
  'examRules.editCategory': 'Edit',
  'examRules.deleteCategory': 'Delete',
  'examRules.saveChange': 'Save Changes',
  'examRules.status': 'status',
  'examRules.actions': 'actions',
  'examRules.popupTitle': 'Add Assessment Category',
  'examRules.popupTitleEdit': 'Edit {param} Category',
  'examRules.categoryName': 'Category Name',
  'examRules.categoryNameEdit': 'Category Name',
  'examRules.categoryNameMin': 'Category name should be at least 1 characters.',
  'examRules.categoryNameMax': 'Category name should be max 100 characters.',
  'examRules.categoryDesc': 'Category Description',
  'examRules.categoryDescMax':
    'Category description should be max 500 characters.',
  'examRules.categoryRoot': 'Associated Main Unit',
  'examRules.reset': 'Reset',
  'examRules.select': 'Select',
  'examRules.switchStatus': 'Active / Passive',
  'examRules.addSuccessAlert': 'Category added successfully!',
  'examRules.editSuccessAlert': 'Category updated successfully!',
  'examRules.moveSuccessAlert': 'Category moved successfully!',
  'examRules.addFailAlert': 'Please select a main unit!',
  'examRules.deleteSuccessAlert': 'Category deleted successfully!',
  'examRules.allRootUnits': 'All Main Units',
  'examRules.showPassiveCategories': 'Show passive categories',
  'examRules.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'examRules.messageInformation': 'There is no {titleValue} definition for your organization yet.',
  'examRules.messageInformationAdd': 'Click the {messageValue} button to add {titleValue}.',
  'examRules.obj': 'Assessment Category',
  'examRules.addAssessmentCategory': '"Add Assessment Category"',
};
