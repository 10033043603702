export const CONTROLLERS = {
  account: '/account',
  plan: '/plan',
  settings: '/settings',
  structuralSettings: '/structural-settings',
  profile: '/profile',
  users: '/users',
  userGroups: '/user-groups',
  roles: '/roles',
  questions: '/questions',
  pages: '/pages',
  invite: '/invite',
  myCourses: '/my-courses',
  myUnits: '/my-units',
  help: '/help',
  sessionView: '/session-preview',
  candResponsePreview: '/response-preview',
};
