export const REGEX_PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.+?[!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?]).{6,32}$/;
export const REGEX_ORGANIZATION = /^[A-Za-z0-9öÖüÜçÇşŞıİğĞ.&@\/\s-]+$/;
export const REGEX_WEBSITE =
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
export const REGEX_USERNAME = /^\S*$/;
export const REGEX_NUMBERS = /^[0-9]*$/;
export const REGEX_GROUPNAME = /^[A-Za-z0-9öüçşığ ]+$/;
export const REGEX_GRADE = /^[0-9]+$/;
export const REGEX_EMAIL = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const REGEX_ASSESSMENT_NAME =
  /^[a-zöÖüÜçÇşŞıİğĞ0-9-_ @;,/':\(\)\s\.{}\[\]\\c]*$/i;
export const REGEX_QUESTION_ID_SEARCH = /^#\d+/;
export const REGEX_IMG_TAG = /<img\b[^>]*>/i;
export const REGEX_UNIT = /^[a-zöÖüÜçÇşŞıİğĞ0-9-_ @;,/':\(\)\s\.{}\[\]\\c]*$/i;
export const REGEX_ACCEPTED_CHAR_PATTERN =
  /^[a-zöÖüÜçÇşŞıİğĞ0-9-_ @;,/':\(\)\s\.{}\[\]\\c]*$/i;

export const ATTRIBUTE_MAX_LENGTH = 100;
export const ATTRIBUTE_OPTION_MIN_LENGHT = 2;
