//* * React Imports
import React from 'react';

import { Progress } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

function ProgressBar({ start = 0, end, progress }) {
  const total = end - start;
  const percentage = progress < 0 ? 0 : Math.floor((progress / total) * 100);

  return (
    <span className="mt-1" style={{ width: '100%' }}>
      <Progress
        multi
        className="progress-lg position-relative"
        max={100}
        style={{ height: '1rem'}}
      >
        <div
          className="progress-text position-absolute"
          style={{
            top: '50%',
            left: '50%',
            color: percentage > 54 ? 'white' : 'black',
          }}
        >
          <b>
            <FormattedMessage
              id="plan.per"
              values={{
                per: percentage,
              }}
            />
          </b>
        </div>
        <Progress bar value={percentage}></Progress>
      </Progress>
    </span>
  );
}

export default ProgressBar;
