export default {
  'faq.title': 'Bir sorunuz mu var?',
  'faq.subtitle':
    'Cevabını bulamadığınız sorular için bizimle dilediğiniz zaman iletişime geçebilirsiniz.',
  'faq.table': 'Konular',
  'faq.supporters': 'Destekçiler',
  'faq.tübitak': 'Tübitak',
  'faq.arge': 'Ar-Ge Desteği',
  'faq.biggEge': 'Bigg Ege',
  'faq.acceleration': 'Hızlandırıcı Kurumlar',
};
