export default {
  'summary.title': 'Confirmation & Summary',
  'summary.subtitle': 'Confirmation, summary and informations',
  'summary.information': 'File information and preview',
  'summary.fileName': 'File name:',
  'summary.warning': 'Warning!',
  'summary.creationDate': 'Creation date:',
  'summary.summary': 'Summary and informations',
  'summary.total': 'Total candidates: ',
  'summary.transfered': 'Transfered candidates: ',
  'summary.nonTransferable': 'Non-transferable candidates: ',
  'summary.danger': 'Danger!',
  'summary.success': 'Success',
  'summary.dangerInfo':
    'If you continue, the file will be sent with the errors below!',
  'summary.successInfo': 'There is no error in the file you uploaded.',
  'summary.nonTransferableCandidates': 'Non-transferable candidates',
  'summary.questionSize': 'Question size is does not match',
  'summary.preview': 'Preview',
  'summary.complete': 'Complete',
  'summary.successMessage': 'OMR Answers imported successfully.',
  'summary.discardChanges': 'Discard Changes',
  'summary.discardChangesText': 'Are you sure you want to discard all changes',
  'summary.cancel': 'Cancel',
  'summary.discard': 'Discard',
  'summary.continueError':
    'If you continue, the file will be sent with the errors below!',
  'summary.importedListTitle': 'Imported data list preview',
  'summary.importedWarn':
    'Not importable candidates (Candidates who cannot be imported will not be assessed.)',
};
