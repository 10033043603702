import en from './en';
import fr from './fr';
import zh from './zh';
import ru from './ru';
import pt from './pt';
import ko from './ko';
import it from './it';
import nl from './nl';
import de from './de';
import da from './da';
import zh_tw from './zh_tw';
import pl from './pl';
import es from './es';
import ja from './ja';
import tr from './tr';

export default {
  en,
  fr,
  zh,
  ru,
  pt,
  ko,
  it,
  nl,
  de,
  da,
  zh_tw,
  pl,
  es,
  ja,
  tr,
};
