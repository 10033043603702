export default {
    "groupAdd.header": "Grup Ekle",
    "groupAdd.groupName": "Grup Adı",
    "groupAdd.groupDescription": "Grup Açıklaması",
    "groupAdd.add": "Grup Ekle",
    "groupAdd.placeholderName": "Grup adını girin",
    "groupAdd.placeholderDesc": "Grup açıklamasını girin",
    "groupAdd.nameSpecial": "Grup adı özel karakter içermemelidir.",
    "groupAdd.nameMin": "Grup adı en az 5 karakter olmalıdır.",
    "groupAdd.nameMax": "Grup adı en fazla 25 karakter olmalıdır.",
    "groupAdd.descMin": "Grup açıklaması en az 10 karakter olmalıdır.",
    "groupAdd.descMax": "Grup açıklaması en fazla 200 karakter olmalıdır.",
    "groupAdd.requiredMessage": " ",
    "groupAdd.addSuccess": "Grup başarıyla eklendi!",
    "groupAdd.status": "Aktif / Pasif"
};
