export default {
  'questionDetail.title': 'Question Details',
  'questionDetail.owner': 'Owner: ',
  'questionDetail.dateAdded': 'Added Date: ',
  'questionDetail.approvalAdded': 'Approval Date: ',
  'questionDetail.learningObjective': 'Learning Objective: ',
  'questionDetail.preview': 'Preview',
  'questionDetail.assessments': 'Assessments',
  'questionDetail.performance': 'Performance',
  'questionDetail.reviews': 'Reviews',
  'questionDetail.activities': 'Activities',
  'questionDetail.root': 'Question Root',
  'questionDetail.expectedAnswer': 'Expected Answer',
  'questionDetail.groupContent': 'Grouped Question Content',
  'questionDetail.answerInfo': 'Question Options',
  'questionDetail.true': 'true',
  'questionDetail.assessmentName': 'assessment name',
  'questionDetail.category': 'assessment category',
  'questionDetail.date': 'Creation Date',
  'questionDetail.attempt': 'attempt',
  'questionDetail.answerTime': 'avg. answer time',
  'questionDetail.correctAnswer': 'correct answer (%)',
  'questionDetail.per': '{count}%',
  'questionDetail.allAssessments': 'overall',
  'questionDetail.difficulty': 'difficulty (%)',
  'questionDetail.discrimination': 'discrimination',
  'questionDetail.general': 'General',
  'questionDetail.details': 'Question Details',
  'questionDetail.videos': 'Video(s)',
  'questionDetail.audios': 'Audio(s)',
  'questionDetail.examPreview': 'Exam View',
};
