export default {
  'permissionGroup.QUESTION_': 'Soru yönetimi',
  'permissionGroup.QUESTION_ATTRIBUTE_': 'Soru özellikleri',
  'permissionGroup.LEARNING_OBJECTIVE_': 'Birim / Ders hedefleri',
  'permissionGroup.PERIODS_': 'Zaman dilimi',
  'permissionGroup.UNIT_': 'Birimler',
  'permissionGroup.USER_': 'Kullanıcı',
  'permissionGroup.USER_ROLE_': 'Kullanıcı rolü',
  'permissionGroup.SETTINGS_': 'Ayarlar',
  'permissionGroup.USER_GROUP_': 'Kullanıcı grupları',
  'permissionGroup.EXAM_EVALUATION_': 'Sınav değerlendirme',
  'permissionGroup.REPORT_': 'Raporlar',
  'permissionGroup.ASSESSMENT_': 'Değerlendirmeler',
  'permissionGroup.PLAN_DETAILS_': 'Hesap detayları',
  'permissionGroup.EXAM_SETTINGS_': 'Sınav ayarları',
  'permissionGroup.RUBRIC_': 'Dereceli puanlama anahtarları',
  'permissionGroup.ASSESSMENT_CATEGORY_': 'Değerlendirme kategorileri',
  'permissionGroup.ASSESSMENT_PROCTORING_RULES_':
    'Değerlendirme gözetim kuralları',
};
