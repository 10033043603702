export default {
  'unitsList.title': 'Unit & Course Management',
  'unitsList.subtitle':
    'Design the unit and course structure of your organization',
  'unitsList.emptySubtitle': 'Manage units and courses you are authorized to',
  'unitsList.moveUnits': 'Move',
  'unitsList.moveUp': 'Move Up',
  'unitsList.moveDown': 'Move Down',
  'unitsList.moveSuccessMessage': 'Units are moved successfully',
  'unitsList.moveUnitDropdownPlaceholder': 'Select target unit',
  'unitsList.root': 'New Main Unit',
  'unitsList.deleteOption': 'Delete',
  'unitsList.moveOption': 'Move',
  'unitsList.updateOption': 'Edit',
  'unitsList.newChildOption': 'New Child Unit',
  'unitsList.manageUsers': 'Manage Users',
  'unitsList.visibilityChangedMessage': 'Unit visibility changed.',
  'unitsList.unitMovedAboveMessage': 'Unit moved above.',
  'unitsList.unitMovedBelowMessage': 'Unit moved below.',
  'unitsList.sortChildrenOption': 'Sort',
  'unitsList.sortUnitCustom': 'Custom',
  'unitsList.sortUnitNameAsc': 'Unit Name A-Z',
  'unitsList.sortUnitNameDesc': 'Unit Name Z-A',
  'unitsList.sortUnitIdAsc': 'Unit ID A-Z',
  'unitsList.sortUnitIdDesc': 'Unit ID Z-A',
  'unitsList.sortUnitMessage': '{unitName} unit sorted: {sortStrategy}',
  'unitsList.search': 'Search',
  'unitsList.moveLabel': 'Move Selected Units',
  'unitsList.deletionSuccess': 'Unit deleted successfully!',
  'unitsList.showHiddenUnit': 'Show hidden units or courses',
  'unitsList.courseBadge': 'Course',
  'unitsList.show': 'Show',
  'unitsList.hide': 'Hide',
  'unitsList.viewCourse': 'View Course',
  'unitsList.viewUnit': 'View Unit',
  'unitsList.emptyUnit':
    'There is no unit/course definition for your organization yet.',
  'unitsList.emptyUnitAdd':
    'There is no unit/course definition for your organization yet. Click the {param} button to add a unit or course.',
  'unitsList.noUnitSelected': 'Please select a unit or course.',
  'unitsList.noPeriodYet': 'Whoops! There is no Period yet.',
  'unitsList.noQAYet': 'Whoops! There is no Question Attribute yet.',
  'unitsList.noACYet': 'Whoops! There is no Assessment Category yet.',
  'unitsList.tableTitle': 'UNIT/COURSE NAME',
  'unitsList.tableActions': 'ACTIONS',
  'unitsList.messageInformationAdd':
    'Click the {messageValue} button to add {titleValue}.',
  'unitsList.unitorCourse': 'Unit or Course',
  'unitsList.addunitorCourse': 'Add Unit',
  'unitsList.assessmentCategory': 'Assessment Category',
  'unitsList.addAssessmentCategory': 'Add Assessment Category',
  'unitsList.questionAttribute': 'Question Attribute',
  'unitsList.addQuestionAttribute': 'Add Question Attribute',
  'unitsList.period': 'Period',
  'unitsList.addPeriod': 'Add Period',
  'unitsList.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'unitsList.messageInformation':
    'There is no {titleValue} definition for your organization yet.',
  'unitsList.obj': 'Unit/Course',
  'unitsList.addUnit': 'Add Unit',
  'unitsList.cancel': 'Cancel',
  'unitsList.moveUnitModalTitle': 'Unit/Course Move',
  'unitsList.moveUnitModalSubtitle':
    'Select the unit/course you want to move the selected unit to from the list below.',
  'unitList.selectedUnit': 'Selected Unit/Course',
  'unitList.targetUnit': 'Target Unit/Course',
};
