export default {
  'toasts.downloadStart': 'Please wait, preparing file for download.',
  'toasts.downloadFailed':
    'The file could not be downloaded, try again or contact your organization.',
  'toasts.downloadWaiting':
    'We are preparing your exams please wait for it to download. this depends on your how many exams are being compressed and downloaded. only {file} files are left',
  'toasts.fileSizeError': 'Attachment should not exceed {maxSize} mb',
  'toasts.fileSizeErrorKb': 'Attachment should not exceed {maxSize} kb',
  'toasts.fileNumberError': 'Only one file can be uploaded.',
  'toasts.invalidFileTypeUpload': 'Invalid file type',
  'questionAdd.generalSizeError':
    "The file you're attaching is bigger than the system allows.",
  'toasts.success':
    'Success!',
  'toasts.error':
    'Error!',
};
