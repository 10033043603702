import React from 'react';
import { UploadCloud } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import PrimaryButton from '../../../components/examod/buttons/primaryButton';
const DropzoneContent = ({ open }) => {
  return (
    <div className="dropzone-content">
      <UploadCloud className="upload-icon" size={46} />
      <p className="mb-0">
        <FormattedMessage id={'questionAdd.attachmentDropHere'} />
      </p>
      <p className="line mb-0">
        <FormattedMessage id={'questionAdd.attachmentOr'} />
      </p>
      <PrimaryButton
        className="btn-filled-ba mt-25 mb-50 "
        type="button"
        textId="questionAdd.attachmentBrowse"
        onClick={open}
      />
      <p className="attachment-restriction-text mb-0">
        <FormattedMessage id={'questionAdd.attachmentRestrictionSize'} />
      </p>
      <p className="attachment-restriction-text mb-0">
        <FormattedMessage id={'questionAdd.attachmentRestrictionType'} />
      </p>
    </div>
  );
};

export default DropzoneContent;
