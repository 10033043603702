export default {
  'delivery.title': 'Delivery Setup',
  'delivery.complete': 'Complete Setup',
  'delivery.previous': 'Previous Step',
  'delivery.draft': 'Save as Draft',
  'delivery.verify': 'Verify Identity',
  'delivery.fullscreen': 'Force fullscreen',
  'delivery.forceDevices': 'Force computer devices',
  'delivery.oneScreen': 'Screen extending disabled',
  'delivery.noTabs': 'No new tabs',
  'delivery.closeTabs': 'Close open tabs',
  'delivery.printing': 'Disable printing',
  'delivery.cache': 'Clear cache',
  'delivery.reEntry': 'Prevent re-entry',
  'delivery.shortcuts': 'Disable cut-copy-paste shortcuts',
  'delivery.rightClick': 'Disable right-click',
  'delivery.userId': '#',
  'delivery.candidateDetails': 'CANDIDATE DETAILS',
  'delivery.enrollmentType': 'ENROLL TYPE',
  'delivery.status': 'STATUS',
  'delivery.actions': 'ACTIONS',
  'delivery.deliveryAndSecurity':
    "Manage the assessment's delivery and proctoring settings",
  'delivery.proctoring': 'Proctoring Exam Settings',
  'delivery.verification': 'Verification Options',
  'delivery.lockdown': 'Lockdown Options',
  'delivery.candidateList': 'Candidates',
  'delivery.addGroup': 'Add Groups',
  'delivery.addUser': 'Add User',
  'delivery.inviteUser': 'Invite New User with E-Mail',
  'delivery.select': 'Select',
  'delivery.reSend': 'Re-send Invitation',
  'delivery.viewProfile': 'View Profile',
  'delivery.unenroll': 'Unenroll',
  'delivery.candidateWarning': 'There are no candidates selected',
  'delivery.invite': 'Invite',
  'delivery.mailWarning': 'Please enter a valid email',
  'delivery.resendAlert': 'Re-invitation sent successfully!',
  'delivery.generateBooklets': 'Generate booklets',
  'delivery.generateBooklets_info':
    'Specify the number of booklets to be generated for assessment. ',
  'delivery.generate': 'Generate',
  'delivery.previewBooklets': 'Preview booklets',
  'delivery.previewBooklets_info': 'Preview the generated booklets.',
  'delivery.downloadBookletGuide':
    'The generated booklets can be downloaded with the <b>"Assessment List > Actions > Download All Booklets"</b> action when the exam setup is complete.',
  'delivery.bookletSettings': 'Booklet Settings',
  'delivery.bookletSettingsUpdate':
    'Make sure the booklet settings are up to date!',
  'delivery.booklet': 'Booklet',
  'delivery.bookletInfo':
    'All rights of these tests are reserved. {param} to copy, photograph, reproduce, publish or use all or part of the tests for any purpose without the written permission of the relevant institute.',
  'delivery.bookletInfoParam': 'It is forbidden',
  'delivery.popupTitle': 'Booklet {char} Preview',
  'delivery.description': 'Assessment Description',
  'delivery.INVITED': 'Invitation by e-mail',
  'delivery.GROUP': 'Group ({groupName})',
  'delivery.SEPARATELY': 'Manually',
  'delivery.emailWarning':
    'The user with this e-mail already exists in the system.',
  'delivery.bookletDuration': '{minutes} minutes',
  'delivery.saveChanges': 'Save Changes',
  'delivery.unverifiedUserFaceId':
    'In this exam, there is a candidate face recognition check. Not verified candidates without Face ID cannot be added to the exam. Remove these candidates from the list and try again.',
  'delivery.alertTitle': 'Are you sure you want to continue?',
  'delivery.alertMessagePart1': 'This assessment will be created in the',
  'delivery.alertMessagePart2':
    'proctoring type. Are you sure you want to continue?',
  'delivery.sufficient': 'Sufficient',
  'delivery.inSufficient': 'Insufficient',
  'delivery.requiredCredit': 'Required Credit:',
  'delivery.cancel': 'Cancel',
  'delivery.continue': 'Continue',
  'delivery.needCredit': 'Need credit',
  'delivery.inSufficient-tooltip':
    'Your credit is insufficient. Change the type of proctoring type or contact your authorized.',
  'delivery.inSufficientToast':
    'Your credit is insufficient. The number of candidates you add to the Record&Review proctoring type assessment is {addedCand}, the number of credits required is {creditNeed}. Change the type of proctoring type or contact your authorized.',
  'delivery.needCredit-tooltip':
    'Your organization does not have a Record & Review credit. Contact the authorized.',
  'delivery.omrCandidates-ADDED_VIA_OMR_IMPORT':
    'Add candidates manually via CSV file',
  'delivery.omrCandidates-ADDED_WITH_ASSESSMENT': 'Add candidates via Examod',
  'delivery.omrCandidates-ADDED_VIA_OMR_IMPORT-tooltip':
    'With this option, the assessment is created without candidates. After the exam, the OMR answers of the candidates registered in Examod and included in the CSV file can be imported.',
  'delivery.omrCandidates-ADDED_WITH_ASSESSMENT-tooltip':
    'With this option, the assessment is created with candidates. After the exam, the OMR answers of only the candidates registered in the assessment candidate list can be imported via CSV file.',
  'delivery.omrCandidates-noCandidatesToast':
    'Candidate must be added to complete the exam setup.',
  'delivery.omrSkipCandidate': 'Skip Candidate Selection',
  'delivery.omrSkipCandidateTooltip':
    'With this option, the assessment is created without candidates. After the exam, the OMR answers of the candidates registered in Examod and included in the CSV file can be imported.',
};
