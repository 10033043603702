export default {
  'performance.search': 'Ara',
  'performance.candidates': 'ADAYLAR ({count})',
  'performance.EVALUATED': 'Değerlendirildi',
  'performance.NOT_EVALUATED': 'Değerlendirilmedi',
  'performance.NOT_ATTENDED': 'Katılmadı',
  'performance.per': '(%{per})',
  'performance.totalScore': 'Toplam Puan',
  'performance.learningObjContent': 'Öğrenme Hedefi :',
  'performance.criteria': 'ÖLÇÜT',
  'performance.total': ' Toplam puana etkisi: {param}',
  'performance.reset': 'Sıfırla',
  'performance.comment': 'YORUM',
  'performance.commentAlert': 'Yorum en fazla 500 karakter olmalıdır.',
  'performance.writtenFeedback': 'Yazılı Genel Geri Bildirim',
  'performance.oralFeedback': 'Sözlü Genel Geri Bildirim',
  'performance.oralFeedback-tooltip1':
    'Her aday için yalnızca 1 sözlü geri bildirim verilebilir.',
  'performance.oralFeedback-tooltip2':
    'Ses kaydının süresi 5 dakikayı geçmemelidir.',
  'performance.oralFeedback-tooltip3':
    'Kaydı durdurduğunuzda otomatik olarak kaydedilir.',
  'performance.cancel': 'İptal',
  'performance.finish': 'Sınavı Bitir',
  'performance.confirmMessage':
    'Bu adayın değerlendirmesiyle ilgili yaptığınız değişiklikleri kaydetmelisiniz yoksa yaptığınız son değişiklikleriniz kaybolacaktır.',
  'performance.discard': 'Değişikliklerden vazgeç',
  'performance.continueWithoutSave': 'Devam Et',
  'performance.levelError': 'Lütfen kriterlerin seviyelerini seçiniz!',
  'performance.successMessage': 'Adayın sonucu başarıyla kaydedilmiştir!',
  'performance.notAttendError':
    'Sonuçları girmek için lütfen durumu güncelleyiniz!',
  'performance.recording': 'Kaydediliyor...',
  'performance.deleteRecordingMessage':
    'Ses kaydını silmek istediğinizden emin misiniz?',
  'performance.delete': 'Evet, Sil',
  'performance.audioFeedbackLimit':
    'En fazla 1 sözlü geri bildirim kaydedilebilir.',
  'performance.audioFeedbackSaved': 'Ses kaydı başarıyla kaydedildi!',
  'performance.audioFeedbackDeleted':
    '{name}.mp3 adlı ses kaydı başarıyla silindi.',
  'performance.micPermissionDenied':
    'Sözlü geri bildirim için tarayıcınızın mikrofona erişmesine izin verin',
  'performance.audioNotAttendError':
    'Sözlü geri bildirim yapmak için lütfen durumu güncelleyiniz!',
  'performance.notFound': 'Bulunamadı',
  'performance.notFoundSearch': 'Arama kriterlerinize uygun sonuç bulunamadı.',
  'performance.clear': 'Temizle',
};
