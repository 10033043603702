export default {
  'assessmentStat.assessmentStatistics': 'Değerlendirme İstatistikleri',
  'assessmentStat.assessmentSubtitle':
    'Aday değerlendirme istatistiklerine genel bakış',
  'assessmentStat.questionStatistics': 'Soru İstatistikleri',
  'assessmentStat.questionSubtitle':
    'Değerlendirmenin soru istatistiklerine genel bakış',
  'assessmentStat.type': 'tür',
  'assessmentStat.affinityType': 'Yakınlık Türü',
  'assessmentStat.attempt': 'uygulama',
  'assessmentStat.avg': 'Ort. yanıt süresİ',
  'assessmentStat.correctAnswer': 'doğru yanıt (%)',
  'assessmentStat.difficulty': 'güçlük (%)',
  'assessmentStat.discrimination': 'Ayırt Edicilik',
  'assessmentStat.reliability': 'Güvenilirlik',
  'assessmentStat.details': 'detaylar',
  'assessmentStat.ALL_GRADES': 'Tüm puanların ortalaması',
  'assessmentStat.ALL_GRADES-tooltip':
    'Değerlendirmeye katılan adayların genel ortalaması',
  'assessmentStat.HIGHEST_GRADES': 'En yüksek puanların ortalaması',
  'assessmentStat.HIGHEST_GRADES-tooltip':
    "Değerlendirmede en yüksek puanı alan adayların %27'si",
  'assessmentStat.LOWEST_GRADES': 'En düşük puanların ortalaması',
  'assessmentStat.LOWEST_GRADES-tooltip':
    "Değerlendirmede en düşük puanı alan adayların %27'si",
  'assessmentStat.candidate': 'Aday',
  'assessmentStat.averageTime': 'Ortalama süre',
  'assessmentStat.range': 'Aralık (%)',
  'assessmentStat.per': '%{per}',
  'assessmentStat.avgDifficulty': 'Ortalama güçlük',
  'assessmentStat.avgDifficulty-tooltip1': '%100 - %60: Kolay soru',
  'assessmentStat.avgDifficulty-tooltip2': '%60% - %40: Orta zorlukta soru',
  'assessmentStat.avgDifficulty-tooltip3': '%40 - %0: Zor soru',
  'assessmentStat.standardDeviation': 'Standart sapma',
  'assessmentStat.standardDeviation-tooltip':
    'Puanların aritmetik ortalama etrafında yayılma düzeyini göstermektedir.',
  'assessmentStat.testVariance': 'Test varyansı',
  'assessmentStat.testVariance-tooltip':
    'Standart sapmanın karesi olarak varyans, değerlendirme sonuçlarının ne ölçüde yayıldığını ifade etmektedir.',
  'assessmentStat.consistencyCoef': 'Cronbach alfa ',
  'assessmentStat.consistencyCoef-tooltip':
    'Cronbach alfa güvenirlik katsayısı , soruların birbiriyle tutarlığını ifade eden güvenirlik hesaplama yöntemidir.',
  'assessmentStat.overallStat': 'Genel İstatistikler',
  'assessmentStat.sectionTitle': 'Bölüm {index} : {name} İstatistikleri',
  'assessmentStat.analysis': 'Analiz',
  'assessmentStat.minAlert':
    'Sınava katılan aday sayısı 3`ün altında olduğu için istatistiksel hesaplamalar yanıltıcı olabilir.',
  'assessmentStat.select': 'Seçiniz',
  'assessmentStat.selectedQuestion': 'Seçilen soru(lar) ',
  'assessmentStat.acceptAsTrue': 'Doğru kabul et',
  'assessmentStat.cancelQuestion': 'İptal et',
  'assessmentStat.applyRecalculate': 'Uygula ve Yeniden Hesapla',
  'assessmentStat.cancelDropdownTooltip':
    'Soru(lar) için gerçekleştirilecek işlem, yalnızca bu değerlendirme için geçerli olacaktır. Bu değişiklikler ilgili sorunun "Performans" ekranında yer alan istatistiklere dahil edilmeyecektir.',
  'assessmentStat.CORRECT-Tooltip':
    'Soru, tüm yanıtlayanlar için doğru olarak kabul edilmiştir. Bu nedenle sorunun istatistikleri performans ekranına ve bu değerlendirmeye dahil edilmemiştir.',
  'assessmentStat.REMOVE-Tooltip':
    'Soru, bu değerlendirme için iptal edilmiştir. Bu nedenle sorunun istatistikleri performans ekranına ve bu değerlendirmeye dahil edilmemiştir.',
  'assessmentStat.noSelectionWarn': 'Lütfen soru(lar) seçiniz',
  'assessmentStat.confirmContinue': 'Devam etmek istediğinize emin misiniz?',
  'assessmentStat.continue': 'Devam',
  'assessmentStat.cancel': 'İptal',
  'assessmentStat.acceptedQuestions':
    'Seçilen {questionCount} ({questions}) soru yanıtlayan adaylar için doğru kabul edilecektir. Bu işlem geri alınamaz.',
  'assessmentStat.canceledQuestions':
    'Seçilen {questionCount} ({questions}) soru iptal edilecektir. Bu işlem geri alınamaz.',
  'assessmentStat.acceptedSuccess':
    'Seçilen soru(lar) adaylar için doğru olarak değiştirildi.',
  'assessmentStat.canceledSuccess': 'Seçilen soru(lar) iptal edildi.',
  'assessmentStat.invalidOperationWarn':
    'Değerlendirme bitiş tarihinden önce soru(lar) üzerinde işlem gerçekleştiremezsiniz.',
  'assessmentStat.updateFreqInfo':
    'Değerlendirme istatistikleri Examod tarafından her 5 dakikada bir güncellenmektedir.',

  // Classification tooltips
  'assessmentStat.classificationRanges': 'Sınıflandırma Aralıkları',
  // Difficulty
  'assessmentStat.class1_difficulty': '%100 - %61: Kolay soru',
  'assessmentStat.class2_difficulty': '%60 - %41: Orta zorlukta soru',
  'assessmentStat.class3_difficulty': '%40 - %0: Zor soru',
  // Discrimination
  'assessmentStat.class1_discrimination': '1 - 0,40: Çok iyi ayırt edici',
  'assessmentStat.class2_discrimination':
    '0,39 - 0,30: İyi ayırt edici fakat geliştirilebilir',
  'assessmentStat.class3_discrimination':
    '0,29 - 0,20: Orta düzey ayırt edici,geliştirilmelidir',
  'assessmentStat.class4_discrimination':
    '0,19 - -1: Hatalı ayırt edici, çıkartılmalıdır',
  //Reliability
  'assessmentStat.class1_reliability': '1 - 0,81:  Çok yüksek güvenilirlik',
  'assessmentStat.class2_reliability': '0,80 - 0,61:  Yüksek güvenilirlik',
  'assessmentStat.class3_reliability': '0,60 - 0,41:  Orta güvenilirlik',
  'assessmentStat.class4_reliability': '0,40 - 0,21:  Düşük güvenirlik',
  'assessmentStat.class5_reliability': '0,20 - 0:  Çok düşük güvenirlik',
};
