import React from 'react';

// **Third Party Compenents
import { Badge } from 'reactstrap';

// **Styles
import '../../../assets/scss/components/sidebar.scss';

const QuestionIdBadge = ({
  idText,
  spanText,
  badgeClassName,
  spanClassName,
}) => {
  return (
    <span>
      <Badge className={badgeClassName} size="md">
        {idText ? `#${idText}` : ''}
      </Badge>
      <span className={spanClassName}>{idText ? spanText : ''}</span>
    </span>
  );
};

export default QuestionIdBadge;
