export default {
  'home.activeUsers': 'Kuruluştaki Aktif Kullanıcılar',
  'home.pendingUsers': 'Davet Bekleyen Kullanıcılar',
  'home.userGroupsCreated': 'Kullanıcı Grubu Oluşturuldu',
  'home.rolesDefined': 'Sistemde Tanımlanan Roller',
  'home.examsPublished': 'Sınav Yayınlandı',
  'home.ongoingExams': 'Devam Eden Sınavlar',
  'home.questionCreated': 'Soru Oluşturuldu',
  'home.updatedNow': 'Yeni Güncellendi',
  'home.actions': 'İşlemler',
  'home.edit': 'Düzenle',
  'home.usersInvited': 'Davet Edilmiş Kullanıcılar',
  'home.welcome': 'Hoş Geldin,',
  'home.quickAccess': 'Hızlı Erişim',
  'home.upcomingExams': 'Yakındaki ve Mevcut Sınavlar',
  'home.upcomingExamDetails': 'Detaylar',
  'home.systemUpdates': 'Sürüm Notları ve Sistem Güncellemeleri',
  'home.supportRequests': 'Destek Talepleri',
  'home.supportRequestSubject': 'Konu',
  'home.supportRequestStatus': 'Durum',
  'home.supportRequestSolved': 'Çözüldü',
  'home.supportRequestNotSolved': 'Çözülmedi',
  'home.supportRequestButtonRequests': 'Talepler',
  'home.supportRequestButtonCreate': 'Talep oluştur',
  'home.newsAndEvents': 'Haberler ve Etkinlikler',
  'home.newsBadge': 'Haber',
  'home.eventsBadge': 'Etkinlik',
  'home.examodOverview': "Examod'a Genel Bakış",
  'home.mainUnit': 'Aktif ana birim',
  'home.totalCourse': 'Aktif ders',
  'home.totalActiveAuthorized': 'Aktif yetkili kullanıcı',
  'home.totalActiveGroup': 'Aktif grup',
  'home.activeLearningObj': 'Aktif öğrenme hedefi',
  'home.activeAssessmentCategory': 'Aktif değerlendirme kategorisi',
  'home.totalActiveCandidate': 'Aktif aday',
  'home.approvalPendingUsers': 'Onay bekleyen kullanıcılar',
  'home.assessmentStatistics': 'Genel Değerlendirme İstatistikleri',
  'home.EXAM_COUNT': 'Sınav',
  'home.CANDIDATES_ASSESSED': 'Değerlendirilen aday',
  'home.QUESTION_COUNT': 'Soru',
  'home.QUESTIONS_USED': 'Kullanılan soru',
  'home.examSuccess': 'Sınavların ort. başarı oranları',
  'home.questionDiscrimination': 'Ort. soru ayırt ediciliği',
  'home.questionDifficulty': 'Ort. soru güçlüğü',
  'home.correctAnswers': 'Doğru yanıtlanma oranı',
  'home.COURSE_COUNT': 'Aktif ders',
  'home.userexamSuccess': 'Sınavlarınızın ort. başarı oranı',
  'home.userquestionDiscrimination': 'Sorularınızın ort. ayırt ediciliği',
  'home.userquestionDifficulty': 'Sorularınızın ort. soru güçlüğü',
  'home.usercorrectAnswers': 'Sorularınızın doğru yanıtlanma oranı',
  'home.userStatistics': 'Sayılarla Siz ({userName})',
  'home.noUpcomingExams': 'Planlanmış bir sınav bulunmamaktadır.',
  'home.ticketText':
    'Examod ile ilgili yaşadığınız sorunlara ilişkin teknik destek talebi oluşurabilirsiniz. Size en kısa sürede dönüş yapmaya çalışacağız.',
  'home.EXAM_COUNT_tooltip':
    'Sınav sayısına iptal edilen ve silinen sınavlar dahil değildir.',
  'home.QUESTION_COUNT_tooltip':
    'Soru sayısına taslak ve kullanımdan kaldırılmış sorular dahil değildir.',
  'home.ROOT_UNIT_COUNT_tooltip':
    'Ana birim sayısına gizli birimler dahil değildir.',
  'home.COURSE_COUNT_tooltip': 'Ders sayısına gizli dersler dahil değildir.',
};
