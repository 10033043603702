export default {
  'userDetailsEdit.header': 'Edit User Details',
  'userDetailsEdit.title': 'Edit User',
  'userDetailsEdit.edit': 'Edit User',
  'userDetailsEdit.change': 'Change',
  'userDetailsEdit.remove': 'Remove',
  'userDetailsEdit.username': 'Username',
  'userDetailsEdit.firstName': 'First Name',
  'userDetailsEdit.lastName': 'Last Name',
  'userDetailsEdit.name': 'Name',
  'userDetailsEdit.firstNameHint': 'First name here..',
  'userDetailsEdit.lastNameHint': 'Last name here..',
  'userDetailsEdit.surname': 'Surname',
  'userDetailsEdit.email': 'Email',
  'userDetailsEdit.status': 'Status',
  'userDetailsEdit.group': 'User Groups',
  'userDetailsEdit.type': 'User Type',
  'userDetailsEdit.select': 'Select',
  'userDetailsEdit.selectSystemRole': 'Select Role',
  'userDetailsEdit.role': 'User Role',
  'userDetailsEdit.delete': 'Delete',
  'userDetailsEdit.saveChanges': 'Save Changes',
  'userDetailsEdit.hintGroup': 'Select Group',
  'userDetailsEdit.resetPassword': 'Reset Password',
  'userDetailsEdit.sendInvitation': 'Re-send Invitation',
  'userDetailsEdit.maxMessageUserName': 'Username should be max 70 characters.',
  'userDetailsEdit.maxMessageEmail': 'Email should be max 70 characters.',
  'userDetailsEdit.usernameMessage': "Username shouldn't contain spaces.",
  'userDetailsEdit.usernameMinMessage':
    'Username should be at least 1 characters.',
  'userDetailsEdit.usernameMaxMessage': 'Username should be max 70 characters.',
  'userDetailsEdit.maxMessageFirstName':
    'First name should be max 70 characters.',
  'userDetailsEdit.maxMessageLastName':
    'Last name should be max 70 characters.',
  'userDetailsEdit.requiredMessage': ' ',
  'userDetailsEdit.maxMessageName': 'Name should be max 70 characters.',
  'userDetailsEdit.maxMessageSurname': 'Surname should be max 70 characters.',
  'userDetailsEdit.systemId': 'System ID',
  'userDetailsEdit.maxMessageSystemId':
    'System ID should be max 20 characters.',
  'userDetailsEdit.minMessageSystemId':
    'System ID should be at least 6 characters.',
};
