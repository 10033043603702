export default {
  'periodAdd.header': 'Add Period',
  'periodAdd.name': 'Period Name',
  'periodAdd.maxLength': 'Period Name should be max 100 characters.',
  'periodAdd.minLength': 'Period Name should be at least 1 characters.',
  'periodAdd.unit': 'Main Unit',
  'periodAdd.status': 'Show / Hide',
  'periodAdd.start': 'Start Date',
  'periodAdd.end': 'End Date',
  'periodAdd.add': 'Add Period',
  'periodAdd.requiredMessage': ' ',
  'periodAdd.select': 'Select',
  'periodAdd.selectAlert':
    "Period couldn't added. Please select a parent unit!",
  'periodAdd.timerAlert':
    "Period couldn't added. Start date musn't equal to or greater than end date!",
  'periodAdd.successAlert': 'Period added successfully!',
  'periodAdd.allUnits': 'All Main Units',
};
