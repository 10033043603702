export default {
  // Generic
  'generic.add': 'Dodaj',
  'generic.cancel': 'Anuluj',

  // BlockType
  'components.controls.blocktype.h1': 'Nagłówek 1',
  'components.controls.blocktype.h2': 'Nagłówek 2',
  'components.controls.blocktype.h3': 'Nagłówek 3',
  'components.controls.blocktype.h4': 'Nagłówek 4',
  'components.controls.blocktype.h5': 'Nagłówek 5',
  'components.controls.blocktype.h6': 'Nagłówek 6',
  'components.controls.blocktype.blockquote': 'Cytat',
  'components.controls.blocktype.code': 'Kod',
  'components.controls.blocktype.blocktype': 'Format',
  'components.controls.blocktype.normal': 'Normalny',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Kolor',
  'components.controls.colorpicker.text': 'Tekst',
  'components.controls.colorpicker.background': 'Tło',

  // Embedded
  'components.controls.embedded.embedded': 'Osadź',
  'components.controls.embedded.embeddedlink': 'Osadź odnośnik',
  'components.controls.embedded.enterlink': 'Wprowadź odnośnik',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Krój czcionki',

  // FontSize
  'components.controls.fontsize.fontsize': 'Rozmiar czcionki',

  // History
  'components.controls.history.history': 'Historia',
  'components.controls.history.undo': 'Cofnij',
  'components.controls.history.redo': 'Ponów',

  // Image
  'components.controls.image.image': 'Obrazek',
  'components.controls.image.fileUpload': 'Prześlij plik',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText':
    'Upuść plik lub kliknij, aby przesłać',

  // Inline
  'components.controls.inline.bold': 'Pogrubienie',
  'components.controls.inline.italic': 'Kursywa',
  'components.controls.inline.underline': 'Podkreślenie',
  'components.controls.inline.strikethrough': 'Przekreślenie',
  'components.controls.inline.monospace': 'Monospace',
  'components.controls.inline.superscript': 'Indeks górny',
  'components.controls.inline.subscript': 'Indeks dolny',

  // Link
  'components.controls.link.linkTitle': 'Tytuł odnośnika',
  'components.controls.link.linkTarget': 'Adres odnośnika',
  'components.controls.link.linkTargetOption': 'Otwórz odnośnik w nowej karcie',
  'components.controls.link.link': 'Wstaw odnośnik',
  'components.controls.link.unlink': 'Usuń odnośnik',

  // List
  'components.controls.list.list': 'Lista',
  'components.controls.list.unordered': 'Lista nieuporządkowana',
  'components.controls.list.ordered': 'Lista uporządkowana',
  'components.controls.list.indent': 'Zwiększ wcięcie',
  'components.controls.list.outdent': 'Zmniejsz wcięcie',

  // Remove
  'components.controls.remove.remove': 'Usuń',

  // TextAlign
  'components.controls.textalign.textalign': 'Wyrównaj tekst',
  'components.controls.textalign.left': 'Do lewej',
  'components.controls.textalign.center': 'Do środka',
  'components.controls.textalign.right': 'Do prawej',
  'components.controls.textalign.justify': 'Wyjustuj',

  // New Examod localizations
  'components.controls.image.modal.title': 'Image Uploader',
  'components.controls.image.modal.description':
    'Browse or drag the image you want to upload',
  'components.controls.image.modal.content.image.or': 'or',
  'components.controls.image.modal.content.image.btn': 'Browse Files',
  'components.controls.image.modal.content.image.attachment':
    'Attachments should not exceed 2 mb',
  'components.controls.image.modal.content.image.supported':
    'Supported file types: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt Text',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Image alt text',
  'components.controls.image.modal.content.input.scale.label': 'Scale (%)',
  'components.controls.image.modal.contnet.url.address.label': '*URL (Adress)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Add image from address',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Clear Highlight',
  'components.controls.color.picker.highlight.black': 'Black Highlight',
  'components.controls.color.picker.highlight.grey': 'Grey Highlight',
  'components.controls.color.picker.highlight.red': 'Red Highlight',
  'components.controls.color.picker.highlight.green': 'Green Highlight',
  'components.controls.color.picker.highlight.blue': 'Blue Highlight',
  'components.controls.color.picker.highlight.yellow': 'Yellow Highlight',
  'components.controls.color.picker.highlight.examod': 'Examod Highlight',
  'components.controls.color.picker.highlight.purple': 'Purple Highlight',
  'components.controls.color.picker.highlight.pink': 'Pink Highlight',
};
