export default {
  'questionField.title': 'Question Attribute',
  'questionField.subtitle':
    'Design the question attribute structure of your organization',
  'questionField.emptySubtitle':
    'Design the question attribute structure of your organization',
  'questionField.addField': 'Add Question Attribute',
  'questionField.search': 'Search',
  'questionField.questionField': 'Question Attribute',
  'questionField.parentUnit': 'Main Unit',
  'questionField.type': 'Question Type',
  'questionField.status': 'Status',
  'questionField.actions': 'Actions',
  'questionField.edit': 'Edit',
  'questionField.delete': 'Delete',
  'questionField.multipleChoice': 'Multiple Choice Question',
  'questionField.multipleChoiceShortcut': 'MCQ',
  'questionField.trueFalse': 'True / False Question',
  'questionField.trueFalseShortcut': 'T / F',
  'questionField.shortAnswer': 'Short Answer Question',
  'questionField.shortAnswerShortcut': 'SAQ',
  'questionField.longAnswer': 'Long Answer Question',
  'questionField.openEnded': 'Open Ended Question',
  'questionField.longAnswerShortcut': 'LAQ',
  'questionField.openEndedShortcut': 'OEQ',
  'questionField.hotspot': 'Hotspot Question',
  'questionField.hotspotShortcut': 'HQ',
  'questionField.veryShortAnswer': 'Very Short Answer Question',
  'questionField.veryShortAnswerShortcut': 'VSAQ',
  'questionField.unknown': 'Unknown',
  'questionField.unknownShortcut': '-',
  'questionField.deleteConfirmationTitle': 'Delete attribute',
  'questionField.deleteConfirmationMessage':
    'Are you sure to delete the attribute? This operation cannot be reversed.',
  'questionField.deleteConfirmationConfirmButton': 'Yes, delete attribute',
  'questionField.parentUnitNone': 'None',
  'questionField.parentUnits': 'All Main Units',
  'questionField.showPassiveStatus': 'Show passive attribute',
  'questionField.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'questionField.messageInformation':
    'There is no {titleValue} definition for your organization yet.',
  'questionField.messageInformationAdd':
    'Click the {messageValue} button to add {titleValue}.',
  'questionField.obj': 'Question Attribute',
  'questionField.addQuestionField': '"Add Question Attribute"',
};
