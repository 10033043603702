export default {
  'assessmentContent.addSection': 'Add Section',
  'assessmentContent.editSection': 'Edit Section',
  'assessmentContent.addQuestion': 'Add Question',
  'assessmentContent.reset': 'Reset',
  'assessmentContent.editDesc': 'Edit Description',
  'assessmentContent.assessmentDescPrev': 'Assessment Description Preview',
  'assessmentContent.sectionDescPrev': 'Section Description Preview',
  'assessmentContent.sectionDesc': 'Section Description',
  'assessmentContent.assessmentDesc': 'Assessment Description',
  'assessmentContent.deleteSection': 'Delete section with questions',
  'assessmentContent.moveQuestions': 'Delete section and move questions',
  'assessmentContent.type': 'Type: {type}',
  'assessmentContent.category': 'Category: {category}',
  'assessmentContent.unit': 'Unit: {count}',
  'assessmentContent.course': 'Course: {count}',
  'assessmentContent.topic': 'Topic: {count}',
  'assessmentContent.timeLimit': 'Total Time Limit: {time} {unit}',
  'assessmentContent.totalQuestion': 'Total Question: {number}',
  'assessmentContent.totalCriteria': 'Total Criteria: {number}',
  'assessmentContent.totalPoint': 'Total Point: {number}',
  'assessmentContent.points': 'points',
  'assessmentContent.shuffled': 'Shuffled',
  'assessmentContent.sectionTime': 'Section Time Limit',
  'assessmentContent.sectionLimit': 'Section Time Limit: {time} minutes',
  'assessmentContent.sectionName': 'Section Name',
  'assessmentContent.minutes': ' (Minutes)',
  'assessmentContent.shuffle': 'Shuffle questions in this section',
  'assessmentContent.newQuestion': 'Add New Question',
  'assessmentContent.bank': 'Select from Bank',
  'assessmentContent.edit': 'Edit {name}',
  'assessmentContent.delete': 'Delete {name}',
  'assessmentContent.add': 'Add {param} Selected Question(s)',
  'assessmentContent.total': 'Unselected',
  'assessmentContent.added': 'Selected',
  'assessmentContent.popupTitle': 'Select Questions from Question Bank',
  'assessmentContent.section': 'Section {index}: ',
  'assessmentContent.timeWarning': 'This field can only contain numbers',
  'assessmentContent.required': 'This field cannot be blank',
  'assessmentContent.limitExceeded':
    'The total time limits of the sections should not exceed the total time limit.',
  'assessmentContent.next': 'Next Step ',
  'assessmentContent.prev': 'Previous Step',
  'assessmentContent.questionWarning':
    'There are no questions added to the assessment',
  'assessmentContent.gradingWarning': 'Please fill in the required fields!',
  'assessmentContent.sectionWarning':
    'There are no questions added to the {section} section',
  'assessmentContent.list': 'List View',
  'assessmentContent.blueprint': 'Blueprint View',
  'assessmentContent.nextStepAlert':
    'Use the list view to go to the next step.',
  'assessmentContent.prevStepAlert':
    'Use the list view to go to the previous step.',
  'assessmentContent.resetConfirmationTitle': 'Reset Blueprint',
  'assessmentContent.resetConfirmationMessage':
    'You will reset the entire blueprint view. Even if you do this, you can continue from the list view. Do you confirm?',
  'assessmentContent.resetConfirmationConfirmButton': 'Yes, reset blueprint',
  'assessmentContent.resetConfirmationCancelButton': 'Cancel',
  'assessmentContent.passPointWarning':
    'The passing score must be between 0 and highest score!',
  'assessmentContent.cancel': 'Cancel',
  'assessmentContent.noDesc': 'No description.',
  'assessmentContent.rubricWarning': 'Please select a rubric!',
  'assessmentContent.maxMessageSectionName':
    'Section name should be max 100 characters.',
  'assessmentContent.maxMessageSectionDesc':
    'Section description must be maximum 5000 characters.',
  'assessmentContent.continueTitle': 'Are you sure you want to continue?',
  'assessmentContent.addGroupedConfirm':
    'There is a grouped question among the question(s) you want to add to the assessment. If you continue, other questions in the grouped question content will be added.',
  'assessmentContent.continue': 'Continue',
  'assessmentContent.cancelPopup': 'Cancel',
};
