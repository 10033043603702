export default {
  'users.header': 'User Management',
  'users.searchFilter': 'Search Filter',
  'users.search': 'Search',
  'users.addUser': 'Add User',
  'users.inviteUser': 'Invite User',
  'users.show': 'Show',
  'users.entries': 'Entries',
  'users.selectGroup': 'Group',
  'users.selectType': 'User Type',
  'users.selectUnit': 'Unit / Course',
  'users.selectStatus': 'Status',
  'users.user': 'User',
  'users.email': 'Email',
  'users.addedDate': 'Creation Date',
  'users.userGroup': 'User Group',
  'users.userType': 'User Type',
  'users.status': 'Status',
  'users.actions': 'Actions',
  'users.edit': 'Edit',
  'users.delete': 'Delete',
  'users.prevButton': 'Previous',
  'users.nextButton': 'Next',
  'users.authorized': 'Authorized',
  'users.candidate': 'Candidate',
  'users.active': 'Active',
  'users.pending': 'Pending Invitation',
  'users.passive': 'Passive',
  'users.unknown': 'Unknown',
  'users.reset': 'Reset Filters',
  'users.confirmationTitle': 'Are you sure you want to continue?',
  'users.passivateConfirmationMessage':
    "Are you sure you want to passivate the user? If you continue, the user's data will not be deleted and will be archived.",
  'users.activateConfirmationMessage':
    'Are you sure you want to activate the user? If you continue, the user will be able to access the platform again with their archived data.',
  'users.cancelInvitationConfirmationMessage':
    "The candidate has been added to an assessment. If you continue, the candidate's invitation will be canceled and the candidate will be removed from the assessment list.",
  'users.confirmationConfirmButton': 'Continue',
  'users.confirmationCancelButton': 'Cancel',
  'users.userActivated': 'User activated successfully!',
  'users.userPassivated': 'User passivated successfully!',
  'users.select': 'Select',
  'users.AUTHORIZED': 'Authorized',
  'users.CANDIDATE': 'Candidate',
  'users.activate': 'Activate',
  'users.passivate': 'Passivate',
  'users.resendInvitation': 'Re-send Invitation',
  'users.resendAlert': 'Re-invitation sent successfully!',
  'users.cancelInvitation': 'Cancel Invitation',
  'users.cancelInvitationSuccessAlert': 'Invitation cancelled successfully!',
  'users.userPassivateTicketReceiverError':
    'User accounts associated with Support Information cannot be passivated. To passivated the user, update support email preferences under General > Support Information or contact your authorized.',
  'users.authType': 'Auth Type',
  'users.authTypePASSWORD': 'Password',
  'users.authTypeOAUTH_2': 'OAuth2',
};
