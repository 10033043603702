import React from 'react';
import createInlineStyleButton from '../utils/createInlineStyleButton.js';

export default createInlineStyleButton({
  style: 'STRIKETHROUGH',
  children: (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0153809 7.18738H16.716V8.5791H12.6654C12.8851 9.04043 12.9961 9.54606 12.9896 10.057C13.0026 10.5418 12.8985 11.0226 12.6863 11.4586C12.474 11.8946 12.1598 12.273 11.7702 12.5618C10.8317 13.2221 9.69966 13.5501 8.55358 13.4938C7.71618 13.4994 6.88694 13.3291 6.11961 12.9937C5.41884 12.703 4.8114 12.2254 4.36359 11.613C3.95127 11.0283 3.7337 10.3285 3.74181 9.61309V9.5079H4.00831V9.50688H4.32514V9.5079H5.60081V9.61306C5.58628 9.93908 5.65074 10.2638 5.78872 10.5595C5.92671 10.8553 6.13411 11.1133 6.39328 11.3116C7.02179 11.7591 7.78303 11.9812 8.55357 11.9418C9.23378 11.9863 9.91001 11.8084 10.4803 11.435C10.6917 11.2787 10.8616 11.0729 10.975 10.8357C11.0885 10.5985 11.142 10.3371 11.131 10.0744C11.1458 9.82056 11.0989 9.56694 10.9944 9.33518C10.8898 9.10343 10.7307 8.90043 10.5307 8.74352C10.4487 8.68411 10.3636 8.62921 10.2756 8.57907H0.0153809V7.18738ZM12.3968 2.41552C12.0057 1.80782 11.4526 1.32143 10.7999 1.01118C10.0723 0.664555 9.27428 0.491103 8.46842 0.504406C7.34472 0.456181 6.23991 0.805056 5.3477 1.48986C4.96466 1.78569 4.65568 2.16657 4.4452 2.60238C4.23472 3.03819 4.12849 3.51698 4.13492 4.00091C4.13168 4.45953 4.23376 4.91276 4.43331 5.3257H6.84254C6.76518 5.27592 6.67055 5.22757 6.60809 5.17666C6.41931 5.0374 6.26607 4.85556 6.16082 4.64592C6.05557 4.43627 6.00129 4.20474 6.00239 3.97017C5.98839 3.70239 6.03913 3.43519 6.15032 3.19118C6.2615 2.94718 6.42985 2.73356 6.64111 2.56843C7.17562 2.19773 7.8191 2.01724 8.46842 2.05588C9.17442 2.01099 9.8716 2.23236 10.4224 2.6763C10.6527 2.89322 10.8331 3.15765 10.951 3.45126C11.069 3.74487 11.1216 4.06061 11.1053 4.37659V4.48182H12.9638V4.37659C12.9669 3.68203 12.7701 3.00125 12.3968 2.41552Z"
        fill="#5E5873"
      />
    </svg>
  ),
});
