export default {
  'periodEdit.header': 'Edit Period',
  'periodEdit.name': 'Period Name',
  'periodEdit.unit': 'Main Unit',
  'periodEdit.status': 'Show / Hide',
  'periodEdit.start': 'Start Date',
  'periodEdit.end': 'End Date',
  'periodEdit.update': 'Save Changes',
  'periodEdit.delete': 'Delete',
  'periodEdit.success': 'Period updated successfully!',
  'periodEdit.deleteSuccess': 'Period deleted successfully!',
  'periodEdit.allUnits': 'All Main Units',
  'periodEdit.timerAlert':
    "Period couldn't update. Start date musn't equal to or greater than end date!",
};
