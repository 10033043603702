export default {
  'permissionAction.CREATE': 'Oluştur',
  'permissionAction.BROWSE': 'Görüntüle',
  'permissionAction.UPDATE': 'Güncelle',
  'permissionAction.DELETE': 'Sil',
  'permissionAction.REJECT': 'Reddet',
  'permissionAction.APPROVE': 'Onayla',
  'permissionAction.ADD': 'Ekle',
  'permissionAction.INVITE': 'Davet et',
  'permissionAction.BULK_IMPORT': 'Çoklu ekle',
  'permissionAction.REVIEW_MARK': 'İnceleme',
  'permissionAction.REVIEW_BROWSE': 'İncelemeleri görüntüle',
  'permissionAction.REVOKE': 'Rolü kaldır',
  'permissionAction.GRANT': 'Rol ata',
  'permissionAction.ASSESS': 'Değerlendir',
  'permissionAction.ANNOUNCE': 'İlan et',
  'permissionAction.BILLING': 'Faturalandırma',
  'permissionAction.PAY': 'Ödeme yap',
  'permissionAction.ORDER': 'Sipariş ver',
};
