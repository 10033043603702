export default {
  'permissionAction.CREATE': 'Create',
  'permissionAction.BROWSE': 'Browse',
  'permissionAction.UPDATE': 'Update',
  'permissionAction.DELETE': 'Delete',
  'permissionAction.REJECT': 'Reject',
  'permissionAction.APPROVE': 'Approve',
  'permissionAction.ADD': 'Add',
  'permissionAction.INVITE': 'Invite',
  'permissionAction.BULK_IMPORT': 'Bulk import',
  'permissionAction.REVIEW_MARK': 'Review',
  'permissionAction.REVIEW_BROWSE': 'View reviews',
  'permissionAction.REVOKE': 'Revoke user role',
  'permissionAction.GRANT': 'Grant role',
  'permissionAction.ASSESS': 'Assess',
  'permissionAction.ANNOUNCE': 'Announce',
  'permissionAction.BILLING': 'Billing',
  'permissionAction.PAY': 'Payment',
  'permissionAction.ORDER': 'Order',
};
