export const ERROR_CODES = {
  INVALID_TOKEN: 381,
  EXPIRED_TOKEN: 382,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER: 500,
  ATTRIBUTE_OPTIONS_INVALID_UPDATE: 1107,
  TICKET_RECEIVER_USER_TYPE_AND_STATUS: 804,
  TICKET_RECEIVER_USER_REMOVE: 805,
};

export const FORM_ERROR_TYPES = {
  required: 'required',
  pattern: 'pattern',
  maxLength: 'maxLength',
  minLength: 'minLength',
  validate: 'validate',
};
