export default {
  'notification.ASSESSMENT_COMPLETED': 'The {examName} exam has ended.',
  'notification.UNIT_ROLE_ASSIGNED':
    'You have been assigned as {role} for the {unitName}.',
  'notification.SYSTEM_ROLE_ASSIGNED':
    'You have been assigned as {role} for the system wide.',
  'notification.exam': 'Exam Notification',
  'notification.account': 'Account Notification',
  'notification.count': '{count} New',
  'notification.notifications': 'Notifications',
  'notification.clear': 'Clear All',
  'notification.noNotifications': 'No notifications yet',
};
