export default {
  'objectiveAdd.title': 'Add Learning Objective',
  'objectiveAdd.objective': 'Learning Objective',
  'objectiveAdd.enable': 'Active / Passive',
  'objectiveAdd.add': 'Add Learning Objective',
  'objectiveAdd.saveChanges': 'Save Changes',
  'objectiveAdd.addSuccess': 'Learning objective added successfully!',
  'objectiveEdit.title': 'Edit Learning Objective',
  'objectiveEdit.editSuccess': 'Learning objective updated successfully!',
  'objectiveAdd.min': 'Learning objective must be at least 1 characters long',
  'objectiveAdd.max': 'Learning objective must be less than 500 characters long',
};
