export default {
  'questions.addNewTopic': 'Add New Topic',
  'questions.topicNameHint': 'Type new topic...',
  'questions.chooseUnit': 'Choose Unit',
  'questions.addQuestion': 'Create Question',
  'questions.selectType': 'SELECT QUESTION TYPE',
  'questions.selectTopic': 'SELECT TOPIC',
  'questions.select': 'Select',
  'questions.search': 'Search by question root or question id (#)',
  'questions.unitsCourses': 'UNITS / COURSES',
  'questions.topics': 'TOPICS',
  'questions.learningObj': 'LEARNING OBJECTIVES',
  'questions.allUnits': 'All Units',
  'questions.allTopics': 'All Topics',
  'questions.allObjs': 'All Objectives',
  'questions.filterStatus': 'Select Status',
  'questions.filterType': 'Select Type',
  'questions.filterObjective': 'Select Objective',
  'questions.filterTopic': 'Select Topic',
  'questions.filterKeyword': 'Select Keyword',
  'questions.deleteSuccess': 'Questions deleted successfully!',
  'questions.moveSuccess': 'Questions moved successfully!',
  'questions.noSelected': 'There are no questions selected',
  'questions.noSelectedTopic': 'There are no topic selected',
  'questions.preview': 'Preview',
  'questions.statistics': 'Statistics',
  'questions.exams': 'Exams',
  'questions.activities': 'Activities',
  'questions.selectAll': 'Select All',
  'questions.generalFilters': 'General Filters',
  'questions.questionAttributeFilters': 'Question Attribute Filters',
  'questions.questionStatus': 'Status',
  'questions.questionType': 'Type',
  'questions.keywords': 'Keywords',
  'questions.Deneme': 'Test',
  'questions.Zorluk': 'Difficulty',
  'questions.move': 'Move Question',
  'questions.deleteConfirmationTitle': 'Delete questions',
  'questions.deleteSingleTitle': 'Delete question',
  'questions.deleteConfirmationMessage':
    'Are you sure to delete the questions? This operation cannot be reversed.',
  'questions.deleteSingleConfirmation':
    'Are you sure you want to delete this question? If you continue, this operation cannot be reversed.',
  'questions.deleteGroupConfirmationMessage':
    'Are you sure you want to delete a grouped question? If you continue, other questions in the grouped question content will also be deleted.',
  'questions.deleteConfirmationConfirmButton': 'Yes, delete questions',
  'questions.deleteSingleConfirm': 'Yes, delete question',
  'questions.noQuestions': 'There are no questions.',
  'questions.selectedInfo': 'Total Questions in Assessment',
  'questions.mcq': 'Multiple Choice Question',
  'questions.openEndedQuestion': 'Open Ended Question',
  'questions.groupedQuestion': 'Grouped Question',
  'questions.selectedFilters': 'Selected Filters',
  'questions.moveWarn':
    'Approved questions cannot be moved to a different topic.',
  'questions.moveGroupedWarn':
    'Grouped questions cannot be moved to a different topic.',
  'questions.affinityType': 'Affinity Type',
  'questions.SINGLE': 'Single',
  'questions.GROUPED': 'Grouped',
  'questions.DRAFT': 'Draft',
  'questions.APPROVED': 'Approved',
  'questions.DEPRECATED': 'Deprecated',
  'questions.MULTIPLE_CHOICE': 'Multiple Choice',
  'questions.LONG_ANSWER': 'Open Ended',
  'questions.TRUE_FALSE': 'True / False',
  'questions.deprecatedQuestionWarn':
    'This question has been deprecated. To include it in the question list, you can perform the "Reuse" operation by listing the questions that have been deprecated from the "Question Status" heading in the question filter options.',
  'questions.confirmContinue': 'Are you sure you want to continue?',
  'questions.DEPRECATE_SINGLE_Confirmation':
    'Are you sure you want to deprecate this question?',
  'questions.DEPRECATE_GROUPED_Confirmation':
    'Are you sure you want to deprecate this question? If you continue, all other questions in the grouped question will also be deprecated.',
  'questions.REUSE_SINGLE_Confirmation':
    'Are you sure you want to reuse this question?',
  'questions.REUSE_GROUPED_Confirmation':
    'Are you sure you want to reuse this question? If you continue, all other questions in the grouped question will also be reused.',
  'questions.cancel': 'Cancel',
  'questions.continue': 'Continue',
  'question.selectCourseForTopic':
    'You must select a course to filter by topic.',
  'question.selectCourseForLO':
    'You must select a course to filter by learning objective.',
  'questions.approvalDate': 'Approval Date',
  'questions.nearestUsageDate': 'Nearest Usage Date',
  'questions.oneMonth': '1 month',
  'questions.sixMonth': '6 month',
  'questions.oneYear': '1 year',
  'questions.twoYear': '2 year',
  'questions.all': 'All',
  'questions.neverUsed': 'Never Used',
  'questions.last1Year': 'Used in last 1 year',
  'questions.between1And2Years': 'Used 1 year to 2 years',
  'questions.between2And3Years': 'Used 2 years to 3 years',
  'questions.before3Years': 'Used before 3 years',
  'questions.owner': 'Owner',
  'questions.obj': 'Question',
  'questions.titleInformation': 'Whoops! There is no {titleValue} yet.',
  'questions.messageInformation':
    'There is no {titleValue} in this course of your organization yet.',
  'questions.messageInformationAdd':
    'Get started with the {titleValue} types available on Examod!',
  'questions.title': 'Question List',
  'questions.info': 'Create the first question for this course',
  'questions.noResultTitle': 'Whoops! No Results Found',
  'questions.noResultSubTitle':
    'We are sorry. Your search result was not found.',
  'questions.ResetSearch': 'Reset Search',
  'questions.showOnlyMyQuestions': 'Only show me my own questions.',
};
