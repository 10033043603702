export default {
  'routes.profile': 'Profil',
  'routes.addPeriod': 'Dönem Ekle',
  'routes.editPeriod': 'Dönem Düzenle',
  'routes.manageUnitUsers': 'Birim Kullanıcılarını Yönet',
  'routes.manageUnitUsers_unitName': '{unitName} Kullanıcılarını Yönet',
  'routes.course_courseName': '{courseName}',
  'routes.viewUser': 'Kullanıcı Detay',
  'routes.viewUser_userName': '{userName} Detay',
  'routes.editUser': 'Kullanıcıyı Düzenle',
  'routes.importUsers': 'Kullanıcıları İçe Aktar',
  'routes.addUserGroup': 'Kullanıcı Grubu Ekle',
  'routes.editUserGroup': 'Kullanıcı Grubu Düzenle',
  'routes.listUsersInGroup': 'Grup Kullanıcıları',
  'routes.listUsersInGroup_groupName': '{groupName} Kullanıcıları',
  'routes.addRole': 'Rol Ekle',
  'routes.editRole': 'Rol Düzenle',
  'routes.course': 'Ders',
  'routes.addQuestion': 'Soru Ekle',
  'routes.editQuestion': 'Soru Düzenle',
  'routes.addAssessment': 'Değerlendirme Oluştur',
  'routes.editAssessment': 'Değerlendirmeyi Düzenle',
  'routes.assessmentResults': 'Değerlendirme Sonuçları',
  'routes.assessmentResults_assessmentName': '{assessmentName} Sonuçları',
  'routes.assessmentStatistics': 'Değerlendirme İstatistikleri',
  'routes.assessmentStatistics_assessmentName':
    '{assessmentName} İstatistikleri',
  'routes.assessments': 'Değerlendirmeler',
  'routes.candidateReport': 'Sınav Sonuç Raporu',
  'routes.candidateReport_candidateName': '{candidateName} Sonuç Raporu',
  'routes.proctoring?': 'Sınav Gözetim Kuralları',
  'routes.typeSettings?': 'Değerlendirme Türü Ayarları',
  'routes.categories?': 'Değerlendirme Kategorileri',
  'routes.assessment': 'Değerlendirme',
  'routes.questions': 'Sorular',
  'routes.rubrics': 'Dereceli Puanlama Anahtarı',
  'routes.reports': 'Raporlar',
  'routes.learning-objectives': 'Öğrenme Hedefleri',
  'routes.omrProcess': 'Optik Yanıtları',
  'routes.omrProcess_assessmentName': '{assessmentName} Optik Yanıtları',
  'routes.addRubric': 'Dereceli Puanlama Anahtarı Oluştur',
  'routes.editRubric': 'Dereceli Puanlama Anahtarı Düzenle',
  'routes.editRubric_rubricName': '{rubricName} Düzenle',
  'routes.copyRubric': 'Dereceli Puanlama Anahtarı Çoğalt',
  'routes.copyRubric_rubricName': '{rubricName} Çoğalt',
  'routes.proctoringResult': 'Gözetim Sonuçları',
  'routes.previewQuestion': 'Soru Önizlemesi',
  'routes.addQuestionAttribute': 'Soru Özelliği Ekle',
  'routes.editQuestionAttribute': 'Soru Özelliğini Düzenle',
};
