export default {
  'groupedQuestion.title': 'Create Grouped Question',
  'groupedQuestion.duplicate': 'Duplicate Grouped Question',
  'groupedQuestion.edit': 'Edit Grouped Question',
  'groupedQuestion.mainContent': 'Main Content',
  'groupedQuestion.addQuestion': 'Add Question',
  'groupedQuestion.content': 'Grouped question content',
  'groupedQuestion.contentTitle': 'Grouped Question Content',
  'groupedQuestion.prepareContent':
    'Prepare the main content of the grouped question',
  'groupedQuestion.questionNumber': 'Question {number}',
  'groupedQuestion.save': 'Save',
  'groupedQuestion.editSave': 'Save Changes',
  'groupedQuestion.approveAndLock': 'Approve & Lock',
  'groupedQuestion.discardConfirmationMessage':
    'The changes you make will not be saved. Are you sure you want to discard all changes?',
  'groupedQuestion.confirmationContinueTitle':
    'Are you sure you want to continue?',
  'groupedQuestion.saveConfirmationMessage':
    'Are you sure you want to save this question as a draft? If you continue, the question will be saved as a draft.',
  'groupedQuestion.lockConfirmationMessage':
    'Are you sure you want to approve & lock this grouped question? If you continue, this question will be available for assessments.',
  'groupedQuestion.deleteGroup':
    'Are you sure you want to delete a grouped question? This operation cannot be reversed.',
  'groupedQuestion.deleteSingle':
    'Are you sure you want to delete this question?',
  'groupedQuestion.cancel': 'Cancel',
  'groupedQuestion.continue': 'Continue',
  'groupedQuestion.minApproved':
    'To approve & lock a grouped question, you must add at least two questions and save the changes.',
  'groupedQuestion.saveToApprove':
    'To approve & lock a group question, you must first save the changes.',
  'groupedQuestion.missingLoError':
    'Warning! Please associate the questions ({indexes}) with a learning objective.',
};
