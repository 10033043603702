import React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

function SecondaryButton({
  // button id
  id = '',

  // button icon
  icon = '',

  // button color
  color = 'emerald',

  // button label
  textId = '',

  // buton label full
  text = null,

  // button color
  className = '',

  textClassName = '',

  // button size
  size = 'normal',

  // runs on click event
  onClick = null,

  // is disabled
  disabled = false,

  outlineId = '',

  active = false,
}) {
  const getText = () => {
    if (icon == '') {
      return <span>{text || <FormattedMessage id={textId} />}</span>;
    }
    if (textId == '') {
      return icon;
    }
    return (
      <>
        {icon}
        <span className={`align-middle ${textClassName} `}>
          <FormattedMessage id={textId} />
        </span>
      </>
    );
  };

  return (
    <Button.Ripple
      id={id}
      className={`${className}`}
      color={color}
      type="reset"
      outline
      size={size}
      onClick={onClick}
      disabled={disabled}
      // id={outlineId} // have two IDS {SULEIMAN} what's outlineid is for?
      active={active}
    >
      {getText()}
    </Button.Ripple>
  );
}

export default SecondaryButton;
