export default {
  'unitsList.title': 'Birim & Ders Yönetimi',
  'unitsList.subtitle': 'Organizasyonunuzun birim ve ders yapısını tasarlayın',
  'unitsList.emptySubtitle': 'Yetkilisi olduğunuz birim ve dersleri yönetin',
  'unitsList.moveUnits': 'Taşı',
  'unitsList.moveUp': 'Yukarıya Taşı',
  'unitsList.moveDown': 'Aşağıya Taşı',
  'unitsList.moveSuccessMessage': 'Birimler başarıyla taşındı',
  'unitsList.moveUnitDropdownPlaceholder': 'Hedef birimi seçiniz',
  'unitsList.root': 'Yeni Ana Birim',
  'unitsList.deleteOption': 'Sil',
  'unitsList.moveOption': 'Taşı',
  'unitsList.updateOption': 'Düzenle',
  'unitsList.newChildOption': 'Yeni Alt Birim',
  'unitsList.manageUsers': 'Kullanıcıları Yönet',
  'unitsList.visibilityChangedMessage': 'Birim görünürlüğü değiştirildi.',
  'unitsList.unitMovedAboveMessage': 'Birim alta taşındı.',
  'unitsList.unitMovedBelowMessage': 'Birim üste taşındı.',
  'unitsList.sortChildrenOption': 'Sırala',
  'unitsList.sortUnitCustom': 'Özel',
  'unitsList.sortUnitNameAsc': 'Birim Adı A-Z',
  'unitsList.sortUnitNameDesc': 'Birim Adı Z-A',
  'unitsList.sortUnitIdAsc': 'Birim ID A-Z',
  'unitsList.sortUnitIdDesc': 'Birim ID Z-A',
  'unitsList.sortUnitMessage': '{unitName} birimi sıralandı: {sortStrategy}',
  'unitsList.search': 'Ara',
  'unitsList.moveLabel': 'Seçili Birimleri Taşı',
  'unitsList.deletionSuccess': 'Birim başarıyla silindi!',
  'unitsList.showHiddenUnit': 'Gizli birim veya dersleri göster',
  'unitsList.courseBadge': 'Ders',
  'unitsList.show': 'Göster',
  'unitsList.hide': 'Gizle',
  'unitsList.viewCourse': 'Dersi Görüntüle',
  'unitsList.viewUnit': 'Birimi Görüntüle',
  'unitsList.emptyUnit':
    'Organizasyonunuz için henüz bir birim/ders tanımlanmamıştır.',
  'unitsList.emptyUnitAdd':
    'Organizasyonunuz için henüz bir birim/ders tanımlanmamıştır. Birim veya ders eklemek için "Birim Ekle" düğmesine tıklayın.',
  'unitsList.noUnitSelected': 'Please select a unit or course.',
  'unitsList.noPeriodYet': 'Hay aksi! Henüz Dönem bulunmamaktadır.',
  'unitsList.noQAYet': 'Hay Aksi! Henüz Soru Özellikleri bulunmamaktadır.',
  'unitsList.noACYet':
    'Hay Aksi! Henüz Değerlendirme Kategorisi bulunmamaktadır.',
  'unitsList.tableTitle': 'BİRİM/DERS ADI',
  'unitsList.tableActions': 'İŞLEMLER',
  'unitsList.messageInformationAdd':
    'Birim/ders eklemek için {messageValue} düğmesine tıklayın.',
  'unitsList.unitorCourse': 'Birim veya Ders',
  'unitsList.addunitorCourse': 'Birim Ekle',
  'unitsList.assessmentCategory': 'Değerlendirme Kategorisi',
  'unitsList.addAssessmentCategory': 'Değerlendirme Kategorisi Ekle',
  'unitsList.questionAttribute': 'Soru Özellikleri',
  'unitsList.addQuestionAttribute': 'Soru Özelliği Ekle',
  'unitsList.period': 'Dönem',
  'unitsList.addPeriod': 'Dönem Ekle',
  'unitsList.titleInformation': 'Hay aksi! Henüz {titleValue} bulunmamaktadır.',
  'unitsList.messageInformation':
    'Organizasyonunuz için henüz bir birim/ders tanımlanmamıştır.',
  'unitsList.obj': 'Birim/Ders',
  'unitsList.addUnit': 'Birim Ekle',
  'unitsList.cancel': 'İptal',
  'unitsList.moveUnitModalTitle': 'Birim/Ders Taşıma',
  'unitsList.moveUnitModalSubtitle':
    'Seçili birimi taşımak istediğiniz birim/dersi aşağıdaki listeden seçiniz.',
  'unitList.selectedUnit': 'Seçili Birim/Ders',
  'unitList.targetUnit': 'Hedef Birim/Ders',
};
