import React, { createContext, useReducer } from 'react';
import { getSwitches, saveSwitches } from '../data/UserLocalDataSource';

let session = {
  user: null,
  userChoices: {
    showPassiveUnits: true,
    showPassivePeriods: false,
    showPassiveAssessmentCategories: true,
    showPassiveFields: true,
    showPassiveGroups: false,
    showPassiveRoles: true,
    showPassiveObjectives: true,
    showPassiveRubrics: true,
  },
};

export const MainContext = createContext(session);

export const sessionReducer = (state, newState) => {
  if (newState.userChoices) {
    saveSwitches(JSON.stringify(newState.userChoices));
  }
  return { ...state, ...newState };
};

function Store({ initialSession, children }) {
  session = JSON.parse(getSwitches())
    ? {
      ...session,
      ...initialSession,
      userChoices: JSON.parse(getSwitches()),
    }
    : {
      ...session,
      ...initialSession,
    };
  const [state, dispatch] = useReducer(sessionReducer, session);
  const value = { state, dispatch };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

Store.defaultProps = { children: null };

export default Store;
