export default {
  'import.title': 'Import',
  'import.subtitle': 'Import CSV file',
  'import.template': 'Template',
  'import.templateInfo': 'You can send your file using the template below.',
  'import.convert': 'Convert XLSX file to CSV',
  'import.convertInfo':
    'Follow the steps to convert your edited template to CSV',
  'import.reminding': 'Reminding',
  'import.remindingInfo':
    'Please ensure that you adhere to the following sheet structure and descriptions.',
  'import.download': 'Download',
  'import.browseFiles': 'Browse files',
  'import.file': 'File',
  'import.fileInfo':
    'Drag or select the OMR outputs of the paper-based exam performed.',
  'import.dragDrop': 'Drag & drop files here',
  'import.or': 'OR',
  'import.attachment': 'Attachment should not exceed 100 mb',
  'import.supported': 'Supported file type: CSV',
  'import.fileName': 'File name:',
  'import.creationDate': 'Creation date:',
  'import.steps':
    'Microsoft Office Excel > File > Save as > Save as type > CSV',
  'import.acceptedTemplateInfo':
    'Accepted template must macth the following headers and as a seperator a semicolon (;) should be used.',
  'import.validSample': 'A valid sample should look like',
  'import.bookletName': 'Booklet Name',
  'import.answers': 'Answers',
  'import.emptyAnswerInfo':
    "For answers, there might be an empty answer, in this case it must either be marked with 'X' or must be a space ' '. e.g",
  'import.uploadError': 'You can only upload .csv Files!',
  'import.discardChanges': 'Discard Changes',
  'import.deleteFileConfirmationText':
    'Are you sure you want to delete the file?',
  'import.delete': 'Yes, Delete',
  'import.cancel': 'Cancel',
};
