export default {
  'classicExam.next': 'Next',
  'classicExam.previous': 'Previous',
  'classicExam.score': 'score',
  'classicExam.questionRoot': 'Question Root',
  'classicExam.totalScore': 'Total Score',
  'classicExam.save': 'Save Changes',
  'classicExam.reset': 'Reset',
  'classicExam.notAttend': 'Did not attend',
  'classicExam.complete': 'Complete Assessment',
  'classicExam.question': 'Question {index}',
  'classicExam.name': 'Name',
  'classicExam.surname': 'Surname',
  'classicExam.email': 'E-mail',
  'classicExam.NOT_ATTENDED': 'Did not Attend',
  'classicExam.CANDIDATES': 'Candidates',
  'classicExam.QUESTIONS': 'Questions',
  'classicExam.cancel': 'Cancel',
  'classicExam.finish': 'Finish Exam',
  'classicExam.confirmMessage':
    "You must save any changes you have made to this candidate's assessment, otherwise your most recent changes will be lost.",
  'classicExam.discard': 'Discard changes',
  'classicExam.continueWithoutSave': 'Continue',
  'classicExam.wantToContinue': 'Are you sure you want to continue?',
  'classicExam.notAttendAlertInfo':
    "Are you sure you want to mark the candidate as “Did not attend”? If you continue, the candidate's score will be deleted. This action cannot be undone.",
  'classicExam.attendanceStatusError':
    'Please update the attendance status to perform the results.',
  'classicExam.saveSuccess': 'The results have been updated successfully.',
  'classicExam.notAttendedTooltip':
    'To assess the candidate, update the status of participation in the assessment from the “Candidates” view.',
  'classicExam.noPointsWarningToast':
    'Please enter a score to save the assessment.',
  'classicExam.finishExamContent':
    'Are you sure you want to complete the assessment? The assessed candidate or questions will be saved.',
};
