export default {
    "objectives.titleName": "Öğrenme Hedefleri",
    "objectives.title": "Öğrenme Hedefleri",
    "objectives.subtitle": "Ölçmek istediğiniz öğrenme hedeflerini yönetin",
    'objectives.emptySubtitle': 'Bu ders için ilk öğrenme hedefini oluşturun',
    "objectives.obj": "Öğrenme Hedefi",
    "objectives.status": "Durum",
    "objectives.actions": "İşlemler",
    "objectives.search": "Ara",
    "objectives.add": "Öğrenme Hedefi Ekle",
    "objectives.delete": "Sil",
    "objectives.edit": "Düzenle",
    "objectives.deleteSuccess": "Öğrenme hedefi başarıyla silindi!",
    "objectives.deleteConfirmationTitle": "Öğrenme hedefini sil",
    "objectives.deleteConfirmationMessage":
    "Öğrenme hedefini silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.",
    "objectives.deleteConfirmationConfirmButton": "Evet, hedefi sil",
    "objectives.questionCount": "Soru Sayısı",
    "objectives.showPassiveObjectives": "Pasif öğrenme hedeflerini göster",
    'objectives.titleInformation': 'Hay Aksi! Henüz {titleValue} bulunmamaktadır.',
    'objectives.messageInformation': 'Organizasyonunuzun bu dersinde henüz {titleValue} bulunmamaktadır.',
    'objectives.messageInformationAdd': 'Ölçmek istediğiniz öğrenme hedefini ekleyerek başlayın!',
};
