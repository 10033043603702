export default {
  'examRules.verification': 'Aday Doğrulama Kuralları',
  'examRules.proctoring': 'Sınav Gözetim Kuralları',
  'examRules.typeSettings': 'Değerlendirme Türü Ayarları',
  'examRules.categories': 'Değerlendirme Kategorileri',
  'examRules.categoriesInfo':
    'Organizasyonunuzun değerlendirme kategorileri yapısını tasarlayın',
  'examRules.deleteConfirmationTitle': 'Kategoriyi sil',
  'examRules.deleteConfirmationMessage':
    'Kategoriyi silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'examRules.deleteConfirmationConfirmButton': 'Evet, kategoriyi sil',
  'examRules.deleteConfirmationCancelButton': 'İptal',
  'examRules.search': 'Ara',
  'examRules.addCategory': 'Değerlendirme Kategorisi Ekle',
  'examRules.editCategory': 'Düzenle',
  'examRules.deleteCategory': 'Kategoriyi Sil',
  'examRules.status': 'durum',
  'examRules.actions': 'İşlemler',
  'examRules.popupTitle': 'Değerlendirme Kategorisi Ekle',
  'examRules.popupTitleEdit': '{param} Kategorisini Düzenle',
  'examRules.categoryName': 'Kategori Adı',
  'examRules.categoryNameEdit': 'Kategori Adı',
  'examRules.categoryNameMin': 'Kategori ismi en az 1 karakter olmalıdır.',
  'examRules.categoryNameMax': 'Kategori ismi en fazla 100 karakter olmalıdır.',
  'examRules.categoryDesc': 'Kategori Açıklaması',
  'examRules.categoryDescMax':
    'Kategori açıklaması en fazla 500 karakter olmalıdır.',
  'examRules.categoryRoot': 'İlişkili Ana Birim',
  'examRules.requiredMessage': 'Bu alan boş bırakılamaz.',
  'examRules.reset': 'Sıfırla',
  'examRules.saveChange': 'Değişiklikleri Kaydet',
  'examRules.select': 'Seçiniz',
  'examRules.switchStatus': 'Aktif / Pasif',
  'examRules.addSuccessAlert': 'Kategori başarıyla eklendi!',
  'examRules.editSuccessAlert': 'Kategori başarıyla düzenlendi!',
  'examRules.moveSuccessAlert': 'Kategori sırası başarıyla değiştirildi!',
  'examRules.addFailAlert': 'Lütfen ana birim seçiniz!',
  'examRules.deleteSuccessAlert': 'Kategori başarıyla silindi!',
  'examRules.allRootUnits': 'Tüm Ana Birimler',
  'examRules.showPassiveCategories': 'Pasif kategorileri göster',
  'examRules.titleInformation': 'Hay Aksi! Henüz {titleValue} bulunmamaktadır.',
  'examRules.messageInformation': 'Organizasyonunuzun bu dersinde henüz {titleValue} bulunmamaktadır.',
  'examRules.messageInformationAdd': 'Değerlendirme kategorisi eklemek için {messageValue} düğmesine tıklayın.',
  'examRules.obj': 'Değerlendirme Kategorisi',
  'examRules.addAssessmentCategory': '"Değerlendirme Kategorisi Ekle"',
};
