import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Sidebar from './components/menu/horizontal-menu/HorizontalMenu';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import themeConfig from '../configs/themeConfig';

class HorizontalLayout extends PureComponent {
  state = {
    width: window.innerWidth,
    sidebarState: false,
    layout: themeConfig.theme,
    collapsedContent: false,
    sidebarHidden: false,
    currentLang: 'en',
    appOverlay: false,
    currRoute: this.props.location.pathname,
    menuOpen: themeConfig.menuOpen,
  };

  mounted = false;

  updateWidth = () => {
    if (this.mounted) {
      this.setState((prevState) => ({
        width: window.innerWidth,
      }));
    }
  };

  updateScroll = () => {
    if (this.mounted) {
      this.setState({ scroll: window.pageYOffset });
    }
  };

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      if (window !== 'undefined') {
        window.addEventListener('resize', this.updateWidth, false);
        window.addEventListener('scroll', this.updateScroll, false);
      }
      if (this.props.location.pathname === '/pages/profile') {
        this.setState({
          sidebarState: true,
          collapsedContent: true,
        });
      }
      const layout = this.state.theme;
      return layout === 'dark'
        ? document.body.classList.add('dark-layout')
        : layout === 'semi-dark'
        ? document.body.classList.add('semi-dark-layout')
        : null;
    }
  }

  componentDidUpdate() {
    if (this.mounted) {
      if (this.state.currRoute !== this.props.location.pathname) {
        this.handleRouteChange();
        this.setState({
          currRoute: this.props.location.pathname,
        });
      }

      const layout = this.state.theme;
      if (layout === 'dark') {
        document.body.classList.remove('semi-dark-layout');
        document.body.classList.add('dark-layout');
      } else if (layout === 'semi-dark') {
        document.body.classList.remove('dark-layout');
        document.body.classList.add('semi-dark-layout');
      } else {
        return document.body.classList.remove(
          'dark-layout',
          'semi-dark-layout'
        );
      }
    }
  }

  handleRouteChange = () => {
    if (this.props.location.pathname === '/pages/profile') {
      this.setState({
        collapsedContent: true,
      });
    } else {
      this.setState({
        sidebarState: false,
        collapsedContent: false,
      });
    }
  };

  toggleSidebarMenu = () => {
    this.setState({
      sidebarState: !this.state.sidebarState,
      collapsedContent: !this.state.collapsedContent,
    });
  };

  sidebarMenuHover = () => {
    this.setState({
      sidebarState: !this.state.sidebarState,
    });
  };

  handleSidebarVisibility = () => {
    if (this.mounted) {
      if (window !== undefined) {
        window.addEventListener('resize', () => {
          if (this.state.sidebarHidden) {
            this.setState({
              sidebarHidden: !this.state.sidebarHidden,
            });
          }
        });
      }
      this.setState({
        sidebarHidden: !this.state.sidebarHidden,
      });
    }
  };

  handleCurrentLanguage = (lang) => {
    this.setState({
      currentLang: lang,
    });
  };

  handleAppOverlay = (value) => {
    if (value.length > 0) {
      this.setState({
        appOverlay: true,
      });
    } else if (value.length > 0 || value === '') {
      this.setState({
        appOverlay: false,
      });
    }
  };

  handleAppOverlayClick = () => {
    this.setState({
      appOverlay: false,
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const navbarTypeArr = ['sticky', 'static', 'sticky', 'floating', 'hidden'];
    const menuThemeArr = [
      'primary',
      'success',
      'danger',
      'info',
      'warning',
      'dark',
    ];
    return (
      <div
        className={classnames(
          `wrapper horizontal-layout theme-${themeConfig.menuTheme}`,
          {
            'menu-collapsed':
              this.state.collapsedContent === true && this.state.width > 1200,
            'fixed-footer': themeConfig.footerType === 'sticky',
            'navbar-static': themeConfig.navbarType === 'static',
            'navbar-sticky': themeConfig.navbarType === 'sticky',
            'navbar-floating':
              themeConfig.navbarType === 'floating' ||
              !navbarTypeArr.includes(themeConfig.navbarType),
            'navbar-hidden': themeConfig.navbarType === 'hidden',
            'theme-primary': !menuThemeArr.includes(themeConfig.menuTheme),
          }
        )}
      >
        <Sidebar
          toggleSidebarMenu={this.toggleSidebarMenu}
          sidebarState={this.state.sidebarState}
          sidebarHover={this.sidebarMenuHover}
          sidebarVisibility={this.handleSidebarVisibility}
          visibilityState={this.state.sidebarHidden}
          activePath={this.props.match.path}
          currentLang={this.state.currentLang}
          activeTheme={themeConfig.menuTheme}
          collapsed={this.state.collapsedContent}
          menuOpen={this.state.menuOpen}
          navbarType={themeConfig.navbarType}
        />
        <div
          className={classnames('app-content content', {
            'show-overlay': this.state.appOverlay === true,
          })}
          onClick={this.handleAppOverlayClick}
        >
          <Navbar
            horizontal
            scrolling={this.state.scroll > 50}
            toggleSidebarMenu={this.toggleSidebarMenu}
            sidebarState={this.state.sidebarState}
            sidebarVisibility={this.handleSidebarVisibility}
            currentLang={this.state.currentLang}
            changeCurrentLang={this.handleCurrentLanguage}
            handleAppOverlay={this.handleAppOverlay}
            appOverlayState={this.state.appOverlay}
            navbarColor={themeConfig.navbarColor}
            navbarType={themeConfig.navbarType}
          />
          <div className="content-wrapper">{this.props.children}</div>
        </div>

        <Footer
          footerType={themeConfig.footerType}
          hideScrollToTop={themeConfig.hideScrollToTop}
        />
        <div
          className="sidenav-overlay"
          onClick={this.handleSidebarVisibility}
        />
      </div>
    );
  }
}

export default HorizontalLayout;
