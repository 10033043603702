export default {
  'proctoringRules.header': 'Proctoring Rules',
  'proctoringRules.headerInfo':
    'Set organization-wide rules for online and onsite exams proctoring',
  'proctoringRules.options': 'Lockdown Options',
  'proctoringRules.FORCE_FULL_SCREEN': 'Full screen required',
  'proctoringRules.FORCE_FULL_SCREENInfo':
    "If you allow, it will be possible to force the candidate's screen to use full screen.",
  'proctoringRules.FORCE_PC': 'Accessible by computer only',
  'proctoringRules.FORCE_PCInfo':
    'If you allow, the candidate will only be able to log in with a computer.',
  'proctoringRules.ONLY_ONE_SCREEN': 'Screen extending disabled',
  'proctoringRules.ONLY_ONE_SCREENInfo':
    'If you allow , the candidate will not be able to make a screen extend.',
  'proctoringRules.NO_NEW_TABS': 'Only one tab',
  'proctoringRules.NO_NEW_TABSInfo':
    'If you allow, the candidate will not be able to open more than one tab.',
  'proctoringRules.CLOSE_OPEN_TABS': 'Close open tabs',
  'proctoringRules.CLOSE_OPEN_TABSInfo':
    "If you allow, the candidate's open tabs will be closed.",
  'proctoringRules.DISABLE_PRINTING': 'Unprintable',
  'proctoringRules.DISABLE_PRINTINGInfo':
    'If you allow, the candidate will not be able to print the exam.',
  'proctoringRules.DETECT_VM': 'Virtual machine are forbidden',
  'proctoringRules.DETECT_VMInfo':
    'If you allow, the candidate will not be able to use a virtual machine.',
  'proctoringRules.DETECT_PRINT_SCREEN': 'Screen capture disabled',
  'proctoringRules.DETECT_PRINT_SCREENInfo':
    'If you allow, the candidate will not be able to take a screen capture.',
  'proctoringRules.CLEAR_CACHE': 'Clear cache',
  'proctoringRules.CLEAR_CACHEInfo':
    "If you allow, the cache of the candidate's browser will be cleared.",
  'proctoringRules.PREVENT_RE_ENTRY': 'Re-login disabled',
  'proctoringRules.PREVENT_RE_ENTRYInfo':
    'If you allow, the candidate will not be able to re-login after exiting the exam.',
  'proctoringRules.DISABLE_CLIPBOARD': 'Cut, copy, paste disabled',
  'proctoringRules.DISABLE_CLIPBOARDInfo':
    'If you allow, the candidate will not be able to use the cut, copy, paste shortcuts.',
  'proctoringRules.DISABLE_RIGHT_CLICK': 'Right click disabled',
  'proctoringRules.DISABLE_RIGHT_CLICKInfo':
    'If you allow, the candidate will not be able to right click.',
  'proctoringRules.DETECT_MULTI_SCREEN': 'Multi screens are disabled',
  'proctoringRules.DETECT_MULTI_SCREENInfo':
    "If you allow, it will be possible to disable or detect the candidate's multiple screen in exams.",
  'proctoringRules.successMessage': 'Proctoring rules saved successfully!',
  'proctoringRules.BLOCK_MOBILE_APPS': 'Block mobile apps',
  'proctoringRules.BLOCK_MOBILE_APPSInfo': '-',
  'proctoringRules.DISABLE_DEVTOOLS': 'Developer tools are disabled',
  'proctoringRules.DISABLE_DEVTOOLSInfo':
    'If you allow, the candidate will not be able to use the developer tools.',
  'proctoringRules.RECORD_SCREEN': 'Screen sharing required',
  'proctoringRules.CANDIDATE_VERIFICATION':
    'Candidate face recognation required',
  'proctoringRules.RECORD_VIDEO': 'Video recording required',
  'proctoringRules.RECORD_WEB_TRAFFIC': 'Network traffic tracking required',
  'proctoringRules.CANDIDATE_VERIFICATIONInfo':
    "If you allow, the candidate's face recognation will be possible.",
  'proctoringRules.RECORD_VIDEOInfo':
    'If you allow, it will be possible to video recording the candidate.',
  'proctoringRules.RECORD_WEB_TRAFFICInfo':
    "If you allow, it will be possible to tracking the candidate's network traffic.",
  'proctoringRules.RECORD_SCREENInfo':
    'If you allow, it will be possible to force the candidate to screen sharing.',
  'proctoringRules.needExtension': 'Extension required',
  'proctoringRules.DISABLE_PRINTINGExtInfo': '-',
  'proctoringRules.DISABLE_DEVTOOLSExtInfo': '-',
  'proctoringRules.DETECT_PRINT_SCREENExtInfo': '-',
  'proctoringRules.DETECT_VMExtInfo': '-',
  'proctoringRules.RECORD_WEB_TRAFFICExtInfo': '-',
  'proctoringRules.NO_NEW_TABSExtInfo': '-',
  'proctoringRules.DETECT_MULTI_SCREENExtInfo': '-',
  'proctoringRules.RECORD_REVIEW-tooltip':
    'In this type of proctoring, video and screen recording of the candidate is taken during the exam. In this way, the examiner can complete the entire exam security assessment in a very short time by watching only the marked sections of certain candidates, instead of watching the video recordings of many candidates that can take hours. This proctoring type currently covers all the features of the Lockdown & Review proctoring type. Recommended for exams of high importance.',
  'proctoringRules.LOCKDOWN_REVIEW-tooltip':
    'In this type of proctoring, the device that the candidate connects to the exam is subject to various restrictions such as full-screen forcing and not being able to print. Some types of violations that cannot be restricted, such as turning off screen sharing, are also reported for this type of proctoring. This proctoring type produces a risk score for the candidates after the exam, allowing the examiner to evaluate the violations committed by the designated candidates on the window and device. All features of this type are also available for the Record & Review proctoring type. Recommended for exams of low to medium importance.',
  'proctoringRules.UNPROCTORED-tooltip':
    'It is a type of proctoring in which no supervision or restriction is made during the exam. A report is not produced after the exam. It can be used in low-importance exams or quizzes.',
  'proctoringRules.videoInstruction-tooltip':
    'In this type of proctoring, video and screen recording of the candidate is taken during the exam. These recordings are processed with artificial intelligence algorithms after the exam, and suspicious behavior, people and objects are presented to the examiner in the form of a marked video. In this way, the examiner can complete the entire exam security assessment in a very short time by watching only the marked sections of certain candidates, instead of watching the video recordings of many candidates that can take hours. This proctoring type currently covers all the features of the Lockdown & Review surveillance type. Recommended for exams of high importance.',
  'proctoringRules.totalViolationChart-tooltip':
    "Chart of the candidate's total violation time during the exam",
  'proctoringRules.classificationRange': 'Classification Range',
  'proctoringRules.noRisk-tooltip-RECORD_REVIEW':
    'No-risk (0 - 4 / Window + Device Violation)',
  'proctoringRules.lowRisk-tooltip-RECORD_REVIEW':
    'Low-risk (5 - 9 / Window + Device Violation)',
  'proctoringRules.midRisk-tooltip-RECORD_REVIEW':
    'Mid-risk (10 - 14 / Window + Device Violation)',
  'proctoringRules.highRisk-tooltip-RECORD_REVIEW': `High-risk (15 and upper / Window + Device + Behavioral Violation) + Face ID mismatched`,

  'proctoringRules.noRisk-tooltip-LOCKDOWN_REVIEW':
    'No-risk (0 - 2 / Window + Device Violation)',
  'proctoringRules.lowRisk-tooltip-LOCKDOWN_REVIEW':
    'Low-risk (3 - 6 / Window + Device Violation)',
  'proctoringRules.midRisk-tooltip-LOCKDOWN_REVIEW':
    'Mid-risk (7 - 10 / Window + Device Violation)',
  'proctoringRules.highRisk-tooltip-LOCKDOWN_REVIEW': `High-risk (11 and upper / Window + Device + Behavioral Violation) + Face ID mismatched`,
};
