export default {
  'invite.password': 'Password',
  'invite.confirmPassword': 'Confirm Password',
  'invite.requiredError': 'This field cannot be blank.',
  'invite.invitationTitle': 'Invitation',
  'invite.invitationSubtitle': 'Sign up Examod',
  'invite.personalTitle': 'Personal',
  'invite.personalSubtitle': 'Account Information',
  'invite.exploreTitle': 'Explore',
  'invite.exploreSubtitle': 'Explore Examod',
  'invite.userGreetings':
    "<b>Dear,</b> <red>{userMail}</red>{br}<h1>You have been invited to</h1><red>{organizationName}</red> <b>organization's Examod: Digital Assessment Software</b>",
  'invite.signUp': 'Sign up Examod',
  'invite.haveAccount': 'Already have an account? <b>Log in</b>',
  'invite.accountInformation':
    "Complete your account information to join your <b>{organizationName}</b> organization's Examod software.",
  'invite.email': 'E-mail',
  'invite.userType': 'User Type',
  'invite.firstName': 'First Name',
  'invite.lastName': 'Last Name',
  'invite.createAccount': 'Create Account',
  'invite.minLengthError': 'Password should be at least 6 characters',
  'invite.patternError':
    'Need at least one uppercase letter, one lowercase letter, one number and one special character',
  'invite.validateError': 'Passwords must match',
  'invite.welcomeUser':
    "<b>Dear,</b> <red>{userName}</red>{br}<h1>Thanks for signing up!</h1<red>Welcome to  {organizationName} organization's software!</red>",
  'invite.goDashboard': 'Go to Your Dashboard',
  'invite.discard': 'Discard Changes',
  'invite.discardInfo':
    'Are you sure, you want to leave registration process? If you cancel the registration process, all your information will be deleted.',
  'invite.cancel': 'Cancel',
  'invite.continue': 'Continue',
  'invite.fewClickAway': 'You are a few clicks away from joining Examod',
  'invite.oneClickAway':
    'Successful! You are now one click away from exploring Examod',
  'invite.invalidTokenError': 'Invalid link',
  'invite.tokenExpiredError': 'The link has expired',
};
