export default {
  // Generic
  'generic.add': 'Toevoegen',
  'generic.cancel': 'Annuleren',

  // BlockType
  'components.controls.blocktype.h1': 'H1',
  'components.controls.blocktype.h2': 'H2',
  'components.controls.blocktype.h3': 'H3',
  'components.controls.blocktype.h4': 'H4',
  'components.controls.blocktype.h5': 'H5',
  'components.controls.blocktype.h6': 'H6',
  'components.controls.blocktype.blockquote': 'Blockquote',
  'components.controls.blocktype.code': 'Code',
  'components.controls.blocktype.blocktype': 'Blocktype',
  'components.controls.blocktype.normal': 'Normaal',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Kleurkiezer',
  'components.controls.colorpicker.text': 'Tekst',
  'components.controls.colorpicker.background': 'Achtergrond',

  // Embedded
  'components.controls.embedded.embedded': 'Ingevoegd',
  'components.controls.embedded.embeddedlink': 'Ingevoegde link',
  'components.controls.embedded.enterlink': 'Voeg link toe',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Lettertype',

  // FontSize
  'components.controls.fontsize.fontsize': 'Lettergrootte',

  // History
  'components.controls.history.history': 'Geschiedenis',
  'components.controls.history.undo': 'Ongedaan maken',
  'components.controls.history.redo': 'Opnieuw',

  // Image
  'components.controls.image.image': 'Afbeelding',
  'components.controls.image.fileUpload': 'Bestand uploaden',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText':
    'Drop het bestand hier of klik om te uploaden',

  // Inline
  'components.controls.inline.bold': 'Dikgedrukt',
  'components.controls.inline.italic': 'Schuingedrukt',
  'components.controls.inline.underline': 'Onderstrepen',
  'components.controls.inline.strikethrough': 'Doorstrepen',
  'components.controls.inline.monospace': 'Monospace',
  'components.controls.inline.superscript': 'Superscript',
  'components.controls.inline.subscript': 'Subscript',

  // Link
  'components.controls.link.linkTitle': 'Linktitel',
  'components.controls.link.linkTarget': 'Link bestemming',
  'components.controls.link.linkTargetOption': 'Open link in een nieuw venster',
  'components.controls.link.link': 'Link',
  'components.controls.link.unlink': 'Unlink',

  // List
  'components.controls.list.list': 'Lijst',
  'components.controls.list.unordered': 'Ongeordend',
  'components.controls.list.ordered': 'Geordend',
  'components.controls.list.indent': 'Inspringen',
  'components.controls.list.outdent': 'Inspringen verkleinen',

  // Remove
  'components.controls.remove.remove': 'Verwijderen',

  // TextAlign
  'components.controls.textalign.textalign': 'Tekst uitlijnen',
  'components.controls.textalign.left': 'Links',
  'components.controls.textalign.center': 'Gecentreerd',
  'components.controls.textalign.right': 'Rechts',
  'components.controls.textalign.justify': 'Uitgelijnd',

  // New Examod localizations
  'components.controls.image.modal.title': 'Image Uploader',
  'components.controls.image.modal.description':
    'Browse or drag the image you want to upload',
  'components.controls.image.modal.content.image.or': 'or',
  'components.controls.image.modal.content.image.btn': 'Browse Files',
  'components.controls.image.modal.content.image.attachment':
    'Attachments should not exceed 2 mb',
  'components.controls.image.modal.content.image.supported':
    'Supported file types: JPEG, JPG, PNG',
  'components.controls.image.modal.content.input.alt.label': 'Alt Text',
  'components.controls.image.modal.content.input.alt.placeholder':
    'Image alt text',
  'components.controls.image.modal.content.input.scale.label': 'Scale (%)',
  'components.controls.image.modal.contnet.url.address.label': '*URL (Adress)',
  'components.controls.image.modal.link.title': 'URL',
  'components.controls.image.modal.link.description': 'Add image from address',

  // New Highlight Colors 'Clear Highlight'
  'components.controls.color.picker.highlight.clear': 'Clear Highlight',
  'components.controls.color.picker.highlight.black': 'Black Highlight',
  'components.controls.color.picker.highlight.grey': 'Grey Highlight',
  'components.controls.color.picker.highlight.red': 'Red Highlight',
  'components.controls.color.picker.highlight.green': 'Green Highlight',
  'components.controls.color.picker.highlight.blue': 'Blue Highlight',
  'components.controls.color.picker.highlight.yellow': 'Yellow Highlight',
  'components.controls.color.picker.highlight.examod': 'Examod Highlight',
  'components.controls.color.picker.highlight.purple': 'Purple Highlight',
  'components.controls.color.picker.highlight.pink': 'Pink Highlight',
};
