export default {
  'userDetailsEdit.header': 'Kullanıcıyı Düzenle',
  'userDetailsEdit.title': 'Kullanıcıyı Düzenle',
  'userDetailsEdit.change': 'Değiştir',
  'userDetailsEdit.remove': 'Kaldır',
  'userDetailsEdit.username': 'Kullanıcı Adı',
  'userDetailsEdit.name': 'Ad',
  'userDetailsEdit.surname': 'Soyad',
  'userDetailsEdit.email': 'E-posta',
  'userDetailsEdit.status': 'Durum',
  'userDetailsEdit.group': 'Kullanıcı Grupları',
  'userDetailsEdit.delete': 'Sil',
  'userDetailsEdit.saveChanges': 'Değişiklikleri Kaydet',
  'userDetailsEdit.hintGroup': 'Grup Seçiniz',
  'userDetailsEdit.resetPassword': 'Şifreyi Sıfırla',
  'userDetailsEdit.sendInvitation': 'Onaylama Davetini Tekrar Gönder',
  'userDetailsEdit.usernameMessage': 'Kullanıcı adı boşluk içermemelidir.',
  'userDetailsEdit.usernameMinMessage':
    'Kullanıcı adı en az 1 karakter olmalıdır.',
  'userDetailsEdit.usernameMaxMessage':
    'Kullanıcı adı en fazla 70 karakter olmalıdır.',
  'userDetailsEdit.maxMessageName': 'Ad en fazla 70 karakter olmalıdır.',
  'userDetailsEdit.maxMessageSurname': 'Soyad en fazla 70 karakter olmalıdır.',
  'userDetailsEdit.firstNameHint': 'İsim buraya..',
  'userDetailsEdit.lastNameHint': 'Soyisim buraya..',
  'userDetailsEdit.edit': 'Kullanıcıyı Düzenle',
  'userDetailsEdit.firstName': 'İsim',
  'userDetailsEdit.lastName': 'Soyisim',
  'userDetailsEdit.type': 'Kullanıcı Tipi',
  'userDetailsEdit.select': 'Seçiniz',
  'userDetailsEdit.selectSystemRole': 'Rol Seçiniz',
  'userDetailsEdit.role': 'Kullanıcı Rolü',
  'userDetailsEdit.maxMessageUserName':
    'Kullanıcı adı en fazla 70 karakter olmalıdır.',
  'userDetailsEdit.maxMessageEmail': 'Eposta en fazla 70 karakter olmalıdır.',
  'userDetailsEdit.maxMessageFirstName': 'İsim en fazla 70 karakter olmalıdır.',
  'userDetailsEdit.maxMessageLastName':
    'Soyisim en fazla 70 karakter olmalıdır.',
  'userDetailsEdit.systemId': 'Sistem ID',
  'userDetailsEdit.maxMessageSystemId':
    'Sistem ID en fazla 20 karakter olmalıdır.',
  'userDetailsEdit.minMessageSystemId': 'Sistem Id en az 6 karakter olmalıdır.',
};
