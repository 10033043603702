export default {
  'questions.addNewTopic': 'Yeni Konu Ekle',
  'questions.topicNameHint': 'Yeni konu giriniz...',
  'questions.chooseUnit': 'Birim Seçiniz',
  'questions.addQuestion': 'Soru Oluştur',
  'questions.selectType': 'SORU TİPİ SEÇİNİZ',
  'questions.selectTopic': 'KONU SEÇİNİZ',
  'questions.select': 'Seçiniz',
  'questions.search': 'Soru kökü veya soru kimliği (#) ile arayın.',
  'questions.unitsCourses': 'BİRİMLER / DERSLER',
  'questions.topics': 'KONULAR',
  'questions.learningObj': 'ÖĞRENME HEDEFLERİ',
  'questions.allUnits': 'Tüm Birimler',
  'questions.allTopics': 'Tüm Konular',
  'questions.allObjs': 'Tüm Hedefler',
  'questions.filterStatus': 'Durum seçin',
  'questions.filterType': 'Soru Türü Seçin',
  'questions.filterObjective': 'Öğrenme Hedefi Seçin',
  'questions.filterTopic': 'Konu Seçin',
  'questions.filterKeyword': 'Anahtar Kelime Seçin',
  'questions.deleteSuccess': 'Sorular başarıyla silindi!',
  'questions.moveSuccess': 'Sorular başarıyla taşındı!',
  'questions.noSelected': 'Seçilmiş soru bulunmamaktadır',
  'questions.noSelectedTopic': 'Seçilmiş konu bulunmamaktadır',
  'questions.preview': 'Önizle',
  'questions.statistics': 'İstatistikler',
  'questions.exams': 'Sınavlar',
  'questions.activities': 'Etkinlikler',
  'questions.selectAll': 'Tümünü Seç',
  'questions.generalFilters': 'Genel Filtreler',
  'questions.questionAttributeFilters': 'Soru Özellik Filtreleri',
  'questions.questionStatus': 'Durumu',
  'questions.questionType': 'Tipi',
  'questions.Deneme': 'Deneme',
  'questions.Zorluk': 'Zorluk',
  'questions.keywords': 'Anahtar Kelimeler',
  'questions.move': 'Soruyu Taşı',
  'questions.deleteConfirmationTitle': 'Soruları sil',
  'questions.deleteSingleTitle': 'Soruyu sil',
  'questions.deleteConfirmationMessage':
    'Soruları silmek istediğinizden emin misiniz? Bu işlem geri alınamayacak.',
  'questions.deleteSingleConfirmation':
    'Bu soruyu silmek istediğinize emin misiniz? Devam ederseniz, bu işlem geri alınamaz.',
  'questions.deleteGroupConfirmationMessage':
    'Gruplandırılmış bir soruyu silmek istediğinize emin misiniz? Devam ederseniz, gruplandırılmış soru içeriğindeki diğer sorular da silinecektir.',
  'questions.deleteConfirmationConfirmButton': 'Evet, soruları sil',
  'questions.deleteSingleConfirm': 'Evet, soruyu sil',
  'questions.noQuestions': 'Soru bulunmamaktadır.',
  'questions.selectedInfo': 'Değerlendirmedeki Toplam Soru',
  'questions.mcq': 'Çoktan Seçmeli Soru',
  'questions.openEndedQuestion': 'Açık Uçlu Soru',
  'questions.groupedQuestion': 'Gruplu Soru',
  'questions.selectedFilters': 'Seçili Filtreler',
  'questions.moveWarn': 'Kilitli sorular farklı bir konuya taşınamaz.',
  'questions.moveGroupedWarn': 'Gruplu sorular farklı bir konuya taşınamaz.',
  'questions.affinityType': 'Yakınlık Türü',
  'questions.SINGLE': 'Tekli',
  'questions.GROUPED': 'Gruplu',
  'questions.DRAFT': 'Taslak',
  'questions.APPROVED': 'Onaylı',
  'questions.DEPRECATED': 'Kullanımdan Kaldırılmış',
  'questions.MULTIPLE_CHOICE': 'Çoktan Seçmeli',
  'questions.LONG_ANSWER': 'Açık Uçlu',
  'questions.TRUE_FALSE': 'Doğru / Yanlış',
  'questions.deprecatedQuestionWarn':
    'Bu soru kullanımdan kaldırılmıştır. Soru listesine dahil etmek için, soru filtre seçeneklerinde yer alan "Soru Durumu" başlığından kullanımdan kaldırılan soruları listeleyerek "Yeniden Kullan" işlemini gerçekleştirebilirsiniz.',
  'questions.confirmContinue': 'Devam etmek istediğinize emin misiniz?',
  'questions.DEPRECATE_SINGLE_Confirmation':
    'Bu soruyu kullanımdan kaldırmak istediğinize emin misiniz?',
  'questions.DEPRECATE_GROUPED_Confirmation':
    'Bu soruyu kullanımdan kaldırmak istediğinize emin misiniz? Devam ederseniz, gruplandırılmış soru içerisindeki diğer tüm sorular da kullanımdan kaldırılacaktır.',
  'questions.REUSE_SINGLE_Confirmation':
    'Bu soruyu yeniden kullanmak istediğinize emin misiniz?',
  'questions.REUSE_GROUPED_Confirmation':
    'Bu soruyu yeniden kullanmak istediğinize emin misiniz? Devam ederseniz, gruplandırılmış soru içerisindeki diğer tüm sorular da yeniden kullanılabilecektir.',
  'questions.cancel': 'İptal',
  'questions.continue': 'Devam',
  'question.selectCourseForTopic':
    'Konuya göre filtreleme yapmak için bir ders seçmelisiniz.',
  'question.selectCourseForLO':
    'Öğrenme hedefine göre filtreleme yapmak için bir ders seçmelisiniz.',
  'questions.approvalDate': 'Onay Tarihi',
  'questions.nearestUsageDate': 'En Yakın Kullanım Tarihi',
  'questions.oneMonth': '1 ay',
  'questions.sixMonth': '6 ay',
  'questions.oneYear': '1 yıl',
  'questions.twoYear': '2 yıl',
  'questions.all': 'Tümü',
  'questions.neverUsed': 'Hiç Kullanılmamış',
  'questions.last1Year': 'Son 1 yıl içinde kullanılmış',
  'questions.between1And2Years': '1 yıl - 2 yıl arası kullanılmış',
  'questions.between2And3Years': '2 yıl - 3 yıl arası kullanılmış',
  'questions.before3Years': '3 yıldan önce kullanılmış',
  'questions.owner': 'Sahibi',
  'questions.titleInformation': 'Hay Aksi! Henüz {titleValue} bulunmamaktadır.',
  'questions.messageInformation':
    'Organizasyonunuzun bu dersinde henüz {titleValue} bulunmamaktadır.',
  'questions.messageInformationAdd':
    "Examod'da bulunan {titleValue} türleri ile başlayınız!",
  'questions.obj': 'Soru',
  'questions.title': 'Soru Listesi',
  'questions.info': 'Bu ders için ilk soruyu ekleyin',
  'questions.noResultTitle': 'Hay Aksi! Sonuç Bulunamadı',
  'questions.noResultSubTitle': 'Üzgünüz. Arama sonucunuz bulunamadı.',
  'questions.ResetSearch': 'Aramayı Sıfırla',
  'questions.showOnlyMyQuestions': 'Yalnızca kendi sorularımı göster.',
};
