export default {
  'topicPopup.title': '{param} Konu Ayarları',
  'topicPopup.nameHint': 'Konu ismi giriniz...',
  'topicPopup.subTitle': 'KONU İSMİ',
  'topicPopup.subTitleActions': 'İŞLEMLER',
  'topicPopup.moveButton': 'Taşı',
  'topicPopup.editButton': 'Düzenle',
  'topicPopup.deleteButton': 'Sil',
  'topicPopup.foundAlert': 'Herhangi bir konu bulunamadı',
  'topicPopup.discardConfirmationTitle': 'Değişiklikleri göz ardı et',
  'topicPopup.discardConfirmationMessage':
    'Yaptığınız değişiklikler kaydedilmeyecektir. Tüm değişiklikleri silmek istediğinize emin misiniz?',
  'topicPopup.discardConfirmationConfirmButton': 'Sil',
  'topicPopup.discardConfirmationCancelButton': 'İptal',
  'topicPopup.minMessage': 'Konu en az 5 karakter olmalıdır.',
  'topicPopup.maxMessage': 'Konu en fazla 50 karakter olmalıdır.',
  'topicPopup.requiredMessage': 'Bu alan boş bırakılamaz.',
  'topicPopup.deleteAlert': 'Öğe başarıyla silindi.',
  'topicPopup.updateAlert': 'Öğe başarıyla güncellendi.',
  'topicPopup.moveAlert': 'Öğeler başarıyla taşındı.',
  'topicPopup.reset': 'Sıfırla',
  'topicPopup.drag': 'sürükle',
  'topicPopup.save': 'Kaydet',
};
