export default {
  'unitDelete.title': 'Delete unit',
  'unitDelete.message':
    'Are you sure to delete the unit? This operation cannot be reversed.',
  'unitDelete.confirm': 'Yes, delete unit',
  'unitDelete.cancel': 'Cancel',
  'unitDelete.ok': 'Ok',
  'unitDelete.move': 'Move',
  'unitDelete.hide': 'Hide',
  'unitDelete.unitCantDeleted': "Unit Couldn't Be Deleted",
  'unitDelete.unitCantDeletedInfo':
    'Within the selected unit there are units with a question bank available for assessment. If you want to delete this unit, please move its subunits to another unit.',
};
